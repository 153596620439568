import { Document, IDocument, IPage, Page } from "../../../core/domain/models/esign/Document";
import {
    CompanyControl, CustomTextControl, DateControl,
    IControl, ICustomData, InitialControl, ISignatureData,
    ITextData, SignatureControl, TitleControl, TextData,
    ControlRole, IControlRole, ISignerInfo, SignerInfo
} from "../../domain/models/esign/IControls";
import { SignatureControlRole } from "../../domain/models/IGroup";
import { Guid } from "../../utilities/Guid";
import { DocumentSignatureDataViewModel, ISignatureControlModel, ISignaturePageData, SignatureControlModel, SignaturePageData } from "../../domain/viewModels/IDocumentSignatureDataViewModel";
import { ISignerControlDataModal, SignerControlDataModal } from "../../domain/viewModels/ISignerControlDataModal";


export interface IDocumentAdapter {
    convertToClientModel(documentModel: DocumentSignatureDataViewModel[]): IDocument[];
	convertToClientModelWithDisable(documentModel: DocumentSignatureDataViewModel[]): IDocument[];
    convertToServerModel(document: IDocument[]): ISignerControlDataModal;
}

const signatureTopAdjustment: number = 5;

export class DocumentAdapter implements IDocumentAdapter {

    public static create(): DocumentAdapter {
        return new DocumentAdapter();
    }

    public convertToClientModel(documentModel: DocumentSignatureDataViewModel[]): IDocument[] {

        let _self = this;
        let documents: IDocument[] = [];


        documentModel.forEach(function (document: DocumentSignatureDataViewModel) {

            let tmpDocument: IDocument = Document.create(document.id,
                document.name,
                document.fileLink,
                document.disabled,
                document.documentType, []);

            document.documentControls.forEach(function (pageItem: ISignaturePageData) {

                let tmpControls: IControl[] = [];

				pageItem.signingControls.forEach(function (controlItem: ISignatureControlModel) {

                    tmpControls.push(_self.convertToClientControlModel(controlItem));

                });

                tmpDocument.pages.push(Page.create(pageItem.pageNo, pageItem.formName, pageItem.bookmark, tmpControls));

            });

            documents.push(tmpDocument);

        });

        return documents;

	}

	public convertToClientModelWithDisable(documentModel: DocumentSignatureDataViewModel[]): IDocument[] {

		let _self = this;
		let documents: IDocument[] = [];


		documentModel.forEach(function (document: DocumentSignatureDataViewModel) {

			let tmpDocument: IDocument = Document.create(document.id,
				document.name,
				document.fileLink,
				document.disabled,
				document.documentType, []);

			document.documentControls.forEach(function (pageItem: ISignaturePageData) {

				let tmpControls: IControl[] = [];

				pageItem.signingControls.forEach(function (controlItem: ISignatureControlModel) {

					tmpControls.push(_self.convertToClientControlModelWithDisable(controlItem));

				});

				tmpDocument.pages.push(Page.create(pageItem.pageNo, pageItem.formName, pageItem.bookmark, tmpControls));

			});

			documents.push(tmpDocument);

		});

		return documents;

	}

    public convertToServerModel(documents: IDocument[]): ISignerControlDataModal {

        let _self = this;
        let signerControlDataModal: ISignerControlDataModal = SignerControlDataModal.create("", "", []);

        let documentsModel: DocumentSignatureDataViewModel[] = [];

        documents.forEach(function (document: IDocument) {

            let tmpDocument: DocumentSignatureDataViewModel = DocumentSignatureDataViewModel.create(document.id, document.name, document.url, document.disabled, document.documentType, []);

            document.pages.forEach(function (page: IPage) {

                let tmpControls: ISignatureControlModel[] = [];

                page.controls.forEach(function (control: IControl) {
                    tmpControls.push(_self.convertToServerControlModel(control, signerControlDataModal));

                });

                tmpDocument.documentControls.push(SignaturePageData.create(page.page, page.formName, page.bookmark, tmpControls));

            });

            documentsModel.push(tmpDocument);

        });

        signerControlDataModal.documentData = documentsModel;

        return signerControlDataModal;
    }



    private convertToServerControlModel(control: IControl, signerControlDataModal: ISignerControlDataModal): ISignatureControlModel {

        let signatureControlModel: ISignatureControlModel;

        const signerInfo: ICustomData | undefined = control.customData.find(x => (x instanceof SignerInfo) == true);
        const controlRole: ICustomData | undefined = control.customData.find(x => (x instanceof ControlRole) == true);

        const signer: string = (signerInfo as ISignerInfo).email;
        const role: SignatureControlRole = (controlRole as IControlRole).role;

        if (control instanceof SignatureControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                1,
				control.top + signatureTopAdjustment, // to fix small change in signature position bug - #42324
                control.left,
                role,
                control.tooltip,
                control.required,
				null
				//{ value: control.data && (control.data as ISignatureData).signature }
			);

            signerControlDataModal.signature = (control.data as ISignatureData).signature;

        }
        else if (control instanceof InitialControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                2,
                control.top,
                control.left,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text });

        }
        else if (control instanceof DateControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                3,
                control.top,
                control.left,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text });

        }
        else if (control instanceof TitleControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                4,
                control.top,
                control.left,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text });

        }
        else if (control instanceof CompanyControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                5,
                control.top,
                control.left,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text });

        }
        else if (control instanceof CustomTextControl) {

            signatureControlModel = SignatureControlModel.create(signer,
                6,
                control.top,
                control.left,
                role,
                control.tooltip,
                control.required,
                { value: control.data && (control.data as ITextData).text });
        }
        else {
            throw new Error("Method not implemented.");
        }

        return signatureControlModel;

    }


    private convertToClientControlModel(control: ISignatureControlModel): IControl {

        let model: IControl;
        let customData: ICustomData[] = [];

        customData.push(SignerInfo.create(control.signer));
        customData.push(ControlRole.create(control.role));

        if (control.required === undefined || control.required === null) {
            control.required = true;
        }

        switch (control.type) {

            case 1: // signature

                model = SignatureControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, customData, undefined);

                break;

            case 2: // Initial                

                model = InitialControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined);

                break;

            case 3: // Date

                model = DateControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, customData, TextData.create(this.getCurrentDate()));

                break

            case 4: // title

                model = TitleControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined);

                break;


            case 11: // company

                model = CompanyControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined);

                break;

            case 10: // Text

                model = CustomTextControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined);

                break;

            default:

                throw new Error("Method not implemented.");

        }

        return model;

	}

	private convertToClientControlModelWithDisable(control: ISignatureControlModel): IControl {

		let model: IControl;
		let customData: ICustomData[] = [];

		customData.push(SignerInfo.create(control.signer));
		customData.push(ControlRole.create(control.role));
        // Making the control optional in disable mode
		//if (control.required === undefined || control.required === null) {
			control.required = false;
		//}

		switch (control.type) {

			case 1: // signature

				model = SignatureControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, customData, undefined,true);

				break;

			case 2: // Initial                

				model = InitialControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined, true);

				break;

			case 3: // Date

				model = DateControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, customData, TextData.create(this.getCurrentDate()), true);

				break

			case 4: // title

				model = TitleControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined, true);

				break;


			case 11: // company

				model = CompanyControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined, true);

				break;

			case 10: // Text

				model = CustomTextControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined, true);

				break;

			default:

				throw new Error("Method not implemented.");

		}

		return model;

	}


    private getCurrentDate = (): string => {

        var today = new Date();
        var dd = today.getDate().toString();

        var mm = (today.getMonth() + 1).toString();
        var yyyy = today.getFullYear();
        if (Number(dd) < 10) {
            dd = '0' + dd;
        }

        if (Number(mm) < 10) {
            mm = '0' + mm;
        }

        return mm + '/' + dd + '/' + yyyy;
    }
}