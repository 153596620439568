import * as React from "react";
import { Form } from "react-bootstrap";
import { IChoiceModel } from "../../../core/domain/models/Organizer/CustomQuestionModel";
import { FormPreviewConstants } from "../../Common/Constants";
import { IHandleChoiceIdentifierPayload } from "../../../store/CqPreview/ActionTypes";
import { IsClientViewFromCache, IsPreviewModeFromCache } from "../../Helper/HelperFunction";
import { ClientType } from "../../../core/common/Enums";
import { ColorCode } from "../../../core/common/BrandColors";

interface MultiChoiceQuestionProps {
    choices: IChoiceModel[];
    isRequired: boolean;
    isInvalid?: boolean;
    subQuestionId?: number;
    sectionIndex: number;
    sectionItemIndex: number;
    isEditMode?: boolean;
    clientType?: ClientType;
    isFollowUpQuestion?: boolean;
    handleMcqOnChange: (payload: IHandleChoiceIdentifierPayload) => void;
}

const MultiChoiceQuestion: React.FC<MultiChoiceQuestionProps> = ({
    choices,
    subQuestionId,
    isRequired,
    sectionIndex,
    sectionItemIndex,
    clientType,
    isInvalid,
    isFollowUpQuestion,
    handleMcqOnChange
}) => {
    const [isInvalidState, setIsInvalidState] = React.useState(isInvalid);
    React.useEffect(() => {
        setIsInvalidState(isInvalid);
    }, [isInvalid]);

    const isEditDisabled: boolean = IsClientViewFromCache() || IsPreviewModeFromCache();

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, choice: IChoiceModel) => {
        if (choice.isSelected) {
            e.preventDefault();
            return;
        }
        setIsInvalidState(false);
    };
    const onOptionClick = (e: React.MouseEvent<HTMLInputElement>, choice: IChoiceModel) => {
        if (choice.isSelected) {
            e.preventDefault();
            return;
        }
        let choiceId: number = choice.id;
        const payload: IHandleChoiceIdentifierPayload = {
            choiceId,
            sectionIndex,
            sectionItemIndex,
            isFollowUpQuestion,
            subQuestionId
        };
        setIsInvalidState(true);
        handleMcqOnChange(payload);

    }
    const getColor = (clientType: any, choice: IChoiceModel) => {
        let color: string = ColorCode.DEFAULT;
        if (choice.isSelected) {
            if (clientType === ClientType.Spouse) {
                color = ColorCode.SPOUSE_COLOR;
            } else if (clientType === ClientType.Taxpayer) {
                color = ColorCode.TAXPAYER_COLOR;
            }
        }
        return color;
    };
    const sortedChoices = () => {
        return [...choices].sort((a, b) => a.id - b.id);
    }

    return (
        <div className={`multi-choice-preview-wrapper answer-wrapper ${sectionItemIndex >= 99 ? "padding-24" : sectionItemIndex >= 9 ? "padding-12" : ""} ${isFollowUpQuestion ? "follow-up-answer" : ""}`}>
            <div className={isEditDisabled ? "non-editable" : ""}>
                {sortedChoices()?.length > 0 &&
                    sortedChoices().map((choice, choiceIndex) => (
                        <Form.Check
                            id={`s-${sectionIndex}_q-${sectionItemIndex}`}
                            checked={choice.isSelected}
                            title={choice.text}
                            label={choice.text}
                            type="radio"
                            key={choiceIndex + choice.id}
                            onChange={(e) => onChangeHandler(e, choice)}
                            onClick={(e) => onOptionClick(e, choice)}
                            style={{ color: getColor(clientType, choice) }}
                            className={`height-unset ${isInvalidState && isRequired ? "error-radio" :
                                clientType ? clientType === ClientType.Taxpayer ? "taxpayer-radio" : "spouse-radio" : ""}`}
                        />
                    ))
                }
            </div>
            {isInvalidState && isRequired && <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>}
        </div>
    );
};

export default MultiChoiceQuestion;