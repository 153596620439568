
export interface ICompany {
    fax?: string;
    address?: string;
    onlinePaymentUrl?: string;
}

export class Company implements ICompany {
    fax?: string;
    address?: string;
    onlinePaymentUrl?: string;


    constructor(fax?: string,
        address?: string,
        onlinePaymentUrl?: string) {

        this.fax = fax;
        this.address = address;
        this.onlinePaymentUrl = onlinePaymentUrl;
    }

    public static createNullObject(): ICompany {
        return new Company(undefined, undefined, undefined);
    }

    public static create(fax?: string,
        address?: string, onlinePaymentUrl?: string): ICompany {
        return new Company(fax, address, onlinePaymentUrl);
    }

}