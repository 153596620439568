import { Action, Reducer } from "redux";
import { AppThunkAction } from "../";
import { actionTypes } from "../ActionTypes";
import { StatusType, NotificationAction } from "../Common/NotificationStore";
import { AxiosResponse } from "axios";
import { logger } from "../../routes";
import {
	IHeaderInfoViewModel,
	initialHeaderInfoViewModel,
} from "src/core/domain/viewModels/IHeaderInfoViewModel";
import { ErrorMessages } from "../../components/Common/Constants";
import { initializeAxios } from "../../core/Services/dataAccess/DataService.Axios";
import { StatusCode } from "src/core/domain/models/Organizer/Organizer";

interface RequestHeaderInfoAction {
	type: actionTypes.HEADERINFO_REQUEST;
	id: string;
}

interface ResponseHeaderInfoAction {
	type: actionTypes.HEADERINFO_RESPONSE;
	data: IHeaderInfoViewModel;
}
interface FailureHeaderInfoAction {
	type: actionTypes.HEADERINFO_FAILURE;
	id: string;
}

type KnownAction = DispatchAction | NotificationAction;

type DispatchAction =
	| ResponseHeaderInfoAction
	| RequestHeaderInfoAction
	| FailureHeaderInfoAction;

export const actionCreators = {
	requestHeaderInfo:
		(
			id: string,
			isPreview: boolean,
			callback?: (data: any) => void
		): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				let state = getState();
				dispatch({ type: actionTypes.HEADERINFO_REQUEST, id: id });
				return initializeAxios()
					.get<IHeaderInfoViewModel>("/api/Helper/GetHeaderInfoAsync/" + id)
					.then(function (response: AxiosResponse<IHeaderInfoViewModel>) {
						dispatch({
							type: actionTypes.HEADERINFO_RESPONSE,
							data: response.data,
						});

						if (callback) {
							callback(response.data);
						}
					})
					.catch(function (error: any) {
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							return;
						}
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: error.response
								? error.response.statusText
								: ErrorMessages.HeaderInfoError,
							statusType: StatusType.Error,
						});
						dispatch({ type: actionTypes.HEADERINFO_FAILURE, id: id });
						logger.trackError(
							`requestHeaderInfo failed to with error ${error.message}`
						);
					});
			},

	refreshToken:
		(
			clientId: string,
			failurecallback: () => void
		): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {

				return initializeAxios()
					.post("external/refresh/" + clientId)//TODO :Changes to be made to this API
					.then(function () { })
					.catch(function (error: any) {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: error.response
								? error.response?.statusText
								: "Failed to refresh token",
							statusType: StatusType.Error,
						});
						logger.trackError(
							`refreshToken failed to push the data  with error ${error.message}`
						);
						failurecallback();
					});
			},
	logout:
		(
			clientId: string,
			callback: (id: string) => void
		): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {

				return initializeAxios()
					.post("account/Logout/" + clientId)
					.then(function (response) {
						if (response.data && response.data.clientId)
							callback(response.data.clientId);
					})
					.catch(function (error: any) {
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							return;
						}
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: error.response
								?? error.response?.statusText,
							statusType: StatusType.Error,
						});
					});
			},
};

export const reducer: Reducer<IHeaderInfoViewModel> = (
	state: IHeaderInfoViewModel = initialHeaderInfoViewModel,
	incomingAction: Action
) => {
	const action = incomingAction as DispatchAction;
	var currentState = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.HEADERINFO_REQUEST:
			return { ...initialHeaderInfoViewModel, isLoading: true };
		case actionTypes.HEADERINFO_RESPONSE:
			return { ...action.data, isLoading: false };
		default:
			return state || initialHeaderInfoViewModel;
	}
};
