import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes, IEventTelemetry, ITelemetryItem, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AxiosResponse } from 'axios';
import { createBrowserHistory } from 'history';
import { ILogger } from './Logger';
import { GetFormatedDateTime } from 'src/components/Helper/HelperFunction';
import { initializeAxios } from 'src/core/Services/dataAccess/DataService.Axios';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
let profileData: IProfileData;
const eventPrefix = 'Taxpayer';

interface IProfileData {
    instrumentationKey: string,
    userId: string,
    companyId: number;
    companyName: string,
    documentId: number;
}

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '',
        disableInstrumentationKeyValidation: true,
        extensions: [reactPlugin],
        disableFetchTracking: false,
        disableAjaxTracking: false,
        enableCorsCorrelation: true,
        distributedTracingMode: DistributedTracingModes.W3C,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        disableCorrelationHeaders: false,
        enableAutoRouteTracking: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    appInsights.config.instrumentationKey = profileData.instrumentationKey;
    envelope.tags = envelope.tags || [];
    envelope.tags.push({ "ai.cloud.role": "ORGTaxpayerService-UI" });

    if (envelope.baseType === "PageviewData" &&
        envelope.baseData != undefined &&
        envelope.ext != undefined) {
        envelope.baseData.name = SetPageViewName(envelope.ext.trace.name);
    }

    if (profileData !== undefined) {
        envelope.tags.push({ "ai.user.accountId": profileData.companyId });
        envelope.tags.push({ "ai.user.id": profileData.userId });
    }

    var telemetryItem = envelope.baseData;
    if (telemetryItem !== undefined && profileData !== undefined) {
        telemetryItem.properties = telemetryItem.properties || {};
        telemetryItem.properties["UserId"] = profileData.userId;
        telemetryItem.properties["CompanyId"] = profileData.companyId;
        telemetryItem.properties["CompanyName"] = profileData.companyName;
        telemetryItem.properties["DocumentId"] = profileData.documentId;
        telemetryItem.properties["ClientTime"] = GetFormatedDateTime(new Date());
        telemetryItem.properties["TimeZone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    }
});

const initializeAppInsights = (clientId: string) => {
     initializeAxios().get<any>('/api/ApplicationInsight/BasicDetails/' + clientId)
          .then(function (response: AxiosResponse<any>) {
              profileData = response.data;
          })
};

const SetPageViewName = (refurl: string) => {
    if (refurl.includes("/Organizer/sign/")) {
        return "Organizer-Esign"
    }
    else if (refurl.includes("/engagementletter/sign")) {
        return "EngagementLetter-Esign"
    }
    else if (refurl.includes("/uploaddocuments")) {
        return "SourceDocuments-Upload"
    }
    else if (refurl.includes("/signingcomplete")) {
        return "Signing-Completed"
    }
    else {
        return "Organizer-Home"
    }

}

export { initializeAppInsights, reactPlugin, appInsights };

export class TelemetryLogger implements ILogger {

    private static instance: TelemetryLogger;
    private static appInsights: ApplicationInsights;

    private constructor() {
        TelemetryLogger.appInsights = appInsights;
    }

    public static getInstance(): TelemetryLogger {
        if (!TelemetryLogger.instance) {
            this.instance = new TelemetryLogger();
        }
        return TelemetryLogger.instance;
    }

    public trackTrace = (message: string) => {
        TelemetryLogger.appInsights.trackTrace(
            {
                message: message,
                severityLevel: SeverityLevel.Information
            }
        );
    }
    public trackError = (message: string) => {
        TelemetryLogger.appInsights.trackException(
            {
                exception: new Error(message)
            }
        );
    }
    public trackEvent = (traceEvent: IEventTelemetry) => {
        traceEvent.name = `${eventPrefix}-${traceEvent.name}`;
        TelemetryLogger.appInsights.trackEvent(traceEvent);
    }
}