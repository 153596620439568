import { AppThunkAction } from "..";
import { IFormData } from "../../core/domain/models/Organizer/CustomQuestionModel";
import { IOnChangeHandlerPayload, IHandleChoiceIdentifierPayload, KnownActions, IYesNoChoiceIdentifierPayload } from "./ActionTypes";
import { actionTypes } from "../ActionTypes";
import { StatusType } from "../Common/NotificationStore";
import { AxiosError, AxiosResponse } from "axios";
import { FormGroupStatus, StatusCode } from "../../core/domain/models/Organizer/Organizer";
import { initializeAxios } from "../../core/Services/dataAccess/DataService.Axios";

export const handleParagraphOnChange = (data: IOnChangeHandlerPayload) => {
	return {
		type: actionTypes.HANDLE_PARAGRAPH_ON_CHANGE,
		payload: data,
	};
};
export const handleYesNoOnChange = (data: IYesNoChoiceIdentifierPayload) => {
	return {
		type: actionTypes.HANDLE_YES_NO_ON_CHANGE,
		payload: data
	};
}
export const handleMcqOnChange = (data: IHandleChoiceIdentifierPayload) => {
	return {
		type: actionTypes.HANDLE_MCQ_ON_CHANGE,
		payload: data
	};
}
export const getFormData =
	(clientId: string, callback?: () => void): AppThunkAction<KnownActions> =>
		(dispatch) => {
			return initializeAxios()
				.get<IFormData>("/api/CustomQuestionnaire/" + clientId)
				.then(function (response: AxiosResponse<IFormData>) {
					if (response.data) {
						dispatch({
							type: actionTypes.GET_FORM_DATA,
							payload: response.data,
						});
					}
				})
				.catch(function (error: any) {
					if (error?.response?.status === StatusCode.OrganizerUnavailable) {
						return;
					}
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response,
						statusType: StatusType.Error,
					});
				});
		};

export const getCustomQuestionStatus = (clientId: string): Promise<any> => {
	return initializeAxios().get(`/api/CustomQuestionnaire/GetCustomQuestionStatus/${clientId}`)
		.then((response: AxiosResponse<FormGroupStatus>) => response.data)
		.catch((error: AxiosError) => {
			if (error?.response?.status === StatusCode.OrganizerUnavailable) {
				return;
			}
			Promise.reject(error.message)
		});
};

export const onSaveForm =
	(
		clientId: string,
		data: IFormData,
		callback: (data?: any) => void
	): AppThunkAction<KnownActions> =>
		(dispatch) => {
			const options = {
				headers: {
					Accept: "application/json, text/plain, *",
					"Content-Type": "application/json; charset=utf-8",
				},
			};
			return initializeAxios()
				.post<IFormData>(
					"/api/CustomQuestionnaire/save/" + clientId,
					data,
					options
				)
				.then(function (response: AxiosResponse<IFormData>) {
					if (response.data) callback(response.data);
				})
				.catch(function (error: any) {
					if (error?.response?.status === StatusCode.OrganizerUnavailable) {
						return;
					}
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response,
						statusType: StatusType.Error,
					});
				})
		};

export const onFinishForm =
	(
		clientId: string,
		data: IFormData,
		callback: (data?: any) => void
	): AppThunkAction<KnownActions> =>
		(dispatch) => {
			const options = {
				headers: {
					Accept: "application/json, text/plain, *",
					"Content-Type": "application/json; charset=utf-8",
				},
			};
			return initializeAxios()
				.post<IFormData>(
					"/api/CustomQuestionnaire/finish/" + clientId,
					data,
					options
				)
				.then(function (response: AxiosResponse<IFormData>) {
					if (response.data) callback(response.data);
				})
				.catch(function (error: any) {
					if (error?.response?.status === StatusCode.OrganizerUnavailable) {
						return;
					}
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: error.response,
						statusType: StatusType.Error,
					});
				})
		};
export const setIsInvalidFlag = () => {
	return {
		type: actionTypes.SET_IS_INVALID_FLAG
	}
}