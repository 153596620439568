import * as React from "react";
import { Modal, Button } from "react-bootstrap";

export interface ModalNotificationProps {
	show: boolean;
	footerChildrens?: React.ReactElement;
	okButtonEnabled?: boolean;
	okButtonText?: string;
	onOkButtonClick?: () => void;
	okButtonClassnames?: string;
	modalBodyText: React.ReactElement | string;
	modalHeaderText?: React.ReactElement | string;
	onHide?: () => void;
	modalBodyCssClassnames?: string;
	modalFooterCssClassnames?: string;
	closeButton?: boolean;
}

export const ModalNotification: React.FC<ModalNotificationProps> = ({
	show,
	modalBodyText,
	modalHeaderText,
	footerChildrens,
	okButtonEnabled,
	okButtonText,
	onOkButtonClick,
	onHide,
	modalBodyCssClassnames,
	modalFooterCssClassnames,
	okButtonClassnames,
	closeButton,
}) => {
	return (
		<Modal
			show={show}
			onHide={() => {
				onHide ? onHide() : onOkButtonClick ? onOkButtonClick() : "";
			}}
		>
			{modalHeaderText && (
				<Modal.Header closeButton={closeButton}>
					{modalHeaderText}{" "}
				</Modal.Header>
			)}
			<Modal.Body>
				<div className={modalBodyCssClassnames}>{modalBodyText}</div>
			</Modal.Body>
			<Modal.Footer>
				<div className={modalFooterCssClassnames}>
					{footerChildrens && footerChildrens}
					{okButtonEnabled && (
						<Button
							data-test-auto="DC3AFACE-78D5-40F4-B5C2-6851E08DEC9A"
							className={okButtonClassnames}
							onClick={() => {
								onOkButtonClick && onOkButtonClick();
							}}
						>
							{okButtonText ?? "Ok"}
						</Button>
					)}
				</div>
			</Modal.Footer>
		</Modal>
	);
};
