import { History } from 'history';
import * as React from 'react';
import { match, RouteComponentProps } from 'react-router';
import { Toaster } from '../../components/Common/Notification/MarsNotifier';
import { UrlConstants } from '../../core/common/Constants';
import * as AccountStore from '../../store/Common/AccountStore';
import * as HelperStore from '../../store/Common/HelperStore';
import { ErrorStatus } from '../../store/CoverPage/CoverPageStore';
import { OverlayLoader } from '../Common/Loader/OverlayLoader';
import Notification from '../Common/Notification/NotificationContainer';
import { Header } from './Header';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';

declare global {
    interface Window {
        Variables: any;
    }
}

export type DefaultLayoutProps =
    {
        headerInfo: IHeaderInfoViewModel,
        match: match;
        history: History;
        isCoverPage:boolean;
    }
    & typeof AccountStore.actionCreators
    & typeof HelperStore.actionCreators
    & RouteComponentProps<{}>;

export class DefaultLayout extends React.Component<DefaultLayoutProps | any, any> {

    private _param: any;

    constructor(props: DefaultLayoutProps | any, states: any) {
        super(props, states);
        this.state = {
            showTimeout: false
        };
    }

    componentDidMount() {
        this._param = this.props.match.params;
        //setting this in local storage so we still have access to this after log in.
        try {
            sessionStorage.setItem("clientId", this._param.clientId);
        } catch (e) {
            console.log(e)
        }

        this.props.requestHeaderInfo(this._param.clientId, false, undefined, (errorStatus: ErrorStatus) => {
            this.props.history.push(`${UrlConstants.Error}${errorStatus}`);
        });

        this.props.requestAllTaxingAuthorities(this._param.clientId);
    }

    public render() {
        return (
            <div>
                <Header
                    headerInfo={this.props.headerInfo}
                    match={this.props.match}
                    history={this.props.history}
                    isCoverPage={this.props.isCoverPage}
                />
                <div id="content-wrapper" className="content-wrapper">
                    <Notification />
                    {this.props.children}
                </div>
                {/* <OverlayLoader /> */}
                <Toaster />
            </div>
        );
    }
}
