import { Container } from "inversify";
import "reflect-metadata";
import { IDataService } from "../core/Services/dataAccess/abstraction/IDataService";
import { IOrganizerService } from "../core/Services/OrganizerService/OrganizerService";
import { DateUtilities, IDateUtilities } from '../core/utilities/date/DateUtilities';
import { UtilityFactory } from "../core/utilities/factory/UtilityFactory";
import { FileUtilities, IFileUtilities } from '../core/utilities/File/FileUtilities';
import { FileUploadUtilities, IFileUploadUtilities } from '../core/utilities/FileUpload/FileUploadUtilities';
import { IHtmlUtilities } from "../core/utilities/HtmlUtilities";
import { IJsonUtilities, JsonUtilities } from "../core/utilities/JSON/JsonUtilities";
import { ILocalStore, ISessionStore } from "../core/utilities/LocalStore";
import { IRegexUtilities, RegexUtilities } from "../core/utilities/RegexUtilities";
import { ITextUtilities } from "../core/utilities/TextUtilities";
import { IDialogBox } from "../core/utilities/ui/DialogBox";
import { ILoader, Loader } from '../core/utilities/ui/Loader';
import { ISnackbar, Snackbar } from '../core/utilities/ui/Snackbar';
import { IUtilities, Utilities } from "../core/utilities/Utilities";
import { IWindowUtilities } from "../core/utilities/WindowUtilities";
import { Factory } from '../startup/Factory';
import { TYPES } from "./types";


 
const container = new Container();

container.bind<ILocalStore>(TYPES.ILocalStore).toConstantValue(new UtilityFactory().createLocalStore());
container.bind<ISessionStore>(TYPES.ISessionStore).toConstantValue(new UtilityFactory().createSessionStore());
container.bind<IDataService>(TYPES.IDataService).toConstantValue(Factory.createDataService());
container.bind<ITextUtilities>(TYPES.ITextUtilities).toConstantValue(new UtilityFactory().createTextUtilityService());
container.bind<IWindowUtilities>(TYPES.IWindowUtilities).toConstantValue(new UtilityFactory().createWindowUtilityService());
container.bind<IHtmlUtilities>(TYPES.IHtmlUtilities).toConstantValue(new UtilityFactory().createHtmlUtilities());
container.bind<IDialogBox>(TYPES.IDialogBox).toConstantValue(new UtilityFactory().createDialogBox());
container.bind<IRegexUtilities>(TYPES.IRegexUtilities).to(RegexUtilities);
container.bind<IUtilities>(TYPES.IUtilities).to(Utilities);
container.bind<IFileUploadUtilities>(TYPES.IFileUploadUtilities).to(FileUploadUtilities);
container.bind<IFileUtilities>(TYPES.IFileUtilities).to(FileUtilities);
container.bind<IJsonUtilities>(TYPES.IJsonUtilities).to(JsonUtilities);
container.bind<IDateUtilities>(TYPES.IDateUtilities).to(DateUtilities);
container.bind<ILoader>(TYPES.ILoader).to(Loader);
container.bind<ISnackbar>(TYPES.ISnackbar).to(Snackbar);
container.bind<IOrganizerService>(TYPES.IOrganizerService).toConstantValue(Factory.createOrganizerService());



export { container };
