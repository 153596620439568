import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { DownloadableStatus, StatusCode } from '../../core/domain/models/Organizer/Organizer';
import { initializeAxios } from '../../core/Services/dataAccess/DataService.Axios';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { logger } from '../../routes';

interface RequestPreviewDocumentStatusAction {
    type: actionTypes.PREVIEW_DOCUMENTS_REQUEST;
}

interface ResponsePreviewDocumentStatusAction {
    type: actionTypes.PREVIEW_DOCUMENTS_RESPONSE;
    data: DownloadableStatus;
}

interface FailurePreviewDocumentStatusAction {
    type: actionTypes.PREVIEW_DOCUMENTS_FAILURE;
}

type KnownAction = DispatchAction
    | NotificationAction;
type DispatchAction = RequestPreviewDocumentStatusAction
    | ResponsePreviewDocumentStatusAction
    | FailurePreviewDocumentStatusAction

export interface PreviewState {
    previewStatus: DownloadableStatus;
}

export const initialPreviewState: PreviewState = {
    previewStatus: DownloadableStatus.Preparing
};

export const actionCreators = {

    requestPreviewDocumentStatus: (clientGuid: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.PREVIEW_DOCUMENTS_REQUEST });
        const state = getState();

        return initializeAxios().get('/api/Document/IsDocumentReadyForPreview/' + clientGuid)
            .then(function (response: AxiosResponse<DownloadableStatus>) {
                dispatch({ type: actionTypes.PREVIEW_DOCUMENTS_RESPONSE, data: response.data });
                if (response.data) {
                    callback && callback();
                }
            }).catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: "Generating documents for preview failed. Please try again",
                    statusType: StatusType.Error
                });
                logger.trackError(`requestPreviewDocumentStatus failed for clientGuid: ${clientGuid} with error ${error.message}`)
                dispatch({ type: actionTypes.PREVIEW_DOCUMENTS_FAILURE });
            });
    }
}

export const reducer: Reducer<PreviewState> = (state: PreviewState = initialPreviewState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.PREVIEW_DOCUMENTS_REQUEST:
            return initialPreviewState;
        case actionTypes.PREVIEW_DOCUMENTS_RESPONSE:
            currentState.previewStatus = action.data;
            return currentState;
        case actionTypes.PREVIEW_DOCUMENTS_FAILURE:
            return initialPreviewState;
        default:
            return currentState || initialPreviewState;
    }
}; 