import * as React from 'react';
import { UploadedFile } from '../../../core/domain/models/SourceDocument/SourceDocument';
import { ConflictErrorMessage } from '../../../store/UploadedDocument/UploadedDocumentStoreModels';
import { UploadStatus } from '../../Common/FileUpload/FileUpload';


interface SourceDocumentTableProps {
    clientId: string;
    files: UploadedFile[];
    deleteUploadedDocument(clientId: string, id: number): void;
    isSourceDocumentUploadCompleted: boolean;
}

export const SourceDocumentTable: React.FunctionComponent<SourceDocumentTableProps> = ({
    clientId, files, deleteUploadedDocument, isSourceDocumentUploadCompleted
}) => {

    let handleDeleteDocument = (id: number) => {
        !isSourceDocumentUploadCompleted &&
        deleteUploadedDocument(clientId, id);
    }

    let createFileList = (): any[] => {

        let collection: any[] = [];

        for (var i = 0; i <= files.length - 1; i++) {

            const tmpFile: UploadedFile = files[i];
            collection.push(<li key={"file-item-" + i} className="item type1 Tablet-flex-between">
                <div className="task col-lg-10 no-padding Tablet-width-80_per">                   
                    <div className="name" title={tmpFile.name}> {tmpFile.name}</div>                       
                </div>
				<div className="col-lg-1 Tablet-hidden"/>
                <div className="col-lg-1 no-padding Tablet-width-10_per">
                    {FileRows(tmpFile)}
                </div>

            </li>);
        }

        return collection;
    }

    let FileRows = (file: UploadedFile): any => {
        const uploadPercentage: string = file.uploadPercentage.toFixed(0) + "%";
        const deleteButtonClassnames = isSourceDocumentUploadCompleted ? 'disabled cursor-default' : '';
        let row: any;
        if (file.status == UploadStatus.Error) {
            row = (<span onClick={(e: any) => { }}
                className="fa fa-exclamation-triangle source-document-failed-icon" title="Source document upload failed"></span>)
        }
        else if (file.uploadPercentage == 100) {
            row = (<span onClick={(e: any) => { file.id && handleDeleteDocument(file.id) }}
                className={`fas fa-trash-alt fa-lg source-document-delete-icon ${deleteButtonClassnames}`}
                title={isSourceDocumentUploadCompleted ? ConflictErrorMessage : "Delete source document"
        }></span >)
        }
        else {
            row = (<div className={"organizer-upload-documents-progress-conatiner"}> <span className={"upload-documents-progress"}>{uploadPercentage}</span></div>)
        }
        return row;
    }

    return (<div className="file-list-container filepicker">
        <div className={"filepicker uploaded-files-list minimized"} data-test-auto="AA01CE21-29D4-42E6-9F0C-2E058D3E9A46">
            <ul className="task-items">
                {createFileList()}
            </ul>
        </div>
    </div>);
};