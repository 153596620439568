import { connect } from 'react-redux';
import { withRouter } from "react-router";
import CoverPageContainer from "./CoverPage";
import * as  CoverPageStore from "../../store/CoverPage/CoverPageStore";
import { actionCreators as coverPageAction } from "../../store/CoverPage/CoverPageStore";
import { actionCreators as helperAction } from "../../store/Common/HelperStore";
import { ApplicationState } from "../../store/index";
import { OtpMode } from '../../core/domain/viewModels/ICommon';


function mapStateToProps(state: ApplicationState) {
    return {
        headerInfo: state.headerInfo
    };
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestHeaderInfo: (id: string, isPreview: boolean) => dispatch(coverPageAction.requestHeaderInfo(id, isPreview)),
        getAuthenticationType: (clientId: string, navigate: (clientId: string, type: OtpMode) => void, errorCallback: (errorStatus: CoverPageStore.ErrorStatus) => void) =>
            dispatch(coverPageAction.getAuthenticationType(clientId, navigate, errorCallback)),
                                            
        setIsCoverPage: (isCoverPage: boolean) =>
            dispatch(helperAction.setIsCoverPage(isCoverPage)),

        dispatch,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(CoverPageContainer));