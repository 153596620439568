import * as React from 'react';

export enum CheckBoxSize {
    sm = 0,
    lg = 1
}

interface ICheckBoxComponent {
    checked?: boolean;
    onChange?: (event: any) => void;
    text: string;
    datatestAuto?: string;
    disabled?: boolean;
    value?: string | string[] | number;
    onClick?: (event: any) => void;
    id: string;
    className?: string;
    size?: CheckBoxSize;
    indeterminate?: boolean;
    wrapperStyle?: React.CSSProperties;
}

export class CheckBoxComponent extends React.Component<ICheckBoxComponent, {}>{

    public render() {

        let checkBoxClass = "magic-checkbox";

        switch (this.props.size) {

            case CheckBoxSize.lg:
                checkBoxClass = this.props.indeterminate ? "magic-checkbox magic-checkbox-indeterminate" : "magic-checkbox";
                break;

            case CheckBoxSize.sm:
                checkBoxClass = this.props.indeterminate ? "magic-checkbox-sm magic-checkbox-sm-indeterminate" : "magic-checkbox-sm";
                break;
        }

        return (
            <div className={this.props.className} style={this.props.wrapperStyle}>
                <div className={'display-checkBox'}>
                <input
                    className={checkBoxClass}
                    type="checkbox"
                    id={this.props.id}
                    value={this.props.value}
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    data-test-auto={this.props.datatestAuto}
                    disabled={this.props.disabled}
                    onClick={this.props.onClick}
                >
                    </input>
                    <label htmlFor={this.props.id} className={this.props.checked ? "checked" : ""}>
                    {this.props.text}
            </label>
            </div>
            </div>
        )
    }
}