import * as React from "react";
import { Form } from "react-bootstrap";
import {
    ISectionItems,
    QuestionType,
} from "../../core/domain/models/Organizer/CustomQuestionModel";
import ParagraphQuestion from "./ParagraphQuestion";
import { IOnChangeHandlerPayload, IHandleChoiceIdentifierPayload, IYesNoChoiceIdentifierPayload } from "../../store/CqPreview/ActionTypes";
import MultiChoiceQuestion from "./QuestionTypes/MultiChoiceQuestion";
import YesNoQuestion from "./YesNoQuestion";

interface SectionItemProps {
    sectionIndex: number;
    sectionItemIndex: number;
    sectionItem: ISectionItems;
    sectionItemsCount?: number;
    handleParagraphOnChange: (payload: IOnChangeHandlerPayload) => void;
    handleYesNoOnChange: (payload: IYesNoChoiceIdentifierPayload) => void;
    handleMcqOnChange: (payload: IHandleChoiceIdentifierPayload) => void;
    calculateProgress: () => void;
}

const SectionItem: React.FC<SectionItemProps> = ({
    sectionItem,
    sectionItemIndex,
    sectionIndex,
    handleParagraphOnChange,
    handleYesNoOnChange,
    handleMcqOnChange,
    calculateProgress,
}) => {
    const [isInputFocused, setIsFocused] = React.useState(false);

    const handleInputBlur = () => {
        setIsFocused(false);
        calculateProgress();
    };
    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const getQuestionControl = () => {
        switch (sectionItem.questionType) {
            case QuestionType.PARAGRAPH:
                return (
                    <ParagraphQuestion
                        sectionIndex={sectionIndex}
                        sectionItemIndex={sectionItemIndex}
                        isRequired={sectionItem.isRequired}
                        answer={sectionItem.answer}
                        clientType={sectionItem.answer?.clientType}
                        handleParagraphOnChange={handleParagraphOnChange}
                        handleInputBlur={handleInputBlur}
                        handleInputFocus={handleInputFocus}
                        isInvalid={sectionItem.isInValid}
                    />
                );
            case QuestionType.YESNO:
                return (
                    <YesNoQuestion
                        sectionIndex={sectionIndex}
                        sectionItemIndex={sectionItemIndex}
                        isRequired={sectionItem.isRequired}
                        isInvalid={sectionItem.isInValid}
                        clientType={sectionItem.answer?.clientType}
                        choices={sectionItem.choices || []}
                        handleMcqOnChange={handleMcqOnChange}
                        handleParagraphOnChange={handleParagraphOnChange}
                        handleYesNoOnChange={handleYesNoOnChange}
                    />
                );
            case QuestionType.MULTICHOICE:
                return (
                    <MultiChoiceQuestion
                        sectionIndex={sectionIndex}
                        sectionItemIndex={sectionItemIndex}
                        isRequired={sectionItem.isRequired}
                        isInvalid={sectionItem.isInValid}
                        clientType={sectionItem.answer?.clientType}
                        choices={sectionItem.choices}
                        handleMcqOnChange={handleMcqOnChange}
                    />
                );

            default:
                return <p>Control Not found</p>;
        }
    };
    return (
        <Form.Group className="question-preview-container">
            <div
                className={`question-container ${isInputFocused ? "active-question" : ""
                    }`}
            >
                <div className="question">
                    <Form.Label>
                        <span>{sectionItemIndex + 1}.</span>
                        {sectionItem.questionTitle}
                        {sectionItem.isRequired && <span className="cq-error-red"> *</span>}
                    </Form.Label>
                    {getQuestionControl()}
                </div>
            </div>
        </Form.Group>
    );
};

export default SectionItem;
