import { connect } from "react-redux";
import { ApplicationState } from "../../store/index";
import CustomQuestions from "./CustomQuestions";
import {
	getFormData,
	onSaveForm,
	handleParagraphOnChange,
	handleMcqOnChange,
	onFinishForm,
	setIsInvalidFlag,
	handleYesNoOnChange,
} from "../../store/CqPreview/Actions";
import { IOnChangeHandlerPayload, IHandleChoiceIdentifierPayload, IYesNoChoiceIdentifierPayload } from "../../store/CqPreview/ActionTypes";
import { IFormData } from "../../core/domain/models/Organizer/CustomQuestionModel";
import { actionCreators as OrganizerMetadataStore } from '../../store/Organizer/OrganizerMetadataStore';

function mapStateToProps(state: ApplicationState) {
	return {
		CustomQuestionStore: state.customQuestionStore
	};
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		getFormData: (clientId: string, callback?: () => void) =>
			dispatch(getFormData(clientId, callback)),
		onSaveForm: (
			clientId: string,
			data: IFormData,
			callback: () => void
		) => dispatch(onSaveForm(clientId, data, callback)),
		onFinishForm: (
			clientId: string,
			data: IFormData,
			callback: () => void
		) => dispatch(onFinishForm(clientId, data, callback)),

		handleParagraphOnChange: (payload: IOnChangeHandlerPayload) =>
			dispatch(handleParagraphOnChange(payload)),
		setIsInvalidFlag: () => dispatch(setIsInvalidFlag()),

		handleYesNoOnChange: (payload: IYesNoChoiceIdentifierPayload) =>
		dispatch(handleYesNoOnChange(payload)),
		
		handleMcqOnChange: (payload: IHandleChoiceIdentifierPayload) =>
			dispatch(handleMcqOnChange(payload)),

		requestOrganizerMetadata: (clientId: string) => dispatch(OrganizerMetadataStore.requestOrganizerMetadata(clientId))
	};
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(CustomQuestions);
