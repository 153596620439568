import { AxiosResponse } from 'axios';
import { initializeAxios } from '../../core/Services/dataAccess/DataService.Axios';
import { Action, Reducer } from 'redux';
import { PreparerMessage, StatusCode } from '../../core/domain/models/Organizer/Organizer';
import { actionTypes } from '../ActionTypes';
import { AppThunkAction } from '../index';
import { logger } from '../../routes';

interface ResponsePreparerMessageAction {
    type: actionTypes.ORGANIZER_PREPARER_MESSAGE_RESPONSE;
    data: PreparerMessage;
}

export interface OrganizerPreparerMessageState {
    preparerMessage: PreparerMessage;
}

export const initialOrganizerPreparerMessageState: OrganizerPreparerMessageState = {
    preparerMessage: PreparerMessage.createNullObject(),
}

type KnownAction =
    DispatchAction

type DispatchAction =
    ResponsePreparerMessageAction

export const actionCreators = {
    requestOrganizerPreparerMessage: (clientGuid: string, successCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();

        return initializeAxios().get<PreparerMessage>('/api/PreparerMessage/GetPreparerMessage/' + clientGuid)
            .then(function (response: AxiosResponse<PreparerMessage>) {
                successCallback && successCallback();
                dispatch({
                    type: actionTypes.ORGANIZER_PREPARER_MESSAGE_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                logger.trackError(`requestOrganizerPrepareerMessage failed to fetch the data with error ${error.message}`)
            });
    }
}

export const reducer: Reducer<OrganizerPreparerMessageState> = (state: OrganizerPreparerMessageState = initialOrganizerPreparerMessageState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.ORGANIZER_PREPARER_MESSAGE_RESPONSE:
            currentState.preparerMessage = action.data;
            return currentState;
        default:
            return currentState || initialOrganizerPreparerMessageState;
    }
}; 