import * as React from "react";
import { Form } from "react-bootstrap";
import { IChoiceModel } from "../../../core/domain/models/Organizer/CustomQuestionModel";
import { FormPreviewConstants } from "../../Common/Constants";
import { ClientType } from "../../../core/common/Enums";
import { IYesNoChoiceIdentifierPayload } from "../../../store/CqPreview/ActionTypes";
import { IsClientViewFromCache, IsPreviewModeFromCache } from "../../Helper/HelperFunction";
import { ColorCode } from "../../../core/common/BrandColors";

interface YesNoFollowUpQuestionProps {
    choiceId: number;
    sectionIndex: number;
    sectionItemIndex: number;
    isRequired: boolean;
    choices: IChoiceModel[];
    resetValidations?: boolean;
    isInvalid?: boolean;
    clientType?: ClientType;
    handleYesNoOnChange?: (payload: IYesNoChoiceIdentifierPayload) => void;
}

const YesNoFollowUpQuestion: React.FC<YesNoFollowUpQuestionProps> = ({
    choiceId,
    sectionIndex,
    sectionItemIndex,
    choices,
    clientType,
    isInvalid,
    isRequired,
    handleYesNoOnChange
}) => {

    const [options, setOptions] = React.useState<IChoiceModel[]>([...choices].sort((a, b) => a.id - b.id));

    const [isInvalidState, setIsInvalidState] = React.useState(isInvalid);

    const isEditDisabled: boolean = IsClientViewFromCache() || IsPreviewModeFromCache();

    React.useEffect(() => {
        setIsInvalidState(isInvalid);
    }, [isInvalid]);

    React.useEffect(() => {
        if (choices) {
            setOptions([...choices].sort((a, b) => a.id - b.id));
        }
    }, [choiceId]);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, choice: IChoiceModel) => {
        if (choice.isSelected) {
            e.preventDefault();
            return;
        }
        let updatedOptions: IChoiceModel[] = options.map((option) => {
            return { ...option, isSelected: option.text === choice.text && e.target.checked };
        })
        setIsInvalidState(false);
        setOptions(updatedOptions);
    };

    const onOptionClick = (e: React.MouseEvent<HTMLInputElement>, choice: IChoiceModel) => {
        if (choice.isSelected) {
            e.preventDefault();
            return;
        }
        let updatedOptions: IChoiceModel[] = options.map((option) => {
            return { ...option, isSelected: false };
        })
        setIsInvalidState(true);
        setOptions(updatedOptions);
        const payload: IYesNoChoiceIdentifierPayload = {
            choiceId: choiceId,
            sectionIndex,
            sectionItemIndex,
            subChoiceId: choice.id,
        };
        if (handleYesNoOnChange) {
            handleYesNoOnChange(payload);
        }
    }
    const getColor = (clientType: any, choice: IChoiceModel) => {
        let color: string = ColorCode.DEFAULT;
        if (choice.isSelected) {
            if (clientType === ClientType.Spouse) {
                color = ColorCode.SPOUSE_COLOR;
            } else if (clientType === ClientType.Taxpayer) {
                color = ColorCode.TAXPAYER_COLOR;
            }
        }
        return color;
    };
    return (
        <div className={`answer-wrapper ${sectionItemIndex >= 99 ? "padding-24" : sectionItemIndex >= 9 ? "padding-12" : ""} follow-up-answer`}>
            <Form>
                <div className={isEditDisabled ? "non-editable" : ""}>
                    {options?.map((option) => (
                        <Form.Check
                            id={`s-${sectionIndex}_q-${sectionItemIndex}`}
                            key={option.text + sectionItemIndex}
                            inline
                            label={option.text}
                            name="radio-group"
                            type="radio"
                            value={sectionItemIndex}
                            onChange={(e) => onChangeHandler(e, option)}
                            onClick={(e) => onOptionClick(e, option)}
                            checked={option.isSelected}
                            style={{ color: getColor(clientType, option) }}
                            className={isInvalidState && isRequired ? "yesno-error-radio" :
                                clientType ? clientType === ClientType.Taxpayer ? "taxpayer-radio" : "spouse-radio" : ""}
                        />
                    ))}
                </div>
            </Form>
            {isInvalidState && isRequired && (
                <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>
            )}
        </div>
    );
};

export default YesNoFollowUpQuestion;
