import * as React from 'react';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { UploadedFile } from '../../../core/domain/models/SourceDocument/SourceDocument';
import { IDateUtilities } from '../../../core/utilities/date/DateUtilities';
import { CheckBoxComponent } from '../../Common/CheckBoxComponent';

interface UploadedDocumentTableProps {
    clientId: string;
    files: UploadedFile[];
    deleteUploadedDocument(clientId: string, id: number, name: string): void;
    deletedButtonDisable?: boolean;
    selectedDocuments: number[];
    sourceDocumentSelected(checked: boolean, documentId: number): void;
    previewUplodedDocument(clientId: string, uploadedDocumentId: number, uploadedDocumentName: string): void;
    isClientView: boolean;
}

const dateUtilities = container.get<IDateUtilities>(TYPES.IDateUtilities);

export const UploadedDocumentTable: React.FunctionComponent<UploadedDocumentTableProps> = ({
    clientId, files, deleteUploadedDocument, deletedButtonDisable, selectedDocuments, sourceDocumentSelected, previewUplodedDocument, isClientView
}) => {

    let handleDeleteDocument = (id: number, name: string) => {
        deleteUploadedDocument(clientId, id, name);
    }
    let handlePreview = (id: number, name: string) => {
        previewUplodedDocument(clientId, id, name);
    }

    let createFileList = (): any[] => {

        let collection: any[] = [];

        for (var i = 0; i <= files.length - 1; i++) {

            const tmpFile: UploadedFile = files[i];
            const uploadPercentage: string = tmpFile.uploadPercentage.toFixed(0) + "%";

            collection.push(<li key={"file-item-" + i} className="item type1 padding-bottom-4">
                <div className="task col-lg-7 col-md-6 col-sm-6 no-padding">
                    <div>
                        <div className="name" title={tmpFile.name}>
                            {tmpFile.uploadPercentage === 100 ? (<div className="form-group form-check">
                                <CheckBoxComponent
                                    id={"Uploaded-doc-fill" + i}
                                    datatestAuto={"3A08F399-77F1-4F00-A144-CA678BCFF7E7"}
                                    text=""
                                    checked={selectedDocuments.includes(tmpFile.id)}
                                    onChange={(e) => sourceDocumentSelected(e.target.checked, tmpFile.id)}
                                    className="form-check-input"
                                />
                                <label className="form-check-label margin-top-3">{tmpFile.name}</label>
                            </div>) : <>{tmpFile.name}</>}
                        </div>
                        <div className="uploaded-date-mobile height-20p" title={dateUtilities.getDateInMMDDYYYYFormat(tmpFile.uploadedOn)}>
                            {dateUtilities.getDateInMMDDYYYYFormat(tmpFile.uploadedOn)}
                        </div>
                    </div>
                </div>
                <div className="dates col-lg-3 col-md-3 col-sm-3 no-padding d-lg-block margin-top-4">
                    <div className="bar" title={dateUtilities.getDateInMMDDYYYYFormat(tmpFile.uploadedOn)}>
                        <div className="margin-top-2">
                            {dateUtilities.getDateInMMDDYYYYFormat(tmpFile.uploadedOn)}</div>
                    </div>
                </div>

                <div className="priority col-lg-2 col-md-3 col-sm-3 no-padding">
                    {tmpFile.uploadPercentage == 100 ? (
                        <div>
                            <button disabled={isClientView} className={"btn btn-default btn-sm no-top-padding"} style={{ color: '#0873ba', border: 'none', cursor: 'default', boxShadow: 'none', margin: '0.25rem 0 0 1.5rem' }}>
                                <span title="Preview document" >
                                    <i className='fas fa-eye fa-lg' style={{ cursor: 'pointer' }} onClick={() => { !isClientView && tmpFile.id && handlePreview(tmpFile.id, tmpFile.name) }} />
                                </span>

                            </button>

                            <button className={"btn btn-default btn-sm no-top-padding"} style={{ color: '#88c656', border: 'none', boxShadow: 'none', cursor: 'default', margin: '0.25rem 0 0 1rem' }} disabled={deletedButtonDisable}>
                                <span className={'src-delete-icon-container' + (deletedButtonDisable ? ' disable-src-document-delete' : '')}
                                    title={!deletedButtonDisable ? "Delete source document" : 'Document upload completed'} >
                                    <i className='fas fa-trash-alt fa-lg'
                                        onClick={() => { !deletedButtonDisable && tmpFile.id && handleDeleteDocument(tmpFile.id, tmpFile.name) }}></i>
                                </span>
                            </button>
                        </div>)
                        : <div className={"upload-documents-progress-conatiner"}> <span className={"upload-documents-progress"}>{uploadPercentage}</span></div>
                    }
                </div>

            </li>);
        }

        return collection;
    }

    return (<div className="file-list-container filepicker">
        <div className={(files.length > 0 ? ("filepicker uploaded-files-list minimized") : ("filepicker uploaded-files-list"))} data-test-auto="AA01CE21-29D4-42E6-9F0C-2E058D3E9A46">
            <ul className="task-items">
                {createFileList()}
            </ul>
        </div>
    </div>);
};