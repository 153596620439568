import { injectable } from "inversify";

export interface ILoader {
    loadingStatus: boolean;
    show(text?: string, container?: string, top?: number): void;
    hide(): void;
    setContainer(container: string): void;
    resetContainer(): void
}

@injectable()
export class Loader implements ILoader {

    public loadingStatus: boolean;
    private loaderContainer: string;

    constructor() {
        this.loadingStatus = false;
        this.loaderContainer = "body";
        this.setContainer("body");
    }

    public setContainer(container: string, top?: number, text?: string): void {

        this.loaderContainer = container;
        this.removeLoader();

        const element = document.querySelector(this.loaderContainer);

        if (element) {
            element.appendChild(this.getLoaderElement(top, text));
        }
    }


    public resetContainer(text?: string): void {
        this.setContainer("body", undefined, text);
    }


    // top have to set loader icon top if we are specifying conatiner
    public show(text?: string, container?: string, top?: number): void {

        if (container) {
            this.setContainer(container, top, text)
        } else {
            this.resetContainer(text);
        } 

        this.loadingStatus = true;
        let loaderElement: any = document.getElementById("overlay-loader");

        if (loaderElement == undefined) {
            this.setContainer("body");
            loaderElement = document.getElementById("overlay-loader");
        }

        loaderElement && loaderElement.setAttribute("data-show", "true");
    }

    public hide(): void {
        const loaderElement: any = document.getElementById("overlay-loader");
        loaderElement && loaderElement.removeAttribute("data-show");
        this.loadingStatus = false;
        this.removeLoader && this.removeLoader();
    }


    public removeLoader(): void {
        const loaderElement: any = document.getElementById("overlay-loader");
        loaderElement && loaderElement.remove();
    }

    private getLoaderElement(top?: number,text?:string): any {

        let spinner: any = document.createElement("div");
        spinner.classList.add('spinner');

        if (top) {
            spinner.style.top = top + "%";
        }

        let overlayLoader: any = document.createElement("div");
        overlayLoader.id = "overlay-loader";  
        overlayLoader.classList.add('d-none');

        overlayLoader.appendChild(spinner);

        if (text) {
            let textEl: any = document.createElement("p");
            textEl.innerText = text;
            textEl.classList.add('overlay-loader-text');
            overlayLoader.appendChild(textEl);
        }

        return overlayLoader;

    }

}