import React, { useEffect, useState } from "react";
import Section from "./Section";
import { Footer } from "./Footer";
import { container } from "../../startup/inversify.config";
import { ILoader } from "../../core/utilities/ui/Loader";
import { TYPES } from "../../startup/types";
import { RouteComponentProps } from "react-router";
import {
    IFormData,
    IQuestionnaireState,
    QuestionType,
} from "../../core/domain/models/Organizer/CustomQuestionModel";
import { IOnChangeHandlerPayload, IHandleChoiceIdentifierPayload, IYesNoChoiceIdentifierPayload } from "../../store/CqPreview/ActionTypes";
import {
    OrganizerConstants,
    OrganizerStatusChangedWarning,
    ValidationModalConstants,
} from "../Common/Constants";
import ValidationModal, {
    IValidationData,
    intialModalData,
} from "./ValidationModal";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import CqProgressBar from "./CqProgressBar";
import { FormGroupStatus, StatusCode } from "../../core/domain/models/Organizer/Organizer";
import { IsClientViewFromCache, IsPreviewModeFromCache } from "../Helper/HelperFunction";
import { IDialogBox } from "../../core/utilities/ui/DialogBox";
import { getCustomQuestionStatus } from "../../store/CqPreview/Actions";

type CustomQuestionProps = {
    getFormData: (clientId: string, callback?: () => void) => void;
    onSaveForm: (clientId: string, data: IFormData, callback: () => void) => void;
    onFinishForm: (
        clientId: string,
        data: IFormData,
        callback: () => void
    ) => void;
    handleParagraphOnChange: (payload: IOnChangeHandlerPayload) => void;
    setIsInvalidFlag: () => void;
    CustomQuestionStore: IQuestionnaireState;
    handleYesNoOnChange: (payload: IYesNoChoiceIdentifierPayload) => void;
    handleMcqOnChange: (payload: IHandleChoiceIdentifierPayload) => void;
    requestOrganizerMetadata: (clientId: string) => void;
} & RouteComponentProps<{ clientId: string }>;

const CustomQuestions: React.FC<CustomQuestionProps> = ({
    getFormData,
    onSaveForm,
    onFinishForm,
    CustomQuestionStore,
    handleParagraphOnChange,
    setIsInvalidFlag,
    match,
    handleYesNoOnChange,
    handleMcqOnChange,
    requestOrganizerMetadata
}) => {
    let { formData } = CustomQuestionStore;
    const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
    const loader = container.get<ILoader>(TYPES.ILoader);
    const [showValidationModal, setShowValidationModal] = useState(false);
    const [validationModalData, setValidationModalData] =
        useState<IValidationData[]>(intialModalData);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [filledQuestions, setFilledQuestions] = useState(0);

    useEffect(() => {
        loader.show();
        getCustomQuestionStatus(match.params.clientId)
            .then((data: number) => {
                validateCustomQuestionStatus(data);
            })
            .catch((err: string) => {
                handleRedirect(
                    OrganizerConstants.OrganizerSummaryPageURL + match.params.clientId
                );
            });
        requestOrganizerMetadata(match.params.clientId);
    }, []);

    useEffect(() => {
        const totalCount = findTotalQuestionsCount();
        if (totalCount && totalCount > 0) {
            setTotalQuestions(totalCount);
        }
        calculateProgress();
    }, [formData.sections]);

    const validateCustomQuestionStatus = (
        customQuestionStatus: FormGroupStatus
    ) => {
        if (customQuestionStatus == FormGroupStatus.QuestionnaireCompleted) {
            dialogBox.refreshAlert(OrganizerStatusChangedWarning, () => {
                handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + match.params.clientId);
            });
        } else {
            getFormData(match.params.clientId, loader.hide);
        }
    };

    const handleRedirect = (url: string): any => {
        window.location.href = url;
    };

    const handleSkipAndFinishForPreview = () => {
        if (IsPreviewModeFromCache()) {
            handleRedirect(OrganizerConstants.PreviewURL + match.params.clientId);
            return true;
        }
        if (IsClientViewFromCache()) {
            handleRedirect(OrganizerConstants.ClientViewURL + match.params.clientId);
            return true;
        }
        return false;
    }

    const isFormDataValid = (data: IFormData) => {
        const output: IValidationData[] = [
            {
                sectionIndex: 0,
                requiredSectionItems: [],
            },
        ];

        const addSectionItemToSection = (index: number, item: number) => {
            const existingItemIndex = output.findIndex(
                (outputItem) => outputItem.sectionIndex === index
            );
            if (existingItemIndex !== -1) {
                if (!output[existingItemIndex].requiredSectionItems.includes(item)) {
                    output[existingItemIndex].requiredSectionItems.push(item);
                }
            } else {
                const temp: IValidationData = {
                    sectionIndex: 0,
                    requiredSectionItems: [],
                };
                temp.sectionIndex = index;
                temp.requiredSectionItems.push(item);
                output.push(temp);
            }
        };

        const trimEmptySection = () => {
            const existingItemIndex = output.findIndex(
                (outputItem) => outputItem.requiredSectionItems.length === 0
            );
            if (existingItemIndex !== -1) {
                output.splice(existingItemIndex, 1);
            }
        };

        data.sections.forEach((section, sectionIndex) => {
            section.sectionItems.forEach((sectionItem, sectionItemIndex) => {
                if (sectionItem.isRequired) {
                    if (sectionItem.questionType === QuestionType.PARAGRAPH &&
                        (sectionItem.answer === undefined ||
                            sectionItem.answer.answerText === undefined ||
                            sectionItem.answer.answerText?.trim().length === 0
                        )) {
                        addSectionItemToSection(sectionIndex, sectionItemIndex);
                    }
                    else if (sectionItem.choices?.length > 0) {
                        if (!sectionItem.choices.some((choice) => choice.isSelected)) {
                            addSectionItemToSection(sectionIndex, sectionItemIndex);
                        }
                        else {
                            sectionItem.choices.forEach((choice) => {
                                if (choice.isSelected && choice.subQuestions?.length > 0) {
                                    choice.subQuestions.map(subQuestion => {
                                        if (subQuestion.isRequired) {
                                            if (subQuestion.choices.length > 0) {
                                                if (!subQuestion.choices.some((choice) => choice.isSelected)) {
                                                    addSectionItemToSection(sectionIndex, sectionItemIndex);
                                                }
                                            }
                                            else if (subQuestion.answer === undefined ||
                                                subQuestion.answer.answerText === undefined ||
                                                subQuestion.answer.answerText?.trim().length === 0
                                            ) {
                                                addSectionItemToSection(sectionIndex, sectionItemIndex);
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    }
                }
            });
        });

        trimEmptySection();

        if (output.length > 0) {
            setValidationModalData(output);
            setShowValidationModal(true);

            return false;
        } else {
            return true;
        }
    };
    const onSaveCallback = (data?: any) => {
        const url =
            OrganizerConstants.OrganizerSummaryPageURL + match.params.clientId;
        if (data.statusCode === StatusCode.Conflict) {
            loader.hide();
            dialogBox.refreshAlert(OrganizerStatusChangedWarning, () => {
                window.location.href = url;
            })
        }
        else {
            window.location.href = url;
            loader.hide();
        }
    };

    const onSaveClick = () => {
        if (!handleSkipAndFinishForPreview()) {
            loader.show();
            onSaveForm(match.params.clientId, formData, onSaveCallback);
        }
    };

    const onFinshClick = () => {
        if (!handleSkipAndFinishForPreview() && isFormDataValid(formData)) {
            loader.show();
            onFinishForm(
                match.params.clientId,
                formData,
                // loader.hide,
                onSaveCallback
            );
        }
    };

    const onCloseValidationModal = () => {
        setShowValidationModal(false);
        setIsInvalidFlag();
        MarsNotifier.Error(ValidationModalConstants.FORM_VALIDATION_ERROR, null);
        let scrollId = "";
        if (validationModalData[0].requiredSectionItems.length !== 0) {
            scrollId = `s-${validationModalData[0].sectionIndex}_q-${validationModalData[0].requiredSectionItems[0]}`;
        }
        const element = document.getElementById(scrollId);
        element && element.scrollIntoView({ block: "center" });
        setValidationModalData(intialModalData);
    }

    const findTotalQuestionsCount = () => {
        let totalQuestions = 0;
        if (formData.sections.length > 0) {
            formData.sections.forEach((section) => {
                totalQuestions = totalQuestions + section.sectionItems.length;
            });
            return totalQuestions;
        }
    };

    const calculateProgress = () => {
        let totalFilledQuestions = 0;
        if (formData.sections.length > 0) {
            formData.sections.forEach((section) => {
                section.sectionItems.forEach((sectionItem) => {
                    if (sectionItem.choices.length > 0) {
                        sectionItem.choices.forEach((choice) => {
                            if (choice.isSelected) {
                                if (choice.subQuestions.length > 0 && sectionItem.isRequired) {
                                    let totalInnerSubQuestion = choice.subQuestions.length;
                                    let subQuestionIsSelected = 0;
                                    choice.subQuestions.map(subQuestion => {
                                        if (subQuestion.choices.length > 0) {
                                            subQuestion.choices.forEach((subChoice) => {
                                                if (subChoice.isSelected) {
                                                    subQuestionIsSelected++;
                                                }
                                            })
                                        }
                                        else if (subQuestion.answer.answerText?.trim().length > 0) {
                                            subQuestionIsSelected++;
                                        }
                                        if (subQuestionIsSelected === totalInnerSubQuestion) {
                                            totalFilledQuestions++;
                                        }
                                    })
                                }
                                else {
                                    totalFilledQuestions++;
                                }
                            }
                        })
                    }
                    else if (sectionItem.answer?.answerText?.trim().length > 0) {
                        totalFilledQuestions++;
                    }
                });
            });
            setFilledQuestions(totalFilledQuestions);
        }
    };

    return (
        <>
            <div className="cq-tab-wrapper">
                {formData.sections.length > 0 && (
                    <div className="custom-questions-tab-template-container">
                        <div className="questionnaire-header-container">
                            <div className="title-description-container">
                                <div className="title ellipsis noborder" title={formData.title}>
                                    {formData.title}
                                </div>
                                <div className="description-container ellipsis">
                                    <div
                                        title={formData.description}
                                        className="description ellipsis noborder"
                                    >
                                        {formData.description}
                                    </div>
                                </div>
                            </div>
                            <CqProgressBar
                                progressBarSize={100}
                                strokeWidth={5}
                                filledQuestions={filledQuestions}
                                totalQuestions={totalQuestions}
                            />
                        </div>

                        {formData &&
                            formData.sections?.length > 0 &&
                            formData.sections.map((section, sectionIndex) => (
                                <Section
                                    key={sectionIndex}
                                    sectionIndex={sectionIndex}
                                    section={section}
                                    sectionsCount={formData.sections.length}
                                    handleParagraphOnChange={handleParagraphOnChange}
                                    calculateProgress={calculateProgress}
                                    handleYesNoOnChange={handleYesNoOnChange}
                                    handleMcqOnChange={handleMcqOnChange}
                                />
                            ))}
                    </div>
                )}
            </div>

            <Footer onSave={onSaveClick} onFinish={onFinshClick} />
            <ValidationModal
                show={showValidationModal}
                onClose={onCloseValidationModal}
                validationModalData={validationModalData}
            />
        </>
    );
};

export default CustomQuestions;
