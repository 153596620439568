import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
// import { ClientTypesNumber } from '../../core/domain/models/ISignerModel';
import { initializeAxios } from '../../core/Services/dataAccess/DataService.Axios';
import { actionTypes } from '../ActionTypes';
import { StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import {
    KnownAction,
    DispatchAction,
    UploadedDocumentStoreState,
    initialUploadedDocumentStoreState,
    UploadedDocumentStatus,
    SourceDocumentStatus,
    CustomResponse,
    ConflictErrorMessage
} from './UploadedDocumentStoreModels';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { ILoader } from '../../core/utilities/ui/Loader';
import { ClientTypesNumber } from '../../core/domain/models/ISignerModel';
import { RedirectToDashBoard } from 'src/components/Helper/HelperFunction';
import { IDialogBox } from 'src/core/utilities/ui/DialogBox';
import { StatusCode } from 'src/core/domain/models/Organizer/Organizer';

const loader = container.get<ILoader>(TYPES.ILoader);
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export const actionCreators = {

    requestUploadedDocumentStatus: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({ type: actionTypes.UPLOADED_DOCUMENT_LOADER, loading: true });

        initializeAxios().get<UploadedDocumentStatus>(`api/OrganizerAdditionalDocument/GetUploadedDocumentStatus/${clientId}`)
            .then((response: AxiosResponse<UploadedDocumentStatus>) => {

                const data = response.data;
                dispatch({ type: actionTypes.RECEIVE_UPLOADED_DOCUMENT_STATUS, payload: data });

                dispatch({ type: actionTypes.UPLOADED_DOCUMENT_LOADER, loading: false });
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: "Error fetching Uploaded Document Status",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.UPLOADED_DOCUMENT_LOADER, loading: false });
            })
    },

    setUploadedDocumentCompleted: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({ type: actionTypes.RECEIVE_UPLOADED_DOCUMENT_COMPLETED, completed: true });

    },

    setUploadedDocumentStatus: (clientType: ClientTypesNumber, documentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        var data: UploadedDocumentStatus = {
            actedBy: clientType,
            status: SourceDocumentStatus.Completed,
            createdOn: new Date(),
            updatedOn: new Date(),
            documentId: documentId,
            id: 0,
            isSourceDocumentCompleted: false
        }

        dispatch({ type: actionTypes.RECEIVE_UPLOADED_DOCUMENT_STATUS, payload: data })

    },

    changeSourceDocumentStatusToCompleted: (
        clientId: string,
        clientType: ClientTypesNumber,
        documentId: number,
        successCallback: () => void
    ): AppThunkAction<KnownAction> => (dispatch, getState) => {

        loader.show();

        initializeAxios().post(`api/OrganizerAdditionalDocument/ChangeSourceDocumentStatusToCompleted/${clientId}`)
            .then((response: AxiosResponse<CustomResponse<any>>) => {
                if (response.data.statusCode === StatusCode.Conflict) {

                    dispatch({ type: actionTypes.UPLOADED_DOCUMENT_ERROR, errorMessage: ConflictErrorMessage, isError: true });

                    dispatch(actionCreators.setUploadedDocumentCompleted());

                    dispatch(actionCreators.requestUploadedDocumentStatus(clientId));

                    loader.hide();

                    return;
                }
                dispatch(actionCreators.setUploadedDocumentStatus(clientType, documentId));
                successCallback();
                loader.hide();
            }).catch((error: any) => {
                loader.hide();
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                if (error?.response?.status === StatusCode.Locked) {
                    RedirectToDashBoard(clientId, dialogBox);
                }
            });

    },

    getSourceDocumentMetadataAsync: (clientId: string, uploadedDocumentId: number, successCallback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        loader.show();

        initializeAxios().get<any>(`api/OrganizerAdditionalDocument/GetSourceDocumentMetadataAsync/${clientId}?uploadedDocumentId=${uploadedDocumentId}`)
            .then((response: AxiosResponse<any>) => {
                if (response.data?.statusCode === StatusCode.NotFound) {

                    dispatch({ type: actionTypes.UPLOADED_DOCUMENT_ERROR, errorMessage: response.data?.data, isError: true });

                    dispatch(actionCreators.requestUploadedDocumentStatus(clientId));

                    loader.hide();

                    return;
                }
                dispatch({
                    type: actionTypes.SET_SOURCE_DOCUMENT_META_DATA,
                    sourceDocumentMetaData: response.data
                })
                successCallback && successCallback();
                loader.hide();
            }).catch((error: any) => {
                loader.hide();
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: 'Failed to fetch Client Tracking',
                    statusType: StatusType.Error
                });
                failureCallback && failureCallback();
            })
    },

    resetUploadedDocumentError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.UPLOADED_DOCUMENT_ERROR_RESET })
    },

    setUploadedDocumentError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.UPLOADED_DOCUMENT_ERROR, errorMessage: ConflictErrorMessage, isError: true });
    },

}

export const reducer: Reducer<UploadedDocumentStoreState> = (
    state: UploadedDocumentStoreState = initialUploadedDocumentStoreState,
    incomingAction: Action) => {

    const action = incomingAction as DispatchAction;

    const currentState = Object.assign({}, state);

    switch (action.type) {

        case actionTypes.RECEIVE_UPLOADED_DOCUMENT_COMPLETED: {
            return { ...currentState, isCompleted: action.completed }
        }

        case actionTypes.UPLOADED_DOCUMENT_LOADER: {
            return { ...currentState, loading: action.loading }
        }

        case actionTypes.RECEIVE_UPLOADED_DOCUMENT_STATUS: {
            return { ...currentState, isCompleted: action.payload ? action.payload.isSourceDocumentCompleted : false, uploadedDocumentStatus: action.payload }
        }

        case actionTypes.UPLOADED_DOCUMENT_ERROR: {
            return { ...currentState, isError: action.isError, errorMessage: action.errorMessage }
        }

        case actionTypes.UPLOADED_DOCUMENT_ERROR_RESET: {
            return { ...currentState, isError: false, errorMessage: "" }
        }

        case actionTypes.SET_SOURCE_DOCUMENT_META_DATA: {
            return {
                ...currentState,
                sourceDocumentMetaData: action.sourceDocumentMetaData
            }
        }

        default:
            return currentState || initialUploadedDocumentStoreState;
    }
}