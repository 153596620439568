import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import {
	FormGroupStatus,
	OrganizerMetadata,
	PreparerMessage,
} from "../../core/domain/models/Organizer/Organizer";
import { IFileUtilities } from "../../core/utilities/File/FileUtilities";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import * as OrganizerMetadataStore from "../../store/Organizer/OrganizerMetadataStore";
import * as OrganizerStore from "../../store/Organizer/OrganizerStore";
import * as PreparerMessageStore from "../../store/Organizer/PreparerMessageStore";
import {
	OrganizerConstants,
	SourceDocumentConstants,
	SignProcessConstants,
	SignProcessInfoConstants,
	PreviewConstants,
} from "../Common/Constants";
import {
	SetDataToStorage,
	IsPreviewMode,
	GetDataFromStorage,
} from "../Helper/HelperFunction";
import { logger } from "../../routes";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { OrganizerPreparerMessageState } from "../../store/Organizer/PreparerMessageStore";

import { UploadedDocumentStoreState } from "../../store/UploadedDocument/UploadedDocumentStoreModels";
import { actionCreators as UploadedDocumentStore } from "../../store/UploadedDocument/UploadedDocumentStore";
import { IOrganizerService } from "../../core/Services/OrganizerService/OrganizerService";
import { IDialogBox } from "../../core/utilities/ui/DialogBox";
import { ILoader } from "../../core/utilities/ui/Loader";
import { DisplayDownloadFile } from "../../core/utilities/DisplayDownloadFile";
import { IHeaderInfoViewModel } from "../../core/domain/viewModels/IHeaderInfoViewModel";
import {
	NotToShowSummaryPageSecondaryMessage,
	ShowSummaryPageSecondaryMessage,
	GetRedirectUrlBasedOnStatus,
	EnableDisableUploadDocumentBasedOnStatus,
	EnableDisableCustomQuestionBasedOnStatus,
	EnableDisableOrganizerBasedOnStatus,
	GetTooltipForOrganizer,
	ShowThanksMessageIfOrganizerCompletedAndDownloadable,
	IsNullorEmpty,
	GetMenuSeperator,
	HandleRedirect,
	EnableDisableContinueBasedOnStatus,
	ShowClickHereToContinueTextBasedOnStatus,
	HideClickHereToContinueTextBasedOnStatus,
	ShowClickHereToContinueTextDependOnStatus,
} from "../Helper/ClientviewTpPreviewHelper";
import { SignProcessInfo, SignProcessSteps } from "../../core/domain/models/signingprocess/SignProcess";
import { Shimmer } from "../Common/Shimmer/Shimmer";
import CompleteOrganizerIcon from "../../assets/images/icon-complete-organizer.svg";
import DownloadOrganizerIcon from "../../assets/images/icon-download-organizer.svg";
import SummarySignDocumentsIcon from "../../assets/images/icon-summary-sign-documents.svg";
import DownloadDocumentIcon from "../../assets/images/icon-download-documents.svg";
import UploadDocumentIcon from "../../assets/images/icon-upload-documents.svg";
import * as SignalRStore from "src/store/SignalR/SignalRStore";

var htmlencode = require("htmlencode");


export type EngagementLetterSignProps = {
	signalROrganizerState: SignalRStore.OrganizerState;
	organizerMetadata: OrganizerMetadata;
	preparerMessageStore: OrganizerPreparerMessageState;
	uploadedDocumentStatus: UploadedDocumentStoreState;
	headerInfo: IHeaderInfoViewModel;
} & typeof SignalRStore.actionCreators &
	typeof OrganizerMetadataStore.actionCreators &
	typeof OrganizerStore.actionCreators &
	typeof PreparerMessageStore.actionCreators &
	typeof UploadedDocumentStore &
	RouteComponentProps<{}>;

interface EngagementLetterSignState {
	organizerStatus: FormGroupStatus;
	engagementLetterStatus: FormGroupStatus;
	organizerMetadata: OrganizerMetadata;
	preparerMessage: PreparerMessage;
	enabledSourceDocuments: boolean;
	signingOrder: number;
	engagementSignerStatus: FormGroupStatus;
	isEngagementControl: boolean;
	hasCustomQuestion: boolean;
	customQuestionStatus: FormGroupStatus;
}

const organizerService = container.get<IOrganizerService>(
	TYPES.IOrganizerService
);
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
const loader = container.get<ILoader>(TYPES.ILoader);

export class OrganizerSignFlow extends React.Component<
	EngagementLetterSignProps,
	EngagementLetterSignState
> {
	private _param: any;

	constructor(props: EngagementLetterSignProps) {
		super(props);

		this.state = {
			engagementLetterStatus: FormGroupStatus.None,
			organizerStatus: FormGroupStatus.None,
			organizerMetadata: OrganizerMetadata.createNullObject(),
			preparerMessage: PreparerMessage.createNullObject(),
			enabledSourceDocuments: false,
			signingOrder: 1,
			engagementSignerStatus: FormGroupStatus.None,
			isEngagementControl: true,
			hasCustomQuestion: false,
			customQuestionStatus: FormGroupStatus.None,
		};
	}

	componentDidMount() {
		this._param = this.props.match.params;
		this.props.requestOrganizerMetadata(this._param.clientId);
		this.props.requestOrganizerPreparerMessage(this._param.clientId);
		this.props.requestUploadedDocumentStatus(this._param.clientId);
		this.getDocumentStatus();
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.signalROrganizerState?.organizerStatus !== this?.props?.signalROrganizerState?.organizerStatus) {
			this.setState({ organizerStatus: this.props.signalROrganizerState.organizerStatus });
		}
	}

	private getDocumentStatus = () => {
		const _self = this;

		organizerService
			.getDocumentStatusAndSourceFileSettings(this._param.clientId)
			.then(function (response: any) {
				if (_self && _self.props && _self.props.updateSignalROrganizerStatus) {
					_self.props.updateSignalROrganizerStatus(response.data.organizerStatus);
				}
				_self.setState({
					engagementLetterStatus: response.data.engagementLetterStatus, //FormGroupStatus.AwaitingESign, //
					organizerStatus: response.data.organizerStatus,
					hasCustomQuestion: response.data.hasCustomQuestion,
					enabledSourceDocuments: response.data.enabledSourceDocuments,
					signingOrder: response.data.client.signingOrder,
					engagementSignerStatus: response.data.engagementSignerStatus,
					isEngagementControl: response.data.isEngagementcontrol,
					customQuestionStatus: response.data.customQuestionStatus,
				});
				SetDataToStorage(
					SourceDocumentConstants.IsSourceDocumentEnabled,
					_self.state.enabledSourceDocuments.toString()
				);

				_self.getLastProcessedStep();
			});
	};

	private getLastProcessedStep = () => {
		const isPreview = IsPreviewMode(this.props.organizerMetadata);
		if (isPreview) {
			return;
		}
		let SignProcessInfo: SignProcessInfo = GetDataFromStorage(
			SignProcessInfoConstants.SignProcessInfo
		);
		if (SignProcessInfo) {
			return;
		}

		if (
			(this.state.organizerStatus != FormGroupStatus.Completed &&
				this.state.organizerStatus != FormGroupStatus.ManuallyCompleted) ||
			(this.state.engagementLetterStatus != FormGroupStatus.None &&
				this.state.engagementLetterStatus != FormGroupStatus.ESigned &&
				this.state.engagementLetterStatus != FormGroupStatus.ManuallySigned &&
				this.state.engagementLetterStatus != FormGroupStatus.Reviewed)
		) {
			const _self = this;
			organizerService
				.getSignProcessinfo(this._param.clientId)
				.then(function (response: any) {
					if (
						response?.data?.lastVisitedStep &&
						response?.data?.lastVisitedStep != SignProcessSteps.Summary
					) {
						SetDataToStorage(
							SignProcessInfoConstants.SignProcessInfo,
							JSON.stringify(response.data)
						);
						_self.signProcessRecoveryConfirmation(response?.data);
					}
				});
		}
	};

	private signProcessRecoveryConfirmation = (ProcessInfo: SignProcessInfo) => {
		const _self = this;
		const hasCustomQuestionAndPartiallyCompleted: boolean =
			_self.state.hasCustomQuestion &&
			_self.state.customQuestionStatus === FormGroupStatus.PartiallyCompleted &&
			_self.state.organizerStatus === FormGroupStatus.PartiallyCompleted;

		if (
			ProcessInfo.lastVisitedStep == SignProcessSteps.SignEngagementLetter &&
			(this.state.engagementLetterStatus == FormGroupStatus.ESigned ||
				this.state.engagementLetterStatus == FormGroupStatus.ManuallySigned ||
				this.state.engagementLetterStatus == FormGroupStatus.Reviewed)
		) {
			return;
		}
		if (
			ProcessInfo.lastVisitedStep == SignProcessSteps.CompleteOrganizer &&
			(this.state.organizerStatus == FormGroupStatus.Completed ||
				this.state.organizerStatus == FormGroupStatus.ManuallyCompleted)
		) {
			return;
		}

		dialogBox.confirmCustom(
			SignProcessConstants.ProcessRecoveryConfirmation,
			SignProcessConstants.ProcessRecoveryMessage,
			SignProcessConstants.ProcessRecoveryConfirmationDialogLeftButton,
			SignProcessConstants.ProcessRecoveryConfirmationDialogRightButton,
			function (result: boolean) {
				if (result) {
					let url;

					if (
						ProcessInfo.lastVisitedStep == SignProcessSteps.SignEngagementLetter
					) {
						url = OrganizerConstants.EngagementLetterSignPageURL;
					} else if (hasCustomQuestionAndPartiallyCompleted) {
						url = OrganizerConstants.CustomQuestionsURL;
					} else {
						url = OrganizerConstants.OrganizerSignPageURL;
					}

					window.location.href = `${url}${_self._param.clientId}`;
				} else {
					ProcessInfo.lastVisitedStep = SignProcessSteps.Summary;
					ProcessInfo.lastVisitedPageNo = 1;
					organizerService.updateSignProcessinfo(ProcessInfo);
					SetDataToStorage(
						SignProcessInfoConstants.SignProcessInfo,
						JSON.stringify(ProcessInfo)
					);
				}
			}
		);
	};

	private downloadOrganizerDocument = () => {
		if (IsPreviewMode(this.props.organizerMetadata)) {
			MarsNotifier.Warning(PreviewConstants.DownloadMessage, null);
			return;
		}

		const _self = this;
		let fileName: string;
		organizerService
			.getOrganizerDownloadLink(this._param.clientId)
			.then(function (response: any) {
				const contentDisposition = response.headers["content-disposition"];
				const fileNameMatch = contentDisposition
					? /filename="?([^"]*)"?;/g.exec(contentDisposition)
					: undefined;
				if (fileNameMatch && fileNameMatch.length > 1) {
					fileName = fileNameMatch[1];
				}
				let displayDownloadFile = new DisplayDownloadFile();
				if (fileName != undefined) {
					displayDownloadFile.showFile(response.data, fileName);
				} else {
					MarsNotifier.Error("Failed to download Organizer document", null);
				}
			})
			.catch(function (error: any) {
				logger.trackError(
					`downloadOrganizerDocument failed with error ${error.message} for client: ${_self._param.clientId}`
				);
			});
	};

	private downloadEngagementLetter = () => {
		if (IsPreviewMode(this.props.organizerMetadata)) {
			MarsNotifier.Warning(PreviewConstants.DownloadMessage, null);
			return;
		}

		const _self = this;
		let fileName: string;
		loader.show();
		organizerService
			.getEngagemementLetterDownloadData(this._param.clientId)
			.then(function (response: any) {
				const contentDisposition = response.headers["content-disposition"];
				const fileNameMatch = contentDisposition
					? /filename="?([^"]*)"?;/g.exec(contentDisposition)
					: undefined;
				if (fileNameMatch && fileNameMatch.length > 1) {
					fileName = fileNameMatch[1];
				}
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.showFile(response.data, fileName);
				loader.hide();
			})
			.catch(function (error: any) {
				logger.trackError(
					`downloadAllVouchers failed with error ${error.message} for client: ${_self._param.clientId}`
				);
			});
	};

	private getRedirectUrl = () => {
		const {
			engagementLetterStatus,
			signingOrder,
			engagementSignerStatus,
			isEngagementControl,
			hasCustomQuestion,
			customQuestionStatus,
		} = this.state;

		return GetRedirectUrlBasedOnStatus(
			engagementLetterStatus,
			signingOrder,
			engagementSignerStatus,
			isEngagementControl,
			hasCustomQuestion,
			customQuestionStatus,
			this._param.clientId
		);
	};

	private getCustomQuestionsButton() {
		const {
			engagementSignerStatus,
			engagementLetterStatus,
			signingOrder,
			isEngagementControl,
			customQuestionStatus,
			organizerStatus,
		} = this.state;

		let isDisableable: boolean = EnableDisableCustomQuestionBasedOnStatus(
			engagementSignerStatus,
			engagementLetterStatus,
			signingOrder,
			isEngagementControl,
			customQuestionStatus,
			organizerStatus
		);

		let redirectUrl = `${OrganizerConstants.CustomQuestionsURL}${this._param.clientId}`;
		let questionnaireText = OrganizerConstants.SummaryPageCustomQuestionsText;
		let disableQuestionnaire =
			customQuestionStatus == FormGroupStatus.QuestionnaireCompleted ||
			organizerStatus == FormGroupStatus.ManuallyCompleted;

		if (disableQuestionnaire) {
			redirectUrl = "";
			questionnaireText =
				OrganizerConstants.SummaryPageCustomQuestionsCompletedText;
		}

		return (
			<React.Fragment>
				<div
					data-test-auto="d50c459c-bbe3-11eb-8529-0242ac130005"
					title={
						isDisableable
							? disableQuestionnaire
								? OrganizerConstants.CustomQuestionsCompleteCQDisabled
								: OrganizerConstants.EsignPendingCQDisabled
							: ""
					}
				>
					<div className={isDisableable ? "area-disabled" : ""}>
						<Link to={redirectUrl}>
							<img src={CompleteOrganizerIcon} />
							<br />
							<div className={"organizer-welcome-page-menu-icon-title"}>
								{questionnaireText}
							</div>
						</Link>
					</div>
				</div>{" "}
				{GetMenuSeperator()}
			</React.Fragment>
		);
	}

	private getOrganizerButton() {
		const {
			enabledSourceDocuments,
			engagementSignerStatus,
			engagementLetterStatus,
			signingOrder,
			isEngagementControl,
			hasCustomQuestion,
			customQuestionStatus,
		} = this.state;
		let redirectUrl: string = this.getRedirectUrl();

		const getIsDisabled = () => {
			return EnableDisableOrganizerBasedOnStatus(
				hasCustomQuestion,
				customQuestionStatus,
				engagementSignerStatus,
				engagementLetterStatus,
				signingOrder,
				isEngagementControl
			);
		};

		const isDisableable = getIsDisabled();

		return (
			<React.Fragment>
				<div
					data-test-auto="d50c459c-bbe3-11eb-8529-0242ac130003"
					title={GetTooltipForOrganizer(
						customQuestionStatus,
						engagementSignerStatus,
						signingOrder,
						isEngagementControl,
						hasCustomQuestion,
						engagementLetterStatus
					)}
				>
					<div className={isDisableable ? "area-disabled" : ""}>
						<Link to={redirectUrl}>
							<img src={CompleteOrganizerIcon} />
							<br />
							<div className={"organizer-welcome-page-menu-icon-title"}>
								{OrganizerConstants.SummaryPageCompleteOrganizerText}
							</div>
						</Link>
					</div>
				</div>{" "}
				{enabledSourceDocuments && GetMenuSeperator()}
			</React.Fragment>
		);
	}

	private getOrganizerDownloadButton() {
		return (
			<React.Fragment>
				<div
					onClick={this.downloadOrganizerDocument}
					data-test-auto="cc0a0b59-3dfc-42e5-b683-3a1a3687b5dd"
				>
					<img src={DownloadOrganizerIcon} />
					<br />
					<div className={"organizer-welcome-page-menu-icon-title"}>
						{OrganizerConstants.SummaryPageDownloadOrganizerText}
					</div>
				</div>{" "}
				{this.state.enabledSourceDocuments && GetMenuSeperator()}
			</React.Fragment>
		);
	}

	private getSignEngagementButton() {
		const { organizerStatus, engagementLetterStatus, signingOrder } =
			this.state;
		if (engagementLetterStatus == FormGroupStatus.Declined) {
			return (
				<React.Fragment>
					<div
						className={"area-disabled"}
						data-test-auto="d50c44d5-bbe3-11eb-8529-0242ac130003"
					>
						<img src={SummarySignDocumentsIcon} />
						<br />
						<div className={"organizer-welcome-page-menu-icon-title"}>
							{OrganizerConstants.SummaryPageDeclinedEngagementLetterText}
						</div>
					</div>{" "}
					<hr className={"organizer-welcome-page-menu-separator"} />
				</React.Fragment>
			);
		} else {
			let isEsignEnable: boolean =
				signingOrder == 2 &&
				engagementLetterStatus == FormGroupStatus.AwaitingESign;
			return (
				<React.Fragment>
					<div
						data-test-auto="d50c44d4-bbe3-11eb-8529-0242ac130003"
						title={
							isEsignEnable
								? OrganizerConstants.SummaryPageSignEngagementLetterTextForSecondSigner
								: ""
						}
					>
						<div className={isEsignEnable ? "area-disabled" : ""}>
							<Link to={"/engagementletter/sign/" + this._param.clientId}>
								<img
									src={SummarySignDocumentsIcon}
								/>
								<br />
								<div className={"organizer-welcome-page-menu-icon-title"}>
									{OrganizerConstants.SummaryPageSignEngagementLetterText}
								</div>
							</Link>
						</div>
					</div>{" "}
					{organizerStatus != FormGroupStatus.None && GetMenuSeperator()}
				</React.Fragment>
			);
		}
	}

	private getPreparerMessage() {
		return (
			<React.Fragment>
				<div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 container"}>
					<div className="preparer-message-container">
						<div className="prepare-message-header">
							Message from{" "}
							{IsNullorEmpty(this.props.headerInfo?.companyName)
								? "Tax Preparer"
								: this.props.headerInfo?.companyName}
						</div>
						<br />
						<div
							className="preparer-message-content"
							dangerouslySetInnerHTML={{
								__html: htmlencode.htmlDecode(
									this.props.preparerMessageStore.preparerMessage.message
								),
							}}
						></div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	private getDownloadEngagementButton() {
		const { organizerStatus } = this.state;

		return (
			<React.Fragment>
				<div
					data-test-auto="d50c4b96-bbe3-11eb-8529-0242ac130003"
					onClick={this.downloadEngagementLetter}
				>
					<img src={DownloadDocumentIcon} />
					<br />
					<div className={"organizer-welcome-page-menu-icon-title"}>
						{OrganizerConstants.SummaryPageDownloadEngagementLetterText}
					</div>
				</div>{" "}
				{organizerStatus != FormGroupStatus.None && GetMenuSeperator()}
			</React.Fragment>
		);
	}

	private getCompletedUploadDocumentsButton() {
		return (
			<React.Fragment>
				<div data-test-auto="05f8ea7a-64bb-4413-819b-027f24d4f010">
					<Link to={"/uploaddocuments/" + this._param.clientId}>
						<img src={DownloadDocumentIcon} />
						<br />
						<div className={"organizer-welcome-page-menu-icon-title"}>
							{OrganizerConstants.SummaryPageCompletedUploadDocumentsText}
						</div>
					</Link>
				</div>
			</React.Fragment>
		);
	}

	private getUploadDocumentsButton() {
		const {
			engagementSignerStatus,
			engagementLetterStatus,
			signingOrder,
			isEngagementControl,
		} = this.state;
		let isDisableable: boolean = EnableDisableUploadDocumentBasedOnStatus(
			engagementSignerStatus,
			engagementLetterStatus,
			signingOrder,
			isEngagementControl
		);

		return (
			<React.Fragment>
				<div
					data-test-auto="05f8ea7a-64bb-4413-819b-027f24d4f0c9"
					title={
						isDisableable
							? OrganizerConstants.EsignPendingUploadDocumentDisabled
							: ""
					}
				>
					<div className={isDisableable ? "area-disabled" : ""}>
						<Link to={"/uploaddocuments/" + this._param.clientId}>
							<img src={UploadDocumentIcon} />
							<br />
							<div className={"organizer-welcome-page-menu-icon-title"}>
								{OrganizerConstants.SummaryPageUploadDocumentsText}
							</div>
						</Link>
					</div>
				</div>
			</React.Fragment>
		);
	}

	private getContinueButton() {
		const {
			engagementLetterStatus,
			organizerStatus,
			engagementSignerStatus,
			signingOrder,
			isEngagementControl,
		} = this.state;

		let isDisableable: boolean = EnableDisableContinueBasedOnStatus(
			signingOrder,
			engagementLetterStatus,
			isEngagementControl
		);
		let isContinueButtonVisibleForSpouse: boolean =
			signingOrder == 2 &&
			engagementSignerStatus == FormGroupStatus.NA &&
			(organizerStatus == FormGroupStatus.Completed ||
				organizerStatus == FormGroupStatus.ManuallyCompleted) &&
			(engagementLetterStatus == FormGroupStatus.PartiallySigned ||
				engagementLetterStatus == FormGroupStatus.PartiallyReviewed);

		let isContinueButtonVisibleForTP: boolean =
			signingOrder == 1 &&
			engagementSignerStatus == FormGroupStatus.NA &&
			(organizerStatus == FormGroupStatus.Completed ||
				organizerStatus == FormGroupStatus.ManuallyCompleted) &&
			engagementLetterStatus == FormGroupStatus.AwaitingESign;

		if (
			engagementLetterStatus == FormGroupStatus.None &&
			organizerStatus == FormGroupStatus.None
		) {
			return <Shimmer height={50} width={150} />;
		} else if (isDisableable) {
			return "";
		} else if (
			isContinueButtonVisibleForSpouse ||
			isContinueButtonVisibleForTP
		) {
			return (
				<a className="rd-nav-link btn-finish">
					<button
						type="submit"
						onClick={this.redirectToELSign}
						data-test-auto="d50c43ee-bbe3-11eb-8529-0242ac130003"
						className="btn btn-primary btn-primary-extended btn-login tablet-btn"
						id="btnContinue2"
					>
						{OrganizerConstants.SummaryPageContinueButtonText}
					</button>
				</a>
			);
		} else if (
			ShowClickHereToContinueTextBasedOnStatus(
				engagementLetterStatus,
				organizerStatus
			)
		) {
			return (
				<a className="rd-nav-link btn-finish">
					<button
						type="submit"
						onClick={this.redirectToELSign}
						data-test-auto="d50c43ee-bbe3-11eb-8529-0242ac130003"
						className="btn btn-primary btn-primary-extended btn-login tablet-btn"
						id="btnContinue2"
					>
						{OrganizerConstants.SummaryPageContinueButtonText}
					</button>
				</a>
			);
		} else if (HideClickHereToContinueTextBasedOnStatus(organizerStatus)) {
			return "";
		} else if (
			ShowClickHereToContinueTextDependOnStatus(
				engagementLetterStatus,
				organizerStatus
			)
		) {
			return (
				<a className="rd-nav-link btn-finish">
					<button
						type="submit"
						onClick={this.handleContinue}
						data-test-auto="d50c41d2-bbe3-11eb-8529-0242ac130003"
						className="btn btn-primary btn-primary-extended btn-login tablet-btn"
						id="btnContinue"
					>
						{OrganizerConstants.SummaryPageContinueButtonText}
					</button>
				</a>
			);
		} else {
			return "";
		}
	}

	private redirectToELSign = () => {
		HandleRedirect(
			OrganizerConstants.EngagementLetterSignPageURL + this._param.clientId
		);
	};

	private handleContinue = () => {
		HandleRedirect(this.getRedirectUrl());
	};

	private SummaryPageSecondaryMessage() {
		const { engagementLetterStatus, organizerStatus } = this.state;

		if (
			NotToShowSummaryPageSecondaryMessage(
				engagementLetterStatus,
				organizerStatus
			)
		) {
			return "";
		} else {
			return ShowSummaryPageSecondaryMessage();
		}
	}

	public render() {
		const {
			uploadedDocumentStatus: { isCompleted },
		} = this.props;
		const {
			engagementLetterStatus,
			organizerStatus,
			hasCustomQuestion,
			enabledSourceDocuments,
			isEngagementControl,
			signingOrder,
			engagementSignerStatus,
		} = this.state;
		const organizerMetadata: OrganizerMetadata = (
			this.props.organizerMetadata as any
		).organizerMetadata;

		return (
			<div
				className={
					"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page"
				}
			>
				<div
					className={
						"col-lg-10 col-md-10 col-xs-12 organizer-welcome-page-container offset-lg-1 offset-md-1"
					}
				>
					<div
						className={
							"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page-title-container"
						}
					>
						<span
							className={"organizer-welcome-page-title"}
							data-test-auto="d50c4664-bbe3-11eb-8529-0242ac130003"
						>
							Welcome{" "}
							{organizerMetadata.clientName ? (
								organizerMetadata.clientName
							) : (
								<Shimmer height={30} width={200} />
							)}
						</span>
					</div>

					<br />
					<br />

					<div
						className={
							"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page-message-container"
						}
					>
						{engagementLetterStatus == FormGroupStatus.None &&
							organizerStatus == FormGroupStatus.None ? (
							<span />
						) : ShowThanksMessageIfOrganizerCompletedAndDownloadable(
							engagementLetterStatus,
							organizerStatus
						) ? (
							<span className={"organizer-welcome-page-message"}>
								Thank you for completing your documents!
								<br />
								<br />
								You may click on a completed document below to download a copy
								for your records
							</span>
						) : (
							<span className={"organizer-welcome-page-message"}>
								{organizerMetadata.senderName ? (
									organizerMetadata.senderName
								) : (
									<Shimmer height={20} width={150} />
								)}{" "}
								has sent your{" "}
								{organizerMetadata.taxYear ? (
									organizerMetadata.taxYear
								) : (
									<Shimmer height={20} width={150} />
								)}{" "}
								Tax Organizer to be Completed Electronically
							</span>
						)}
					</div>

					<br />
					{this.props.preparerMessageStore.preparerMessage.message &&
						this.getPreparerMessage()}
					<br />
					<div
						className={
							"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page-menu-container"
						}
					>
						{this.SummaryPageSecondaryMessage()}

						<div className={"organizer-welcome-page-menu-icon-container"}>
							{engagementLetterStatus == FormGroupStatus.NA ||
								engagementLetterStatus == FormGroupStatus.None ||
								(signingOrder > 1 && !isEngagementControl)
								? ""
								: engagementLetterStatus == FormGroupStatus.ESigned ||
									engagementLetterStatus == FormGroupStatus.ManuallySigned ||
									engagementSignerStatus == FormGroupStatus.Reviewed
									? this.getDownloadEngagementButton()
									: this.getSignEngagementButton()}
							{hasCustomQuestion && this.getCustomQuestionsButton()}
							{organizerStatus != FormGroupStatus.None &&
								(organizerStatus == FormGroupStatus.Completed ||
									organizerStatus == FormGroupStatus.ManuallyCompleted
									? this.getOrganizerDownloadButton()
									: this.getOrganizerButton())}

							{organizerStatus != FormGroupStatus.None &&
								enabledSourceDocuments &&
								(isCompleted
									? this.getCompletedUploadDocumentsButton()
									: this.getUploadDocumentsButton())}
						</div>

						<br />
						<br />
						<br />
						{this.getContinueButton()}
					</div>
				</div>
			</div>
		);
	}
}
