import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { ValidationModalConstants } from "../Common/Constants";

interface IValidationModalProps {
	show: boolean;
	onClose(): void;
	validationModalData: IValidationData[];
}
export interface IValidationData {
	sectionIndex: number;
	requiredSectionItems: number[];
}
export const intialModalData: IValidationData[] = [
	{
		sectionIndex: 0,
		requiredSectionItems: [],
	},
];

const ValidationModal: React.FC<IValidationModalProps> = ({
	show,
	onClose,
	validationModalData,
}) => {
	return (
		<Modal show={show} size="lg">
			<Modal.Header data-test-auto="320325DC-3043-44C8-A9DB-ACD876903B41">
				<Modal.Title>
					{ValidationModalConstants.ModalTitle}
					<button type="button" className="close" onClick={onClose}>
						<span aria-hidden="true">x</span>
					</button>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>{ValidationModalConstants.ModalBodyText}</p>
				{validationModalData.map((section, index) => (
					<div key={index} className="validation-container">
						<span>Section {section.sectionIndex + 1}</span>
						<div className="questions-conatiner">
							{section.requiredSectionItems.length > 0 &&
								section.requiredSectionItems.map(
									(questions, questionIndex, questionsArray) => (
										<span key={questionIndex}>
											Q{questions + 1}
											{questionIndex < questionsArray.length - 1 && ","}
										</span>
									)
								)}
						</div>
					</div>
				))}
			</Modal.Body>
			<Modal.Footer>
				<Button
					className={"btn-primary-extended"}
					data-test-auto="0CDC79C8-111C-49C2-A196-AD8CD3D2D362"
					onClick={onClose}
				>
					Ok
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ValidationModal;
