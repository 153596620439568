import React from "react";
import {
    ISectionItems,
    QuestionType,
    initialYesNoChoiceData
} from "../../../core/domain/models/Organizer/CustomQuestionModel";
import MultiChoiceQuestion from "./MultiChoiceQuestion";
import ParagraphQuestion from "../ParagraphQuestion";
import { IHandleChoiceIdentifierPayload, IOnChangeHandlerPayload, IYesNoChoiceIdentifierPayload } from "../../../store/CqPreview/ActionTypes";
import YesNoFollowUpQuestion from "./YesNoFollowUpQuestion";

interface IFollowupQuestionProps {
    choiceId: number;
    subQuestionId: number;
    subQuestionIndex: number;
    sectionItem: ISectionItems;
    sectionItemIndex: number;
    sectionIndex: number;
    isEditMode?: boolean;
    isParentQuestionRequired: boolean;
    handleParagraphOnChange: (payload: IOnChangeHandlerPayload) => void;
    handleYesNoOnChange: (payload: IYesNoChoiceIdentifierPayload) => void;
    handleMcqOnChange: (payload: IHandleChoiceIdentifierPayload) => void;
}

const FollowupQuestion: React.FC<IFollowupQuestionProps> = ({
    choiceId,
    subQuestionIndex,
    subQuestionId,
    sectionItem,
    sectionItemIndex,
    sectionIndex,
    isParentQuestionRequired,
    handleParagraphOnChange,
    handleYesNoOnChange,
    handleMcqOnChange
}) => {
    const getQuestionType = () => {
        switch (sectionItem.questionType) {
            case QuestionType.PARAGRAPH:
                return (
                    <ParagraphQuestion
                        choiceId={choiceId}
                        sectionIndex={sectionIndex}
                        sectionItemIndex={sectionItemIndex}
                        isRequired={isParentQuestionRequired}
                        answer={sectionItem.answer}
                        clientType={sectionItem.answer?.clientType}
                        handleParagraphOnChange={handleParagraphOnChange}
                        isInvalid={sectionItem.isInValid}
                        isFollowUpQuestion={true}
                        subQuestionIndex={subQuestionIndex}
                    />
                );
            case QuestionType.YESNO:
                return (
                    <YesNoFollowUpQuestion
                        choiceId={choiceId}
                        choices={sectionItem.choices?.length > 0 ? sectionItem.choices : initialYesNoChoiceData}
                        sectionIndex={sectionIndex}
                        clientType={sectionItem.answer?.clientType}
                        sectionItemIndex={sectionItemIndex}
                        isRequired={isParentQuestionRequired}
                        isInvalid={sectionItem.isInValid}
                        handleYesNoOnChange={handleYesNoOnChange}
                    />
                );
            case QuestionType.MULTICHOICE:
                return (
                    <MultiChoiceQuestion
                        sectionIndex={sectionIndex}
                        sectionItemIndex={sectionItemIndex}
                        choices={sectionItem.choices}
                        clientType={sectionItem.answer?.clientType}
                        isRequired={isParentQuestionRequired}
                        isInvalid={sectionItem.isInValid}
                        handleMcqOnChange={handleMcqOnChange}
                        isFollowUpQuestion={true}
                        subQuestionId={subQuestionId}
                    />
                );

            default:
                break;
        }
    };
    return <div className="followup-question-wrapper">{getQuestionType()}</div>;
};

export default FollowupQuestion;
