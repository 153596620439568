import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { actionCreators as helperAction } from '../../store/Common/HelperStore';
import { actionCreators as coverAction, ErrorStatus } from '../../store/CoverPage/CoverPageStore';
import { ApplicationState } from "../../store/index";
import { DefaultLayout } from "./DefaultLayout";
import _ from 'lodash';
const createLoadingSelector = (actions:string[]) => (state:ApplicationState) => {
    // returns true only when all actions is not loading
    return _(actions)
        .some((action:string) => _.get(state.loader, action,true));
};

const loadingSelector = createLoadingSelector(['HEADERINFO', 'TAXING_AUTHORITY']);

function mapStateToProps(state: ApplicationState) {
    return {
        headerInfo: state.headerInfo,
        loading: loadingSelector(state),
        isCoverPage:state.commonData.isCoverPage
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestHeaderInfo: (id: string, isPreview: boolean, callback?: (data: any) => void, errorCallback?: (errorStatus: ErrorStatus) => void) =>
            dispatch(coverAction.requestHeaderInfo(id, isPreview, callback, errorCallback)),

        requestAllTaxingAuthorities: (id: string) => dispatch(helperAction.requestAllTaxingAuthorities(id)),
        setIsCoverPage:(isCoverPage: boolean) => dispatch(helperAction.setIsCoverPage(isCoverPage)),
        dispatch,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(DefaultLayout));

