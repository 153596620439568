import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CheckBoxComponent } from '../../Common/CheckBoxComponent';

export interface WarningProps {
    onDoNotShowClick: (value: boolean) => void;
    show: boolean;
    OnOkClick: () => void;
}

export const WarningPopup: React.FC<WarningProps> = (props) => {
    const popover = <Modal show={props.show} onHide={props.OnOkClick}>        
        <Modal.Body>
            <div>
                <span>Please make sure that your spouse is not currently working on this organizer.</span>
            </div>
            
        </Modal.Body>
        <Modal.Footer style={{ display: 'initial' }}>
            <div className="pull-left padT07 div-flex">
            <CheckBoxComponent
                id={"organizer-fill"}
                text={" Do not show this information again"}
                    onChange={(e) => { props.onDoNotShowClick(e.target.checked) }}
                datatestAuto={"3A08F399-77F1-4F00-A144-CA678BCFF7E3"}
                />
                </div>
            <Button
                data-test-auto="DC3AFACE-78D5-40F4-B5C2-6851E08DEC0A"
                className="btn-primary-extended"
                style={{ float: 'right', minWidth: '100px !important' }}
                onClick={props.OnOkClick} >
                    Ok
                </Button>
            
        </Modal.Footer>
    </Modal>
    return (
        <div >
            {popover}
        </div>
    );
}

