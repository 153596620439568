import { connect } from 'react-redux';
import { UploadDocumentsModal } from "./UploadDocumentsModal";
import { ApplicationState } from "../../store/index";
import { actionCreators as OrganizerStore } from '../../store/Organizer/OrganizerStore';
import { actionCreators as OrganizerMetadataStore } from '../../store/Organizer/OrganizerMetadataStore';
import { actionCreators as UploadedDocumentStore } from '../../store/UploadedDocument/UploadedDocumentStore';
import { ClientTypesNumber } from '../../core/domain/models/ISignerModel';

function mapStateToProps(state: ApplicationState) {
    return {
        uploadedDocuments: (state.organizerDocument as any).uploadedDocuments,
        organizerMetadata: state.organizerMetadata,
        uploadedDocumentStore: state.uploadedDocumentStore
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        addUploadedDocument: (clientId: string,
            uploadedDocument: any,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.addUploadedDocument(clientId, uploadedDocument, successCallback, failureCallback)),

        deleteUploadedDocument: (clientId: string,
            id: number,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.deleteUploadedDocument(clientId, id, successCallback, failureCallback)),

        getSourceDocumentMetadataAsync: (clientId: string,
            id: number,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(UploadedDocumentStore.getSourceDocumentMetadataAsync(clientId, id, successCallback, failureCallback)),


        requestUploadedDocuments: (clientId: string,
            forceRefresh?: boolean) => dispatch(OrganizerStore.requestUploadedDocuments(clientId, forceRefresh)),

        completeDocumentUpload: (clientId: string,
            deletedFiles: string,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.completeDocumentUpload(clientId, deletedFiles, successCallback, failureCallback)),

        requestOrganizerMetadata: (clientId: string) =>
            dispatch(OrganizerMetadataStore.requestOrganizerMetadata(clientId)),

        requestUploadedDocumentStatus: (clientId: string) =>
            dispatch(UploadedDocumentStore.requestUploadedDocumentStatus(clientId)),

        changeSourceDocumentStatusToCompleted: (
            clientId: string,
            clientType: ClientTypesNumber,
            documentId: number,
            successCallback: () => void
        ) =>
            dispatch(UploadedDocumentStore.changeSourceDocumentStatusToCompleted(
                clientId,
                clientType,
                documentId,
                successCallback
            )),

        setUploadedDocumentCompleted: () =>
            dispatch(UploadedDocumentStore.setUploadedDocumentCompleted()),

        setUploadedDocumentError: () =>
            dispatch(UploadedDocumentStore.setUploadedDocumentError()),

        resetUploadedDocumentError: () =>
            dispatch(UploadedDocumentStore.resetUploadedDocumentError())


    }
}
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(UploadDocumentsModal);
