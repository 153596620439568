import * as React from "react";
import SectionItemPreview from "./SectionItemPreview";
import { ISections } from "../../core/domain/models/Organizer/CustomQuestionModel";
import { IOnChangeHandlerPayload, IHandleChoiceIdentifierPayload, IYesNoChoiceIdentifierPayload } from "../../store/CqPreview/ActionTypes";
import { handleYesNoOnChange } from "../../store/CqPreview/Actions";

interface SectionProps {
	section: ISections;
	sectionsCount?: number;
	sectionIndex: number;
	handleParagraphOnChange: (payload: IOnChangeHandlerPayload) => void;
	calculateProgress: () => void;
	handleYesNoOnChange: (payload: IYesNoChoiceIdentifierPayload) => void;
	handleMcqOnChange: (payload: IHandleChoiceIdentifierPayload) => void;
}

const Section: React.FC<SectionProps> = ({
	section,
	sectionIndex,
	handleParagraphOnChange,
	calculateProgress,
	handleYesNoOnChange,
	handleMcqOnChange
}) => {
	const [questionaireSection, setQuestionaireSection] = React.useState({
		...section,
	});
	React.useEffect(() => {
		setQuestionaireSection(section);
	}, [section]);

	return (
		<div className="preview-section-container">
			<div className="preview-section-header">
				<p>{questionaireSection.sectionName}</p>
			</div>

			{questionaireSection &&
				questionaireSection.sectionItems.length > 0 &&
				questionaireSection.sectionItems.map(
					(sectionItem, sectionItemIndex) => (
						<SectionItemPreview
							key={sectionItemIndex}
							sectionItem={sectionItem}
							sectionItemIndex={sectionItemIndex}
							sectionIndex={sectionIndex}
							sectionItemsCount={questionaireSection.sectionItems.length}
							handleParagraphOnChange={handleParagraphOnChange}
							calculateProgress={calculateProgress}
							handleYesNoOnChange={handleYesNoOnChange}
							handleMcqOnChange={handleMcqOnChange}
						/>
					)
				)}
		</div>
	);
};

export default Section;
