import * as React from "react";
import { Form } from "react-bootstrap";
import { IChoiceModel, ISectionItems } from "../../core/domain/models/Organizer/CustomQuestionModel";
import { FormPreviewConstants } from "../Common/Constants";
import { ClientType } from "../../core/common/Enums";
import { IHandleChoiceIdentifierPayload, IOnChangeHandlerPayload, IYesNoChoiceIdentifierPayload } from "../../store/CqPreview/ActionTypes";
import { IsClientViewFromCache, IsPreviewModeFromCache } from "../Helper/HelperFunction";
import FollowupQuestion from "./QuestionTypes/FollowupQuestion";
import { ColorCode } from "../../core/common/BrandColors";

interface YesNoQuestionProps {
    sectionIndex: number;
    sectionItemIndex: number;
    isRequired: boolean;
    choices: IChoiceModel[];
    resetValidations?: boolean;
    isInvalid?: boolean;
    clientType?: ClientType;
    handleParagraphOnChange: (payload: IOnChangeHandlerPayload) => void;
    handleYesNoOnChange: (payload: IYesNoChoiceIdentifierPayload) => void;
    handleMcqOnChange: (payload: IHandleChoiceIdentifierPayload) => void;
}

const YesNoQuestion: React.FC<YesNoQuestionProps> = ({
    sectionIndex,
    sectionItemIndex,
    choices,
    clientType,
    isInvalid,
    isRequired,
    handleParagraphOnChange,
    handleYesNoOnChange,
    handleMcqOnChange
}) => {

    const [options, setOptions] = React.useState<IChoiceModel[]>([...choices].sort((a, b) => a.id - b.id));

    const [isInvalidState, setIsInvalidState] = React.useState(isInvalid);
    const [followupQuestion, setFollowupQuestion] = React.useState<ISectionItems[]>([]);
    const [selectedChoiceId, setSelectedChoiceId] = React.useState<number>(0);
    const isEditDisabled: boolean = IsClientViewFromCache() || IsPreviewModeFromCache();

    React.useEffect(() => {
        setIsInvalidState(isInvalid);
    }, [isInvalid]);

    React.useEffect(() => {
        if (choices?.length > 0) {
            choices.forEach((choice) => {
                if (choice.isSelected && choice.subQuestions.length > 0) {
                    setSelectedChoiceId(choice.id);
                    setFollowupQuestion(choice.subQuestions);
                }
            })
        }
    }, [choices]);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, choice: IChoiceModel) => {
        if (choice.isSelected) {
            e.preventDefault();
            return;
        }
        let updatedOptions: IChoiceModel[] = options.map((option) => {
            return { ...option, isSelected: option.text === choice.text && e.target.checked };
        })
        setIsInvalidState(false);
        setOptions(updatedOptions);
        if (choice.subQuestions.length > 0) {
            setFollowupQuestion(choice.subQuestions);
        } else {
            setFollowupQuestion([]);
        }
    };

    const onOptionClick = (e: React.MouseEvent<HTMLInputElement>, choice: IChoiceModel) => {
        if (choice.isSelected) {
            e.preventDefault();
            return;
        }
        let updatedOptions: IChoiceModel[] = options.map((option) => {
            return { ...option, isSelected: false };
        })
        setIsInvalidState(true);
        setOptions(updatedOptions);
        const payload: IYesNoChoiceIdentifierPayload = {
            choiceId: choice.id,
            sectionIndex,
            sectionItemIndex
        };
        setFollowupQuestion([]);

        if (handleYesNoOnChange) {
            handleYesNoOnChange(payload);
        }
    }
    const getColor = (clientType: any, choice: IChoiceModel) => {
        let color: string = ColorCode.DEFAULT;
        if (choice.isSelected) {
            if (clientType === ClientType.Spouse) {
                color = ColorCode.SPOUSE_COLOR;
            } else if (clientType === ClientType.Taxpayer) {
                color = ColorCode.TAXPAYER_COLOR;
            }
        }
        return color;
    };
    return (
        <div className={`answer-wrapper ${sectionItemIndex >= 99 ? "padding-24" : sectionItemIndex >= 9 ? "padding-12" : ""}`}>
            <Form>
                <div className={isEditDisabled ? "non-editable" : ""}>
                    <div className={followupQuestion.length > 0 ? "sub-question-question-border" : ""}>
                        {options?.map((option) => (
                            <Form.Check
                                id={`s-${sectionIndex}_q-${sectionItemIndex}`}
                                key={option.text + sectionItemIndex}
                                inline
                                label={option.text}
                                name="radio-group"
                                type="radio"
                                value={sectionItemIndex}
                                onChange={(e) => onChangeHandler(e, option)}
                                onClick={(e) => onOptionClick(e, option)}
                                checked={option.isSelected}
                                style={{ color: getColor(clientType, option) }}
                                className={isInvalidState && isRequired ? "yesno-error-radio" :
                                    clientType ? clientType === ClientType.Taxpayer ? "taxpayer-radio" : "spouse-radio" : ""}
                            />
                        ))}
                    </div>
                </div>
            </Form>
            {isInvalidState && isRequired && (
                <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>
            )}
            <div className="yes-no-followup-wrapper">
            {
                followupQuestion &&
                    followupQuestion.length > 0 &&
                        followupQuestion.map((question, subQuestionIndex)=>{
                            return <>
                                <label className="sub-question-label">
                                    {question.questionTitle}
                                    {isRequired && <span className="cq-error-red"> *</span>}
                                </label>
                                <FollowupQuestion
                                    choiceId={selectedChoiceId}
                                    sectionIndex={sectionIndex}
                                    sectionItemIndex={sectionItemIndex}
                                    sectionItem={question}
                                    isParentQuestionRequired={isRequired}
                                    handleParagraphOnChange={handleParagraphOnChange}
                                    handleYesNoOnChange={handleYesNoOnChange}
                                    handleMcqOnChange={handleMcqOnChange}
                                    subQuestionIndex={subQuestionIndex}
                                    subQuestionId={question.sectionItemId}
                                />
                            </>
                        })
            }
            </div>
        </div>
    );
};

export default YesNoQuestion;
