import { AxiosResponse } from 'axios';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { Action, Reducer } from 'redux';
import { SignerDocumentModel, ClientInfo, StatusCode } from '../../core/domain/models/Organizer/Organizer';
import { actionTypes } from '../ActionTypes';
import { NotificationAction } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { ILoader } from '../../core/utilities/ui/Loader';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { logger } from '../../routes';
import { CustomEventLog } from "../../components/Common/Constants";
import { initializeAxios } from '../../core/Services/dataAccess/DataService.Axios';


interface RequestSpouseInfoAction {
    type: actionTypes.SPOUSE_INFO_REQUEST;
}

interface RequestCacheSpouseInfoAction {
    type: actionTypes.SPOUSE_INFO_CACHE;
    data: ClientInfo;
}

interface ResponseSpouseInfoAction {
    type: actionTypes.SPOUSE_INFO_RESPONSE;
    data: ClientInfo;
}

interface FailureSpouseInfoAction {
    type: actionTypes.SPOUSE_INFO_FAILURE;
    data: ClientInfo;
}

export interface SpouseInfoState {
    data: ClientInfo;
}

export const initialSpouseInfoState: SpouseInfoState = {
    data: ClientInfo.createNullObject()
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    ResponseSpouseInfoAction
    | RequestSpouseInfoAction
    | FailureSpouseInfoAction
    | RequestCacheSpouseInfoAction

const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {

    requestSpouseInfo: (clientGuid: string, forceRefresh?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const state = getState();

        if (!forceRefresh && state.clientInfo.id > 0) {
            dispatch({ type: actionTypes.SPOUSE_INFO_CACHE, data: state.clientInfo });
            return;
        }

        loader.show();

        return initializeAxios().get<SignerDocumentModel>('/api/OrganizerClient/GetSpouseInfo/' + clientGuid)
            .then(function (response: AxiosResponse<ClientInfo>) {

                dispatch({
                    type: actionTypes.SPOUSE_INFO_RESPONSE, data: response.data
                });

                loader.hide();

            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({ type: actionTypes.SPOUSE_INFO_FAILURE, data: state.clientInfo });
                loader.hide();
                logger.trackError(`requestSpouseInfo failed to push the data with error ${error.message}`)
            });
    },

    updateSpouseEmail: (clientGuid: string,
        clientInfo: ClientInfo,
        successCallback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

            loader.show();

            return initializeAxios().postJson(clientInfo, '/api/OrganizerClient/UpdateSpouseEmail/' + clientGuid)
                .then(function (response: AxiosResponse<any>) {
                    successCallback && successCallback();
                    const TraceEvent: IEventTelemetry = {
                        name: `${CustomEventLog.UpdateSpouseEMail}`,
                        properties: { ClientGuid: clientGuid, SpouseEmail: clientInfo.email }
                    };
                    logger.trackEvent(TraceEvent);
                })
                .catch(function (error: any) {
                    if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                        return;
                    }
                    failureCallback && failureCallback();
                    loader.hide();
                    logger.trackError(`requestSpouseInfo failed to push the data with error ${error.message}`)
                });
        },

    updateWarningPreference: (clientGuid: string, hideWarning: boolean, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().postJson({
            hideWarning: hideWarning
        }, '/api/OrganizerClient/UpdateClientPreference/' + clientGuid)
            .then(function (response: AxiosResponse<any>) {
                callback && callback();
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                logger.trackError(`updateWarningPreference failed to push the data with error ${error.message}`)
            });
    }

}

export const reducer: Reducer<SpouseInfoState> = (state: SpouseInfoState = initialSpouseInfoState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.SPOUSE_INFO_REQUEST:
            currentState.data = ClientInfo.createNullObject();
            return currentState;
        case actionTypes.SPOUSE_INFO_RESPONSE:
        case actionTypes.SPOUSE_INFO_CACHE:
            currentState.data = action.data;
            return currentState;
        case actionTypes.SPOUSE_INFO_FAILURE:
            currentState.data = action.data;
            return currentState;
        default:
            return currentState || initialSpouseInfoState;
    }
}; 