import { FormGroupStatus } from '../../core/domain/models/Organizer/Organizer';
import * as React from 'react';
import { OrganizerConstants, PreviewConstants } from '../Common/Constants';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';

//Below functions are sharing in between the below files:
//1) PreviewSignFlow.tsx
//2) ClientView.tsx
//3) OrganizerSignFlow.tsx
export function NotToShowSummaryPageSecondaryMessage(engagementLetterStatus: FormGroupStatus, organizerStatus: FormGroupStatus) {
    return (
            engagementLetterStatus == FormGroupStatus.NA ||
            engagementLetterStatus == FormGroupStatus.None ||
            engagementLetterStatus == FormGroupStatus.ESigned ||
            engagementLetterStatus == FormGroupStatus.ManuallySigned ||
            engagementLetterStatus == FormGroupStatus.Reviewed
           ) &&
           (
            organizerStatus == FormGroupStatus.None ||
            organizerStatus == FormGroupStatus.Completed ||
            organizerStatus == FormGroupStatus.ManuallyCompleted
           );
}

export function ShowSummaryPageSecondaryMessage() {
    return (<>
        <span className={"organizer-welcome-page-menu-title"}>
            {OrganizerConstants.SummaryPageSecondaryMessage}
        </span><br /><br /><br /></>);
}

export function GetRedirectUrlBasedOnStatus(
    engagementLetterStatus: FormGroupStatus,
    signingOrder: number,
    engagementSignerStatus: FormGroupStatus,
    isEngagementControl: boolean,
    hasCustomQuestion: boolean,
    customQuestionStatus: FormGroupStatus,
    clientId: any) {
    if (engagementLetterStatus === FormGroupStatus.None || engagementLetterStatus === FormGroupStatus.NA) {
        if (hasCustomQuestion) {
            if (!(customQuestionStatus == FormGroupStatus.QuestionnaireCompleted)) {
                return OrganizerConstants.CustomQuestionsURL + clientId;
            }
        }
    }

    if (!(engagementLetterStatus === FormGroupStatus.AwaitingESign)) {
        if (engagementLetterStatus === FormGroupStatus.ESigned ||
            engagementLetterStatus === FormGroupStatus.Reviewed ||
            engagementLetterStatus === FormGroupStatus.ManuallySigned ||
            engagementLetterStatus === FormGroupStatus.PartiallyReviewed) {
            if (isEngagementControl && (engagementLetterStatus !== FormGroupStatus.ManuallySigned && engagementLetterStatus !== FormGroupStatus.ESigned && engagementSignerStatus !== FormGroupStatus.Reviewed)) {
                return OrganizerConstants.EngagementLetterSignPageURL + clientId;
            }
            else if (hasCustomQuestion) {
                if (!(customQuestionStatus == FormGroupStatus.QuestionnaireCompleted)) {
                    return OrganizerConstants.CustomQuestionsURL + clientId;
                }
            }
        }
    }

    if (engagementLetterStatus === FormGroupStatus.ESigned || engagementLetterStatus === FormGroupStatus.ManuallySigned || engagementLetterStatus === FormGroupStatus.Reviewed) {
        return OrganizerConstants.OrganizerSignPageURL + clientId;
    }
    else {
        if (signingOrder == 2) {
            if (!isEngagementControl) {
                if(hasCustomQuestion && (customQuestionStatus !== FormGroupStatus.QuestionnaireCompleted)){
                    return OrganizerConstants.CustomQuestionsURL + clientId;
                }
                else{
                    return OrganizerConstants.OrganizerSignPageURL + clientId;
                }
            }
            else {
                return ((engagementLetterStatus == FormGroupStatus.PartiallyReviewed
                    || engagementLetterStatus == FormGroupStatus.PartiallySigned)
                    && engagementSignerStatus == FormGroupStatus.NA)
                    ? OrganizerConstants.EngagementLetterSignPageURL + clientId
                    : OrganizerConstants.OrganizerSignPageURL + clientId;
            }
        }
        else {
            return ((engagementLetterStatus == FormGroupStatus.None
                || engagementLetterStatus == FormGroupStatus.NA
                || engagementLetterStatus == FormGroupStatus.PartiallySigned)
                || engagementSignerStatus == FormGroupStatus.Reviewed)
                ? OrganizerConstants.OrganizerSignPageURL + clientId
                : OrganizerConstants.EngagementLetterSignPageURL + clientId;
        }
    }
}

export function EnableDisableUploadDocumentBasedOnStatus(
    engagementSignerStatus: FormGroupStatus,
    engagementLetterStatus: FormGroupStatus,
    signingOrder: number,
    isEngagementControl: boolean): boolean {
    return (
        engagementSignerStatus == FormGroupStatus.NA &&
        engagementLetterStatus != FormGroupStatus.None &&
        engagementLetterStatus != FormGroupStatus.NA
    ) &&
        !(signingOrder > 1 && !isEngagementControl)
        &&
        !(
            engagementLetterStatus == FormGroupStatus.ESigned ||
            engagementLetterStatus == FormGroupStatus.Reviewed ||
            engagementLetterStatus == FormGroupStatus.ManuallySigned
        );
}

export function EnableDisableCustomQuestionBasedOnStatus(
    engagementSignerStatus: FormGroupStatus,
    engagementLetterStatus: FormGroupStatus,
    signingOrder: number,
    isEngagementControl: boolean,
    customQuestionStatus: FormGroupStatus,
    organizerStatus: FormGroupStatus): boolean {
    return (
        engagementSignerStatus == FormGroupStatus.NA &&
        engagementLetterStatus != FormGroupStatus.NA) &&
        !(signingOrder > 1 && !isEngagementControl) &&
        !(
            engagementLetterStatus == FormGroupStatus.ESigned ||
            engagementLetterStatus == FormGroupStatus.Reviewed ||
            engagementLetterStatus == FormGroupStatus.ManuallySigned
        ) ||
        customQuestionStatus == FormGroupStatus.QuestionnaireCompleted ||
        organizerStatus == FormGroupStatus.ManuallyCompleted;
}

export function EnableDisableOrganizerBasedOnStatus(
    hasCustomQuestion: boolean,
    customQuestionStatus: FormGroupStatus,
    engagementSignerStatus: FormGroupStatus,
    engagementLetterStatus: FormGroupStatus,
    signingOrder: number,
    isEngagementControl: boolean): boolean {
    if (hasCustomQuestion) {
        if (customQuestionStatus !== FormGroupStatus.QuestionnaireCompleted) {
            return true;
        }

        if (engagementSignerStatus == FormGroupStatus.NA &&
            engagementLetterStatus != FormGroupStatus.None &&
            engagementLetterStatus != FormGroupStatus.NA &&
            !(signingOrder > 1 && !isEngagementControl) &&
            !(
                engagementLetterStatus == FormGroupStatus.ESigned ||
                engagementLetterStatus == FormGroupStatus.Reviewed ||
                engagementLetterStatus == FormGroupStatus.ManuallySigned
            )) {
            return true;
        }
        return false;
    } else {
        if (
            engagementSignerStatus == FormGroupStatus.NA &&
            engagementLetterStatus != FormGroupStatus.None &&
            engagementLetterStatus != FormGroupStatus.NA &&
            !(signingOrder > 1 && !isEngagementControl) &&
            !(
                engagementLetterStatus == FormGroupStatus.ESigned ||
                engagementLetterStatus == FormGroupStatus.Reviewed ||
                engagementLetterStatus == FormGroupStatus.ManuallySigned
            )
        ) {
            return true;
        }
        return false;
    }
}

export function HasEngagementControlForSingleSigner(
    signingOrder: number,
    isEngagementControl: boolean,
    engagementLetterStatus: FormGroupStatus,
    engagementSignerStatus: FormGroupStatus): boolean {
    let result = false;

    if ((signingOrder === 1 || isEngagementControl) &&
        engagementSignerStatus === FormGroupStatus.NA &&
        engagementLetterStatus !== FormGroupStatus.None &&
        engagementLetterStatus !== FormGroupStatus.ESigned &&
        engagementLetterStatus !== FormGroupStatus.Reviewed &&
        engagementLetterStatus !== FormGroupStatus.ManuallySigned) {
        result = true;
    }

    return result;
}

export function GetTooltipForOrganizer(
    customQuestionStatus: FormGroupStatus,
    engagementSignerStatus: FormGroupStatus,
    signingOrder: number,
    isEngagementControl: boolean,
    hasCustomQuestion: boolean,
    engagementLetterStatus: FormGroupStatus) {
    let toolTip = "";
    let isCustomQuestionCompleted = customQuestionStatus === FormGroupStatus.QuestionnaireCompleted;
    let isEngagementLetterSigned = engagementSignerStatus === FormGroupStatus.ESigned;

    if (!EnableDisableOrganizerBasedOnStatus(
        hasCustomQuestion,
        customQuestionStatus,
        engagementSignerStatus,
        engagementLetterStatus,
        signingOrder,
        isEngagementControl))
        return toolTip;

    if (signingOrder > 1) {

        toolTip = OrganizerConstants.EsignPendingCompleteOrgDisabled;

        if (!isEngagementControl || isEngagementLetterSigned) {
            if (hasCustomQuestion && !isCustomQuestionCompleted) {
                toolTip = OrganizerConstants.IncompleteCQCompleteOrganizerDisbaled;
            }
        }
    }
    else {
        if (hasCustomQuestion) {
            if (engagementSignerStatus === FormGroupStatus.NA && engagementLetterStatus === FormGroupStatus.NA) {
                toolTip = OrganizerConstants.IncompleteCQCompleteOrganizerDisbaled;
            }
            else if (HasEngagementControlForSingleSigner(
                signingOrder,
                isEngagementControl,
                engagementLetterStatus,
                engagementSignerStatus)) {
                toolTip = OrganizerConstants.EsignPendingCompleteOrgDisabled;
            }
            else if (isCustomQuestionCompleted) {
                toolTip = OrganizerConstants.SummaryPageCompleteOrganizerText;
            }
            else {
                toolTip = OrganizerConstants.IncompleteCQCompleteOrganizerDisbaled;
            }
        }
        else {
            toolTip = OrganizerConstants.EsignPendingCompleteOrgDisabled;
        }
    }

    return toolTip;
}

export function ShowThanksMessageIfOrganizerCompletedAndDownloadable(
    engagementLetterStatus: FormGroupStatus,
    organizerStatus: FormGroupStatus) {
    return (
        engagementLetterStatus == FormGroupStatus.NA ||
        engagementLetterStatus == FormGroupStatus.None ||
        engagementLetterStatus == FormGroupStatus.ESigned ||
        engagementLetterStatus == FormGroupStatus.ManuallySigned ||
        engagementLetterStatus == FormGroupStatus.Reviewed
    ) &&
        (
            organizerStatus == FormGroupStatus.Completed ||
            organizerStatus == FormGroupStatus.ManuallyCompleted
        );
}

export function IsNullorEmpty(expression: string) {
    return expression === null || expression === undefined || expression.trim() === "";
}

export function GetMenuSeperator() {
    return (<hr className={"organizer-welcome-page-menu-separator"} />);
}

export function HandleRedirect(url: string) {
    window.location.href = url;
}

export function EnableDisableContinueBasedOnStatus(
    signingOrder: number,
    engagementLetterStatus: FormGroupStatus,
    isEngagementControl: boolean): boolean {
    return (
        signingOrder == 2 &&
        (
            engagementLetterStatus == FormGroupStatus.AwaitingESign
        ) && isEngagementControl
    );
}

export function ShowClickHereToContinueTextBasedOnStatus(engagementLetterStatus: FormGroupStatus, organizerStatus: FormGroupStatus) {
    return (
        engagementLetterStatus != FormGroupStatus.None &&
        engagementLetterStatus != FormGroupStatus.ESigned &&
        engagementLetterStatus != FormGroupStatus.Reviewed &&
        engagementLetterStatus != FormGroupStatus.ManuallySigned
    )
        && organizerStatus == FormGroupStatus.None;
}

export function HideClickHereToContinueTextBasedOnStatus(organizerStatus: FormGroupStatus) {
    return (
        organizerStatus == FormGroupStatus.Completed ||
        organizerStatus == FormGroupStatus.None ||
        organizerStatus == FormGroupStatus.ManuallyCompleted
    );
}

export function ShowClickHereToContinueTextDependOnStatus(engagementLetterStatus: FormGroupStatus, organizerStatus: FormGroupStatus) {
    return (
        engagementLetterStatus != FormGroupStatus.ESigned &&
        engagementLetterStatus != FormGroupStatus.ManuallySigned &&
        engagementLetterStatus != FormGroupStatus.Reviewed
    ) ||
        (
            organizerStatus != FormGroupStatus.Completed &&
            organizerStatus != FormGroupStatus.ManuallyCompleted
        );
}

export function DownloadOrganizerDocument() {
    MarsNotifier.Warning(PreviewConstants.DownloadMessage, null);
}

export function DownloadEngagementLetter() {
    MarsNotifier.Warning(PreviewConstants.DownloadMessage, null);
}

export function CheckStatusforDisplayingMenu(clientId: any) {
    return (clientId != null);
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////