import { connect } from 'react-redux';
import { ApplicationState } from "../../store/index";
import { actionCreators as OTPStore } from '../../store/OTP/OTPStore';
import { actionCreators as helperAction } from "../../store/Common/HelperStore";
import SMSOTP from './SMSOTP';

function mapStateToProps(state: ApplicationState) {
    return {
        otpDetails:state.otpDetails
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        generateOtp: (clientId: string) => dispatch(OTPStore.generateMobileOTP(clientId)),
        resetNotificationResponse: () => dispatch(OTPStore.resetNotificationResponse()),
        verifyOTP: (otp: string, clientId: string, handleVerify?: (guid: string) => void, errorCallback?: () => void) =>
            dispatch(OTPStore.verifyOTP(otp, clientId, handleVerify, errorCallback)),
        pageValidity: (clientId: string, errorCallback: () => void) => dispatch(OTPStore.pageValidity(clientId, errorCallback)),
        setIsCoverPage:(isCoverPage: boolean) => dispatch(helperAction.setIsCoverPage(isCoverPage)),


    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SMSOTP);
