import * as React from "react";
import { Form } from "react-bootstrap";
import { FormPreviewConstants } from "../Common/Constants";
import { IOnChangeHandlerPayload } from "../../store/CqPreview/ActionTypes";
import { ClientType } from "../../core/common/Enums";
import {
	IsClientViewFromCache, IsPreviewModeFromCache
} from "../Helper/HelperFunction";
import { IAnswerModel } from "../../core/domain/models/Organizer/CustomQuestionModel";
import { ColorCode } from "../../core/common/BrandColors";

interface ParagraphQuestionProps {
	isRequired: boolean;
	sectionIndex: number;
	sectionItemIndex: number;
	handleParagraphOnChange: (payload: IOnChangeHandlerPayload) => void;
	handleInputBlur?: () => void;
	handleInputFocus?: () => void;
	answer: IAnswerModel;
	isInvalid?: boolean;
	clientType?: ClientType;
	choiceId?: number;
	isFollowUpQuestion?: boolean;
	subQuestionIndex?: number;
}

const ParagraphQuestion: React.FC<ParagraphQuestionProps> = ({
	isRequired,
	sectionIndex,
	sectionItemIndex,
	answer,
	handleParagraphOnChange,
	handleInputBlur,
	handleInputFocus,
	isInvalid,
	clientType,
	choiceId,
	subQuestionIndex,
	isFollowUpQuestion
}) => {
	const [answerState, setAnswerState] = React.useState(answer);
	const [isInvalidState, setIsInvalidState] = React.useState(isInvalid);

	React.useEffect(() => {
		setIsInvalidState(isInvalid);
	}, [isInvalid]);
	
	React.useEffect(() => {
		if (answer) {
			setAnswerState({ ...answer });
		}
	}, [choiceId]);

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (IsClientViewFromCache() || IsPreviewModeFromCache()) {
			e.preventDefault();
			e.target.value = "";
			return;
		}

		const input = e.target.value.trimStart();
		setAnswerState({ ...answerState, answerText: input });
		if (isRequired) {
			if (input === "") {
				setIsInvalidState(true);
			} else {
				setIsInvalidState(false);
			}
		}

		const payload: IOnChangeHandlerPayload = {
			value: input,
			sectionIndex,
			sectionItemIndex,
			choiceId,
			subQuestionIndex
		};
		handleParagraphOnChange(payload);

	};

	const getColor = (clientType: any) => {
		let color: string = ColorCode.DEFAULT;
		if (clientType === ClientType.Spouse) {
			color = ColorCode.SPOUSE_COLOR;
		} else if (clientType === ClientType.Taxpayer) {
			color = ColorCode.TAXPAYER_COLOR;
		}
		return color;
	};

	return (
		<>
			<div className={`answer-wrapper ${sectionItemIndex >= 99 ? "padding-24" : sectionItemIndex >= 9 ? "padding-12" : ""} ${isFollowUpQuestion ? "follow-up-answer" : ""}`}>
				<Form.Control
					id={`s-${sectionIndex}_q-${sectionItemIndex}`}
					onChange={onChangeHandler}
					as="textarea"
					placeholder={FormPreviewConstants.ParagraphTypeQuestion.placeholder}
					value={answerState.answerText}
					required={isRequired}
					maxLength={FormPreviewConstants.ParagraphTypeQuestion.maxLength}
					className={
						isInvalidState ? "invalid-input" : ""
					}
					onFocus={handleInputFocus}
					style={{ color: getColor(clientType) }}
					onBlur={handleInputBlur}
				/>
				{isInvalidState === true && (
					<p className="error-text">{FormPreviewConstants.RequiredValidation}</p>
				)}
			</div>
		</>
	);
};

export default ParagraphQuestion;
