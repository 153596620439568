import { History } from 'history';
import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { match } from 'react-router';
import { Link } from 'react-router-dom';
import { IUtilities } from '../../core/utilities/Utilities';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { SvgIconContactHeader } from '../Common/Icons/SvgIcons';
import { UrlConstants } from '../../core/common/Constants';
import { initialUserModel } from '../../core/domain/models/IUserModel';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';

const utilities = container.get<IUtilities>(TYPES.IUtilities);

export interface HeaderProps {
    headerInfo: IHeaderInfoViewModel,
    match: match;
    history: History;
    isCoverPage: boolean;
}

export interface HeaderState {
    headerInfo: IHeaderInfoViewModel,
    showMyAccountPopUp: boolean,
    emailAddress: string,
    mobileNumber: string,
}

export class Header extends React.Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps, states: HeaderState) {
        super(props, states);
        this.state = {
            headerInfo: initialHeaderInfoViewModel,
            showMyAccountPopUp: false,
            emailAddress: "",
            mobileNumber: "",
        }
    }

    static getDerivedStateFromProps(nextProps: HeaderProps, prevState: HeaderState) {
        if (nextProps.headerInfo) {
            return {
                headerInfo: nextProps.headerInfo
            }
        }
        else {
            return null;
        }
    }

    componentDidMount() {

    }

    getMyaccount = () => {
        this.setState({
            showMyAccountPopUp: true,
        })
    }

    Logout() {
        const taxDocumentClient: any = this.props.match.params;
        window.location.href = UrlConstants.Logout + taxDocumentClient.clientId;
    }

    goToHomeHandler = () => {
        this.props.history.goBack();
    }

    public render() {
        let companyLogo = null;
        if (this.state.headerInfo.companyWhiteLogoPath?.length > 0) {
            companyLogo = <img className="logo-img" src={this.state.headerInfo.companyWhiteLogoPath} />;
        }
        else {
            companyLogo = <span className="company-name">{this.state.headerInfo.companyName}</span>;
        }

        let contactPersonFullName: string = "";
        const contactPerson = this.state.headerInfo.contactPerson != null ? this.state.headerInfo.contactPerson : initialUserModel;
        contactPersonFullName += contactPerson.firstName;
        if (contactPerson.lastName && contactPerson.lastName.length > 0) {
            contactPersonFullName += " " + contactPerson.lastName;
        }

        const contactInfoPopover = (
            <Popover id="contactInfoPopover" placement="bottom">
                <h3 className="popover-header">Contact Information</h3>
                <div className="popover-body" data-test-auto="3464EE21-1DF8-4F1F-BE4B-D838ACE36298">
                    <div className='medium'>{contactPersonFullName} </div>
                    <div>
                        <strong>{utilities.formateFax(contactPerson.phone)}
                            {contactPerson.phone && contactPerson.phone != '' && contactPerson.extension && contactPerson.extension != '' ? ` Ext: ${contactPerson.extension}` : ''}</strong>
                    </div>
                    <div className='mail'>
                        <a href={"mailto:" + contactPerson.emailAddress}>{contactPerson.emailAddress}</a>
                    </div>
                </div>
            </Popover>
        );
        const accountPopover = (
            (!this.state.headerInfo.isPreview ?
                <Popover id="accountPopover" placement="bottom-start">
                    <div className="popover-body">
                        <ul className='account-menu'>
                            {
                                <li><Link to={"#"} onClick={() => this.Logout()} data-test-auto="767609CB-7931-49FF-A431-CBF72F015D70"><i className="fa fa-sign-out" style={{ marginRight: '14px' }}></i><span>Logout</span></Link></li>
                            }
                        </ul>
                    </div>
                </Popover>
                : <div></div>)
        );

        return (
            <header className="app-header">
                <div className="header-left-container" data-test-auto="951602DF-76D9-480A-BA0F-D12E216FBB2B">

                    <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" />
                    <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                        <div className="spinner diagonal part-1"></div>
                        <div className="spinner horizontal"></div>
                        <div className="spinner diagonal part-2"></div>
                    </label>
                    <div id="sidebarMenu">
                        <ul className="sidebar navbar-nav">
                            <li className="nav-item layout-dropdown show" data-test-auto="1D9B4F96-A523-4A04-AA58-AB62A9B1DD3A">
                                <span className="nav-link layout-dropdown-toggle" role="button" data-toggle="layout-dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-calendar margin-right-10"></i>
                                    <span>Tax Year</span></span>
                                <div className="layout-dropdown-menu show" aria-labelledby="pageslayout-dropdown">
                                    <a className="layout-dropdown-item active" >{this.state.headerInfo.taxYear}</a>
                                </div>
                            </li>
                            <li className="nav-item layout-dropdown show" data-test-auto="9E3149F1-20E9-48E8-9067-5E70360C410F">
                                <span className="nav-link layout-dropdown-toggle" role="button" data-toggle="layout-dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-cog margin-right-10"></i>
                                    <span >Account</span></span>
                                <div className="layout-dropdown-menu show" aria-labelledby="pageslayout-dropdown">
                                    <a className="layout-dropdown-item" onClick={() => this.getMyaccount()} data-test-auto="05558ACE-66E2-42FA-A41F-FF6E8AEC1881"><i className="fa fa-user ddl-icon headerForeColor"></i>My Account</a>
                                    {!this.state.headerInfo.isPreview ?
                                        <a className="layout-dropdown-item" onClick={() => this.Logout()} data-test-auto="A213D79D-034B-4264-8249-C1D438D433B3"><i className="fa fa-sign-out ddl-icon headerForeColor"></i>Logout</a>
                                        : null
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                    {companyLogo}
                </div>
                <div className="header-right-container" data-test-auto="710F957F-072E-4A27-867F-0B40C2077D3B">
                    {!this.props.isCoverPage ? <span className="header-contact-info">
                        <a onClick={this.goToHomeHandler} style={{ display: 'flex' }}>
                            <i className="fa fa-home" aria-hidden="true" style={{ fontSize: '20px', width: '20px' }}></i>
                            Back To Home
                        </a>
                    </span> : null}
                    {

                        !this.props.headerInfo.isLoading &&

                        <span className="header-contact-info">
                            <OverlayTrigger
                                data-test-auto="CB7E32C5-518D-40B5-8E8F-E75C9A89CF58"
                                rootClose trigger="click"
                                onEnter={() => document.body.click()}
                                overlay={contactInfoPopover} placement="bottom">
                                <a>
                                    <SvgIconContactHeader
                                        fillColor="var(--headerForeColor)"
                                        height={20} />
                                    Contact Person
                                </a>
                            </OverlayTrigger>
                        </span>
                    }
                </div>
            </header>);
    }
}
