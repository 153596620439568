import { ITaxingAuthority } from "../models/ITaxingAuthority";

export interface ICommonDataViewModel {
    taxingAuthorities: ITaxingAuthority[],
    isCoverPage:boolean,
    profileData:any,
    clientData:any,
}

export const initialCommonData: ICommonDataViewModel = {
    taxingAuthorities: [],
    isCoverPage:true,
    profileData:null,
    clientData:null
}