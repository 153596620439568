import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { UploadedDocument } from '../../core/domain/models/Organizer/Organizer';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { OrganizerUploadedDocuments } from '../Common/Constants';
import { SourceDocumentsUpload } from './Parts/SourceDocumentUpload';
import * as OrganizerStore from '../../store/Organizer/OrganizerStore';
import { RouteComponentProps } from 'react-router';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { ISnackbar } from '../../core/utilities/ui/Snackbar';
import { BatchUploadedStatus } from '../../core/domain/models/SourceDocument/SourceDocument';

export type UploadModalProps = {
    show: boolean;
    clientId: string;
    formName: string;
    formId?: number;
    addUploadedDocument(clientId: string, signedDocument: UploadedDocument, successCallback?: () => void): void;
    deleteUploadedDocument(clientId: string, id: number, successCallback?: () => void): void;
    requestUploadedDocuments(clientId: string): void;
    uploadedDocuments: UploadedDocument[];
    onClose(): void;
    disabled: boolean;
    changeStatusToCompleted: () => void;
    requestUploadedDocumentStatus: (clientId: string) => void;
    setUploadedDocumentError: () => void;
    isSourceDocumentUploadCompleted: boolean;
}
    & typeof OrganizerStore.actionCreators
    & RouteComponentProps<{}>;


const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

export class UploadModal extends React.Component<UploadModalProps> {

    private _uploadingStatus: BatchUploadedStatus = BatchUploadedStatus.Completed;
    constructor(props: UploadModalProps) {
        super(props);
    }

    componentDidMount() {
        const param = this.props.match.params;
        this.props.requestUploadedDocuments((param as any).clientId, false);
    }

    private handleUploadedStatusUpdate = (status: BatchUploadedStatus) => {
        this._uploadingStatus = status;
    }

    private handleClose = () => {

        const { onClose } = this.props;

        if (!this.checkIfUploadInprogress()) {

            this.setState({ disableSave: true }, () => {
                onClose();
            });
        }
        else {
            dialogBox.alert(OrganizerUploadedDocuments.UploadInProgressMessage);
        }
    }

    private checkIfUploadInprogress(): boolean {
        return this._uploadingStatus == BatchUploadedStatus.Uploading;
    }

    private changeStatusToCompleted = () => {

        const { changeStatusToCompleted, requestUploadedDocumentStatus,
            clientId, setUploadedDocumentError
        } = this.props;

        setUploadedDocumentError();
        changeStatusToCompleted();
        requestUploadedDocumentStatus(clientId);
    }

    public render() {

        const { clientId, show,
            requestUploadedDocuments,
            addUploadedDocument,
            deleteUploadedDocument,
            uploadedDocuments } = this.props;

        return (<Modal show={show} className="source-document-upload-model">
            <Modal.Header data-test-auto="320325DC-3043-44C8-A9DB-ACD876903B41">
                <Modal.Title>
                    {OrganizerUploadedDocuments.ModalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SourceDocumentsUpload clientId={clientId}
                    existinguploadedDocuments={uploadedDocuments}
                    deleteUploadedDocument={deleteUploadedDocument}
                    addDocument={addUploadedDocument}
                    requestUploadedDocuments={requestUploadedDocuments}
                    updateUploadedStatus={this.handleUploadedStatusUpdate}
                    formName={this.props.formName}
                    formId={this.props.formId}
                    disabled={this.props.disabled}
                    changeStatusToCompleted={this.changeStatusToCompleted}
                    isSourceDocumentUploadCompleted={this.props.isSourceDocumentUploadCompleted}
                />
                <span className="uploaded-src-documents-indicator">{OrganizerUploadedDocuments.MaximumFileNameLengthCharactersMessage}</span>
            </Modal.Body>
            <Modal.Footer className='source-document-upload-model-footer'>
                <div className="row width-100" style={{alignItems: 'center'}}>
                    <div className={"col-sm-10"}>
                        <div className='source-document-upload-warning-msg'>
                            <b>Important!</b> {OrganizerUploadedDocuments.SourceDocumentUploadWarningmsg}
                        </div>
                        <span style={{fontSize:'13px'}}><i className="fas fa-info-circle"></i> {OrganizerUploadedDocuments.ModelSourceDocumentFooterInfo}</span>
                    </div>
                    <div className={"col-sm-2 text-align-right"}>
                        <Button variant="info" onClick={this.handleClose} className={"btn-primary-extended btn-tablet-width-100"}>
                            <i className='glyphicon glyphicon-floppy-disk'></i>Done</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>);
    }
}
