import * as React from 'react';
import { ConflictErrorMessage } from '../../../store/UploadedDocument/UploadedDocumentStoreModels';
import { TaxPayerPage } from '../../Common/Constants'; 

interface FooterProps {
    onFinish(): void;
    finishEnabled?: boolean;
    completeDisabled: boolean;
    onComplete(): void;
    isCompleted: boolean;
}

export const Footer: React.FC<FooterProps> = ({
    completeDisabled,
    onComplete,
    finishEnabled,
    onFinish,
    isCompleted
}) => {      

    return (
    <footer style={{ position: 'fixed', bottom: '0', width: '100%' }}>

        <a className="rd-nav-link btn-finish" title={isCompleted ? ConflictErrorMessage : ''}>
            <button 
                type="submit" 
                onClick={onComplete} 
                disabled={completeDisabled}
                className="btn btn-primary complete-btn-primary-extended btn-login pull-right"
                id="btnContinue"
                data-test-auto='2d123d32-c0cd-3272-8749-ce74da5e11b4'>
                    {TaxPayerPage.FinishButton}
                </button>
        </a>
        <a className="rd-nav-link btn-decline">
             <button 
                type="submit" 
                onClick={onFinish}
                title={isCompleted ? ConflictErrorMessage : ''}
                className="btn btn-default btn-default-extended btn-login text-blue pull-right"
                id="btnDecline"
                data-test-auto='2d123d32-c0cd-4272-8749-ce74da5e10b5'> 
                {TaxPayerPage.ContinueLaterButton }
            </button>
 </a>

    </footer>);
}