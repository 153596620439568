import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { EngagementLetterConstants } from '../../Common/Constants';
import { DeclineRemarks } from './Parts/DeclineRemarks';

interface DeclineModalProps {
    show: boolean;
    clientId: string;
    onClose(): void;
    onDecline(remarks: string): void;
}

interface DeclineModalState {
    remarks: string;
}

export class DeclineModal extends React.Component<DeclineModalProps, DeclineModalState> {

    constructor(props: DeclineModalProps) {
        super(props);

        this.state = {
            remarks: ""
        }

    }

    componentDidMount() {

    }

    private handleDecline = () => {

        const { onDecline } = this.props;
        const { remarks } = this.state;
        onDecline(remarks);
    }

    private handleRemarksChange = (remarks: string) => {
        this.setState({ remarks: remarks });
    }


    public render() {

        const { show, onClose } = this.props;

        return (<Modal show={show} size="lg">
            <Modal.Header data-test-auto="320325DC-3043-44C8-A9DB-ACD876903B41">
                <Modal.Title>
                    {EngagementLetterConstants.DeclineRemarksModalTitle}
                    <button type="button" className="close" onClick={onClose}>
                        <span aria-hidden="true">x</span>
                    </button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DeclineRemarks onChange={this.handleRemarksChange} />
            </Modal.Body>
            <Modal.Footer>

                <Button
                    data-test-auto="54915B7B-9FC9-4B6C-AE09-8BD00668F54D"
                    className={"btn btn-white btn-default bootbox-cancel"}
                    onClick={onClose}>
                    Cancel
                </Button>

                <Button
                    data-test-auto="E26910AB-5FBD-4852-AADE-107514109A15"
                    onClick={this.handleDecline}>
                    Decline & Continue to Organizer
                </Button>

            </Modal.Footer>
        </Modal>);
    }
}
