import * as React from 'react';
import { Prompt, RouteComponentProps } from 'react-router';
import { ClientType, ReviewStatus } from '../../core/common/Enums';
import {
    BlobForm, ClientInfo, FormGroupStatus, OrganizerFormDataViewModel, OrganizerMetadata,
    SignerDocumentModel, UploadedDocument
} from '../../core/domain/models/Organizer/Organizer';
import { SignProcessInfo, SignProcessSteps } from '../../core/domain/models/signingprocess/SignProcess';
import { logger } from '../../routes';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { actionCreators as UploadedDocumentStore } from '../../store/UploadedDocument/UploadedDocumentStore';
import * as OrganizerClientStore from '../../store/Organizer/OrganizerClientStore';
import * as OrganizerMetadataStore from '../../store/Organizer/OrganizerMetadataStore';
import * as OrganizerStore from '../../store/Organizer/OrganizerStore';
import { UploadedDocumentStoreState } from '../../store/UploadedDocument/UploadedDocumentStoreModels';
import { OrganizerConstants, OrganizerStatusChangedWarning, PreviewConstants, SignProcessInfoConstants, SupportedFilePreviewTypes } from '../Common/Constants';
import { GetDataFromStorage, IsPreviewMode, IsPreviewModeFromCache, IsClientViewFromCache, SetDataToStorage, RemoveEventListeners } from '../Helper/HelperFunction';
import { Viewer } from './Viewer/Viewer';
import { WarningPopup } from './WarningPopup/WarningPopup';
import { ModalNotification } from './../Common/Notification/ModalNotification';
import { PreviewUplodedFileModal } from '../Preview/PreviewUploadedFileModal';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { ISnackbar } from '../../core/utilities/ui/Snackbar';
import { IOrganizerService } from '../../core/Services/OrganizerService/OrganizerService';
import { SpouseInfoModal } from '../EngagementLetter/SpouseInfo/SpouseInfoModal';


export type MainComponentProps =
    {
        organizerDocument: SignerDocumentModel,
        blobForms: BlobForm[],
        organizerMetadata: OrganizerMetadata,
        uploadedDocuments: UploadedDocument[],
        clientInfo: ClientInfo,
        uploadedDocumentStore: UploadedDocumentStoreState,
    }
    & typeof UploadedDocumentStore
    & typeof OrganizerStore.actionCreators
    & typeof OrganizerMetadataStore.actionCreators
    & typeof OrganizerClientStore.actionCreators
    & RouteComponentProps<{}>;

interface MainComponentState {

    pageNumber: number;
    showValidationError: boolean;
    scrollToControl: string;
    showWarning: boolean;
    isReviewAllowed: boolean;
    showSpouseModal: boolean;
    signingOrder: number;
    clientType: ClientType;
    spouseName: string;
    showReview: boolean;
    warningChecked: boolean;
    showRedirectWarning: boolean;
    filePreviewModal: {
        url: string,
        showPDFPreview: boolean;
        fileType: string | undefined;
        fileName: string;
    }
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const organizerService = container.get<IOrganizerService>(TYPES.IOrganizerService);

export class Sign extends React.Component<MainComponentProps, MainComponentState> {

    private _param: any;
    private _viewerRef: any = null;
    constructor(props: MainComponentProps) {
        super(props);

        this.state = {
            pageNumber: 1,
            showValidationError: false,
            scrollToControl: "",
            showWarning: false,
            spouseName: "",
            isReviewAllowed: false,
            showSpouseModal: false,
            signingOrder: 0,
            clientType: ClientType.Undefined,
            showReview: false,
            warningChecked: false,
            showRedirectWarning: false,
            filePreviewModal: {
                url: "",
                showPDFPreview: false,
                fileType: "",
                fileName: "",
            },
        };
    }

    componentDidMount() {
        this.disableRedirectWarning();
        this._param = this.props.match.params;
        this.getDocumentStatus();
        this.props.requestOrganizerMetadata(this._param.clientId,
            () => {
                this.UpdateSignProcessStep();
            });

        this.props.requestUploadedDocumentStatus(this._param.clientId);
        this.props.requestUploadedDocuments(this._param.clientId);
    }

    componentWillUnmount() {
        (window as any).onbeforeunload = undefined;
    }

    componentDidUpdate = (prevProps: Readonly<MainComponentProps>, prevState: Readonly<MainComponentState>, snapshot?: any) => {
        this.setRedirectionWarning();
        if (this.props.uploadedDocumentStore?.sourceDocumentMetaData?.path != prevProps.uploadedDocumentStore?.sourceDocumentMetaData?.path) {
            if (SupportedFilePreviewTypes.includes(this.props.uploadedDocumentStore.sourceDocumentMetaData?.extension)) {
                this.setState({
                    filePreviewModal: {
                        ...this.state.filePreviewModal,
                        url: this.props.uploadedDocumentStore.sourceDocumentMetaData.path,
                        showPDFPreview: true,
                        fileType: this.props.uploadedDocumentStore.sourceDocumentMetaData?.extension?.substring(1),
                        fileName: this.props.uploadedDocumentStore.sourceDocumentMetaData.fileName
                    }
                })
            } else if (this.props.uploadedDocumentStore.sourceDocumentMetaData?.extension) {
                MarsNotifier.Warning(PreviewConstants.PreviewNotSupported, null);
            }
        }
    }

    componentWillReceiveProps = () => {
        this.setRedirectionWarning();
    }

    private setRedirectionWarning() {
        if (this.state.showRedirectWarning && !IsPreviewMode(this.props.organizerMetadata)) {
            window.onbeforeunload = () => true;
        } else {
            (window as any).onbeforeunload = undefined;
        }
    }

    private enableRedirectWarning = () => {
        this.setState({ showRedirectWarning: true });
        this.setRedirectionWarning();
    }

    private disableRedirectWarning = () => {
        this.setState({ showRedirectWarning: false });
        this.setRedirectionWarning();
        RemoveEventListeners();
    }

    private getDocumentStatus = () => {

        const _self = this;

        organizerService.getDocumentStatusAndSourceFileSettings(this._param.clientId).then(function (response: any) {

            _self.validateOrganizerStatus(response.data.organizerStatus);
            _self.updateReviewPopup(response.data.organizerStatus, response.data.client.reviewStatus);
            _self.updateBasedOnClientInfo(response.data.client);

        });
    }

    updateReviewPopup = (organizerStatus: FormGroupStatus, reviewStatus: ReviewStatus) => {

        if (IsPreviewModeFromCache() || IsClientViewFromCache()) return;

        if (organizerStatus != FormGroupStatus.Completed && organizerStatus != FormGroupStatus.ManuallyCompleted &&
            reviewStatus == ReviewStatus.PendingReview) {
            this.setState({
                showReview: true
            });
            this.showReview();
        }
    }

    updateBasedOnClientInfo = (client: any) => {
        let { spouseName, isReviewAllowed } = this.state;
        if (client.spouseName != null && client.spouseName != "") {
            spouseName = client.spouseName;
            if (!client.hideWarning && client.reviewStatus != ReviewStatus.PendingReview) {
                this.showWarning();
            }
            if (client.reviewStatus == ReviewStatus.None) {
                isReviewAllowed = true;
            }
        }
        this.setState({
            spouseName: spouseName,
            isReviewAllowed: isReviewAllowed,
            signingOrder: client.signingOrder,
            clientType: client.clientType
        });
    }

    private validateOrganizerStatus = (organizerStatus: FormGroupStatus) => {
        const { requestOrganizerForms, requestOrganizerControls } = this.props;

        this.disableRedirectWarning();
        if (organizerStatus == FormGroupStatus.Completed || organizerStatus == FormGroupStatus.ManuallyCompleted || organizerStatus == FormGroupStatus.OrganizerDownloaded) {
            dialogBox.refreshAlert(OrganizerStatusChangedWarning, () => {
                RemoveEventListeners();
                this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);
            });
        } else {
            this.enableRedirectWarning();
            requestOrganizerForms(this._param.clientId);
            requestOrganizerControls(this._param.clientId);
        }
    }

    private handleSave = () => {
        logger.trackTrace("Organizer save");
        if (!this.handleSkipAndFinishForPreview()) {
            const { organizerDocument } = this.props;
            const _self = this;
            const organizerForms: OrganizerFormDataViewModel = OrganizerFormDataViewModel.create((organizerDocument as any).data.formGroup[0], "");
            this.UpdateSignProcessInfo(this.state.pageNumber);
            this.disableRedirectWarning();
            this.props.submitSignedDocument(this._param.clientId, organizerForms, () => {
                this.setRedirectionWarning();
                _self.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);
            }, this.saveError);
        }
    }

    private handleFinish = () => {
        logger.trackTrace("Organizer finish");
        if (!this.handleSkipAndFinishForPreview()) {
            this.state.spouseName != "" && this.state.isReviewAllowed ?
                this.dialogIfSpouseExists() :
                this.dialogIfSpouseNotExists();
        }
    }

    private showSpouseModal = () => {
        const { clientInfo } = this.props;
        return ((clientInfo.email == null || clientInfo.email == "")
            && this.state.signingOrder == 1 && clientInfo && clientInfo.id > 0
            && clientInfo.isDeceased == false);
    }

    private dialogIfSpouseExists = () => {
        dialogBox.customDialog(
            OrganizerConstants.FinishConfirmationTitle,
            OrganizerConstants.FinishSendForReviewMessage,
            OrganizerConstants.FinishSendForReviewText,
            OrganizerConstants.FinishConfirmationConfirmText,
            () => {
                if (this.showSpouseModal())
                    this.setState({ showSpouseModal: true });
                else
                    this.sendForReview();
            },
            () => {
                const { organizerDocument } = this.props;
                const organizerForms: OrganizerFormDataViewModel = OrganizerFormDataViewModel.create((organizerDocument as any).data.formGroup[0], "");
                this.disableRedirectWarning();
                this.props.finishDocumentSign(
                    this._param.clientId, organizerForms, () => {
                        this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);
                    }, this.saveError);
            });
    }

    private sendForReview = () => {
        logger.trackTrace("Organizer sendforreview");
        const { organizerDocument } = this.props;
        const organizerForms: OrganizerFormDataViewModel = OrganizerFormDataViewModel.create((organizerDocument as any).data.formGroup[0], "");
        this.disableRedirectWarning();
        this.props.submitSignedDocument(this._param.clientId, organizerForms);
        this.UpdateSignProcessInfo(this.state.pageNumber);
        this.props.sendForReview(
            this._param.clientId,
            this.state.clientType,
            () => {
                this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);
            },
            this.saveError);
    }

    private dialogIfSpouseNotExists = () => {
        dialogBox.confirm(
            OrganizerConstants.FinishConfirmationTitle,
            OrganizerConstants.FinishConfirmationMessage,
            OrganizerConstants.FinishConfirmationCancellText,
            OrganizerConstants.FinishConfirmationConfirmText,
            (result: boolean) => {
                if (result) {
                    const { organizerDocument } = this.props;
                    const organizerForms: OrganizerFormDataViewModel = OrganizerFormDataViewModel.create((organizerDocument as any).data.formGroup[0], "");
                    this.disableRedirectWarning();
                    this.props.finishDocumentSign(this._param.clientId, organizerForms, () => {
                        this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);
                    }, this.saveError);
                }
            });
    }

    saveError = () => {
        RemoveEventListeners();
        dialogBox.refreshAlert(OrganizerStatusChangedWarning, () => {
            RemoveEventListeners();
            this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);
        });
    }

    private handleRedirect = (url: string): any => {
        window.location.href = url;
    }

    private toggleRedirectWarning = (value: boolean) => {
        this.setState({ showRedirectWarning: value });
        this.setRedirectionWarning();
    }

    private handleSkipAndFinishForPreview = () => {
        if (IsPreviewModeFromCache()) {
            this.handleRedirect(OrganizerConstants.PreviewURL + this._param.clientId);
            return true;
        }
        if (IsClientViewFromCache()) {
            this.handleRedirect(OrganizerConstants.ClientViewURL + this._param.clientId);
            return true;
        }
        return false;
    }

    private UpdateSignProcessStep = () => {
        if (!(IsPreviewModeFromCache() || IsClientViewFromCache())) {
            let processInfo: SignProcessInfo;
            const cachedSignProcessInfo = GetDataFromStorage(SignProcessInfoConstants.SignProcessInfo);
            if (cachedSignProcessInfo) {
                processInfo = JSON.parse(cachedSignProcessInfo);
                if (processInfo != null) {
                    this.setState({
                        pageNumber: this.GetLastVisitedPage(processInfo)
                    });
                    if (processInfo.lastVisitedStep != SignProcessSteps.CompleteOrganizer) {
                        processInfo.lastVisitedStep = SignProcessSteps.CompleteOrganizer;
                        organizerService.updateSignProcessinfo(processInfo);
                    }
                }
            }
            else {
                processInfo = new SignProcessInfo(this._param.clientId, SignProcessSteps.CompleteOrganizer, this.state.pageNumber);
                organizerService.updateSignProcessinfo(processInfo);
                SetDataToStorage(SignProcessInfoConstants.SignProcessInfo, JSON.stringify(processInfo));
            }
        }
    }

    private GetLastVisitedPage = (processInfo: SignProcessInfo) => {
        const lastVisitedPage: number = processInfo.lastVisitedPageNo;
        processInfo.lastVisitedPageNo = 1; //reseting last visited page in local storage
        SetDataToStorage(SignProcessInfoConstants.SignProcessInfo, JSON.stringify(processInfo));
        return lastVisitedPage;
    }

    private UpdateSignProcessInfo = (pageNo: number) => {
        if (!(IsPreviewModeFromCache() || IsClientViewFromCache())) {
            const processInfo = new SignProcessInfo(this._param.clientId, SignProcessSteps.CompleteOrganizer, this.state.pageNumber);
            organizerService.updateSignProcessinfo(processInfo);
        }
    }

    handlePageChange = (page: number, callback: () => void) => {
        this.setState({
            pageNumber: page
        }, callback);
        SetDataToStorage(SignProcessInfoConstants.LastVisitedPage, JSON.stringify(page));
    }

    onDoNotShowWarningClick = (value: boolean) => {
        this.setState({
            warningChecked: value
        });
    }

    onCloseWarning = () => {
        this.props.updateWarningPreference(this._param.clientId, this.state.warningChecked);
        this.setState({
            showWarning: false
        });
    }

    private handleUpdateSpouseEmail = (clientInfo: ClientInfo) => {
        const { updateSpouseEmail } = this.props;
        updateSpouseEmail(this._param.clientId, clientInfo, () => {
            this.toggleSpouseInfoModal();
            this.sendForReview();
        });
    }

    private toggleSpouseInfoModal = () => {
        this.setState({ showSpouseModal: !this.state.showSpouseModal });
    }

    showReview = () => {
        let _self = this;
        let showPopup = setInterval(() => {
            const { organizerDocument } = this.props;
            const { spouseName, showReview } = this.state;

            if (organizerDocument?.data?.formGroup[0]?.forms != null && organizerDocument.data.formGroup[0].forms.length > 0
                && showReview) {
                if (document.getElementsByClassName('awesome-pdf-viewer-loader visible').length == 0) {
                    _self.setState({
                        showReview: false
                    });
                    clearInterval(showPopup);
                    _self.showReviewPopUp(spouseName);
                }
            }
        }, 500);
    }

    showReviewPopUp = (spouseName: string) => {
        dialogBox.confirm(
            OrganizerConstants.ReviewBySecondSignerPopup.Title,
            OrganizerConstants.ReviewBySecondSignerPopup.Mesage.replace('{0}', spouseName),
            OrganizerConstants.ReviewBySecondSignerPopup.ReviewButton,
            OrganizerConstants.ReviewBySecondSignerPopup.ConfirmButton,
            (result) => {
                this.disableRedirectWarning();
                if (result) {
                    this.props.ignoreReviewOrganizer(this._param.clientId);
                    this.props.finishDocumentSign(this._param.clientId, OrganizerFormDataViewModel.createForReview(true), () => {
                        this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);
                    }, this.saveError);
                }
                else {
                    this.props.reviewOrganizer(this._param.clientId);
                }
            }
        );
    }

    onHidePDFPreview = () => {
        this.setState({
            filePreviewModal: {
                ...this.state.filePreviewModal,
                showPDFPreview: false
            }
        })
    }

    showWarning = () => {
        let _self = this;
        let showPopup = setInterval(() => {
            if (document.getElementsByClassName('awesome-pdf-viewer-loader visible').length == 0) {
                _self.setState({
                    showWarning: true
                });
                clearInterval(showPopup);
            }
        }, 500);
    }

    public render() {
        this._param = this.props.match.params;
        const { organizerDocument,
            requestSpouseInfo, clientInfo, uploadedDocumentStore: { isCompleted, isError, errorMessage },
            setUploadedDocumentCompleted, resetUploadedDocumentError, getSourceDocumentMetadataAsync
        } = this.props;
        const { pageNumber, showValidationError, scrollToControl, showWarning, showSpouseModal, clientType, filePreviewModal } = this.state;
        const isPreview = (IsPreviewModeFromCache() || IsClientViewFromCache());
        var showPrompt = !!this.state.showRedirectWarning && !isPreview;
        return (<div>

            <Prompt when={showPrompt} message={location => OrganizerConstants.RedirectConfirmationMessage} />
            <Viewer
                ref={(ref: any) => { this._viewerRef = ref }}
                onToggleRedirectWarning={this.toggleRedirectWarning}
                forms={organizerDocument}
                isReadMode={isPreview}
                page={pageNumber}
                handlePageChange={this.handlePageChange}
                showValidationError={showValidationError} scrollToControl={scrollToControl}
                clientId={this._param ? this._param.clientId : ""}
                clientType={clientType}
                onSave={this.handleSave}
                onFinish={this.handleFinish} onGoBack={() => {
                    this.handleRedirect((isPreview ? IsPreviewModeFromCache() ? OrganizerConstants.PreviewURL : OrganizerConstants.ClientViewURL :
                        OrganizerConstants.OrganizerSummaryPageURL) + this._param.clientId)
                }} currentPage={this.state.pageNumber}
                {...this.props}
                isSourceDocumentUploadCompleted={isCompleted}
                changeStatusToCompleted={setUploadedDocumentCompleted}
                deleteUploadedDocument={this.props.deleteUploadedDocument}
                requestUploadedDocuments={this.props.requestUploadedDocuments}
                getSourceDocumentMetadataAsync={getSourceDocumentMetadataAsync}
            />
            <PreviewUplodedFileModal
                url={filePreviewModal.url}
                fileName={filePreviewModal.fileName}
                fileType={filePreviewModal.fileType}
                onHidePDFPreview={this.onHidePDFPreview}
                showPDFPreview={filePreviewModal.showPDFPreview}
                customClass=''
            />
            <WarningPopup
                show={!isPreview && showWarning}
                onDoNotShowClick={this.onDoNotShowWarningClick}
                OnOkClick={this.onCloseWarning}
            />
            {
                isError &&
                <ModalNotification
                    show={isError}
                    modalBodyText={errorMessage}
                    okButtonEnabled={true}
                    okButtonText={"Ok"}
                    onOkButtonClick={resetUploadedDocumentError}
                />
            }

            <SpouseInfoModal clientId={this._param.clientId}
                clientInfo={clientInfo}
                onUpdateSpouseEmail={this.handleUpdateSpouseEmail}
                onRequestSpouseInfo={requestSpouseInfo}
                show={showSpouseModal}
                onClose={this.toggleSpouseInfoModal}
                message={OrganizerConstants.SpouseInfoUpdateModalBodyText}
                title={OrganizerConstants.SpouseInfoUpdateModalTitle} />
        </div>);
    }
}
