export enum SignProcessSteps {
    None = 0,
    Summary = 1,
    SignEngagementLetter = 2,
    CompleteOrganizer = 3
}

export class SignProcessInfo {
    clientId: string;
    lastVisitedStep: SignProcessSteps;
    lastVisitedPageNo: number;

    constructor(clientId: string,
        lastVisitedStep: SignProcessSteps,
        lastVisitedPageNo: number) {
        this.clientId = clientId;
        this.lastVisitedStep = lastVisitedStep;
        this.lastVisitedPageNo = lastVisitedPageNo;
    }
}