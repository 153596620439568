import { Role } from '../../common/Enums';
import { initialSignProcessInfoViewModel, ISignProcessInfoViewModel } from './ISignProcessInfoViewModel';

export interface IClientProcessViewModel {
    role: Role;
    currentstep: ISignProcessInfoViewModel;
    permission: string,
    isSigned: boolean;
    isDelegated: boolean;
}

export const initialClientProcessModel: IClientProcessViewModel = {
    role: Role.None,
    currentstep: initialSignProcessInfoViewModel,
    permission: "",
    isSigned: false,
    isDelegated: false
}