import * as React from "react";

interface FooterProps {
	onSave(): void;
	onFinish(): void;
}

export const Footer: React.FC<FooterProps> = ({ onFinish, onSave }) => {
	return (
		<footer style={{ position: "fixed", bottom: "0", width: "100%" }}>
			<a className="rd-nav-link btn-finish" title={"Finish"}>
				<button
					type="submit"
					onClick={onFinish}
					disabled={false}
					className="btn btn-primary btn-primary-extended btn-login pull-right"
					id="btnContinue"
					data-test-auto="2d123d32-c0cd-3272-8749-ce74da5e11b4"
				>
					Finish
				</button>
			</a>

			<a className="rd-nav-link btn-finish">
				<button
					type="submit"
					onClick={onSave}
					title={"Save & Close"}
					disabled={false}
					className="btn btn-primary btn-primary-extended btn-login pull-right"
					id="btnFinish"
					data-test-auto="2d123d32-c0cd-4272-8749-ce74da5e10b5"
				>
					Save & Close
				</button>
			</a>
		</footer>
	);
};
