import * as React from 'react';
import { EngagementLetterConstants } from '../../../Common/Constants';

export interface DeclineRemarksProps {
    onChange(remarks: string): void;
}

export const DeclineRemarks: React.FunctionComponent<DeclineRemarksProps> = ({
    onChange
}) => {

    let handleChange = (event: any) => {
        onChange(event.target.value);
    }

    return (<div className="col-md-12">
        <div className="form-group">
            <label>{EngagementLetterConstants.DeclineModalBodyText} </label>
        </div>
        <div className="form-group">
            <strong>{EngagementLetterConstants.DeclineRemarksTextboxLabel}</strong>
            <textarea rows={6} onChange={handleChange} maxLength={1000}
                className="form-control" />
        </div>
    </div>);
};