export interface ITaxClient {
    clientGuid: string;
    id: number,
    name: string,
    ssn: string,
    email: string,
    isModified: boolean,
    mobileNumber: string
}
export interface ITaxpayer extends ITaxClient {
    address: string,
    city: string,
    state: string,
    zip: string,
    dob: Date,
    isDeceased: boolean
}

export enum ClientTypes {
    Undefined = "none",
    Taxpayer = "Taxpayer",
    Spouse = "Spouse",
    Partner = "Partner",
    PartnerShip = "PartnerShip",
    ShareHolder = "ShareHolder"
}