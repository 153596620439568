import { DocumentSignatureDataViewModel, IDocumentType } from "../../viewModels/IDocumentSignatureDataViewModel";
import { Control, ControlValue, ControlType } from "./Controls";

export enum TaxSoftware {
    None = -1,
    ProSystems = 0,
    UltraTax = 1,
    GoSystem = 2,
    UltraTaxM18 = 3,
    Lacerte = 4,
    Drake = 5
}

export enum DocumentStatus {
    None = 0,
    Uploaded = 1,
    Ready = 2,
    Error = 3,
    Processing = 4,
    PreparingForDelivery = 5,
    Delivered = 6,
    PartiallyCompleted = 7,
    Completed = 8,
    Downloaded = 9
}

export enum GroupType {
    None = 0,
    Organizer = 1,
    Engagement = 2,
    OrganizerWithSign = 6,
    AdditionalEFile = 5
}

export enum FormGroupStatus {
    None = 0,

    // organizer
    Delievered = 1,
    PartiallyCompleted = 2,
    Completed = 3,
    OrganizerDownloaded = 4,
    Dropped = 5,
    ManuallyCompleted = 6,
    QuestionnaireCompleted = 8,

    // engagement 
    AwaitingESign = 21,
    PartiallySigned = 22,
    ESigned = 23,
    NA = 25,
    Reviewed = 26,
    Declined = 27,
    ManuallySigned = 28,
    PartiallyReviewed = 29,
}

export enum StatusCode {
    Conflict = 409,
    NotFound = 404,
    Gone = 410,
    Ok = 200,
    Locked = 423,
    OrganizerUnavailable = 601,
}

export enum FormType {
    None = 0,
    OrganizerClient = 1,
    Organizer = 2,
    EngagementLetter = 3,
    Removed = 4,
    AdditionalQuestion = 5
}

export enum UploadMethod {
    None = 0,
    Proforma = 1,
    Blank = 2,
    Batch = 3
}

export enum DownloadableStatus {
    Unknown = 0,
    Preparing = 1,
    Ready = 2,
    Delivered = 3
}

export class SignerDocumentModel {

    id: number;
    documentGuid: string;
    organizerClients: [];
    formGroup: FormGroup[];
    documentSettings: any;
    documentUrl: string;
    uploadMethod: UploadMethod;
    additionalEsign!: DocumentSignatureDataViewModel[];
    data: any;
    processInfoId!: number;
    fileGuid!: string;
    fileName!: string;
    documentType!: IDocumentType;
    uploadedDocuments: any;

    constructor(id: number,
        documentGuid: string,
        organizerClients: [],
        formGroup: FormGroup[],
        documentSettings: any,
        documentUrl: string, uploadMethod: UploadMethod,
        additionalEsign: DocumentSignatureDataViewModel[]) {

        this.id = id;
        this.documentGuid = documentGuid;
        this.organizerClients = organizerClients;
        this.formGroup = formGroup;
        this.documentSettings = documentSettings;
        this.documentUrl = documentUrl;
        this.uploadMethod = uploadMethod;
        this.additionalEsign = additionalEsign;
    }

    public static createNullObject(): SignerDocumentModel {
        return new SignerDocumentModel(0, "", [], [{ groupType: GroupType.None, forms: [] }], {}, "", UploadMethod.None, []);
    }
}

export class FormGroup {
    groupType: GroupType;
    forms: Form[];

    constructor(groupType: GroupType,
        forms: Form[]) {

        this.groupType = groupType;
        this.forms = forms;
    }
    public static createNullObject(forms: Form[], groupType: GroupType): FormGroup {
        return new FormGroup(groupType, forms);
    }
}

export interface IFormData {

}

export class EngagementLetterFormData implements IFormData {
    controlList: Control[];

    constructor(controlList: Control[]) {
        this.controlList = controlList;
    }

    public static createNullObject(controlList: Control[]): EngagementLetterFormData {
        return new EngagementLetterFormData(controlList);
    }
}

export class OrganizerFormData implements IFormData {

    controlValues: ControlValue[];

    constructor(controlValues: ControlValue[]) {
        this.controlValues = controlValues;
    }

    public static create(controlValues: ControlValue[]): OrganizerFormData {
        return new OrganizerFormData(controlValues);
    }
}

export class ValueFormData implements IFormData {
    controlValues: ControlValue[];

    constructor(controlValues: ControlValue[]) {
        this.controlValues = controlValues;
    }

    public static createNullObject(): ValueFormData {
        return new ValueFormData([]);
    }
}

export class Form {

    formType: FormType;
    pageNo: number;
    formName: string;
    bookmark: string;
    formData: IFormData;
    formGroupId: string;
    formId: number;
    formGuid: string;
    individualFilePageNo?: number;
    message: string;
    constructor(formType: FormType,
        pageNo: number,
        formName: string,
        bookmark: string,
        formData: IFormData,
        formGroupId: string,
        formId: number,
        formGuid: string,
        message: string,
        individualFilePageNo?: number,
    ) {

        this.formType = formType;
        this.pageNo = pageNo;
        this.formName = formName;
        this.message = message;
        this.bookmark = bookmark;
        this.formData = formData;
        this.formGroupId = formGroupId;
        this.formId = formId;
        this.formGuid = formGuid;
        this.individualFilePageNo = individualFilePageNo;
    }


    public static create(formType: FormType, formGuid: string, formData: IFormData): Form {
        return new Form(formType, 0, "", "", formData, "", 0, formGuid, "", 0);
    }

}

export class OrganizerForm extends Form {
    enabledSourceDocument: boolean;
    constructor(formType: FormType,
        pageNo: number,
        formName: string,
        bookmark: string,
        formData: IFormData,
        formGroupId: string,
        formId: number,
        formGuid: string,
        message: string,
        enabledSourceDocument: boolean,
        individualFilePageNo?: number) {
        super(
            formType,
            pageNo,
            formName,
            bookmark,
            formData,
            formGroupId,
            formId,
            formGuid,
            message,
            individualFilePageNo
        );
        this.enabledSourceDocument = enabledSourceDocument;
    }
}

export class BlobForm {
    formGuid: string;
    controlList: Control[];

    constructor(formGuid: string,
        controlList: Control[]) {
        this.formGuid = formGuid;
        this.controlList = controlList;
    }

    public static createNullObject(): BlobForm {
        return new BlobForm("", []);
    }
}

export class OrganizerMetadata {
    clientName: string;
    senderName: string;
    taxYear: number;
    isPreview: boolean;
    isAccessedFromOneHub: boolean;

    constructor(
        clientName: string,
        senderName: string,
        taxYear: number,
        isPreview: boolean,
        isAccessedFromOneHub: boolean = false) {
        this.clientName = clientName;
        this.senderName = senderName;
        this.taxYear = taxYear;
        this.isPreview = isPreview;
        this.isAccessedFromOneHub = isAccessedFromOneHub;
    }

    public static createNullObject(): OrganizerMetadata {
        return new OrganizerMetadata("", "", 0, false, false);
    }
}

export class UploadedDocument {
    id: number;
    fileName: string;
    clientId: number;
    uploadedOn: Date;
    documentId: number;
    formId?: number;
    documentGuid: string;
    taxYear: number;
    fileSize: string;

    constructor(id: number,
        fileName: string,
        clientId: number,
        uploadedOn: Date,
        documentId: number,
        documentGuid: string,
        taxYear: number,
        fileSize: string,
        formId?: number,
    ) {
        this.id = id;
        this.fileName = fileName;
        this.clientId = clientId;
        this.uploadedOn = uploadedOn;
        this.documentId = documentId;
        this.formId = formId;
        this.documentGuid = documentGuid;
        this.taxYear = taxYear;
        this.fileSize = fileSize;
    }

    public static createNullObject(): UploadedDocument {
        return new UploadedDocument(0, "", 0, new Date(), 0, "", 0, "", 0);
    }

    public static create(fileName: string, fileSize: string, formId?: number): UploadedDocument {
        return new UploadedDocument(0, fileName, 0, new Date(), 0, "", 0, fileSize, formId);
    }
}

export class ClientInfo {

    id: number;
    name: string;
    email: string;
    SSN: string;
    mobileNumber: string;
    countryCode: string;
    DOB: Date;
    address: string;
    city: string;
    state: string;
    zip: string;
    isDeceased: boolean;
    documentSigner: boolean;

    constructor(id: number,
        name: string,
        email: string,
        SSN: string,
        mobileNumber: string,
        countryCode: string,
        DOB: Date,
        address: string,
        city: string,
        state: string,
        zip: string,
        isDeceased: boolean,
        documentSigner: boolean) {

        this.id = id;
        this.name = name;
        this.email = email;
        this.SSN = SSN;
        this.mobileNumber = mobileNumber;
        this.countryCode = countryCode;
        this.DOB = DOB;
        this.address = address;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.isDeceased = isDeceased;
        this.documentSigner = documentSigner;
    }

    public static createNullObject(): ClientInfo {
        return new ClientInfo(0, "", "", "", "", "", new Date(), "", "", "", "", false, false);
    }
}

export class OrganizerFormDataViewModel {

    formGroup: FormGroup;
    signature: string;
    isReviewed: boolean;

    constructor(formGroup: FormGroup,
        signature: string,
        isReviewed: boolean) {

        this.formGroup = formGroup;
        this.signature = signature;
        this.isReviewed = isReviewed;
    }

    public static create(formGroup: FormGroup,
        signature: string,
        isReviewed: boolean = false): OrganizerFormDataViewModel {
        return new OrganizerFormDataViewModel(formGroup, signature, isReviewed);
    }

    public static createForReview(isReviewed: boolean): OrganizerFormDataViewModel {
        return new OrganizerFormDataViewModel({} as FormGroup, "", isReviewed);
    }
}

//Can extend to support data type, regex, length validations 
export interface IValidator {
    Validate: (data: object, controlType: ControlType) => boolean;
}

export class ValidationModel {
    id: string;
    data: object;
    validators: IValidator[];
    controlType: ControlType;
    pageNumber: number;
    properties: any;

    constructor(id: string,
        value: object,
        controlType: ControlType,
        pageNumber: number,
        properties?: any) {
        this.id = id;
        this.data = value;
        this.controlType = controlType;
        this.validators = [new RequiredValidator()];
        this.pageNumber = pageNumber;
        if (properties) {
            this.setProperties(properties);
        }
    }

    setProperties(properties: any) {
        this.properties = properties;
    }

    Validate() {
        let isValid = true;
        this.validators.forEach(val => {
            if (!val.Validate(this.data, this.controlType)) {
                isValid = false;
                return;
            }
        });
        return isValid;
    }

    public static Create(key: string,
        value: object,
        controlType: ControlType,
        pageNumber: number,
        properties?: any) {
        return new ValidationModel(key, value, controlType, pageNumber, properties);
    }
}

export class RequiredValidator implements IValidator {
    Validate(data: object, controlType: ControlType) {
        switch (controlType) {
            case ControlType.Textbox:
                return (data != null && (data as any).value as String != "");
            case ControlType.RadioButton:
                let isValid = false;
                (data as any).items.forEach(function (radio: any) {
                    if (radio.controlData.selected) {
                        isValid = true;
                        return;
                    }
                });
                return isValid;
        }
        return true;
    }
}

export class PreparerMessage {
    message: string;
    constructor(message: string) {
        this.message = message;
    }

    public static createNullObject(): PreparerMessage {
        return new PreparerMessage("");
    }
}