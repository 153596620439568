import { CheckBox, ControlBaseProps, ControlData, RadioButton, TextBoxData } from "awesome-pdf-viewer/dist/Controls/ControlBase";
import * as Enumerable from 'linq';
import { IElDocument } from "../../core/domain/models/esign/Document";
import { CustomTextControl, IControl, TextData } from "../../core/domain/models/esign/IControls";
import { CheckBoxControl, ChoosableControlResult, Control, ControlType, ControlValue, RadioButtonControl, SignatureData, TextboxData } from "../../core/domain/models/Organizer/Controls";
import { EngagementLetterFormData, Form, FormGroup, SignerDocumentModel, UploadMethod, ValueFormData } from "../../core/domain/models/Organizer/Organizer";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import { ViewerHelper } from "./Viewer/ViewerHelper";
import { IDateUtilities } from "../../core/utilities/date/DateUtilities";

const dateUtilities = container.get<IDateUtilities>(TYPES.IDateUtilities);

export class EngagementLetterSignHelper {

    public static getServerModel(controls: any[], organizerDocumentModel: SignerDocumentModel, isAdditionalDocument: boolean = false): SignerDocumentModel {

        if ((organizerDocumentModel as any).data.uploadMethod == UploadMethod.Batch && !isAdditionalDocument) {

            (organizerDocumentModel as any).data.formGroup && (organizerDocumentModel as any).data.formGroup.forEach(function (formGroup: FormGroup) {
                formGroup.forms.forEach(function (form: Form) {

                    const formData: EngagementLetterFormData = form.formData as EngagementLetterFormData;

                    const tmpBatchFormData: ValueFormData = ValueFormData.createNullObject();

                    formData && formData.controlList && formData.controlList.forEach(function (control: Control) {

                        const tmpControl = controls.find(x => x && x.props.id == ViewerHelper.ControlIdPrefix + control.id);

                        if (tmpControl) {

                            switch (control.controlType) {

                                case ControlType.Textbox: // TextBox

                                    tmpBatchFormData.controlValues.push(ControlValue.create(form.formGuid,
                                        control.id,
                                        tmpControl.getControlData().text));

                                    break;

                                case ControlType.Date: // Date

                                    tmpBatchFormData.controlValues.push(ControlValue.create(form.formGuid,
                                        control.id,
                                        dateUtilities.getCurrentDate().toString()));

                                    break;

                                case ControlType.Signature: // Signature

                                    const controlData = tmpControl.getControlData();


                                    if (controlData) {
                                        const signatureData: SignatureData = SignatureData.create(controlData.name, controlData.signature, controlData.signatureMode);

                                        tmpBatchFormData.controlValues.push(ControlValue.create(form.formGuid,
                                            control.id,
                                            JSON.stringify(signatureData)));
                                    }
                                    break;

                                default:
                                    throw new Error("Selected control not implemented !!");
                            }
                        }
                    });
                    form.formData = tmpBatchFormData;
                });
            });
        }
        else { // perfoma,blank

            (organizerDocumentModel as any).data.formGroup?.forEach(function ({ forms }: FormGroup) {

               forms.forEach(function (form: Form) {

                   const formData: EngagementLetterFormData = form.formData as EngagementLetterFormData;

                   if (formData && formData.controlList) {

                       formData.controlList.forEach(function (control: Control) {

                           const uiControl = controls.find(x => x && x.props.id == ViewerHelper.ControlIdPrefix + control.id);

                           if (uiControl) {
                               control = EngagementLetterSignHelper.getControlData(control, uiControl);
                           }
                       });

                       formData.controlList = formData.controlList.filter((x: any) => x.controlType != ControlType.Signature? true :  x.controlData)
                   }
                });
            });
        }

        return organizerDocumentModel;
    }

    private static getControlData = (actualControl: any, uiControl: any): any => {
        switch (actualControl.controlType) {

            case ControlType.Textbox: // TextBox

                actualControl.controlData = new TextboxData(uiControl.getControlData().text);

                break;

            case ControlType.Date: // Date

                actualControl.controlData = { value: dateUtilities.getCurrentDate() }

                break;

            case ControlType.Signature: // Signature

                const controlData = uiControl.getControlData();
                if (controlData) {
                    actualControl.controlData = SignatureData.create(controlData.name, controlData.signature, controlData.signatureMode);
                }

                break;

            case ControlType.SignatureCheckBoxControl: //Checkbox

                const controlCheckData = uiControl.getControlData();

                actualControl.items.forEach((item: any) => {
                    item.value.checked = controlCheckData.items.find((x: any) => x.id == item.id).value.checked;
                })

                break;

            case ControlType.SignatureRadioButtonControl: // Radio button

                const controlRadioData = uiControl.getControlData();

                actualControl.items.forEach((item: any) => {
                    item.value.checked = controlRadioData.items.find((x: any) => x.id == item.id).value.checked;
                })
                break;

            default:
                throw new Error("Selected control not implemented !!");
        }
        return actualControl;
    }

    public static getAdditionalEsignServerModel(controls: any[], organizerDocumentModel: SignerDocumentModel): SignerDocumentModel {

        (organizerDocumentModel as any).data.additionalEsign.forEach((document: any) => {
            document.documentControls.formData.forEach((form: any) => {

                form.controlList.forEach((control: any) => {
                    const uiControl = controls.find(x => x && x.props.id == ViewerHelper.ControlIdPrefix + control.id);
                    if (uiControl) {
                        control = EngagementLetterSignHelper.getControlData(control, uiControl);
                    }
                });

                form.controlList = form.controlList.filter((x: any) => x.controlType != ControlType.Signature ? true : x.controlData)
            });
        });
        return organizerDocumentModel;
    }

    public static updateControlData(documents: IElDocument[], controlData: ControlData, controlProps: ControlBaseProps): boolean {
        let signingCompleted: boolean = false;
        try {
            let total: number = 0;
            let controlsWithValue: number = 0;

            for (var i = 0; i <= documents.length - 1; i++) { // doument loop

                const pages = documents[i].forms;

                for (var j = 0; j <= pages.length - 1; j++) { // document pages loop

                    const controls = pages[j].controls;

                    if (controls && controls.length > 0) {
                        for (var k = 0; k <= controls.length - 1; k++) { // page controls loop

                            const control = controls[k];

                            this.update(control, controlData, controlProps);

                        }

                        const totalRequiredControls = Enumerable.from(controls).count((x: any) => x.required == true && x.controlType != ControlType.Date);
                        total = total + totalRequiredControls;

                        const tmpControlsWithValues = Enumerable.from(controls).count((x: any) => x.required == true &&
                            x.data &&
                            ((x.data["text"] && x.data["text"].trim() != '') ||
                                (x.data["uri"]) ||
                                (x.data["checked"] && x.data["checked"] === true) ||
                                (this.controlDataExist(x.data) === true)));

                        controlsWithValue = controlsWithValue + tmpControlsWithValues;

                        pages[j].allControlSigned = totalRequiredControls == tmpControlsWithValues;
                    }
                    else if (controls.length == 0) {
                        pages[j].allControlSigned = true;
                    }
                }
                const totalAllSignedPages = Enumerable.from(documents[i].forms).count(x => x.allControlSigned == true);
                documents[i].signCompleted = documents[i].forms.length == totalAllSignedPages;
            }

            signingCompleted = controlsWithValue === total;
        }
        catch (error) {
            console.log(error);
        }

        return signingCompleted;
    }

    private static controlDataExist(controlData: any[]): boolean {
        if (controlData && controlData.length > 0) {
            return controlData.filter(x => x.checked === true).length > 0;
        }
        return false;
    }

    public static update(control: IControl, controlData: ControlData, controlProps: ControlBaseProps) {

        if (controlProps.data["controlType"] == 1
            || (controlProps.data["controlType"] == 2)
        ) {
            if (control.id == controlProps.data["controlId"] || (control.controlType === ControlType.Signature && control.data !== undefined)) { // add signature to the particluar control or update already signed controls
                const tmpSignatureData = SignatureData.create((controlData as SignatureData).name, (controlData as any).signature, (controlData as SignatureData).signatureMode);
                control.data = tmpSignatureData;
            }
        }
        else if (controlProps.data["controlType"] == 11) {
            if (control.id == controlProps.data.controlId) {
                let tempControl = control as CheckBoxControl;
                let tmpCheckBoxData: ChoosableControlResult[] = [];
                let checkBoxData = controlData as CheckBox[];
                tempControl.items.forEach(function (checkbox: CheckBox) {
                    const index = checkBoxData.findIndex(x => x.id == checkbox.id);
                    if (index != -1) {
                        tmpCheckBoxData.push(ChoosableControlResult.create(checkbox.id, checkBoxData[index].value.checked));
                    }
                });
                control.data = tmpCheckBoxData;
            }
        }
        else if (controlProps.data["controlType"] == 12) {
            if (control.id == controlProps.data.controlId) {
                let tempControl = control as RadioButtonControl;
                let tmpRadioButtonData: ChoosableControlResult = ChoosableControlResult.create('', false);
                let radiobuttonData = controlData as RadioButton[];
                tempControl.items.forEach(function (radio: RadioButton) {
                    const index = radiobuttonData.findIndex(x => x.id == radio.id);
                    if (index != -1 && radiobuttonData[index].value.checked) {
                        tmpRadioButtonData = ChoosableControlResult.create(radio.id, true);
                    }
                });
                control.data = tmpRadioButtonData;
            }
        }
        else if (control.id == controlProps.data["controlId"]) {
            if (controlProps.data["controlType"] == 6
                || controlProps.data["controlType"] == 7
                || (controlProps.data["controlType"] == 8)
                || (controlProps.data["controlType"] == 9)) {
                const tmpTextData: TextBoxData = TextData.create((controlData as TextBoxData).text || "");
                control.data = tmpTextData;
            }
        }

        else if ((control instanceof CustomTextControl)) {

            if (control.id == controlProps.data["controlId"]) {

                const tmpTextData: TextBoxData = TextData.create((controlData as TextBoxData).text || "");
                control.data = tmpTextData;

            }
        }
    }
}
