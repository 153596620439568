import React, { memo, useEffect } from "react";
import { ISignalRConnectionInfo } from "src/core/common/model/SignalRConnectionInfo";
import { addGroup, getWebSocketConnectionInfo, signalRConnectionInit } from "src/core/Services/SignalR/SignalR-Service";
import * as signalR from '@microsoft/signalr';
import * as SignalRStore from "src/store/SignalR/SignalRStore";
import { useDispatch } from "react-redux";
import { FormGroupStatus } from "src/core/domain/models/Organizer/Organizer";

interface SignalRProps {
    client_id: string;
}

interface ConnectionDetails {
    url: string,
    token: string
}

export interface INotificationMessage {
    organizerId: number;
    companyId: number;
    notificationType: NotificationType;
    data: any;
    clientGuids: string[],
    accountId: number;
    organizerStatus: FormGroupStatus;
}

export enum NotificationType {
}

export const SignalRWebSocket: React.FC<SignalRProps> = ({ client_id }) => {
    var user = "";
    var group = "";
    var apiBaseUrl = "";
    var token = "";

    const dispatch = useDispatch();

    useEffect(() => {
        getWebSocketConnectionInfo(client_id, handleGetWebSocketConnectionInfoResponse);
    }, [client_id]);

    const handleGetWebSocketConnectionInfoResponse = (connectionDetails: ConnectionDetails) => {
        apiBaseUrl = connectionDetails.url;
        token = connectionDetails.token;
        user = client_id;
        group = client_id;

        signalRConnectionInit(user, apiBaseUrl, connectionDetails.token, initiateConnection);
    };

    const initiateConnection = (info: ISignalRConnectionInfo) => {
        info.accessToken = info.accessToken || info.accessKey;
        info.url = info.url || info.endpoint;
        const options = {
            accessTokenFactory: () => info.accessToken
        };
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(info.url, options)
            .configureLogging(signalR.LogLevel.Information)
            .build();
        connection.onclose(() => {
            startConnection(connection);
        });
        startConnection(connection);
    };

    const startConnection = (connection: any) => {
        connection.start()
            .then(function () {
                connection.on('DocumentStatusUpdated', documentStatusUpdated);
                addGroup(user, group, apiBaseUrl, token);
                connection.invoke('getConnectionId')
                    .then(function (connectionId: any) {
                        // Send the connectionId to controller
                    });
            })
            .catch(function (err: any) {
                console.error(err);
                setTimeout(startConnection, 5000, connection);
            });
    };

    const documentStatusUpdated = (notificationMessage: INotificationMessage) => {
        var clientGuids = notificationMessage.clientGuids;
        if (clientGuids.includes(client_id)) {
            var statusString: string = notificationMessage.organizerStatus as unknown as string;
            const newStatus = FormGroupStatus[statusString as keyof typeof FormGroupStatus];
            dispatch(SignalRStore.actionCreators.updateSignalROrganizerStatus(newStatus));
        }
    };

    return <div />;
}

export const MemoizedSignalRWebSocket = memo(SignalRWebSocket);