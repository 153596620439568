import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { DownloadableStatus, FormGroupStatus, OrganizerMetadata } from '../../core/domain/models/Organizer/Organizer';
// import { IOrganizerService } from '../../core/services/OrganizerService/OrganizerService';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import * as OrganizerMetadataStore from '../../store/Organizer/OrganizerMetadataStore';
import * as OrganizerStore from '../../store/Organizer/OrganizerStore';
import * as PreviewStore from '../../store/Organizer/PreviewStore'
import { OrganizerConstants, PreviewConstants, SourceDocumentConstants } from '../Common/Constants';
import {
    ShowSummaryPageSecondaryMessage,
    IsNullorEmpty,
    GetMenuSeperator,
    HandleRedirect,
    DownloadOrganizerDocument,
    DownloadEngagementLetter,
    CheckStatusforDisplayingMenu,
    ShowClickHereToContinueTextBasedOnStatus } from '../Helper/ClientviewTpPreviewHelper';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { Link } from 'react-router-dom';
import { SetDataToStorage } from '../Helper/HelperFunction';
import { UploadedDocumentStoreState } from '../../store/UploadedDocument/UploadedDocumentStoreModels';
import { actionCreators as UploadedDocumentStore } from '../../store/UploadedDocument/UploadedDocumentStore';
import * as PreparerMessageStore from '../../store/Organizer/PreparerMessageStore';
import { IOrganizerService } from '../../core/Services/OrganizerService/OrganizerService';
import { ILoader } from '../../core/utilities/ui/Loader';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import  CompleteOrganizerIcon from "../../assets/images/icon-complete-organizer.svg";
import  DownloadOrganizerIcon from "../../assets/images/icon-download-organizer.svg";
import  SummarySignDocumentsIcon from "../../assets/images/icon-summary-sign-documents.svg";
import  DownloadDocumentIcon from "../../assets/images/icon-download-documents.svg";
import  UploadDocumentIcon from "../../assets/images/icon-upload-documents.svg";
var htmlencode = require('htmlencode');

export type PreviewSignProps =
    {
        organizerMetadata: OrganizerMetadata,
        previewStatus: DownloadableStatus;
        uploadedDocumentStatus: UploadedDocumentStoreState,
        preparerMessageStore: PreparerMessageStore.OrganizerPreparerMessageState,
        headerInfo: IHeaderInfoViewModel,
    }
    & typeof OrganizerMetadataStore.actionCreators
    & typeof OrganizerStore.actionCreators
    & typeof PreviewStore.actionCreators
    & typeof UploadedDocumentStore
    & typeof PreparerMessageStore.actionCreators
    & RouteComponentProps<{}>;

interface PreviewSignState {
    organizerStatus: FormGroupStatus;
    engagementLetterStatus: FormGroupStatus;
    organizerMetadata: OrganizerMetadata;
    enabledSourceDocuments: boolean;
    disableLinks: boolean;
    hasCustomQuestion: boolean;
    customQuestionStatus: FormGroupStatus;
    signingOrder: number;
    isEngagementControl: boolean;
}

const organizerService = container.get<IOrganizerService>(TYPES.IOrganizerService);
const loader = container.get<ILoader>(TYPES.ILoader);
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class PreviewSignFlow extends React.Component<PreviewSignProps, PreviewSignState> {

    private _param: any;
    constructor(props: PreviewSignProps) {

        super(props);

        this.state = {
            engagementLetterStatus: FormGroupStatus.NA,
            organizerStatus: FormGroupStatus.None,
            organizerMetadata: OrganizerMetadata.createNullObject(),
            enabledSourceDocuments: false,
            disableLinks: false,
            hasCustomQuestion: false,
            customQuestionStatus: FormGroupStatus.None,
            signingOrder: 1,
            isEngagementControl: false
        }
    }

    componentDidMount() {
        this._param = this.props.match.params;
        this.props.requestOrganizerMetadata(this._param.clientId);
        this.getDocumentStatus();
        this.initiatePreviewDocumentsStatusPolling(0);
        this.props.requestUploadedDocumentStatus(this._param.clientId);
        this.props.requestOrganizerPreparerMessage(this._param.clientId);
    }

    private initiatePreviewDocumentsStatusPolling = (retrycount: number) => {
        this.showLoader(retrycount);
        let timeout: number = 10000;
        if (retrycount > 10) {
            timeout = 60000;
        }
        if (retrycount == 16) {
            this.OnGeneratingDocsError(PreviewConstants.GeneratingPreviewTimeLimitExceededMsg);
            return;
        }
        switch (this.props.previewStatus) {
            case DownloadableStatus.Ready:
                loader.hide();
                break;
            case DownloadableStatus.Unknown:
                this.OnGeneratingDocsError(PreviewConstants.GeneratingPreviewFailedMessage);
                return;
            case DownloadableStatus.Preparing:
            default:
                this.props.requestPreviewDocumentStatus && this.props.requestPreviewDocumentStatus(this._param.clientId);
                setTimeout(this.initiatePreviewDocumentsStatusPolling, (retrycount < 2 ? 1000 : timeout), retrycount + 1);
                break;
        }
    }

    private OnGeneratingDocsError = (message: string) => {
        this.setState({
            disableLinks: true
        });
        loader.hide();
        dialogBox.alert(message);
    }

    private showLoader = (retryCount: number) => {
        if (retryCount < 1) {
            loader.show();
        }
        else {
            loader.show(PreviewConstants.GeneratingPreviewMessage);
        }
    }

    private getDocumentStatus = () => {

        const _self = this;

        organizerService.getDocumentStatusAndSourceFileSettings(this._param.clientId).then(function (response: any) {

            _self.setState({
                engagementLetterStatus: response.data.engagementLetterStatus,
                organizerStatus: response.data.organizerStatus,
                enabledSourceDocuments: response.data.enabledSourceDocuments,
                hasCustomQuestion: response.data.hasCustomQuestion,
                customQuestionStatus: response.data.customQuestionStatus,
                isEngagementControl: response.data.isEngagementcontrol,
                signingOrder: response.data.client.signingOrder
            });
            SetDataToStorage(SourceDocumentConstants.IsSourceDocumentEnabled, _self.state.enabledSourceDocuments.toString());
        });
    }

    private getCustomQuestionsButton() {
        const { customQuestionStatus } = this.state;
        let isDisableable: boolean = customQuestionStatus === FormGroupStatus.QuestionnaireCompleted;
        const redirectUrl = OrganizerConstants.CustomQuestionsURL + this._param.clientId
        return (<React.Fragment>
            <div data-test-auto='d50c459c-bbe3-11eb-8529-0242ac130005'
                title={isDisableable ? customQuestionStatus == FormGroupStatus.QuestionnaireCompleted ?
                    OrganizerConstants.CustomQuestionsCompleteCQDisabled : OrganizerConstants.EsignPendingCQDisabled : ""}>
                <div className={isDisableable ? 'area-disabled' : ''}>
                    <Link to={redirectUrl} >
                        <img src={CompleteOrganizerIcon} />
                        <br />
                        <div className={"organizer-welcome-page-menu-icon-title"}>
                            {OrganizerConstants.SummaryPageCustomQuestionsText}
                        </div>
                    </Link>
                </div>

            </div> {GetMenuSeperator()}</React.Fragment>);
    }

    private getOrganizerButton() {
        const { enabledSourceDocuments } = this.state;

        if (CheckStatusforDisplayingMenu(this._param?.clientId)) {
            return (<React.Fragment><div>
                <Link to={OrganizerConstants.OrganizerSignPageURL + this._param.clientId}
                    onClick={(event) => { this.disableLink(event); }}>
                    <img src={CompleteOrganizerIcon} />
                    <br />
                    <div className={this.state.disableLinks ? "area-disabled link-disabled" :
                        "organizer-welcome-page-menu-icon-title"}>
                        {OrganizerConstants.SummaryPageCompleteOrganizerText}
                    </div>
                </Link>
            </div> {enabledSourceDocuments && GetMenuSeperator()}</React.Fragment>);
        }
    }

    private getOrganizerDownloadButton() {

        return (<React.Fragment><div onClick={DownloadOrganizerDocument}
            data-test-auto='cc0a0b59-3dfc-42e5-b683-3a1a3687b5dd'>
            <img src={DownloadOrganizerIcon} />
            <br />
            <div className={"organizer-welcome-page-menu-icon-title"}>
                {OrganizerConstants.SummaryPageDownloadOrganizerText}
            </div>
        </div> {this.state.enabledSourceDocuments && GetMenuSeperator()}</React.Fragment>);
    }

    private getDownloadEnagementButton() {

        const { organizerStatus } = this.state;

        return (<React.Fragment><div data-test-auto='d50c4b96-bbe3-11eb-8529-0242ac130003'
            onClick={DownloadEngagementLetter}>
            <img src={DownloadDocumentIcon} />
            <br />
            <div className={"organizer-welcome-page-menu-icon-title"}>
                {OrganizerConstants.SummaryPageDownloadEngagementLetterText}
            </div>
        </div> {organizerStatus != FormGroupStatus.None && GetMenuSeperator()}</React.Fragment>);
    }

    private getSignEngegementButton() {
        if (CheckStatusforDisplayingMenu(this._param?.clientId)) {
            return (<React.Fragment><div>
                <Link to={"/engagementletter/sign/" + this._param.clientId}
                    onClick={(event) => { this.disableLink(event); }}>
                    <img src={SummarySignDocumentsIcon} />
                    <br />
                    <div className={this.state.disableLinks ? "area-disabled link-disabled" :
                        "organizer-welcome-page-menu-icon-title"}>
                        {OrganizerConstants.SummaryPageSignEngagementLetterText}
                    </div>
                </Link>
            </div> {GetMenuSeperator()}</React.Fragment >);
        }
    }

    private getCompletedUploadDocumentsButton() {
        return (<React.Fragment>
            <div data-test-auto='05f8ea7a-64bb-4413-819b-027f24d4f010'>
                <Link to={"/uploaddocuments/" + this._param.clientId} >
                    <img src={DownloadDocumentIcon} />
                    <br />
                    <div className={"organizer-welcome-page-menu-icon-title"}>
                        {OrganizerConstants.SummaryPageCompletedUploadDocumentsText}
                    </div>
                </Link>
            </div></React.Fragment>);
    }

    private getUploadDocumentsButton() {
        if (CheckStatusforDisplayingMenu(this._param?.clientId)) {
            return (<React.Fragment>
                <div>
                    <Link to={"/uploaddocuments/" + this._param.clientId}
                        onClick={(event) => { this.disableLink(event); }}>
                        <img src={UploadDocumentIcon} />
                        <br />
                        <div className={this.state.disableLinks ? "area-disabled link-disabled" :
                            "organizer-welcome-page-menu-icon-title"}>
                            {OrganizerConstants.SummaryPageUploadDocumentsText}
                        </div>
                    </Link>
                </div></React.Fragment>);
        }
    }

    private disableLink = (event: any) => {
        if (this.state.disableLinks)
            event.preventDefault();
    }

    private getContinueButton() {

        const { engagementLetterStatus, organizerStatus } = this.state;

        if (ShowClickHereToContinueTextBasedOnStatus(engagementLetterStatus, organizerStatus)) {
            return (<a className="rd-nav-link btn-finish">
                <button type="submit" onClick={this.handleContinue} data-test-auto='d50c43ee-bbe3-11eb-8529-0242ac130003'
                    className="btn btn-primary btn-primary-extended btn-login tablet-btn"
                    disabled={this.state.disableLinks}
                    id="btnContinue2">{OrganizerConstants.SummaryPageContinueButtonText}</button>
            </a>);
        }
        else if (
            (
                engagementLetterStatus == FormGroupStatus.None ||
                engagementLetterStatus == FormGroupStatus.NA ||
                engagementLetterStatus == FormGroupStatus.ESigned ||
                engagementLetterStatus == FormGroupStatus.Reviewed ||
                engagementLetterStatus == FormGroupStatus.PartiallySigned ||
                engagementLetterStatus == FormGroupStatus.PartiallyReviewed ||
                engagementLetterStatus == FormGroupStatus.Declined ||
                engagementLetterStatus == FormGroupStatus.ManuallySigned
            ) &&
            (
                organizerStatus == FormGroupStatus.Completed ||
                organizerStatus == FormGroupStatus.ManuallyCompleted
            )
        ) {
            return "";
        }
        else if (
            (
                engagementLetterStatus != FormGroupStatus.ESigned &&
                engagementLetterStatus != FormGroupStatus.ManuallySigned
            ) ||
            (
                organizerStatus != FormGroupStatus.Completed &&
                organizerStatus != FormGroupStatus.ManuallyCompleted
            )
        ) {
            return (<a className="rd-nav-link btn-finish">
                <button type="submit" onClick={this.handleContinue}
                    className="btn btn-primary btn-primary-extended btn-login tablet-btn"
                    id="btnContinue" disabled={this.state.disableLinks}>{OrganizerConstants.SummaryPageContinueButtonText}</button>
            </a>);
        }
        else {
            return "";
        }
    }

    private handleContinue = () => {
        const { engagementLetterStatus, hasCustomQuestion } = this.state;

        if (engagementLetterStatus === FormGroupStatus.NA && hasCustomQuestion) {
            HandleRedirect(OrganizerConstants.CustomQuestionsURL + this._param.clientId);
            return;
        }

        if (engagementLetterStatus == FormGroupStatus.NA ||
            engagementLetterStatus == FormGroupStatus.ESigned ||
            engagementLetterStatus == FormGroupStatus.Reviewed ||
            engagementLetterStatus == FormGroupStatus.ManuallySigned) {
            HandleRedirect(OrganizerConstants.OrganizerSignPageURL + this._param.clientId);
            return;
        }
        else {
            HandleRedirect(OrganizerConstants.EngagementLetterSignPageURL + this._param.clientId);
            return;
        }
    }

    private SummaryPageSecondaryMessage() {
        return ShowSummaryPageSecondaryMessage();
    }

    private getPreparerMessage() {
        return (
            <React.Fragment >
                <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 container"}>
                    <div className='preparer-message-container'>
                        <div className='prepare-message-header'>
                            Message from {IsNullorEmpty(this.props.headerInfo?.companyName) ? "Tax Preparer" : this.props.headerInfo?.companyName}
                        </div>
                        <br />
                        <div className='preparer-message-content' dangerouslySetInnerHTML={{ __html: htmlencode.htmlDecode(this.props.preparerMessageStore.preparerMessage.message) }}></div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    public render() {

        const { uploadedDocumentStatus: { isCompleted } } = this.props;
        const {
            engagementLetterStatus,
            enabledSourceDocuments,
            organizerStatus,
            hasCustomQuestion } = this.state;
        const organizerMetadata: OrganizerMetadata = (this.props.organizerMetadata as any).organizerMetadata;

        return (<div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page"}>
            <div className={"col-lg-10 col-md-10 col-xs-12 organizer-welcome-page-container offset-lg-1 offset-md-1"}>

                <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page-title-container"}>
                    <span className={"organizer-welcome-page-title"}>
                        Welcome {organizerMetadata.clientName ? organizerMetadata.clientName : < Shimmer height={30} width={200} />}
                    </span>
                </div>

                <br /><br />

                <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page-message-container"}>
                    <span className={"organizer-welcome-page-message"}>
                        {organizerMetadata.senderName ? organizerMetadata.senderName : < Shimmer height={20} width={150} />} has
                        sent your {organizerMetadata.taxYear ? organizerMetadata.taxYear : < Shimmer height={20} width={150} />} Tax
                        Organizer to be Completed Electronically
                    </span>
                </div>

                <br />
                {this.props.preparerMessageStore.preparerMessage.message && this.getPreparerMessage()}
                <br />

                <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page-menu-container"}>

                    {this.SummaryPageSecondaryMessage()}

                    <div className={"organizer-welcome-page-menu-icon-container"}>
                        {
                            engagementLetterStatus != FormGroupStatus.NA &&
                            (
                                (
                                    engagementLetterStatus == FormGroupStatus.ESigned ||
                                    engagementLetterStatus == FormGroupStatus.ManuallySigned ||
                                    engagementLetterStatus == FormGroupStatus.Reviewed
                                )
                                ? this.getDownloadEnagementButton()
                                    : this.getSignEngegementButton()
                            )
                        }
                        { hasCustomQuestion && this.getCustomQuestionsButton() }
                        {
                            (
                                organizerStatus != FormGroupStatus.None &&
                                (
                                    organizerStatus == FormGroupStatus.Completed ||
                                    organizerStatus == FormGroupStatus.ManuallyCompleted
                                )
                            )
                            ? this.getOrganizerDownloadButton() : this.getOrganizerButton()
                        }

                        {
                            enabledSourceDocuments &&
                            (
                                isCompleted ?
                                    this.getCompletedUploadDocumentsButton() :
                                    this.getUploadDocumentsButton()
                            )
                        }
                    </div>

                    <br /><br /><br />
                    {this.getContinueButton()}
                </div>
            </div>
        </div>
        );
    }
}
