import { UploadStatus } from "../../../../components/Common/FileUpload/FileUpload";

export enum BatchUploadedStatus {
    Uploading = 1,
    Completed = 2
}

export interface UploadedFile {
    id: number;
    uploadedOn: Date;
    name: string;
    file: any;
    status: UploadStatus;
    uploadPercentage: number;
    documentGuid: string;
    taxYear: number;
    documentId:number
}

export interface ConvertedUploadedFileModel {
    isFileSizeLimitExceeded: boolean;
    isUnsupportedFileExtension: boolean;
    counterFileSizeLimitFailed: number;
    counterUnsupportedFileExtensionFailed: number;
    uploadedFiles: UploadedFile[]
}