import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SessionKey } from '../Common/Constants';
import { GetDataFromStorage, RemoveStorageData } from '../Helper/HelperFunction';
export enum ErrorStatus {
    None = 0,
    InvalidLink = 1,
    OrganizerDeleted = 2,
    ClosedByFirm = 3,
    OrganizerWithOldCustomQuestion = 4
}

export const ErrorPage: React.FC<RouteComponentProps<{ errorStatus: string }>> = ({ match: { params: { errorStatus } } }) => {

    React.useEffect(() => {
        var isPopUpOpen = GetDataFromStorage(SessionKey.RefreshPopUpOpen);
        if (isPopUpOpen !== "") {
            RemoveStorageData(SessionKey.RefreshPopUpOpen);
        }
    }, []);

    const getErrorMessage = (): string => {
        switch (errorStatus) {
            case ErrorStatus.ClosedByFirm.toString(): return "Your Organizer has been closed to prevent any further use. If you have any questions please contact your CPA.";
            case ErrorStatus.OrganizerDeleted.toString():
            case ErrorStatus.InvalidLink.toString():
            case ErrorStatus.None.toString():
            case ErrorStatus.OrganizerWithOldCustomQuestion.toString():
            default: return "This link is no longer valid. Please contact the company that prepared your Organizer.";
        }
    }

    return <div>
        <div className="invalid-container">

            <div className="invalid-header">

            </div>

            <div className="content-wrapper">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page-container">
                        <div className="message">
                            {getErrorMessage()}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>;
};
