import { injectable } from "inversify";
import jQuery from 'jquery';
import * as bootbox from 'bootbox';

const $ = jQuery;

export interface IDialogBox {
    refreshAlert(message: string, callback?: () => void): void;
    alert(message: string, callback?: () => void): void;
    alertHeader(title: string, message: string, callback?: () => void): void;
    confirm(title: string, message: string, cancel: string, confirm: string, callback?: (result: boolean) => void): void;
    confirmCustom(title: string, message: string, rightButtonText: string, leftButtonText: string, callback?: (result: boolean) => void): void;
    customDialog(title: string, message: string, rightButtonText: string, leftButtonText: string, rightCallback?: () => void, leftCallback?: () => void): void;
}

@injectable()
export class DialogBox implements IDialogBox {

    public refreshAlert(message: string, callback?: () => void): void {

        bootbox.alert({
            message: message,
            closeButton: false,
            buttons: {
                ok: {
                    label: "Refresh",
                    callback: () => {
                        if (callback) {
                            callback();
                        }
                    }
                }
            },
            callback: () => {
                if (callback) {
                    callback();
                }
            }
        });
        $('.bootbox.modal').css('z-index', '1110000');
    }

    public alert(message: string, callback?: () => void): void {

        bootbox.alert(message, function () {
            callback && callback();
        });
    }

    public alertHeader(title: string, message: string, callback?: () => void): void {
        bootbox.alert({
            message: message,
            title: '<i className="text-secondary"></i>' + title,
            callback: () => {
                if (callback) {
                    callback();
                }
            }
        });
    }

    public confirm(title: string, message: string, cancel: string, confirm: string, callback?: (result: boolean) => void): void {

        bootbox.confirm({
            title: '<i className="text-secondary"></i>' + title,
            message: message,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> ' + cancel,
                    className: 'btn-white btn-default btn-default-extended'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i>' + confirm,
                    className: 'btn-primary btn-primary-extended'
                }
            },
            callback: (result: boolean) => {
                if (callback) {
                    callback(result);
                }
            }
        });
    }


    public confirmCustom(title: string, message: string, rightButtonText: string, leftButtonText: string, callback?: (result: boolean) => void): void {

        bootbox.confirm({
            title: '<i className="text-secondary"></i>' + title,
            message: message,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> ' + leftButtonText,
                    className: 'btn-white btn-default btn-default-extended',
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i>' + rightButtonText,
                    className: 'btn-primary'
                }
            },
            callback: (result: boolean) => {
                if (callback) {
                    callback(result);
                }
            }
        });
    }

    public customDialog(title: string, message: string, rightButtonText: string, leftButtonText: string, rightCallback?: () => void, leftCallback?: () => void): void {

        bootbox.dialog({
            title: '<i className="text-secondary"></i>' + title,
            message: message,
            buttons: {
                right: {
                    label: '<i class="glyphicon glyphicon-remove"></i> ' + rightButtonText,
                    className: 'btn-primary btn-primary-extended',
                    callback: () => {
                        rightCallback && rightCallback();
                    }

                },
                left: {
                    label: '<i class="glyphicon glyphicon-ok"></i>' + leftButtonText,
                    className: 'btn-primary btn-primary-extended',
                    callback: () => {
                        leftCallback && leftCallback();
                    }
                }
            }
        });
    }
}