import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { ErrorMessages } from '../../components/Common/Constants';
import { CoverPageConstants } from '../../components/Common/Constants';
import { logger } from '../../routes';
import { initializeAxios } from '../../core/Services/dataAccess/DataService.Axios';
import { OtpMode } from '../../core/domain/viewModels/ICommon';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { IClientProcessViewModel, initialClientProcessModel } from '../../core/domain/viewModels/IClientProcessViewModel';
import { StatusCode } from 'src/core/domain/models/Organizer/Organizer';


const clientData = {
    Name: "",
    companyName: ""
}


export interface IClientCoverState {
    companyName: string,
    logoPath: string,
    taxYear: number | null,
    engagementType: EngagementTypes | null,
    clientName: string,
    contactAddress: any,
    mobileVerification: any,
    loaderState: boolean,
    mfaSettings: any

}


interface RequestSignProcessAction {
    type: actionTypes.SIGN_PROCESS_REQUEST;
}
interface RequestCurrentStepAction {
    type: actionTypes.SIGN_PROCESS_STEP;
    data: IClientProcessViewModel;
}


interface ResponseSignProcessAction {
    type: actionTypes.SIGN_PROCESS_RESPONSE;
    data: number;
}

interface FailureSignProcessAction {
    type: actionTypes.SIGN_PROCESS_FAILURE;
    data: number;
}


export interface SignProcessState {
    data: number;
    clientprocessmodel: IClientProcessViewModel;
}

export const initialSignProcessState: SignProcessState = {
    data: 0,
    clientprocessmodel: initialClientProcessModel
}

interface CoverState {
    type: actionTypes.RECEIVE_CLIENT_DETAILS,
    data: typeof clientData
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    ResponseSignProcessAction
    | RequestSignProcessAction
    | FailureSignProcessAction
    | RequestCurrentStepAction
    // | UpdateLastVisitedStep
    | CoverState


export enum EngagementTypes {
    None = 0,
    E1040 = 1,
    E1065 = 2,
    E1120 = 3,
    E1120S = 4,
    E1041 = 5,
    E1040NR = 6
}

export const initialCoverState: IClientCoverState = {
    companyName: "",
    logoPath: "",
    taxYear: null,
    engagementType: null,
    clientName: "",
    contactAddress: {},
    mobileVerification: null,
    loaderState: false,
    mfaSettings: null

}

interface AuthResponse<T> {
    data: T;
    isError: boolean;
    errorStatus: ErrorStatus;
}

export enum ErrorStatus {
    None = 0,
    InvalidLink = 1,
    OrganizerDeleted = 2,
    ClosedByFirm = 3,
    OrganizerWithOldCustomQuestion = 4
}

export const actionCreators = {

    getClientId: (
        clientId: string,
        navigate: (guid: string) => void): AppThunkAction<any> => (dispatch, getState) => {
            return initializeAxios().get<any>("api/Coverpage/getClientId/" + clientId)
                .then(function (response: AxiosResponse<string>) {
                    let result = response.data;
                    navigate(result);
                })
                .catch((error: any) => {
                    if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                        return;
                    }
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: CoverPageConstants.ErrorMessage.serverError,
                        statusType: StatusType.Error
                    })
                }
                );

        },

    getAuthenticationType: (
        clientId: string,
        navigate: (clientId: string, type: OtpMode) => void,
        errorCallback: (status: ErrorStatus) => void): AppThunkAction<any> => (dispatch, getState) => {
            return initializeAxios().get<OtpMode>("api/Coverpage/GetAuthenticationTypeAsync/" + clientId)
                .then(function (response: AxiosResponse<AuthResponse<OtpMode>>) {
                    let result = response.data;

                    if (result.isError) {
                        errorCallback && errorCallback(result.errorStatus);
                        return;
                    }

                    navigate(clientId, result.data);
                })
                .catch((error: any) => {
                    if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                        return;
                    }
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: CoverPageConstants.ErrorMessage.serverError,
                        statusType: StatusType.Error
                    })
                }
                );

        },

    requestHeaderInfo: (id: string, isPreview: boolean, callback?: (data: any) => void, errorCallback?: (status: ErrorStatus) => void): AppThunkAction<any> => (dispatch, getState) => {
        dispatch({ type: actionTypes.HEADERINFO_REQUEST, id: id });
        return initializeAxios().get<IHeaderInfoViewModel>(`/api/Coverpage/GetHeaderInfoAsync/${id}?isPreview=${isPreview}`)
            .then(function (response: AxiosResponse<AuthResponse<IHeaderInfoViewModel>>) {

                if (response.data.isError) {
                    errorCallback && errorCallback(response.data.errorStatus);
                    return;
                }

                dispatch({
                    type: actionTypes.HEADERINFO_RESPONSE, data: response.data.data
                });

                if (callback) {
                    callback(response.data.data)
                }

            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                errorCallback && errorCallback(ErrorStatus.InvalidLink);
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.HeaderInfoError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.HEADERINFO_FAILURE, id: id });
                logger.trackError(`requestHeaderInfo failed to with error ${error.message}`)
            });
    },

}

export const reducer: Reducer<typeof initialCoverState> = (state: any = initialCoverState, incomingAction: Action) => {
    const action = incomingAction as any;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.FETCH_CLIENT_DETAILS:
            return ({ ...currentState, loaderState: true });

        case actionTypes.RECEIVE_CLIENT_DETAILS:
            let data = action.data;
            let recievedClientDetails: IClientCoverState = initialCoverState;

            recievedClientDetails.companyName = data.companyName;
            recievedClientDetails.logoPath = data.logoPath;
            recievedClientDetails.taxYear = data.taxYear;
            recievedClientDetails.engagementType = data.engagementType;
            recievedClientDetails.clientName = data.clientName;
            recievedClientDetails.contactAddress = data.contactAddress;
            recievedClientDetails.loaderState = false;
            recievedClientDetails.mfaSettings = data.mfaSettings;
            recievedClientDetails.mobileVerification = data.mobileVerification;

            return { ...currentState, headerInfo: recievedClientDetails };
        default:
            return currentState || initialSignProcessState;
    }
};