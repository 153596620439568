import * as React from "react";
import {
	OrganizerForm,
	OrganizerFormData,
} from "../../../core/domain/models/Organizer/Organizer";
import { container } from "../../../startup/inversify.config";
import { TYPES } from "../../../startup/types";
import * as bootbox from "bootbox";
import { ILoader } from "../../../core/utilities/ui/Loader";
import SourceDcoumentIcon from "../../../assets/images/icon-sourceDocument.svg";
import TPNotesIcon from "../../../assets/images/icon-tpNotes.svg";
interface FormListProps {
	forms: OrganizerForm[];
	onClickForm(page: number, forceRefresh?: boolean, form?: any): void;
	onFilterUpdate(isPaginationdisable: boolean): void;
	sourceDocumentSettingEnabled: boolean;
}

interface FormListState {
	filter: BookMarkFilter;
}

export enum BookMarkFilter {
	SelectAll = 1,
	SourceDocuments = 2,
	Notes = 3,
}

const loader = container.get<ILoader>(TYPES.ILoader);

export class FormList extends React.Component<FormListProps, FormListState> {
	constructor(props: FormListProps, states: FormListState) {
		super(props, states);
		this.state = {
			filter: BookMarkFilter.SelectAll,
		};
	}

	private handleFormItemClick = (page: number, form: any) => {
		loader.show("", ".page");
		const { onClickForm } = this.props;
		onClickForm(page, undefined, form);
	};

	private FilterByValues = [
		{ label: "Show All", value: 1 },
		{ label: "Source Documents", value: 2 },
		{ label: "Notes", value: 3 },
	];

	private createFormList = () => {
		const forms: OrganizerForm[] =
			this.state.filter == BookMarkFilter.SelectAll
				? this.props.forms
				: this.state.filter == BookMarkFilter.SourceDocuments
				? this.props.forms.filter(
						(x: OrganizerForm) =>
							x.enabledSourceDocument == true &&
							this.props.sourceDocumentSettingEnabled == true
				  )
				: this.props.forms.filter((i) => i.message);
		const _self = this;

		let list: any[] = [];
		if (this.props.forms.length > 0 && forms.length == 0) {
			bootbox.alert({
				message: `No page with the selected filter '${
					this.FilterByValues[this.state.filter - 1].label
				}'`,
				className: "FilterMessage",
				size: "small",
				buttons: {
					ok: {
						label: '<i class="fas fa-check"></i> OK',
						className: "btn btn-primary",
					},
				},
			});

			this.props.onClickForm(1, undefined, forms[0]);
			this.setState({
				filter: BookMarkFilter.SelectAll,
			});
			this.props.onFilterUpdate(false);
		}
		forms.forEach(function (form: OrganizerForm) {
			const formData: OrganizerFormData = form.formData as OrganizerFormData;

			let pageNo = form.individualFilePageNo
				? form.individualFilePageNo
				: form.pageNo;

			if (form.bookmark) {
				list.push(
					<div
						key={form.formId}
						className={" row bookmark-list-item-conatiner"}
						title={form.bookmark}
						onClick={() => {
							_self.handleFormItemClick(pageNo, form);
						}}
					>
						<i
							className="fa fa-bookmark-o bookmark-list-item-icon"
							aria-hidden="true"
						></i>
						<label className="bookmark-list-item bookmark-list-item--checkbox">
							<span>{`${form.bookmark}`}</span>
						</label>
						{_self.props.sourceDocumentSettingEnabled &&
							form.enabledSourceDocument && (
								<img
									width="15px"
									height="15px"
									src={SourceDcoumentIcon}
									alt=""
									title="Require source document from client"
									className="svg-shadow"
								/>
							)}
						{form.message && (
							<img
								width="15px"
								height="15px"
								src={TPNotesIcon}
								alt=""
								title="Notes"
								className="svg-shadow"
							/>
						)}
					</div>
				);
			}
		});

		return list;
	};

	private changeFilterOptions = (event: any) => {
		const option = Number(event.target.value);
		const { onClickForm } = this.props;
		const forms: OrganizerForm[] =
			option == BookMarkFilter.SelectAll
				? this.props.forms
				: option == BookMarkFilter.SourceDocuments
				? this.props.forms.filter(
						(x: OrganizerForm) => x.enabledSourceDocument == true
				  )
				: this.props.forms.filter((i) => i.message);
		if (forms.length > 0) {
			let pageNo = forms[0].individualFilePageNo
				? forms[0].individualFilePageNo
				: forms[0].pageNo;
			onClickForm(pageNo, undefined, forms[0]);

			this.setState({
				filter: option,
			});
			this.props.onFilterUpdate(option != BookMarkFilter.SelectAll);
		} else {
			bootbox.alert({
				message: `No page with the selected filter '${
					this.FilterByValues[option - 1].label
				}'`,
				className: "FilterMessage",
				size: "small",
				buttons: {
					ok: {
						label: '<i class="fas fa-check"></i> OK',
						className: "btn btn-primary",
					},
				},
			});
		}
	};

	public render() {
		return (
			<div style={{ overflowY: "hidden", height: "100%" }}>
				<div className="row">
					<div className="BookMarkFilterLable"> Filter By :</div>

					<select
						name="Filter By"
						onChange={this.changeFilterOptions}
						className={
							"form-control toolbar-item-form-control toolbar-item-select BookMarkFilterSelect"
						}
						value={this.state.filter}
					>
						<option
							key={BookMarkFilter.SelectAll}
							title=""
							value={BookMarkFilter.SelectAll}
						>
							Show All
						</option>
						<option
							key={BookMarkFilter.SourceDocuments}
							title=""
							value={BookMarkFilter.SourceDocuments}
						>
							Source Documents
						</option>
						<option
							key={BookMarkFilter.Notes}
							title=""
							value={BookMarkFilter.Notes}
						>
							Notes
						</option>
					</select>
				</div>
				<br />
				<div
					className="bookmark-list-item-list-conatiner"
					style={{ overflowY: "scroll", height: "90%" }}
				>
					{this.createFormList()}
				</div>
			</div>
		);
	}
}
