import * as React from 'react';
import { ClientInfo } from '../../../../core/domain/models/Organizer/Organizer';
import { EngagementLetterConstants } from '../../../Common/Constants';
import { Shimmer } from '../../../Common/Shimmer/Shimmer';

export interface SpouseInfoProps {
    clientInfo: ClientInfo;
    onEmailChange(email: string): void;
    message: string;
}

export const SpouseInfo: React.FunctionComponent<SpouseInfoProps> = ({
    clientInfo, onEmailChange, message
}) => {

    let handleEmailChange = (event: any) => {
        let emailAddress = event.target.value.trim();
        onEmailChange(emailAddress);
    }

    return (<div>
        <div className={"col-lg-12 row"}>
            {message}
        </div>
        <br />
        <br />

        {clientInfo.id > 0 ? <div>
            <div className={"col-lg-12 row"}>
                <p className={"paragraph-spouse-emailaddress"}>
                    <strong>{clientInfo.name + "'s "}</strong>
                    {EngagementLetterConstants.SpouseEmailTitle}
                    <label className={"label-spouse-emailaddress-required-indictor"}>*</label>
                </p>
            </div>
            <div className={"col-lg-12 row"}>
                <input type="text" className={"textbox-spouse-emailaddress"} value={clientInfo.email} onChange={handleEmailChange} />
            </div>
        </div> : <Shimmer height={30} />}

        <br />
    </div>);
};