export const SignalRConstant = {
    headers: {
        userId: 'x-ms-signalr-userid',
        clientType: 'ClientType'
    },
    TP: 'TP'
};

export const HeaderConstant = {
    ContactInformation: "Contact Information",
    Authorization: "Authorization",
    Bearer: "Bearer"
};