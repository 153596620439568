import { IBrandingSettings, initialBrandingSettings } from "../models/IBrandingSettings";
import { ITaxDocumentModel, initialTaxDocumentModel } from "../models/ITaxDocumentModel";
import { IUserModel, initialUserModel } from "../models/IUserModel";
import { ILoggedInUserInfo, initialLoggedInUserInfo } from './ILoggedInUserInfo';

export interface IHeaderInfoViewModel {
	clientName: string,
	taxYear: number;
	companyName: string,
	companyLogoPath: string,
	companyWhiteLogoPath: string,
	contactPerson: IUserModel,
	brandingSettings: IBrandingSettings,
	data: ITaxDocumentModel,
	loggedInUserInfo: ILoggedInUserInfo,
	isPreview: boolean;
	isLoading: boolean;
}

export const initialHeaderInfoViewModel: IHeaderInfoViewModel = {
	clientName: "",
	taxYear: 0,
	companyName: "",
	companyLogoPath: "",
	companyWhiteLogoPath: "",
	contactPerson: initialUserModel,
	brandingSettings: initialBrandingSettings,
	data: initialTaxDocumentModel,
	loggedInUserInfo: initialLoggedInUserInfo,
	isPreview: false,
	isLoading: false
}