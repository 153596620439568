import * as React from 'react';
import { Button } from 'react-bootstrap';
import { SignerDocumentModel, UploadedDocument } from '../../../core/domain/models/Organizer/Organizer';
import { ConflictErrorMessage } from '../../../store/UploadedDocument/UploadedDocumentStoreModels';
import { MarsNotifier } from '../../Common/Notification/MarsNotifier';
import { CustomEventLog, PreviewConstants, TaxPayerPage } from '../../Common/Constants';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { logger } from '../../../routes';


interface SourceDocumentProps {
    clientId:string;
    openSourceDocumentPopup(): void;
    currentFormId: number;
    organizerDocument: SignerDocumentModel
    currentFormName: string;
    isSourceDocumentUploadCompleted: boolean;
    deleteUploadedDocument(clientId: string, id: number, successCallback?: () => void): void;
    getSourceDocumentMetadataAsync?(clientId: string, uploadedDocumentId: number, successCallback?: () => void): void;
    requestUploadedDocuments(clientId: string, forceRefresh:boolean) : void;
    disabled: boolean;
}

export const SourceDocumentBox: React.FunctionComponent<SourceDocumentProps> = ({
    clientId,
    openSourceDocumentPopup,
    currentFormId,
    organizerDocument,
    currentFormName,
    isSourceDocumentUploadCompleted,
    deleteUploadedDocument,
    getSourceDocumentMetadataAsync,
    requestUploadedDocuments,
    disabled
}) => {

    let uploadedDocuments: UploadedDocument[] = organizerDocument?.uploadedDocuments.length > 0 ? organizerDocument.uploadedDocuments : [];
    let numberOfSourceDocumentsUploaded = 0;

    if (uploadedDocuments.length > 0) {
        uploadedDocuments.forEach((uploadedDocument) => {
            if (uploadedDocument.formId == currentFormId) {
                numberOfSourceDocumentsUploaded++;
            }
        })
    }
    const handlePreviewDocument = (uploadedDocumentId: number, uploadedDocumentName:string) => {
    if (getSourceDocumentMetadataAsync) {
        getSourceDocumentMetadataAsync(clientId, uploadedDocumentId, () => {
            const traceEvent: IEventTelemetry = {
                name: `${CustomEventLog.PreviewSourceDocument}`,
                properties: { 
                    Page: TaxPayerPage.CompleteOrganizerPage,
                    SourceDocumentId: uploadedDocumentId,
                    SourceDocumentName: uploadedDocumentName
                }
            };
            logger.trackEvent(traceEvent);
        });
    }
}
    const deleteUploadedSourceDocument = (id: number, name:string) => {
         if (disabled) {
            MarsNotifier.Warning(PreviewConstants.DeleteDocumentMessage, null);
            return;
        }
        deleteUploadedDocument(clientId, id, () => {
            requestUploadedDocuments(clientId, true);
            const traceEvent: IEventTelemetry = {
                name:`${CustomEventLog.DeleteSourceDocument}` ,
                properties: { 
                    Page: TaxPayerPage.CompleteOrganizerPage,
                    SourceDocumentId: id,
                    SourceDocumentName: name
                }
            };
            logger.trackEvent(traceEvent);
        });
    }

    uploadedDocuments = uploadedDocuments.filter(c => c.formId && currentFormId === c.formId)?.sort((prev, cur) => new Date(cur.uploadedOn).valueOf() - new Date(prev.uploadedOn).valueOf());
    const uploadedSourceDocumentsList = uploadedDocuments.map((uploadedDocument) => (
        <li key={uploadedDocument.id}>
            <span className='uploaded-src-file-name' title={uploadedDocument.fileName}>{uploadedDocument.fileName}</span>
            <span className="uploaded-src-preview-icon-container" title="Preview Document"
                onClick={() => handlePreviewDocument(uploadedDocument.id, uploadedDocument.fileName)}>
                <i className="fas fa-eye uploaded-src-preview-icon" ></i>
            </span>
            <span className= {'uploaded-src-delete-icon-container' + (isSourceDocumentUploadCompleted ? ' disable-src-document-delete' : '')} 
                title={!isSourceDocumentUploadCompleted ? "Delete source document" : 'Document upload completed'}
                onClick={() => !isSourceDocumentUploadCompleted ? deleteUploadedSourceDocument(uploadedDocument.id, uploadedDocument.fileName) : false}>
                <i className='fas fa-trash-alt fa-lg uploaded-src-delete-icon'></i>
            </span>
        </li>
    ));

    return (<div className={"source-document-upload-box"} title={isSourceDocumentUploadCompleted ? ConflictErrorMessage : ''}>
        <div className={"source-document-header"}>
            Provide Source Documents
        </div>
        <div className={"source-document-section"}>
            {currentFormName || currentFormName !== "" ? `Please upload all ${currentFormName} forms` : "Please upload all applicable forms"}
        </div>
        <Button className={"source-document-upload-button"} data-test-auto="007436B7-4379-4AEC-A3BF-FBC405D4C19B"
            onClick={openSourceDocumentPopup} disabled={isSourceDocumentUploadCompleted}>
            Click Here To Upload
        </Button>
        <div>
            <strong style={{ color: '#007ee6' }}>{numberOfSourceDocumentsUploaded} Document(s) uploaded</strong>
        </div>
        <div>
            {uploadedDocuments && uploadedDocuments.length > 0 && 
            <ul className='uploaded-src-documents-list'>
                {uploadedSourceDocumentsList}
            </ul>
            }
        </div>
    </div>

    );
}