import * as React from 'react';
import { OrganizerConstants } from '../../Common/Constants';

export class SigningComplete extends React.Component<any, {}> {

    public render() {

        const params = this.props.match.params;

        return <div id="content-wrapper" className="col-xs-12">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-page" style={{ textAlign: "center" }}>

                <div className="greeting text-center" style={{ width: "100%" }}>
                    <strong className="text-center">Organizer Completed!</strong><br />
                        You have indicated your tax organizer has been completed. You will receive an email with an option to download your completed organizer shortly.
                    </div>

                <a href={OrganizerConstants.OrganizerSummaryPageURL + params.clientId}> Go to Summary Page </a>

            </div>
        </div>
    }
}
