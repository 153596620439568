import * as React from 'react';
export interface SvgImageProps {
    height: number;
    width: number;
    style?: React.CSSProperties;
    className?: string;
};

export const LoginSession: React.SFC<SvgImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} style={props.style} className={props.className} viewBox="0 0 300 300">
        <path fill="#FFFFFF" d="M132.5,160c44.112,0,80-35.888,80-80s-35.888-80-80-80s-80,35.888-80,80S88.388,160,132.5,160z" />
        <path fill="#FFFFFF" d="M297.199,221.809l-54.191-93.964c-3.766-6.523-10.515-10.418-18.055-10.418 c-7.538,0-14.286,3.895-18.051,10.421l-17.276,29.955l-4.402-0.207c-1.358-0.063-2.577-0.095-3.724-0.095h-11.321l-1.987,0.913 c-11.318,5.2-23.326,7.837-35.691,7.837c-12.315,0-24.329-2.639-35.708-7.844l-1.981-0.906H83.5C37.458,157.5,0,194.958,0,241 v22.749C0,283.738,16.262,300,36.25,300h192.499C248.738,300,265,283.738,265,263.749v-10.687h14.146 c7.529,0,14.274-3.896,18.044-10.423C300.957,236.116,300.96,228.328,297.199,221.809z" />
        <path fill="#4D4D4D" d="M132.5,150c38.664,0,70-31.336,70-70s-31.336-70-70-70s-70,31.336-70,70S93.836,150,132.5,150z" />
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="224.5654" y1="217.7676" x2="225.3447" y2="217.7676">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M224.955,217.378c-0.215,0-0.39,0.175-0.39,0.39c0,0.214,0.175,0.389,0.39,0.389 s0.39-0.175,0.39-0.389C225.345,217.553,225.17,217.378,224.955,217.378L224.955,217.378z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="159.9102" y1="185.2441" x2="290" y2="185.2441">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_2_)" stroke="#FBB03B" stroke-width="3" d="M288.536,226.805c4.169,7.226-1.063,16.258-9.391,16.258H170.763 c-8.342,0-13.551-9.046-9.39-16.258l54.191-93.961c4.171-7.229,14.617-7.216,18.782,0L288.536,226.805L288.536,226.805z M224.955,207.378c-5.737,0-10.39,4.651-10.39,10.39c0,5.737,4.652,10.389,10.39,10.389s10.39-4.651,10.39-10.389 C235.345,212.029,230.692,207.378,224.955,207.378z M215.092,170.034l1.675,30.716c0.079,1.438,1.268,2.563,2.706,2.563h10.964 c1.44,0,2.628-1.125,2.707-2.563l1.675-30.716c0.085-1.553-1.151-2.857-2.706-2.857h-14.314 C216.243,167.177,215.007,168.481,215.092,170.034L215.092,170.034z" />
        <path fill="#808080" d="M170.763,252.396c-7.288,0-13.816-3.771-17.464-10.086c-3.648-6.318-3.652-13.857-0.01-20.17l31.465-54.556 c-1.079-0.051-2.162-0.084-3.254-0.084h-9.134c-12.141,5.578-25.648,8.75-39.866,8.75c-14.219,0-27.672-3.172-39.867-8.75H83.5 C42.922,167.5,10,200.422,10,241v22.749C10,278.242,21.758,290,36.25,290h192.499C243.242,290,255,278.242,255,263.749v-11.354 H170.763z" />
    </svg>;
}

export const LoginHistory: React.SFC<SvgImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} style={props.style} className={props.className} viewBox="0 0 300 300">
        <path fill="#FFFFFF" d="M132.5,160c44.111,0,80-35.888,80-80s-35.889-80-80-80c-44.112,0-80,35.888-80,80S88.388,160,132.5,160z" />
        <path fill="#4D4D4D" d="M132.5,150c38.664,0,70-31.336,70-70s-31.336-70-70-70s-70,31.336-70,70S93.836,150,132.5,150z" />
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="480.5654" y1="-263.7676" x2="481.3447" y2="-263.7676" gradientTransform="matrix(1 0 0 -1 -256 -46)">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M224.955,217.378c-0.215,0-0.391,0.175-0.391,0.39s0.176,0.39,0.391,0.39s0.391-0.175,0.391-0.39 S225.17,217.378,224.955,217.378L224.955,217.378z" />
        <path fill="#FFFFFF" d="M36.251,300c-14.598,0-27.707-8.689-33.397-22.139C0.96,273.385,0,268.637,0,263.749V241 c0-46.042,37.458-83.5,83.5-83.5h11.271l2.247,1.006c11.319,5.139,23.256,7.744,35.482,7.744c12.226,0,24.163-2.605,35.479-7.743 l2.207-0.999l2.18-0.008h9.134c46.042,0,83.5,37.458,83.5,83.5v22.749c0,4.89-0.96,9.638-2.854,14.113 C256.456,291.311,243.348,300,228.75,300H36.251z" />
        <path fill="#808080" d="M181.5,167.5h-9.133h-0.001c-0.084,0.038-0.169,0.074-0.253,0.112c-12.129,5.507-25.493,8.638-39.613,8.638 s-27.485-3.131-39.613-8.638c-0.084-0.038-0.169-0.074-0.253-0.112h-0.001H83.5C42.922,167.5,10,200.422,10,241v22.749 c0,3.623,0.735,7.075,2.063,10.216C16.05,283.387,25.381,290,36.25,290h0.001h192.498h0.001c10.869,0,20.2-6.613,24.187-16.035 c1.329-3.141,2.063-6.593,2.063-10.216V241C255,200.422,222.078,167.5,181.5,167.5z" />
        <path fill="#FFFFFF" d="M232.183,284.391c-15.447,0-30.563-5.334-42.565-15.019c-3.432-2.771-5.534-6.886-5.769-11.292 c-0.234-4.414,1.424-8.738,4.549-11.864l2.626-2.626c2.96-2.958,6.891-4.586,11.069-4.586c3.494,0,6.923,1.193,9.653,3.361 c5.858,4.648,12.925,7.105,20.437,7.105c4.496,0,8.785-0.907,12.692-2.547c-0.485,0.045-0.977,0.067-1.472,0.067l0,0 c-3.448,0-6.847-1.167-9.571-3.285l-19.109-14.863v-15.598c-0.889,0.18-1.804,0.272-2.736,0.272h-33.891 c-7.571,0-13.73-6.159-13.73-13.73v-33.891c0-7.575,6.172-13.738,13.757-13.738c3.645,0,7.083,1.43,9.681,4.027l5.096,5.096 c11.41-8.131,25.052-12.525,39.284-12.525c37.326,0,67.749,30.365,67.817,67.689c0.033,18.081-7.013,35.121-19.841,47.982 c-12.827,12.859-29.848,19.949-47.927,19.963H232.183z M255.271,216.142c3.287,2.556,5.384,6.241,5.9,10.375 c0.285,2.285,0.07,4.555-0.611,6.684c2.871-4.882,4.519-10.565,4.519-16.627c0-11.94-6.395-22.416-15.939-28.182 c0.328,1.258,0.503,2.577,0.503,3.936v19.437L255.271,216.142z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="174.3652" y1="216.5732" x2="290" y2="216.5732">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_2_)" stroke="#FBB03B" stroke-width="3" d="M290,216.464c0.059,31.855-25.92,57.903-57.775,57.927 c-13.759,0.01-26.396-4.786-36.327-12.801c-2.583-2.085-2.776-5.955-0.429-8.303l2.626-2.626c2.008-2.007,5.212-2.228,7.436-0.463 c7.316,5.806,16.576,9.272,26.652,9.272c23.711,0,42.896-19.189,42.896-42.897c0-23.711-19.189-42.897-42.896-42.897 c-11.381,0-21.717,4.424-29.392,11.641l11.833,11.833c2.35,2.351,0.687,6.368-2.638,6.368h-33.891c-2.062,0-3.73-1.669-3.73-3.73 v-33.891c0-3.324,4.018-4.988,6.368-2.639l11.51,11.511c10.38-9.92,24.447-16.013,39.939-16.013 C264.078,158.756,289.941,184.582,290,216.464z M247.823,234.831l2.29-2.944c1.897-2.439,1.458-5.955-0.981-7.852l-9.489-7.381 v-24.327c0-3.091-2.505-5.595-5.595-5.595h-3.73c-3.09,0-5.595,2.504-5.595,5.595v31.625l15.249,11.86 C242.41,237.709,245.926,237.271,247.823,234.831z" />
    </svg>;
}

export const SessionTimeout: React.SFC<SvgImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} style={props.style} className={props.className} viewBox="0 19 192 192">
        <path fill="#F7C001" d="M-323-93" />
        <path fill="#F8F8F8" d="M96,207c-50.729,0-92.001-41.271-92.001-92c0-50.729,41.272-92,92.001-92s92.001,41.271,92.001,92 C188.001,165.729,146.729,207,96,207L96,207z M75.27,66.749C56.591,74.805,43.484,93.4,43.484,115 c0,28.957,23.558,52.516,52.516,52.516c28.959,0,52.517-23.559,52.517-52.516c0-21.6-13.107-40.195-31.786-48.251 c0.198,1,0.302,2.033,0.302,3.09v39.601l16.607,12.083c3.438,2.483,5.704,6.193,6.349,10.423c0.633,4.152-0.378,8.317-2.844,11.729 l-6.078,8.374c-3.028,4.113-7.813,6.53-12.846,6.53c-3.364,0-6.586-1.042-9.314-3.016l-27.51-19.993 c-4.025-2.986-6.43-7.758-6.43-12.764V69.839C74.967,68.782,75.071,67.749,75.27,66.749L75.27,66.749z" />
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="16" y1="115" x2="176" y2="115">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M96,35c-44.193,0-80,35.807-80,80s35.807,80,80,80s80-35.807,80-80S140.193,35,96,35z M96,179.516 c-35.646,0-64.516-28.87-64.516-64.516c0-35.645,28.87-64.516,64.516-64.516S160.517,79.355,160.517,115 C160.517,150.646,131.646,179.516,96,179.516z M115.936,145.839l-27.388-19.902c-1.001-0.744-1.58-1.904-1.58-3.13V69.839 c0-2.13,1.741-3.871,3.871-3.871h10.322c2.13,0,3.871,1.741,3.871,3.871v45.71l21.548,15.677c1.742,1.259,2.098,3.678,0.84,5.42 L121.355,145C120.096,146.71,117.677,147.097,115.936,145.839L115.936,145.839z" />
    </svg>;
}

export const AccessDenied: React.SFC<SvgImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 192} height={props.height || 230} style={props.style} className={props.className} viewBox="0 0 192 230">
        <path fill="#F8F8F8" d="M58.281,207c-19.497,0-33.114-14.358-33.114-34.917v-41.5c0-12.87,5.987-23.82,15.828-29.916 c0.018-5.999-0.014-14.663-0.161-27.024c-0.168-14.121,4.603-26.818,13.432-35.753C64.028,28.01,78.07,23,96,23	c24.594,0,38.162,12.609,45.215,23.187c7.858,11.787,9.62,23.979,9.618,27.321c-0.005,6.934-0.004,18.167-0.002,26.797 c10.171,5.809,16.002,16.569,16.002,30.279v41.5c0,24.12-17.134,34.917-34.114,34.917H58.281z M106.833,95.667V77.063 c-0.592-3.703-2.778-9.313-10.588-9.313c-7.735,0-10.164,6.225-10.912,9.626v18.291H106.833z" />
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="96.0864" y1="38.8223" x2="95.9784" y2="179.2495">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M138.832,108.382c-0.002-7.937-0.005-25.415,0.001-34.882c0.003-3.75-6-38.5-42.833-38.5 c-35.667,0-43.354,22.75-43.167,38.5c0.233,19.563,0.175,29.916,0.098,34.923c-7.854,2.193-15.764,9.237-15.764,22.16v41.5 C37.167,184,43.833,195,58.281,195h74.438c11.114,0,22.114-6.333,22.114-22.917v-41.5 C154.833,116.745,147.109,110.365,138.832,108.382z M104.833,171.667h-17l3.855-23.554c0,0-4.758-2.903-4.758-7.903	c0-4.597,3.951-8.871,9.069-8.871c5.366,0,9.553,3.285,9.56,8.629c0.007,5.323-5.282,8.268-5.282,8.268L104.833,171.667z M118.833,107.667h-45.5V76.25c0,0,3-20.5,22.912-20.5c20.588,0,22.588,20.5,22.588,20.5V107.667z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="54.25" y1="124.7505" x2="54.25" y2="124.7505">
            <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
            <stop offset="1" style={{ stopColor: "#525252" }} />
        </linearGradient>
        <path fill="url(#SVGID_2_)" d="M54.25,124.75" />
        <path fill="#F7C001" d="M96,37" />
    </svg>;
}