import { ControlBaseProps, ControlData } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { PageProperties } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import * as Enumerable from 'linq';
import * as React from 'react';
import * as bootbox from 'bootbox';
import { RouteComponentProps } from 'react-router';
import { ClientType } from '../../core/common/Enums';
import { ELDocType, ElDocument, IElDocument, IElForm, ELDocControlState } from '../../core/domain/models/esign/Document';
import { IControl } from '../../core/domain/models/esign/IControls';
import { Control, ControlType } from '../../core/domain/models/Organizer/Controls';
import { ClientInfo, FormGroupStatus, GroupType, OrganizerMetadata, SignerDocumentModel } from '../../core/domain/models/Organizer/Organizer';
import { SignProcessInfo, SignProcessSteps } from '../../core/domain/models/signingprocess/SignProcess';

import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import * as EngagementDocumentStore from '../../store/Organizer/EngagementDocumentStore';
import * as OrganizerClientStore from '../../store/Organizer/OrganizerClientStore';
import * as OrganizerMetadataStore from '../../store/Organizer/OrganizerMetadataStore';
import { EngagementLetterConstants, OrganizerConstants, OrganizerStatusChangedWarning, SignProcessInfoConstants } from '../Common/Constants';
import { IsPreviewModeFromCache, SetDataToStorage, IsClientViewFromCache, RemoveEventListeners } from '../Helper/HelperFunction';
import { DeclineModal } from './Decline/DeclineModal';
import { EngagementLetterSignHelper } from './EngagementLetterSignHelper';
import { Footer } from './Footer/Footer';
import { SpouseInfoModal } from './SpouseInfo/SpouseInfoModal';
import { FileList } from './Viewer/FileList';
import { Viewer } from './Viewer/Viewer';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { IOrganizerService } from '../../core/Services/OrganizerService/OrganizerService';
import { IDocumentType } from '../../core/domain/viewModels/IDocumentSignatureDataViewModel';

export type EngagementLetterSignProps =
    {
        engagementDocument: SignerDocumentModel,
        clientInfo: ClientInfo,
        organizerMetadata: OrganizerMetadata,
        addtionalEfileDocument: SignerDocumentModel[],
    }
    & typeof EngagementDocumentStore.actionCreators
    & typeof OrganizerClientStore.actionCreators
    & typeof OrganizerMetadataStore.actionCreators
    & RouteComponentProps<{}>;

interface EngagementLetterSignState {
    finishEnabled: boolean;
    showSpouseInfo: boolean;
    showDeclineModal: boolean;
    isSkipped: boolean;
    engagementLetterStatus: FormGroupStatus,
    organizerStatus: FormGroupStatus,
    selectedPageNo: number,
    selectedEngagementDocument: SignerDocumentModel,
    isAdditionalDocument: boolean;
    controlList: any[];
    documents: IElDocument[];
    document: IElDocument;
    startNavigationOnDocumentLoad: boolean;
    clientType: ClientType;
    hideNavigationControl: boolean;
    signingCompleted: boolean;
    controlStatus: ELDocControlState;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const organizerService = container.get<IOrganizerService>(TYPES.IOrganizerService);

export class EngagementLetterSign extends React.Component<EngagementLetterSignProps, EngagementLetterSignState> {

    private _param: any;
    private _viewerRef: any = null;
    constructor(props: EngagementLetterSignProps) {
        super(props);

        this.state = {
            finishEnabled: false,
            showSpouseInfo: false,
            showDeclineModal: false,
            isSkipped: false,
            engagementLetterStatus: FormGroupStatus.None,
            organizerStatus: FormGroupStatus.None,
            selectedPageNo: 1,
            selectedEngagementDocument: SignerDocumentModel.createNullObject(),
            isAdditionalDocument: false,
            controlList: [],
            documents: [],
            document: ElDocument.createNullObject(),
            startNavigationOnDocumentLoad: false,
            clientType: ClientType.Taxpayer,
            hideNavigationControl: false,
            signingCompleted: false,
            controlStatus: ELDocControlState.None
        }

    }

    componentDidMount() {
        this._param = this.props.match.params;
        this.getDocumentStatus();
        this.props.requestOrganizerMetadata(this._param.clientId);
        this.UpdateSignProcessInfo();


        this.setState({
            startNavigationOnDocumentLoad: true
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps: EngagementLetterSignProps, nextState: EngagementLetterSignState) {
        if ((nextProps.engagementDocument).data.id > 0) {
            this.setDocumentControls(nextProps.engagementDocument);
        }
    }

    setDocumentControls = (document: SignerDocumentModel) => {
        let documents: IElDocument[] = [];
        const engagementDocument = document.data;
        const client = engagementDocument.organizerClients &&
            engagementDocument.organizerClients.find((x: any) => x.clientGuid == this._param.clientId);
        if (engagementDocument.formGroup != undefined) {
            const engagementFormGroup = engagementDocument.formGroup.find((x: any) => x.type == GroupType.Engagement);
            if (engagementFormGroup != undefined) {
                const controlsFound = engagementFormGroup.forms.length <= 0 ?
                    true : engagementFormGroup.forms.some((x: any) => x.formData
                        && x.formData.controlList.some((x: any) => x.controlRole == client.clientType && x.controlType != ControlType.Date) == true && x.formData.controlList.length > 0);
                let elForms: IElForm[] = [];
                if (controlsFound) {
                    const formWithControls = engagementFormGroup.forms.filter((x: any) => x.formData
                        && x.formData.controlList.filter((x: any) => x.controlRole == client.clientType) && x.formData.controlList.length > 0);

                    formWithControls.forEach((form: any) => {
                        elForms.push({
                            controls: form.formData.controlList.filter((x: any) => x.controlRole == client.clientType),
                            pageNo: form.individualFilePageNo
                        })
                    });
                    //elForms.forEach((form: IElForm) => {
                    //    form.controls.forEach((control: IControl) => {
                    //        control.required = true
                    //    });
                    //})
                }
                documents.push({
                    id: engagementDocument.id,
                    url: engagementDocument.documentUrl,
                    forms: elForms,
                    type: ELDocType.EL,
                    documentType: IDocumentType.None,
                    fileGuid: engagementDocument.fileGuid,
                });
            }
        }

        if (engagementDocument.additionalEsign.length > 0) {
            const additionalDocs = engagementDocument.additionalEsign;
            additionalDocs.forEach((doc: any) => {
                let elForms: IElForm[] = [];
                const sortFormData = doc.documentControls?.formData?.sort((a: any, b: any) => a.pageNo - b.pageNo);
                sortFormData?.forEach((form: any) => {
                    elForms.push({
                        controls: form.controlList.filter((x: any) => x.controlRole == client.clientType),
                        pageNo: form.pageNo
                    })
                    //This condition is for retaining the old controls required and not adding default required on new added controls
                    //elForms.forEach((form: IElForm) => {
                    //    form.controls.forEach((control: any) => {
                    //        if (control.controlType != ControlType.SignatureRadioButtonControl
                    //            && control.controlType != ControlType.SignatureCheckBoxControl
                    //            && (control.dataType != 10))
                    //        {
                    //            control.required = true
                    //        }

                    //    });
                    //})
                });
                documents.push({
                    id: doc.id,
                    url: doc.sasURL,
                    forms: elForms,
                    type: ELDocType.Additional,
                    fileName: doc.fileName,
                    documentType: doc.documentType,
                    fileGuid: doc.fileGuid
                });
            })
        }

        const organizerFormGroup = engagementDocument.formGroup.find((x: any) => x.type == GroupType.OrganizerWithSign);
        if (organizerFormGroup != undefined) {
            const controlsFound = organizerFormGroup.forms.length <= 0 ?
                true : organizerFormGroup.forms.some((x: any) => x.formData
                    && x.formData.controlList.some((x: any) =>
                        x.controlRole == client.clientType &&
                        x.controlType != ControlType.Date) == true &&
                    x.formData.controlList.length > 0);
            const organizerSignForms: IElForm[] = [];
            if (controlsFound) {
                const formWithControls = organizerFormGroup.forms.filter((x: any) => x.formData
                    && x.formData.controlList.filter((x: any) => x.controlRole == client.clientType) && x.formData.controlList.length > 0);

                formWithControls.forEach((form: any) => {
                    organizerSignForms.push({
                        controls: form.formData.controlList.filter((x: any) => x.controlRole == client.clientType),
                        pageNo: form.individualFilePageNo
                    })
                });

                //organizerSignForms.forEach((form: IElForm) => {
                //    form.controls.forEach((control: any) => {
                //        if (control.controlType != ControlType.SignatureRadioButtonControl
                //            && control.controlType != ControlType.SignatureCheckBoxControl
                //            && (control.dataType != 10)) {
                //            control.required = true
                //        }

                //    });
                //});
            }

            documents.push({
                id: engagementDocument.id + 1,
                url: engagementDocument.organizerFormGroupUrl,
                forms: organizerSignForms,
                type: ELDocType.OrganizerSignDocument,
                documentType: IDocumentType.organizerWithSignatureDocument,
                fileGuid: engagementDocument.fileGuid,
            });
        }
        let controlStatus: ELDocControlState = this.getControlStatus(documents)

        this.setState({
            documents: documents,
            document: documents[0],
            clientType: client.clientType,
            controlStatus: controlStatus,
        });
    }

    private getControlStatus = (documents: IElDocument[]) => {
        let allControls: IControl[] = [];

        documents.forEach(({ forms }) => {
            forms.forEach(({ controls }) => {
                allControls = [...allControls, ...controls];
            })
        })

        if (allControls.length < 1) {
            return ELDocControlState.NoControles;
        }
        if (allControls.filter(({ required }) => required === true).length > 0) {
            return ELDocControlState.RequiredControlExists;
        }
        else {
            return ELDocControlState.RequiredControlNotExists;
        }
        return ELDocControlState.None;
    }

    private getDocumentStatus = () => {

        const _self = this;

        organizerService.getDocumentStatusAndSourceFileSettings(this._param.clientId).then(function (response: any) {

            _self.validateEngagementStatus(response.data.engagementLetterStatus, response.data.organizerStatus);

        });
    }

    private validateEngagementStatus = (engagementLetterStatus: FormGroupStatus, organizerStatus: FormGroupStatus) => {

        if (engagementLetterStatus === FormGroupStatus.ESigned || engagementLetterStatus === FormGroupStatus.ManuallySigned || engagementLetterStatus === FormGroupStatus.Reviewed) {
            dialogBox.refreshAlert(OrganizerStatusChangedWarning, () => {
                RemoveEventListeners();
                this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);
            });
        } else {
            const { requestEngagementDocuments } = this.props;
            requestEngagementDocuments(this._param.clientId);

            this.setState({
                engagementLetterStatus: engagementLetterStatus,
                organizerStatus: organizerStatus
            });
        }
    }

    private handleFinish = () => {
        if (!this.handleSkipAndFinishForPreview()) {
            this.setState({ isSkipped: false });

            if (this.showSpouse()) { // spouse found with signature controls
                this.setState({ showSpouseInfo: true });
            } else {
                this.setControlValues(() => { this.initializeSignDocument() });
            }
        }
    }

    private handleSkip = () => {
        if (!this.handleSkipAndFinishForPreview()) {
            if (this.state.controlStatus === ELDocControlState.RequiredControlNotExists) {
                bootbox.confirm({
                    title: 'Reviewed Document Confirmation',
                    message: "By making this document as <b>reviewed,</b> you are agreeing that you have read through the document(s) and understand its contents.</br></br> Please confirm you understand the contents and you are declining to sign or complete signing the document.",
                    className: "",
                    buttons: {
                        cancel: {
                            label: 'Cancel',
                            className: 'btn btn-white btn-default btn-default-extended bootbox-cancel'
                        },
                        confirm: {
                            label: 'Confirm',
                            className: 'btn-primary-extended confirm-btn',
                        }
                    },
                    callback: (result: boolean) => {
                        if (result) {
                            this.setState({ isSkipped: true });
                            if (this.showSpouse()) { // spouse found with signature controls
                                this.setState({ showSpouseInfo: true });
                            } else {
                                this.skipEngagementSignature();
                            }
                        }
                    }
                });
            } else {
                this.setState({ isSkipped: true });

                if (this.showSpouse()) { // spouse found with signature controls
                    this.setState({ showSpouseInfo: true });
                } else {
                    this.skipEngagementSignature();
                }
            }
        }
    }

    private showSpouse = () => {
        const { clientInfo } = this.props;
        if (clientInfo && clientInfo.id > 0 && clientInfo.isDeceased == false
            && this.state.engagementLetterStatus != FormGroupStatus.PartiallyReviewed
            && this.state.engagementLetterStatus != FormGroupStatus.PartiallySigned
            && clientInfo.documentSigner) {
            return true;
        } else {
            return false;
        }
    }


    private handleUpdateSpouseEmail = (clientInfo: ClientInfo) => {

        const { updateSpouseEmail } = this.props;

        updateSpouseEmail(this._param.clientId, clientInfo, () => {
            this.toggleSpouseInfoModal();

            if (this.state.isSkipped) {
                this.skipEngagementSignature();
            }
            else {
                this.setControlValues(() => { this.initializeSignDocument() });
            }
        });
    }

    private handleDecline = (remarks: string) => {

        const { decline } = this.props;

        decline(this._param.clientId, remarks, () => {
            this.toggleDeclineModal();
            this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);
        }, this.saveError);
    }

    private initializeSignDocument = () => {
        const { engagementDocument } = this.props;
        let tmpEngagementDocumentModel = EngagementLetterSignHelper.getServerModel(this.state.controlList, engagementDocument);
        tmpEngagementDocumentModel = EngagementLetterSignHelper.getAdditionalEsignServerModel(this.state.controlList, engagementDocument);
        this.props.submitSignedDocument(this._param.clientId, (tmpEngagementDocumentModel as any).data, () => {

            this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);

        }, this.saveError);
    }

    private skipEngagementSignature = () => {
        const { engagementDocument } = this.props;
        this.props.skipEngagementSign(this._param.clientId, (engagementDocument as any).data, () => {
            this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);
        }, this.saveError);
    }

    private handleSkipAndFinishForPreview = () => {
        const isPreview = IsPreviewModeFromCache();
        const isClientView = IsClientViewFromCache();
        if (isPreview) {
            this.handleRedirect(OrganizerConstants.PreviewURL + this._param.clientId);
            return true;
        }
        if (isClientView) {
            this.handleRedirect(OrganizerConstants.ClientViewURL + this._param.clientId);
            return true;
        }
        return false;
    }

    private saveError = () => {
        dialogBox.refreshAlert(OrganizerStatusChangedWarning, () => {
            RemoveEventListeners();
            this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this._param.clientId);
        });
    }

    private handleRedirect = (url: string) => {
        window.location.href = url;
    }

    private handleSigningCompleted = () => {
        const documents = [...this.state.documents]
        this.setState({
            finishEnabled: documents.filter(x => x.signCompleted).length === documents.length
        });
    }

    private toggleSpouseInfoModal = () => {
        this.setState({ showSpouseInfo: !this.state.showSpouseInfo });
    }

    private toggleDeclineModal = () => {
        this.setState({ showDeclineModal: !this.state.showDeclineModal });
    }

    private ShowDeclineModel = () => {
        this.setState({ showDeclineModal: true });
    }

    private UpdateSignProcessInfo = () => {
        const isPreview = (IsPreviewModeFromCache() || IsClientViewFromCache());
        if (!isPreview) {
            const processInfo = new SignProcessInfo(this._param.clientId, SignProcessSteps.SignEngagementLetter, 1);
            organizerService.updateSignProcessinfo(processInfo);
            SetDataToStorage(SignProcessInfoConstants.SignProcessInfo, JSON.stringify(processInfo));
        }
    }

    handleFileClick = (engagementDocument: IElDocument, pageNo: number, isAdditionalDocument: boolean) => {
        const selectedDocument = this.state.documents.filter(x => x.id === engagementDocument.id).length > 0 ? this.state.documents.filter(x => x.id === engagementDocument.id)[0] : ElDocument.createNullObject();
        this.setControlValues();
        this.state.documents.length > 1 && this.clearAllControls();
        this.setState({
            selectedPageNo: pageNo,
            isAdditionalDocument: isAdditionalDocument,
            startNavigationOnDocumentLoad: true,
            document: selectedDocument
        }, () => {
            const _self = this;
            setTimeout(
                function () {
                    _self._viewerRef._viewPanel.gotoPage(pageNo)
                }.bind(this),
                1000);
        });
    }

    handleNavigationFinish = () => {
        this.setControlValues();
        this.setDocument();
    }

    private updateControlData = (controlData: ControlData, controlProps: ControlBaseProps) => {

        const { documents, document } = this.state;
        const signCompleted = EngagementLetterSignHelper.updateControlData(
            documents,
            controlData,
            controlProps
        );
        const updatedDocument = documents.filter(x => x.id === document.id)[0];
        this.setState({
            document: updatedDocument,
            signingCompleted: signCompleted
        });

    }


    setControlValues = (callback?: any) => {
        const controls = this._viewerRef._controlList.filter((x: any) => x != null);
        let tempcontrols = [...this.state.controlList];

        // Update existing signature controls data with the latest signature data
        var signatureControl = controls.find((c: any) => c.props.data["controlType"] === 1 && c.state.signatureData !== undefined);
        if (signatureControl) {
            const controlData = signatureControl.getControlData();
            tempcontrols.forEach((control: any) => {
                if (control.props.data["controlType"] === 1 && control.state.signatureData !== undefined) {
                    control.state = { ...control.state, signatureData: controlData }
                }
            });
        }

        controls.forEach((control: any) => {
            const index = tempcontrols?.findIndex((x: any) => x?.props?.id == control?.props?.id);
            if (index < 0) {
                tempcontrols.push(control);
            }
            else {
                tempcontrols[index] = control;
            }
        });
        this.setState({
            controlList: tempcontrols
        }, () => { callback && callback() });
    }

    //clear the current page controls before loading the next file
    //Fix for bug #69779
    clearAllControls = () => {
        this._viewerRef._controlList?.filter((x: any) => x != null).forEach((control: any) => {
            this._viewerRef._controlLayer.removeControl(control.props.id);
        });
    }

    onPageChanging = (pageProperties: PageProperties) => {
        this.setState({ selectedPageNo: pageProperties.page > 0 ? pageProperties.page : 1 });
    }

    documentHasControls = (document: ElDocument) => {
        return document.forms.filter(y => y.controls.some((x: any) => x.controlRole == this.state.clientType
            && x.controlType != ControlType.Date && x.controlData == null) == true).length > 0;
    }

    private setDocument = () => {

        if (this.state.document.id > 0) {
            const documents = this.state.documents;
            //check what is disabled flag in SSR for documents
            const filteredDocuments = documents.filter(x => x.forms.some(x => x.controls.length > 0));

            const nextDocumentIndex = filteredDocuments.indexOf(this.state.document) + 1;

            const nextDocument: IElDocument | undefined =
                Enumerable.from(filteredDocuments).elementAtOrDefault(nextDocumentIndex);

            if (nextDocument == undefined) {
                this.navigateToFirstUnsignedDocument();
                return;
            }

            if (documents.length == nextDocumentIndex + 1) {
                this.clearAllControls();
                this.setLastDocument(nextDocument);
            }
            else {
                this.clearAllControls();
                this.setNextDocument(nextDocument);
            }
        }
        else {
            this.clearAllControls();
            this.setFirstDocument();
        }
    }

    navigateToFirstUnsignedDocument = () => {
        const unsignedDocuments = this.state.documents.filter(x => !x.signCompleted);
        if (unsignedDocuments.length > 0) {
            if (unsignedDocuments?.length === 1) {
                if (unsignedDocuments[0].id !== this.state.document.id)
                    this.clearAllControls();
                this.setLastDocument(unsignedDocuments[0]);
            }
            else {
                this.clearAllControls();
                this.setNextDocument(unsignedDocuments[0]);
            }
        }
        else
            return;

    }

    private setFirstDocument = () => {
        this.setState({ document: this.state.documents[0], selectedPageNo: 1 });
    }

    private setNextDocument = (nextDocument: IElDocument) => {
        if (nextDocument) {
            this.setState({
                document: nextDocument,
                startNavigationOnDocumentLoad: true,
                selectedPageNo: 1,
                isAdditionalDocument: nextDocument && nextDocument.type == ELDocType.Additional ? true : false
            });
        }

    }

    private setLastDocument = (nextDocument: IElDocument) => {
        nextDocument && this.setState({
            document: nextDocument,
            startNavigationOnDocumentLoad: true,
            selectedPageNo: 1,
            isAdditionalDocument: nextDocument.type == ELDocType.Additional ? true : false
        });
    }

    private isLastDocument = (document: IElDocument) => {
        if (document) {
            const filteredDocuments = this.state.documents.filter(x => x.forms.some(x => x.controls.length > 0));
            // set next document using index
            const nextDocumentIndex = filteredDocuments.indexOf(document) + 1;

            const nextDocument: IElDocument | undefined =
                Enumerable.from(filteredDocuments).elementAtOrDefault(nextDocumentIndex);

            if (nextDocument == undefined) {
                return true;
            }
            return false;
        }
        return true;
    }

    checkControlFound = () => {
        let controlpresent = this.state.documents.filter(y => y.forms.some((x) => x.controls.some((x: any) => x.controlRole == this.state.clientType
            && x.controlType != ControlType.Date && x.controlData == null) == true)).length > 0;

        let requiredControlPresent = this.state.documents.filter(y => y.forms.some((x) => x.controls.some((x: any) => x.controlRole == this.state.clientType
            && x.controlType != ControlType.Date && x.required == true) == true)).length > 0;

        return controlpresent && requiredControlPresent;
    }


    checkAllDocumentsSigned = () => {
        return this.state.documents.filter(x => x.signCompleted).length === this.state.documents.length;
    }

    setNavigationStatus = (show: boolean) => {
        this.setState({
            hideNavigationControl: show
        })
    }

    public render() {
        this._param = this.props.match.params;
        const {
            clientInfo,
            requestSpouseInfo,
            engagementDocument,
            organizerMetadata,
        } = this.props;
        const { showSpouseInfo,
            showDeclineModal,
            documents,
            document,
            selectedEngagementDocument,
            startNavigationOnDocumentLoad,
            hideNavigationControl,
            controlStatus } = this.state;

        let controlsFound: boolean = this.checkControlFound();


        const isPreview = (IsPreviewModeFromCache() || IsClientViewFromCache());
        const finishEnabled = isPreview || this.checkAllDocumentsSigned() || !controlsFound
        const finishTarget: string = this.isLastDocument(document) ? "awesome-multi-steps-btn-next-container" : "";
        return (<div>

            <Viewer ref={(ref: any) => { this._viewerRef = ref }}
                controlsFound={controlsFound}
                onSigningComplete={this.handleSigningCompleted}
                forms={this.state.document.type === ELDocType.Additional ? selectedEngagementDocument : engagementDocument}
                clientId={this._param.clientId}
                isReadMode={isPreview}
                documentUrl={document?.url}
                isAdditionalDocument={this.state.document.type === ELDocType.EL || this.state.document.type === ELDocType.OrganizerSignDocument ? false : true}
                onNavigationFinish={this.handleNavigationFinish}
                finishTarget={finishTarget}
                elForms={document?.forms}
                hideNavigationControl={hideNavigationControl}
                onPageChanging={this.onPageChanging}
                startNavigationOnDocumentLoad={startNavigationOnDocumentLoad}
                EngagementFiledocuments={engagementDocument}
                setNavigationStatus={this.setNavigationStatus}
                pageNo={this.state.selectedPageNo}
                fileList={
                    <FileList
                        elDocuments={documents}
                        selectedDocumentId={document.id}
                        onFileClick={this.handleFileClick}
                        selectedPageNo={1} />
                }
                document={this.state.document}
                getAdditionalFileDownLoadLink={this.props.getAdditionalFileDownLoadLink}
                uploadMethod={engagementDocument.data.uploadMethod}
                onAddControlData={this.updateControlData}
                signCompleted={this.state.signingCompleted}
                organizerMetadata={organizerMetadata}
                controlStatus={controlStatus}
            />

            <DeclineModal clientId={this._param.clientId}
                onDecline={this.handleDecline} show={showDeclineModal}
                onClose={this.toggleDeclineModal} />

            <SpouseInfoModal clientId={this._param.clientId}
                clientInfo={clientInfo}
                onUpdateSpouseEmail={this.handleUpdateSpouseEmail}
                onRequestSpouseInfo={requestSpouseInfo} show={showSpouseInfo} onClose={this.toggleSpouseInfoModal}
                title={EngagementLetterConstants.SpouseInfoUpdateModalTitle} message={EngagementLetterConstants.SpouseInfoUpdateModalBodyText} />

            <Footer onFinish={this.handleFinish} finishEnabled={finishEnabled} controlStatus={controlStatus}
                onBack={() => {
                    this.handleRedirect((isPreview ? IsPreviewModeFromCache() ? OrganizerConstants.PreviewURL : OrganizerConstants.ClientViewURL :
                        OrganizerConstants.OrganizerSummaryPageURL) + this._param.clientId)
                }}
                onDecline={this.ShowDeclineModel}
                onSkip={this.handleSkip} />

        </div>);

    }

}
