import { SignatureMode } from "awesome-pdf-viewer/dist/Controls/ControlBase";
import { ClientType } from "../../../../core/common/Enums";
import { IControl, ICustomData } from "../esign/IControls"

export enum ControlType {
    Textbox = 1,
    Checkbox = 2,
    Date = 3,
    Dropdown = 4,
    Grid = 5,
    Signature = 6,
    RadioButton = 7,
    RadioButtonGroup = 8,
    Question = 9,
    Section = 10,
    SignatureCheckBoxControl = 11,
    SignatureRadioButtonControl = 12
}

export enum TextPlacement {
    Left = 1,
    Right = 2,
    Center = 3
}

export enum DataType {
    None = 0,
    AlphaNumeric = 1,
    Numeric = 2,
    SSN = 3,
    TextArea = 4,
    Label = 5,
    Amount = 6
}

export class BoundingRectangle {

    left: number;
    top: number;
    width: number;
    height: number;

    constructor(left: number,
        top: number,
        width: number,
        height: number) {

        this.left = left;
        this.top = left;
        this.width = left;
        this.height = left;

    }
}

export interface IChoosableControlResult extends IControlData {
    checked: boolean;
    id: string;
}

export class Control implements IControl {

    id: string;
    controlType: ControlType;
    name: string;
    readonly: boolean;
    tooltip: string;
    required: boolean;
    boundingRectangle: BoundingRectangle;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;


    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean    ) {


        this.id = id;
        this.controlType = controlType;
        this.name = name;
        this.readonly = readonly;
        this.tooltip = tooltip;
        this.required = required;
        this.boundingRectangle = boundingRectangle;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;

    }

}

export interface IControlData {

}

export class CheckboxData implements IControlData {
    checked: boolean;


    constructor(checked: boolean) {
        this.checked = checked;
    }
}


export class Checkbox extends Control {

    defaultValue: string;
    controlData: CheckboxData;

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        defaultValue: string,
        controlData: CheckboxData) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData);

        this.defaultValue = defaultValue;
        this.controlData = controlData;

    }


}
export class CheckBoxControl extends Control {

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData,
            data,
            disabled);

        this.items = items;
    }
   
    items: ChoosableControlData[];

    static create(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): CheckBoxControl {

        return new CheckBoxControl(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            items,
            boundingRectangle,
            customData,
            data,
            disabled);
    }
}

export class RadioButtonControl extends Control {

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData,
            data,
            disabled);

        this.items = items;
    }

    items: ChoosableControlData[];

    static create(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): CheckBoxControl {

        return new CheckBoxControl(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            items,
            boundingRectangle,
            customData,
            data,
            disabled);
    }
}

export class ChoosableControlData {

    id: string;
    name: string;
    top: number;
    left: number;
    width: number;
    height: number;
    value: any;

    constructor(id: string,
        name: string,
        top: number,
        left: number,
        width: number,
        height: number,
        value: any) {
        this.id = id;
        this.name = name;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.value = value;
    }

    public static create(
        id: string,
        name: string,
        top: number,
        left: number,
        width: number,
        height: number,
        value: any
    ) {

        var data = new ChoosableControlData(id, name, top, left, width, height, value);
        return data;
    }

    public static createNullObject() {

        var data = new ChoosableControlData('', '', 0, 0, 0, 0, { checked: false, value: '' });
        return data;
    }
}

export class ChoosableControlResult implements IChoosableControlResult {
    id: string;
    checked: boolean;

    constructor(id: string,
        checked: boolean
    ) {
        this.id = id;
        this.checked = checked;
    }

    public static create(
        id: string,
        checked: boolean
    ) {

        var data = new ChoosableControlResult(id, checked);
        return data;
    }
}


export class DateControl extends Control {

    placeholder: string;
    textPlacement: TextPlacement;
    controlData: IControlData;

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[], placeholder: string,
        textPlacement: TextPlacement,
        controlData: IControlData) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData);

        this.placeholder = placeholder;
        this.textPlacement = textPlacement;
        this.controlData = controlData;

    }

}

export class DateControlData implements IControlData {
    value: Date;

    constructor(value: Date) {
        this.value = value;
    }

}


export class Dropdown extends Control {

    defaultItem: string;
    options: string[];
    placeholder: string;
    textPlacement: TextPlacement;
    controlData: IControlData;

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[], placeholder: string,
        textPlacement: TextPlacement,
        controlData: IControlData, defaultItem: string,
        options: string[]) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData);

        this.placeholder = placeholder;
        this.textPlacement = textPlacement;
        this.controlData = controlData;

        this.defaultItem = defaultItem;
        this.options = options;

    }

}

export class DropdownData implements IControlData {
    selectedItem: string;

    constructor(selectedItem: string) {
        this.selectedItem = selectedItem;
    }
}


export class RadioButton extends Control {

    helptext: string;
    fillText: string;
    controlData: IControlData;

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[], helptext: string,
        fillText: string,
        controlData: IControlData) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData);

        this.helptext = helptext;
        this.fillText = fillText;
        this.controlData = controlData;

    }


}

export class RadioButtonGroup extends Control {

    helptext: string;
    defaultValue: string;
    controlData: IControlData;

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[], helptext: string,
        defaultValue: string,
        controlData: IControlData) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData);

        this.helptext = helptext;
        this.defaultValue = defaultValue;
        this.controlData = controlData;

    }

}

export class RadioButtonGroupData implements IControlData {
    items: RadioButton[];
    selectedId: string;

    constructor(items: RadioButton[],
        selectedId: string) {

        this.items = items;
        this.selectedId = selectedId;

    }
}

export class RadioButtonData implements IControlData {
    selected: boolean
    filledBy: ClientType;

    constructor(selected: boolean) {
        this.selected = selected;
        this.filledBy = ClientType.Undefined;
    }
}

export class Signature extends Control {

    controlData: IControlData;

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        controlData: IControlData) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData);

        this.controlData = controlData;

    }
}

export class SignatureData implements IControlData {
    name: string;
    uri: string;
    signatureMode: SignatureMode;
    uniqueId?: string;

    constructor(name: string,
        uri: string, signatureMode: SignatureMode, uniqueId?: string) {
        this.name = name;
        this.uri = uri;
        this.signatureMode = signatureMode;
        this.uniqueId = uniqueId
    }

    public static create(name: string,
        uri: string, signatureMode: SignatureMode, uniqueId?: string) {

        return new SignatureData(name,
            uri, signatureMode, uniqueId);

    }


    public static createNullObject() {

        return new SignatureData("",
            "", SignatureMode.None, "");

    }

}


export class Textbox extends Control {
    dataType: DataType;
    maxLength: number;
    placeholder: string;
    textPlacement: TextPlacement;
    controlData: TextboxData;

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        dataType: DataType,
        maxLength: number,
        placeholder: string,
        textPlacement: TextPlacement,
        controlData: TextboxData) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData);

        this.dataType = dataType;
        this.maxLength = maxLength;
        this.placeholder = placeholder;
        this.textPlacement = textPlacement;
        this.controlData = controlData;

    }
}

export class TextboxData implements IControlData {
    value: string;
    filledBy: ClientType;

    constructor(value: string) {
        this.value = value;
        this.filledBy = ClientType.Undefined;
    }
}

export interface SubQuestionMapping {
    subQuestionIds: string[];
    condition: boolean;
}

export class QuestionControl extends Control {
    radioButtonGroup: RadioButtonGroup;
    textbox: Textbox;
    type: QuestionType;
    inputTextbox: Textbox;

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        radioButtonGroup: RadioButtonGroup,
        textbox: Textbox,
        type: QuestionType = QuestionType.YesNo,
        inputTextbox: Textbox = {} as Textbox) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData);

        this.radioButtonGroup = radioButtonGroup;
        this.textbox = textbox;
        this.type = type;
        this.inputTextbox = inputTextbox

    }
}



export class ControlValue {

    //id: number;
    formGuid: string;
    controlGuid: string;
    value: string;
    filledBy: ClientType;

    constructor(/*id: number,*/
        formGuid: string,
        controlGuid: string,
        value: string,clientType?: ClientType) {

        //this.id = id;
        this.formGuid = formGuid;
        this.controlGuid = controlGuid;
        this.value = value;
        this.filledBy = clientType ? clientType : ClientType.Undefined;

    }

    public static create(formGuid: string,
        controlGuid: string,
        value: string,
        clientType?: ClientType): ControlValue {

        return new ControlValue(formGuid, controlGuid, value,clientType);
    }

}

export enum QuestionType {
    YesNo,
    ShortAnswer
}

