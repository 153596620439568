import * as React from 'react';
import { StatusCode, UploadedDocument } from '../../../core/domain/models/Organizer/Organizer';
import { IFileUploadUtilities } from '../../../core/utilities/FileUpload/FileUploadUtilities';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { CustomEventLog, OrganizerUploadedDocuments, PreviewConstants, SourceDocumentConstants, TaxPayerPage, UploadFileWarningMessage } from '../../Common/Constants';
import { FileUpload, UploadStatus } from '../../Common/FileUpload/FileUpload';
import { UploadedDocumentTable } from './UploadedDocumentTable';
import { UploadHeader } from './UploadHeader';
import { BatchUploadedStatus, UploadedFile, ConvertedUploadedFileModel } from '../../../core/domain/models/SourceDocument/SourceDocument';
import { AddUploadedFileToStorage, AddDeletedFilesToStorage, getFileSize, IsClientViewFromCache, RedirectToDashBoard } from '../../Helper/HelperFunction';
import { MarsNotifier } from '../../Common/Notification/MarsNotifier';
import { AxiosResponse } from 'axios';
import { ConflictErrorMessage, CustomResponse } from '../../../store/UploadedDocument/UploadedDocumentStoreModels';
import { Dropdown } from 'react-bootstrap';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { logger } from '../../../routes';
import { initializeAxios } from '../../../core/Services/dataAccess/DataService.Axios';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';
import { convertToUploadedFileModel, handleDuplicates, handleFilesWithZeroSize } from '../../Helper/UploadDocumentHelper';

export interface UploadDocumentsProps {
    clientId: string;
    uploadedDocuments: UploadedDocument[];
    addDocument(clientId: string, signedDocument: UploadedDocument, successCallback?: () => void, failureCallback?: () => void): void;
    deleteUploadedDocument(clientId: string, id: number, successCallback?: () => void): void;
    requestUploadedDocuments(clientId: string, forceRefresh: boolean): void;
    updateUploadedStatus(status: BatchUploadedStatus): void;
    onUploadedDocumentModification(): void;
    disabled?: boolean;
    isCompleted: boolean;
    changeStatusToCompleted: () => void;
    setUploadedDocumentError: () => void;
    downloadIndividualSourceDocument(documentId: number, fileName: string, documentGuid: string, year: number): void;
    downloadAllSourceDocuments(uploadedDocuments: UploadedDocument[]): void;
    downloadSelectedSourceDocuments(selectedDocuments: UploadedDocument[]): void;
    getSourceDocumentMetadataAsync?(clientId: string, uploadedDocumentId: number, successCallback?: () => void): void;
}

export interface UploadDocumentsState {
    files: UploadedFile[];
    selectedDocuments: number[];
    isClientView: boolean;
}

const fileUploadUtilities = container.get<IFileUploadUtilities>(TYPES.IFileUploadUtilities);
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class UploadDocuments extends React.Component<UploadDocumentsProps, UploadDocumentsState> {

    constructor(props: UploadDocumentsProps) {
        super(props);

        this.state = {
            files: [],
            selectedDocuments: [],
            isClientView: IsClientViewFromCache()
        }
    }

    componentWillReceiveProps(nextProps: UploadDocumentsProps, nextState: UploadDocumentsState) {
        let files: UploadedFile[] = [];

        if (Array.isArray(nextProps.uploadedDocuments) && (nextProps?.uploadedDocuments !== this.props.uploadedDocuments)) {
            nextProps.uploadedDocuments.forEach((item: UploadedDocument, index: number) => {
                files.push({
                    id: item.id,
                    uploadedOn: item.uploadedOn,
                    name: item.fileName,
                    file: undefined,
                    status: UploadStatus.Uploaded,
                    uploadPercentage: 100,
                    documentGuid: item.documentGuid,
                    taxYear: item.taxYear,
                    documentId: item.documentId
                });
            });
            this.setState({ files: files, selectedDocuments: [] });
        }
    }

    private handleAddFiles = (files: any) => {

        if (!this.props.disabled) {
            let convertedUploadedFileModel: ConvertedUploadedFileModel = convertToUploadedFileModel(files);

            if (convertedUploadedFileModel.isFileSizeLimitExceeded) {
                MarsNotifier.Warning(UploadFileWarningMessage(
                    convertedUploadedFileModel.counterFileSizeLimitFailed,
                    files.length,
                    OrganizerUploadedDocuments.SupportedUploadFileSizeWarningMessage), null);
            }
            if (convertedUploadedFileModel.isUnsupportedFileExtension) {
                MarsNotifier.Warning(UploadFileWarningMessage(
                    convertedUploadedFileModel.counterUnsupportedFileExtensionFailed,
                    files.length,
                    OrganizerUploadedDocuments.SupportedFilesMessage), null);
            }

            convertedUploadedFileModel.uploadedFiles = handleDuplicates(convertedUploadedFileModel.uploadedFiles, this.state.files);

            handleFilesWithZeroSize(convertedUploadedFileModel.uploadedFiles).then((uploadFilesWithValidData) => {
                if (uploadFilesWithValidData.length != convertedUploadedFileModel.uploadedFiles.length) {
                    MarsNotifier.Warning(OrganizerUploadedDocuments.SupportedFilesSizeMessage, null);
                }

                if (uploadFilesWithValidData.length > 0) {

                    this.setState((prevState: UploadDocumentsState) => ({
                        files: prevState.files.concat(uploadFilesWithValidData)
                    }), () => {

                        this.getUploadLink();
                    });
                }
            });
        }
    }

    private uploadProgress = (progress: number, file: any) => {

        const { updateUploadedStatus } = this.props;

        const tmpFile: UploadedFile | undefined = this.state.files.find(x => x.file && x.file.upload.uuid == file.file.upload.uuid);

        if (tmpFile) {
            tmpFile.uploadPercentage = progress;

            this.setState((prevState: UploadDocumentsState) => ({
                files: prevState.files
            }));
        }
    }

    private getUploadLink = () => {

        let _self = this;
        const {
            clientId, updateUploadedStatus,
            setUploadedDocumentError, changeStatusToCompleted
        } = this.props;

        let fileUploaded: number = 0;
        let fileInError: number = 0;
        const uploadFiles: number = this.state.files.filter(x => x.status == UploadStatus.Wait).length;

        updateUploadedStatus(BatchUploadedStatus.Uploading);

        this.state.files.filter(x => x.status == UploadStatus.Wait).forEach((file: UploadedFile, index: number) => {

            file.status = UploadStatus.Initiating;
            file.name = file.name.trim();
            initializeAxios().get(`/api/OrganizerAdditionalDocument/GetAdditionDocumentUploadLink/${encodeURIComponent(clientId)}?fileName=${file.name}`)
                .then((response: AxiosResponse<CustomResponse<any>>) => {

                    if (response.data.statusCode === StatusCode.Conflict) {

                        fileInError++;

                        if (fileInError == uploadFiles) {

                            setUploadedDocumentError()

                            this.setState((prevState: UploadDocumentsState) => ({
                                files: prevState.files.filter(x => x.status !== UploadStatus.Initiating && x.status !== UploadStatus.Wait)
                            }));

                            changeStatusToCompleted();

                            updateUploadedStatus(BatchUploadedStatus.Completed);
                        }
                        return;
                    }
                    this.setState((prevState: UploadDocumentsState) => ({
                        files: prevState.files
                    }), () => {

                        fileUploadUtilities.upload(file.file,
                            response.data.data.sas,
                            file.name ? file.name : "",
                            this.uploadProgress,
                            () => {
                                fileUploaded++;
                                this.handleAddDocument(file.name, getFileSize(file.file.size), uploadFiles, fileUploaded)
                            });
                    });
                }, (error: any) => {
                    if (error?.response?.status === StatusCode.Locked) {
                        RedirectToDashBoard(clientId, dialogBox);
                    }
                    else {
                        _self.state.files.splice(index, 1);

                        this.setState((prevState: UploadDocumentsState) => ({
                            files: prevState.files
                        }));
                    }
                });
        });
    }

    private handleAddDocument = (fileName: string, fileSize: string, uploadFiles: number, fileUploaded: number) => {
        const { clientId, addDocument, requestUploadedDocuments, updateUploadedStatus, onUploadedDocumentModification } = this.props;
        let uploadedDocument: UploadedDocument = UploadedDocument.create(fileName, fileSize);
        AddUploadedFileToStorage(fileName);

        uploadFiles == fileUploaded ? addDocument(
            this.props.clientId,
            uploadedDocument,
            () => {
                const updateFilesStatus = this.state.files.map(x => {
                    if (x.name === fileName) {
                        x.status = UploadStatus.Uploaded;
                    }
                    return x;
                });

                let filteredUploadedFiles: UploadedFile[] = updateFilesStatus.filter(f => f.status == UploadStatus.Uploaded);
                this.setState({ files: filteredUploadedFiles });

                requestUploadedDocuments(clientId, true);
                updateUploadedStatus(BatchUploadedStatus.Completed);
                onUploadedDocumentModification();
            },
            () => {
                const updateFilesStatus = this.state.files.map(x => {
                    if (x.name === fileName) {
                        x.status = UploadStatus.Error;
                    }
                    return x;
                });

                let filteredUploadedFiles: UploadedFile[] = updateFilesStatus.filter(f => f.status != UploadStatus.Error);
                this.setState({ files: filteredUploadedFiles });
            }) : addDocument(clientId, uploadedDocument);
    }

    private handleDeleteDocument = (clientId: string, id: number, name: string) => {

        if (this.props.disabled) {
            MarsNotifier.Warning(PreviewConstants.DeleteDocumentMessage, null);
            return;
        }

        const { deleteUploadedDocument, requestUploadedDocuments, onUploadedDocumentModification } = this.props;
        deleteUploadedDocument(clientId, id, () => {
            AddDeletedFilesToStorage(this.state.files, id);
            onUploadedDocumentModification();
            requestUploadedDocuments(clientId, true);
        });
    }

    private sourceDocumentSelected = (checked: boolean, documentId: number): void => {
        var selectedDocuments = this.state.selectedDocuments;

        if (checked && !selectedDocuments.includes(documentId)) {
            selectedDocuments.push(documentId);
        }
        else if (!checked) {
            selectedDocuments = selectedDocuments.filter((x) => { return x != documentId; });
        }
        this.setState({ selectedDocuments: selectedDocuments });
    }

    private onDownloadSelectedClick = () => {
        if (this.state.selectedDocuments.length === 0) {
            MarsNotifier.Warning(SourceDocumentConstants.NoFilesSelected, null);
            return;
        }

        if (this.state.selectedDocuments.length === 1) {
            const docId = this.state.selectedDocuments[0]
            const fileData = this.state.files.find(file => file.id == docId && file.status == UploadStatus.Uploaded);
            fileData && this.props.downloadIndividualSourceDocument(fileData.documentId, fileData.name, fileData.documentGuid, fileData.taxYear);
        } else {
            const selectedDocumentIds = this.props.uploadedDocuments.filter(file => this.state.selectedDocuments.includes(file.id));
            this.props.downloadSelectedSourceDocuments(selectedDocumentIds);
        }
    }

    private handlePreviewDocument = (clientId: string, uploadedDocumentId: number, uploadedDocumentName: string) => {
        const { getSourceDocumentMetadataAsync } = this.props;
        if (getSourceDocumentMetadataAsync) {
            getSourceDocumentMetadataAsync(clientId, uploadedDocumentId, () => {
                const traceEvent: IEventTelemetry = {
                    name: `${CustomEventLog.PreviewSourceDocument}`,
                    properties: {
                        Page: TaxPayerPage.UploadDocumentsPage,
                        SourceDocumentId: uploadedDocumentId,
                        SourceDocumentName: uploadedDocumentName
                    }
                };
                logger.trackEvent(traceEvent);
            });
        }
    }

    private onDownloadAllClick = () => {
        this.props.downloadAllSourceDocuments(this.props.uploadedDocuments);
    }

    public render() {
        const { files, selectedDocuments, isClientView } = this.state;

        const { isCompleted, clientId, disabled } = this.props;

        const fileUploadCssClass: string = files.length > 0 ? "" : "with-border";
        let uploadeFiles = files.filter(x => x.status == UploadStatus.Uploaded);

        return (<React.Fragment>

            <div className={"col-lg-12"} title={isCompleted ? ConflictErrorMessage : ''}>
                <div className={"col-lg-12 upload-documents with-border"} id={"document-upload"} data-test-auto="B5EF7D9E-9B8B-49BB-8262-D84557544CA7">
                    <FileUpload id={"uploaded-document"} cssClass={fileUploadCssClass} onAddFiles={this.handleAddFiles} disabled={disabled}>
                        <UploadHeader minimized={false} disabled={disabled} />
                    </FileUpload>
                </div>
            </div>

            <div className={"col-lg-12"}>
                <span className="uploaded-src-documents-indicator alignLeft={true}">{OrganizerUploadedDocuments.MaximumFileNameLengthCharactersMessage}</span>
                <Dropdown className="flrt margin-top-10 source-download-all-btn" aria-readonly alignRight={true} >
                    <Dropdown.Toggle variant="success" id="drp-source-download" disabled={uploadeFiles.length == 0}
                        title={uploadeFiles.length == 0 ? SourceDocumentConstants.NoSourceDocuments : ""} className="cls-source-download">
                        Download
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={this.onDownloadSelectedClick} disabled={isClientView} className="larger" >Download Selected</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={this.onDownloadAllClick} disabled={isClientView} className="larger" >Download All</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className={"col-lg-12"}
                style={{ marginTop: '10px' }}
                data-test-auto="B5EF7D9E-9B8B-49BB-8262-D84557544CA7">
                <UploadedDocumentTable
                    clientId={clientId}
                    files={files}
                    deleteUploadedDocument={this.handleDeleteDocument}
                    deletedButtonDisable={isCompleted || this.props.disabled}
                    selectedDocuments={selectedDocuments}
                    sourceDocumentSelected={this.sourceDocumentSelected}
                    previewUplodedDocument={this.handlePreviewDocument}
                    isClientView={isClientView}
                />
            </div>

        </React.Fragment>);
    }
}
