
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import * as OrganizerStore from '../../../store/Organizer/OrganizerStore';

export type NotesProps = {
    taxPayerNotes?: string;
    ontaxPayerNotesChange: (event: any) => void;
    onDelete: () => void;
    onAdd: (Notes: string) => void;
    id: number;
    isReadMode?: boolean,
}
    & typeof OrganizerStore.actionCreators
    & RouteComponentProps<{}>

export interface NotesState {
    taxPayerNotes?: string;
    isSaveDisabled: boolean;
}

export class TaxPayerNotes extends React.Component<NotesProps, NotesState> {
    private _tpNotes: boolean = false;
    public _controlList: any[] = [];
    constructor(props: any) {
        super(props);
        this.state = {
            taxPayerNotes: "",
            isSaveDisabled: true
        }
    }
    componentWillReceiveProps(nextProps: NotesProps) {

        if (nextProps.taxPayerNotes != undefined) {
            this.setState({
                taxPayerNotes: nextProps.taxPayerNotes,
                isSaveDisabled: true
            })
        }
    }
    removeUnwantedWhitespacesFromText(text: string) {
        const splittedText = text.split(/\s+/);
        return splittedText.join(" ");
    }
    onTextChange = (event: any) => {
        let notes = event.target.value.trimStart();
        this.setState({ taxPayerNotes: notes });
        if (this.props.taxPayerNotes != notes) {
            this.setState({
                isSaveDisabled: false
            });
            this._tpNotes = true;
        }
        else {
            this.setState({
                isSaveDisabled: true
            });
        }
    }
    afterTextChangeComplete = (event: any) => {
        let notes = this.removeUnwantedWhitespacesFromText(event.target.value.trim());
        this.setState({ taxPayerNotes: notes });
    }
    onDeleteNotes = () => {
        this.setState({ taxPayerNotes: "" })
        this.props.onDelete();
        this.setState({
            isSaveDisabled: true
        });
    }
    updateTPnotes = () => {
        if (this.props.taxPayerNotes != this.state.taxPayerNotes && !this._tpNotes) {
            this.setState({
                taxPayerNotes: this.props.taxPayerNotes
            })
            this._tpNotes = true;
        }
    }
    onAddNotes = () => {
        this.props.onAdd(this.state.taxPayerNotes == undefined ? "" : this.state.taxPayerNotes);
    }
    public render() {

        const { taxPayerNotes } = this.state;
        {
            if (this.state.taxPayerNotes != this.props.taxPayerNotes && !this._tpNotes) {
                this.updateTPnotes();
            }
        }

        return (

            <div className={"view-TaxPayerNotes"} style={{ pointerEvents: this.props.isReadMode ? 'none' : 'auto' }}>
                <div className="NotesHeader row" style={{}}>
                    <span style={{ color: '#007ee6' }} >Notes to Tax Preparer</span>
                </div>
                <div className="NotesSection row" style={{}}>
                    <span>Add Notes for tax preparer </span>
                </div>
                <div>
                    <textarea
                        className="view-form-control NotesText"
                        rows={5}
                        placeholder="Notes"
                        data-test-auto="0fc0b438-2d7c-4935-9808-667e50315e9b"
                        value={this.state.taxPayerNotes ? this.state.taxPayerNotes : ""}
                        onChange={this.onTextChange}
                        onBlur={this.afterTextChangeComplete}
                        maxLength={200}
                    />
                </div>
                <div>
                    {
                        taxPayerNotes == "" ?
                            "" : <i className="fas fa-trash-alt fa-lg marL10 delete-icon "
                                onClick={this.onDeleteNotes} >
                            </i>
                    }
                    {
                        <Button
                            disabled={this.state.isSaveDisabled}
                            onClick={this.onAddNotes}
                            data-test-auto='056DD1B3-F56D-439C-B5E0-8998A4BDDAC7'
                            variant="info"
                            className={"btn btn-primary btn-Notes-Add btn-login pull-right"}
                        >
                            <i className='glyphicon glyphicon-plus'></i>Save
                        </Button>
                    }
                </div>
            </div>
        );
    }
}
