
import { DocumentSignatureDataViewModel } from "./IDocumentSignatureDataViewModel";

export interface ISignerControlDataModal {
    clientGuid: string,
    signature: string,
    documentData: DocumentSignatureDataViewModel[]
}

export class SignerControlDataModal implements ISignerControlDataModal {

    clientGuid: string;
    signature: string;
    documentData: DocumentSignatureDataViewModel[];

    constructor(clientGuid: string, signature: string, documentData: DocumentSignatureDataViewModel[]) {

        this.clientGuid = clientGuid;
        this.signature = signature;
        this.documentData = documentData;
    }


    static create(clientGuid: string, signature: string, documentData: DocumentSignatureDataViewModel[]): ISignerControlDataModal {
        return new SignerControlDataModal(clientGuid, signature, documentData);
    }

    public static createNullObject(): ISignerControlDataModal {
        return new SignerControlDataModal("", "", []);
    }
}