import React from "react";

const BlockedClientPage = () => {
	return (
		<div>
			<div className="invalid-container">
				<div className="invalid-header"></div>

				<div className="content-wrapper">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page-container">
							<div className="message">
								Sorry! Site is temporarily unavailable while we complete
								maintenance work. Please check back later.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlockedClientPage;
