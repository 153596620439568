import "babel-polyfill";
import "bootstrap";
import { ConnectedRouter } from "connected-react-router";
import "isomorphic-fetch";
import * as React from "react";
import { Provider } from "react-redux";
import { history } from "./core/Services/dataAccess/History";
import * as RoutesModule from "./routes";
import { ApplicationState } from "./store";
import configureStore from "./configureStore";
let routes = RoutesModule.routes;

const initialState = (window as any).initialReduxState as ApplicationState;
const store = configureStore(history, initialState);
function App() {
	return (
		<Provider store={store}>
			<ConnectedRouter children={routes} history={history} />
		</Provider>
	);
}

export default App;
