import * as React from 'react';
import { UploadedDocument } from '../../../core/domain/models/Organizer/Organizer';
import { UploadDocuments } from './UploadDocuments';
import { BatchUploadedStatus } from '../../../core/domain/models/SourceDocument/SourceDocument';


export interface UploadDocumentsProps {
    clientId: string;
    uploadedDocuments: UploadedDocument[];    
    addDocument(clientId: string, signedDocument: UploadedDocument, successCallback?: () => void, failureCallback?: () => void): void;
    deleteUploadedDocument(clientId: string, id: number, successCallback?: () => void): void;
    requestUploadedDocuments(clientId: string): void;
    updateUploadedStatus(status: BatchUploadedStatus): void; 
    onUploadedDocumentModification(): void;
    disabled?: boolean;
    isCompleted: boolean;
    changeStatusToCompleted: () => void;
    setUploadedDocumentError: () => void;
    downloadIndividualSourceDocument(documentId: number, fileName: string, documentGuid: string, year: number): void;
    downloadAllSourceDocuments(uploadedDocuments: UploadedDocument[]): void;
    downloadSelectedSourceDocuments(selectedDocuments: UploadedDocument[]): void;
    getSourceDocumentMetadataAsync?(clientId: string, uploadedDocumentId: number,successCallback?: () => void) : void;
}

export interface UploadDocumentsState {

}

export class UploadDocumentsArea extends React.Component<UploadDocumentsProps, UploadDocumentsState> {

    constructor(props: UploadDocumentsProps) {
        super(props);
    }

    public render() {

        const { clientId,
            uploadedDocuments,
            addDocument,
            deleteUploadedDocument,
            requestUploadedDocuments,
            updateUploadedStatus, onUploadedDocumentModification,
            disabled,
            changeStatusToCompleted,
            isCompleted,
            setUploadedDocumentError,
            downloadIndividualSourceDocument,
            downloadAllSourceDocuments,
            downloadSelectedSourceDocuments,
            getSourceDocumentMetadataAsync
        } = this.props;

        return (<div className={"col-lg-12 no-padding upload-documents-area"} data-test-auto="FF0E42CF-9DD1-4370-AFB1-9C29D890F85C">
                     
            <div className={"upload-documents-container row"}>

                <UploadDocuments clientId={clientId}
                    updateUploadedStatus={updateUploadedStatus}
                    addDocument={addDocument}
                    deleteUploadedDocument={deleteUploadedDocument}
                    uploadedDocuments={uploadedDocuments}
                    requestUploadedDocuments={requestUploadedDocuments} onUploadedDocumentModification={onUploadedDocumentModification}
                    disabled={disabled}
                    changeStatusToCompleted={changeStatusToCompleted}
                    isCompleted={isCompleted}
                    setUploadedDocumentError={setUploadedDocumentError}
                    downloadIndividualSourceDocument={downloadIndividualSourceDocument}
                    downloadAllSourceDocuments={downloadAllSourceDocuments}
                    downloadSelectedSourceDocuments={downloadSelectedSourceDocuments}
                    getSourceDocumentMetadataAsync={getSourceDocumentMetadataAsync}
                />
            </div>

        </div>);
    }
}
