import { Reducer, Action } from "redux";
import { DispatchActions } from "./ActionTypes";
import { produce } from "immer";
import {
	IQuestionnaireState,
	intialQuestionnaireState,
	QuestionType,
} from "../../core/domain/models/Organizer/CustomQuestionModel";
import { actionTypes } from "../ActionTypes";

export const reducer: Reducer<IQuestionnaireState> = produce(
	(
		draft: IQuestionnaireState = intialQuestionnaireState,
		incomingAction: Action
	) => {
		const action = incomingAction as DispatchActions;
		switch (action.type) {
			case actionTypes.GET_FORM_DATA:
				draft.formData = action.payload;
				draft.intialFormData = action.payload;
				return draft;
			case actionTypes.HANDLE_PARAGRAPH_ON_CHANGE: {
				const { sectionIndex, sectionItemIndex, value, choiceId, subQuestionIndex } = action.payload;
				const selectedItem = draft.formData.sections[sectionIndex]?.sectionItems[sectionItemIndex];
				if (choiceId && selectedItem?.choices?.length > 0) {
					selectedItem.choices.forEach((choice) => {
						if (choice.id === choiceId && choice.subQuestions?.length > 0) {
							choice.subQuestions[subQuestionIndex||0].answer.answerText = value;
						}
					});
				}

				else if (draft.formData.sections[sectionIndex]) {
					draft.formData.sections[sectionIndex].sectionItems[
						sectionItemIndex
					].answer.answerText = value;
				}
				draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex] = selectedItem;
				return draft;
			}
			case actionTypes.SET_IS_INVALID_FLAG: {
				draft.formData.sections.forEach((section, sectionIndex) =>
					section.sectionItems.forEach((sectionItem, sectionItemIndex) => {
						if (sectionItem.isRequired) {
							var isInValid = false;
							switch (sectionItem.questionType) {
								case QuestionType.PARAGRAPH: {
									if (sectionItem.answer === undefined ||
										sectionItem.answer.answerText === undefined ||
										sectionItem.answer?.answerText?.trim().length === 0) {
										isInValid = true;
									}
									break;
								}
								case QuestionType.YESNO: {
									const selectedItem = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex];
									const selectedChoices = selectedItem.choices;
									if (!selectedChoices.some(choice => choice.isSelected === true)) {
										isInValid = true;
									}
									else {
										sectionItem.choices.forEach((choice) => {
											if (choice.isSelected && choice.subQuestions?.length > 0) {
												choice.subQuestions.map(subQuestion => {
													if(subQuestion?.isRequired){
														if(subQuestion.choices.length > 0){
															if (!subQuestion.choices.some((choice) => choice.isSelected)) {
																subQuestion.isInValid = true;
															}
														}
														else if (subQuestion.answer === undefined ||
															subQuestion.answer.answerText === undefined ||
															subQuestion.answer.answerText?.trim().length === 0
														) {
															subQuestion.isInValid = true;
														}
													}
												})
											}
										})
									}
									break;
								}
								case QuestionType.MULTICHOICE: {
									const selectedItem = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex];
									const selectedChoices = selectedItem.choices;
									if (!selectedChoices.some(choice => choice.isSelected === true)) {
										isInValid = true;
									}
									break;
								}
							}

							draft.formData.sections[sectionIndex].sectionItems[
								sectionItemIndex
							].isInValid = isInValid;
						}
					})
				);
				return draft;
			}
			case actionTypes.HANDLE_YES_NO_ON_CHANGE: {
				const { choiceId, sectionIndex, sectionItemIndex, subChoiceId } = action.payload;
				const selectedItem = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex];

				if (selectedItem?.choices?.length > 0) {
					selectedItem.choices.forEach((choice) => {
						if(choice.subQuestions?.length > 0){
								choice.subQuestions.map((subQuestion)=>{
									subQuestion.answer.answerText = "";
								})								
						}
						if (subChoiceId && choice.subQuestions?.length > 0) {
							choice.subQuestions.map(subQuestion=>{
								let subQuestionChoices = subQuestion.choices;
								subQuestionChoices.forEach((subChoice,index) => {
									if (subChoiceId === subChoice.id) {
										subChoice.isSelected = !subChoice.isSelected;
										if(index==0){subQuestionChoices[1].isSelected = false}
										else{subQuestionChoices[0].isSelected = false}
									}
								})
							})
						}
						else if (choiceId === choice.id) {
							choice.isSelected = !choice.isSelected;
						} else {
							choice.isSelected = false;
							if (choice.subQuestions?.length > 0) {
								choice.subQuestions.map(subQuestion=>{
									let subQuestionChoices = subQuestion.choices;
									subQuestionChoices?.forEach((subChoice) => {
										subChoice.isSelected = false;
									})
								})
							}
						}
					});
					draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex] = selectedItem;
					if (selectedItem.isRequired) {
						let isAnySelected = selectedItem.choices.some(choice => choice.isSelected === true);

						draft.formData.sections[sectionIndex].sectionItems[
							sectionItemIndex
						].isInValid = !isAnySelected;
					}
				}

				return draft;
			}
			case actionTypes.HANDLE_MCQ_ON_CHANGE: {
				const { choiceId, sectionIndex, sectionItemIndex, isFollowUpQuestion, subQuestionId } = action.payload;
				const selectedItem = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex];

				if (selectedItem?.choices?.length > 0) {
					selectedItem.choices.forEach((choice) => {
						if (isFollowUpQuestion && choice.subQuestions?.length > 0) {
							choice.subQuestions.map(subQuestion=>{
								if(subQuestion.sectionItemId===subQuestionId){
									let subQuestionChoices = subQuestion.choices;
									subQuestionChoices.forEach((subChoice) => {
										if (choiceId === subChoice.id) {
											subChoice.isSelected = !subChoice.isSelected;
										}
										else{
											subChoice.isSelected = false;
										}
									})
								}
							})
						}
						else {
							if (choiceId === choice.id) {
								choice.isSelected = !choice.isSelected;
							}
							else {
								choice.isSelected = false;
							}
						}
					});
					draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex] = selectedItem;
					if (selectedItem.isRequired) {
						let isAnySelected = selectedItem.choices.some(choice => choice.isSelected === true);

						draft.formData.sections[sectionIndex].sectionItems[
							sectionItemIndex
						].isInValid = !isAnySelected;
					}
				}

				return draft;
			}
			default:
				return draft;
		}
	}
);
