import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';
import { start } from 'repl';

export interface IRequestStatusDictionary {
    [requestName: string]: boolean
}

const unloadedState: IRequestStatusDictionary = {} as IRequestStatusDictionary;
export const reducer: Reducer<IRequestStatusDictionary> = (state: IRequestStatusDictionary = unloadedState, incomingAction: Action) => {
    const action = actionTypes[incomingAction.type];
    if (!action) return state;

    const matches = /(.*)_(REQUEST|RESPONSE|FAILURE)/.exec(action.toString());
    if (!matches) return state;

    const [, requestName, requestState] = matches;

    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    state[requestName] = requestState === 'REQUEST';
    return state;
}

