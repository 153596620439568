import { EngagementType, SignatureType, SignatureStatus, ClientType, DocumentStatus } from "src/core/common/Enums";
import { ITaxClient, ITaxpayer } from "./ITaxClient";

export interface ITaxDocumentModel {
    documentId: number,
    taxYear: string,
    engagementType: EngagementType,
    clientID: string,
    signatureType: SignatureType,
    signatureStatus: SignatureStatus,
    isKBAEnabled: boolean,
    deliverFirstTo: ClientType,
    documentStatus: DocumentStatus,
    taxpayer: ITaxpayer,
    spouse: ITaxpayer,
    partnership: ITaxClient
}

export const initialTaxDocumentModel: ITaxDocumentModel = {
    documentId: 0,
    taxYear: "",
    engagementType: EngagementType.None,
    clientID: "",
    signatureType: SignatureType.None,
    signatureStatus: SignatureStatus.None,
    isKBAEnabled: false,
    deliverFirstTo: ClientType.Undefined,
    documentStatus: DocumentStatus.None,
    taxpayer: {
        clientGuid: "",
        email: "",
        id: 0,
        isModified: false,
        name: "",
        ssn: "",
        address: "",
        city: "",
        dob: new Date(),
        isDeceased: false,
        state: "",
        zip: "",
        mobileNumber: ""
    },
    spouse: {
        clientGuid: "",
        email: "",
        id: 0,
        isModified: false,
        name: "",
        ssn: "",
        address: "",
        city: "",
        dob: new Date(),
        isDeceased: false,
        state: "",
        zip: "",
        mobileNumber: ""
    },
    partnership: {
        clientGuid: "",
        email: "",
        id: 0,
        isModified: false,
        name: "",
        ssn: "",
        mobileNumber: ""
    }
}