export interface ISignerModel {
    documentId: number;
    signer: string;
    isSigned: boolean;
    signedOn: Date;
    signerType: ClientTypesNumber;
    order: number;
    name: string;
    isDeceased: boolean;
    clientGuid: string;
}

export enum ClientTypesNumber {
    Undefined = 0,
    Taxpayer,
    Spouse,
    Partner,
    Partnership,
    ShareHolder
}