import * as React from 'react';
import { ELDocControlState } from '../../../core/domain/models/esign/Document';

interface FooterProps {
    onFinish(): void;
    onDecline(): void;
    onBack(): void;
    onSkip(): void;
    finishEnabled?: boolean;
    controlStatus: ELDocControlState;
}

interface FooterState {

}


export class Footer extends React.Component<FooterProps> {

    constructor(props: FooterProps, states: FooterState) {
        super(props, states);
    }

    public render() {

        const { finishEnabled, controlStatus } = this.props;

        return (<footer>

            <a className="rd-nav-link btn-decline">               
                <button data-test-auto='d50c4a06-bbe3-11eb-8529-0242ac130003' type="submit" onClick={this.props.onBack}
                    className="btn btn-default btn-default-extended btn-login text-green"
                    id="btnDecline"><i className={"fa fa-angle-left"}></i> Back</button>
            </a>
            {/*
            <a>
                <button type="submit" onClick={this.props.onDecline} data-test-auto='69D531CB-3A40-4263-8A0C-E1827BB644FA'
                    className="btn btn-danger btn-default-extended btn-login"
                    id="btnDecline">Decline to Sign</button>
            </a>
            */}
            {controlStatus && controlStatus != ELDocControlState.NoControles?
                <a className="rd-nav-link btn-finish">
                    <button data-test-auto='d50c492a-bbe3-11eb-8529-0242ac130003' type="submit" onClick={this.props.onFinish} disabled={!finishEnabled}
                        className="btn btn-primary btn-primary-extended btn-login pull-right"
                        id="btnFinish">Finish</button>
                </a>
                :''
            }

            {controlStatus && controlStatus != ELDocControlState.RequiredControlExists ?
                <a className="rd-nav-link btn-finish">
                    <button data-test-auto='d50c4ace-bbe3-11eb-8529-0242ac130003' type="submit" onClick={this.props.onSkip}
                        className="btn btn-primary btn-danger-extended btn-login pull-right"
                    id="btnSkip">Decline</button>
                </a>
                : ''
            }
            
            
        </footer>);
    }
}
