export interface ISelectItem {
    key: string,
    value: string
}

export enum DateType {
    Date,
    Month,
    Year
}

export enum OtpMode {
    None = 0,
    Email = 1,
    Text = 2
}