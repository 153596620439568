import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { FileViewer } from "./FileViewer";


interface PreviewUplodedFileModalProps {
    url: string,
    showPDFPreview: boolean;
    customClass: string | '';
    onHidePDFPreview: () => void;
    fileType: string | undefined;
    fileName: string;
}

export class PreviewUplodedFileModal extends React.Component<PreviewUplodedFileModalProps, {}>{
    render() {
        return <div>
            <Modal className={`file-preview-modal center-modal ${this.props.customClass}`} style={{ overflowY: "hidden" }} show={this.props.showPDFPreview} onHide={this.props.onHidePDFPreview}>
                <div style={{ height: 'auto', maxHeight: '800px' }} >
                    <Modal.Header closeButton data-test-auto="">
                        <Modal.Title>{this.props.fileName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FileViewer url={this.props.url} fileType={this.props.fileType} />
                    </Modal.Body>
                </div>
            </Modal>
        </div >
    }

}
