import { injectable } from "inversify";

export interface IFileUtilities {
    getExtension(fileName: string): string;
    isValidateSize(file: any, maxSize: number): boolean;
    isValidateExtension(file: any, expectedExtensions: string[]): boolean;
    download(url: string, fileName?: string): void;
    downloadObject(url: string): Promise<any>;
    normalizeFileName(fileName: string): string;
}

@injectable()
export class FileUtilities implements IFileUtilities {

    getExtension(fileName: string): string {
        return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    isValidateSize(file: any, maxSize: number): boolean {
        return file.size <= maxSize;
    }

    isValidateExtension(file: any, expectedExtensions: string[]): boolean {
        const fileExtension: string = this.getExtension(file);
        return expectedExtensions.indexOf(fileExtension.toLowerCase()) !== -1;
    }

    download(url: string, fileName?: string): void {
        let link: HTMLAnchorElement = document.createElement('a');
        link.href = url;
        if (fileName) {
            link.download = fileName;
        }
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            document.body.removeChild(link);
        }, 100);
    }

    downloadObject(url: string): Promise<any> {

        return new Promise(function (resolve, reject) {

            fetch(url).
                then(data => data.json())
                .then(function (data) {
                    resolve(data);

                }).catch(function (error) {
                    reject(error);
                });

        });
    }

    normalizeFileName(fileName: string): string {
        var pattern = new RegExp(/[^a-z A-Z 0-9. _\-]+/g);
        let newFileName = fileName.replace(pattern, '_');
        let extension = this.getExtension(fileName);
        return newFileName.replace(new RegExp('\\.' + extension + '$'), `.${extension.toLowerCase()}`)
    }
}