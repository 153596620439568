import * as React from "react";
import { RouteComponentProps } from 'react-router';
import Skeleton from "react-loading-skeleton";
import { IClientCoverState } from "../../store/CoverPage/CoverPageStore";
import * as AccountStore from '../../store/Common/AccountStore';
import * as  CoverPageStore from "../../store/CoverPage/CoverPageStore";
import * as OTPPageStore from "../../store/OTP/OTPStore";
import * as HelperStore from "../../store/Common/HelperStore";
import { History } from 'history';
import { RemoveStorageData } from "../Helper/HelperFunction";
import { SignProcessInfoConstants, PreviewConstants } from "../Common/Constants";
import { IHeaderInfoViewModel } from "../../core/domain/viewModels/IHeaderInfoViewModel";
import { UrlConstants } from "../../core/common/Constants";
import { OtpMode } from "../../core/domain/viewModels/ICommon";


export type CoverPageProps =
    {
        clientCoverState: IClientCoverState,
        headerInfo: IHeaderInfoViewModel;
        history: History;
    }
    & typeof CoverPageStore.actionCreators
    & typeof AccountStore.actionCreators
    & typeof OTPPageStore.actionCreators
    & typeof HelperStore.actionCreators
    & RouteComponentProps<{}>;



export class CoverPage extends React.Component<CoverPageProps> {
    private _param: any;
    constructor(props: CoverPageProps) {
        super(props);
    }

    componentDidMount() {
        this._param = this.props.match.params;
        this.props.requestHeaderInfo(this._param.clientId, false);
        this.props.setIsCoverPage(true);
        this.clearLocalSession();
    }


    navigate = () => {
        this._param = this.props.match.params;
        const clientId = this._param.clientId;
        if (this.props.headerInfo.isPreview) {
            this.props.history.push(`${UrlConstants.PreviewFlow}${clientId}`)
        } else {
            this.props.getAuthenticationType(clientId, this.movetoOtp, (errorStatus: CoverPageStore.ErrorStatus) => {
                this.props.history.push(`${UrlConstants.Error}${errorStatus}`);
            });
        }
    }

    movetoOtp = (clientId: string, otpmode: OtpMode) => {
        switch (otpmode) {
            case OtpMode.Text:
                this.props.history.push(`${UrlConstants.SMSOTPPage}${clientId}`);
                break;
            default:
                this.props.history.push(`${UrlConstants.EmailOTPPage}${clientId}`);
                break;
        }
    };

    clearLocalSession = () => {
        RemoveStorageData(SignProcessInfoConstants.SignProcessInfo);
        RemoveStorageData(SignProcessInfoConstants.LastVisitedPage);
        RemoveStorageData(PreviewConstants.IsPreviewMode);
        RemoveStorageData(PreviewConstants.IsClientView);
    }

    render() {

        return (
            <>
                {this.props.headerInfo.isLoading ? (
                    <Skeleton circle={false} height={"100vh"} width={"100vw"} />
                ) : (
                    <div className="cp-welcome-page">
                        <div
                            data-test-auto="NYG6C9FLML943538RXC2D9XDW8"
                            className="col-lg-12 col-md-12 col-sm-12 col-xs-12 cp-welcome-page-container">
                            <div className="cp-company-info-container">
                                {(this.props.headerInfo?.companyLogoPath?.length > 0 ?
                                    <img data-test-auto="VM1Y507X3KAMT985AC3UCPACMW" className="prepared-by-img" src={this.props.headerInfo?.companyLogoPath} />
                                    :
                                    <span data-test-auto="R3YCTEA0QLM4T6Z3495P4NJ1DR">{this.props.headerInfo?.companyName}</span>)}
                                <div className="cp-company-address-container">
                                    <span data-test-auto="U0D2DQTWM3UMX3DEVJCLGXRREM">
                                    </span>
                                    {/* {Model.ContactAddress?.StreetAddress1} {Model.ContactAddress?.City}  {Model.ContactAddress?.Zip} */}
                                </div>
                            </div>

                            <div className="cp-tax-client-info-container">
                                <div className="cp-tax-client-info-label">TO</div>
                                <div className="cp-tax-client-info-text">
                                    <span data-test-auto="JY3NUDQEG0D471NH0XPU52468D"
                                        className="cp-tax-year-header">
                                        Tax Organizer
                                    </span>
                                    <h6
                                        data-test-auto="W4GRMP71REY4Z1DV1YG7L6XDLH"
                                        className="cp-tax-client-name"
                                        title={this.props.headerInfo?.clientName}
                                    >
                                        {this.props.headerInfo?.clientName}
                                    </h6>
                                </div>
                            </div>

                            <button
                                className="btn btn-default btn-round cp-btn-organizer-continue"
                                onClick={() => this.navigate()}
                            >
                                Get Started
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default CoverPage;
