import * as React from "react";
import { Route, Switch } from "react-router-dom";
import RouteManager from "./components/Home/RouteMangerContainer";
import { Layout } from "./components/Layout/Layout";
import { ErrorPage } from "./components/Layout/ErrorPage";
import {
	TelemetryLogger,
	initializeAppInsights,
} from "./components/Common/Logger/AppInsights";
import CoverPageContainer from "./components/Cover/CoverPageContainer";
import { LoginLayout } from "./components/LoginLayout/Layout";
import InvalidPage from "./components/Layout/InvalidPage";
import OrganizerOTPContainer from "./components/OrganizerOTP/OrganizerOTPContainer";
import OrganizerSignFlowContainer from "./components/Home/OrganizerSignFlowContainer";
import UploadDocumentsContainer from "./components/UploadDocuments/UploadDocumentsContainer";
import CustomQuestionsContainer from "./components/CustomQuestions/CustomQuestionsContainer";
import PreviewCoverPageContainer from "./components/Preview/PreviewCoverPageContainer";
import PreviewSignFlowContainer from "./components/Preview/PreviewSignFlowContainer";
import ClientViewCoverPageContainer from "./components/ClientView/ClientViewCoverPageContainer";
import ClientViewContainer from "./components/ClientView/ClientViewContainer";
import { Common } from "./components/Common/Constants";
import SignContainer from "./components/Organizer/SignContainer";
import { SigningComplete } from "./components/Organizer/SignComplete/SigningComplete";
import EngagementLetterSignContainer from "./components/EngagementLetter/EngagementLetterSignContainer";
import SMSOTPContainer from "./components/OrganizerSMSOTP/SMSOTPContainer";
import { initializeAxios } from "./core/Services/dataAccess/DataService.Axios";
import { AxiosResponse } from "axios";
import UnsupportedDevicePage from "./components/Layout/UnsupportedDevicePage";
import BlockedClientPage from "./components/Layout/BlockedClientPage";
import { container } from "./startup/inversify.config";
import { ILoader } from "./core/utilities/ui/Loader";
import { TYPES } from "./startup/types";

export const logger = TelemetryLogger.getInstance();
const loader = container.get<ILoader>(TYPES.ILoader);
export const routes = (
	<Switch>
		<Route exact path="/InvalidLink" component={InvalidPage} />
		<Route exact path="/UnsupportedDevice" component={UnsupportedDevicePage} />
		<Route exact path="/BlockedClient" component={BlockedClientPage} />
		<Route exact path="/InvalidLink" component={InvalidPage} />
		<Route exact path="/error/:errorStatus" component={ErrorPage} />
		<ApiRouteWrapper exact path="/:clientId" />
		<RouteWrapper
			exact
			path="/coverpage/Index/:clientId"
			component={CoverPageContainer}
			layout={LoginLayout}
		/>
		<RouteWrapper
			exact
			path="/previewcoverpage/:clientId"
			component={PreviewCoverPageContainer}
			layout={LoginLayout}
		/>
		<RouteWrapper
			exact
			path="/previewflow/:clientId"
			component={PreviewSignFlowContainer}
			layout={Layout}
		/>
		<RouteWrapper
			exact
			path="/clientViewcoverpage/:clientId"
			component={ClientViewCoverPageContainer}
			layout={LoginLayout}
		/>
		<RouteWrapper
			exact
			path="/clientView/:clientId"
			component={ClientViewContainer}
			layout={Layout}
		/>
		<RouteWrapper
			exact
			path={Common.Routes.Organizer}
			component={SignContainer}
			layout={Layout}
		/>
		<RouteWrapper
			exact
			path="/signingcomplete/:clientId"
			component={SigningComplete}
			layout={Layout}
		/>
		<RouteWrapper
			exact
			path="/engagementletter/sign/:clientId"
			component={EngagementLetterSignContainer}
			layout={Layout}
		/>
		<RouteWrapper
			exact
			path="/organizer/login/email/:clientId"
			component={OrganizerOTPContainer}
			layout={LoginLayout}
		/>
		<RouteWrapper
			exact
			path="/organizer/login/sms/:clientId"
			component={SMSOTPContainer}
			layout={LoginLayout}
		/>
		<RouteWrapper
			exact
			path="/organizer/login/email/:clientId"
			component={OrganizerOTPContainer}
			layout={LoginLayout}
		/>
		<RouteWrapper
			exact
			path="/organizer/:clientId"
			component={OrganizerSignFlowContainer}
			layout={Layout}
		/>
		<RouteWrapper exact path="/error/:errorStatus" component={ErrorPage} />
		<RouteWrapper
			exact
			path="/uploaddocuments/:clientId"
			component={UploadDocumentsContainer}
			layout={Layout}
		/>
		<RouteWrapper
			exact
			path="/customQuestions/:clientId"
			component={CustomQuestionsContainer}
			layout={Layout}
		/>
		<Route exact path="*" component={InvalidPage} />
	</Switch>
);

function RouteWrapper({ component: Component, layout: Layout, ...rest }: any) {
	initializeAppInsights(rest.computedMatch.params.clientId);
	return (
		<Route
			{...rest}
			render={(props) =>
				Layout ? (
					<Layout {...props}>
						<Component {...props} />
					</Layout>
				) : (
					<Component {...props} />
				)
			}
		/>
	);
}
function ApiRouteWrapper({ ...rest }: any) {
	React.useEffect(() => {
		let path: string = rest.computedMatch.url;
		if (path && path[0] === "/") {
			path = path.substring(1);
		}
		loader.show();
		initializeAxios()
			.get<any>(`/api/Taxpayer/` + path)
			.then(function (response: AxiosResponse<any>) {
				loader.hide();
				window.location.href = response.data;
			})
			.catch(function (error: any) {
				loader.hide();
				console.log(error);
			});
	}, []);

	return <></>;
}
