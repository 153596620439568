import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../index';
import { actionTypes } from '../ActionTypes';
import { ITaxDocumentModel, initialTaxDocumentModel } from '../../core/domain/models/ITaxDocumentModel';
import { initializeAxios } from '../../core/Services/dataAccess/DataService.Axios'
import { AxiosResponse } from 'axios';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { IDocument } from '../../core/domain/models/esign/Document';
import * as Constants from '../../components/Common/Constants';
import { ILoader } from '../../core/utilities/ui/Loader';
import { TYPES } from '../../startup/types';
import { container } from '../../startup/inversify.config';
import { ISignerModel } from '../../core/domain/models/ISignerModel';
import { ISignerControlDataModal } from '../../core/domain/viewModels/ISignerControlDataModal';
import { DocumentAdapter, IDocumentAdapter } from '../../core/Services/adapters/ControlDataAdapter';
import { DocumentSignatureDataViewModel } from '../../core/domain/viewModels/IDocumentSignatureDataViewModel';
import { KBATransactionResultType, SignatureType, ClientType } from '../../core/common/Enums';
import { IKBATransactionResponse, IBaseServiceResponse, initialKBAResponse, IKBAAnswers } from '../../core/domain/models/IKBA';
import { StatusCode } from 'src/core/domain/models/Organizer/Organizer';

interface RequestEsignDocumentAction {
    type: actionTypes.ESIGN_REQUEST;
}

interface ResponseEsignDocumentAction {
    type: actionTypes.ESIGN_RESPONSE;
    data: ITaxDocumentModel;
}

interface FailureEsignDocumentAction {
    type: actionTypes.ESIGN_FAILURE;
    data: ITaxDocumentModel;
}

interface RequestSignatureControlsDataAction {
    type: actionTypes.SIGNATURE_CONTROLS_DATA_REQUEST;
}

interface ResponseSignatureControlsDataAction {
    type: actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE;
    data: IDocument[];
}

interface FailureSignatureControlsDataAction {
    type: actionTypes.SIGNATURE_CONTROLS_DATA_FAILURE;
}

interface RequestCADocumentAction {
    type: actionTypes.CA_REQUEST;
}

interface ResponseCADocumentAction {
    type: actionTypes.CA_RESPONSE;
    data: IKBATransactionResponse;
}

interface FailureCADocumentAction {
    type: actionTypes.CA_FAILURE;
    data: IKBATransactionResponse;
}

interface RequestSignerDetailsAction {
    type: actionTypes.SIGNER_REQUEST;
}

interface ResponseSignerDetailsAction {
    type: actionTypes.SIGNER_RESPONSE;
    data: ISignerModel[];
}

interface FailureSignerDetailsAction {
    type: actionTypes.SIGNER_FAILURE;
    data: ISignerModel[];
}

interface RequestEsignSubmitAction {
    type: actionTypes.SIGN_SUBMIT_REQUEST;
}

interface ResponseEsignSubmitAction {
    type: actionTypes.SIGN_SUBMIT_RESPONSE;
    data: IDocument[];
}

interface FailureEsignSubmitAction {
    type: actionTypes.SIGN_SUBMIT_FAILURE;
    data: IDocument[];
}

export interface EsignState {
    data: ITaxDocumentModel;
    controlsData: IDocument[];
    signerDetails: ISignerModel[];
}

export const initialEsignState: EsignState = {
    data: initialTaxDocumentModel,
    controlsData: [],
    signerDetails: []
}

export interface UpdateSignatureSettingsAction {
    type: actionTypes.UPDATE_DOCUMENT_SIGNATURE_SETTINGS_REQUEST;
    clientId: string;
    signatureType: number;
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = ResponseEsignDocumentAction
    | RequestEsignDocumentAction
    | FailureEsignDocumentAction
    | RequestSignatureControlsDataAction
    | ResponseSignatureControlsDataAction
    | FailureSignatureControlsDataAction
    | UpdateSignatureSettingsAction
    | RequestCADocumentAction
    | ResponseCADocumentAction
    | FailureCADocumentAction
    | RequestSignerDetailsAction
    | ResponseSignerDetailsAction
    | FailureSignerDetailsAction;

const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {

    requestSigners: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.SIGNER_REQUEST });
        return initializeAxios().get<ITaxDocumentModel>('/api/Esign/GetAllSigners/' + clientGuid)
            .then(function (response: AxiosResponse<ISignerModel[]>) {
                dispatch({
                    type: actionTypes.SIGNER_RESPONSE, data: response.data
                });
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.Signers,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.SIGNER_FAILURE, data: state.esignData.signerDetails });
            });
    },

    clientAuthentication: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.CA_REQUEST });
        return initializeAxios().get<IBaseServiceResponse>('/api/Esign/ClientAuthentication/' + clientGuid)
            .then(function (response: AxiosResponse<IBaseServiceResponse>) {
                //We may need to refactor this further where we can take out the concrete IKBATransactionResponse below and return IBaseServiceResponse.
                //To achieve it we need a separate reducer to handle IKBATransactionResponse, separate actionCreators and separate DispatchActions
                const kbaResult = response.data as IKBATransactionResponse;
                if (kbaResult.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.error]) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: kbaResult.transactionDetails,
                        statusType: StatusType.Error
                    });
                }
                dispatch({
                    type: actionTypes.CA_RESPONSE, data: kbaResult
                });
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.ClientAuthentication,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.CA_FAILURE, data: state.kbaData });
            });
    },

    clientValidation: (clientGuid: string, data: IKBAAnswers, successCallback: () => void, failureCallback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.CA_REQUEST });
        const options = {
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        return initializeAxios().post<IBaseServiceResponse>('/api/Esign/ClientValidation/' + clientGuid, data, options)
            .then(function (response: AxiosResponse<IBaseServiceResponse>) {
                //We may need to refactor this further where we can take out the concrete IKBATransactionResponse below and return IBaseServiceResponse.
                //To achieve it we need a separate reducer to handle IKBATransactionResponse, separate actionCreators and separate DispatchActions
                const kbaResult = response.data as IKBATransactionResponse;
                if (kbaResult.transactionStatus.toString() == KBATransactionResultType[KBATransactionResultType.failed].toString() ||
                    kbaResult.transactionStatus.toString() == KBATransactionResultType[KBATransactionResultType.error].toString() ||
                    kbaResult.transactionStatus.toString() == KBATransactionResultType[KBATransactionResultType.retryExceeded].toString()) {
                    failureCallback();
                }
                else {
                    successCallback();
                }
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                failureCallback();
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.ClientValidation,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.CA_FAILURE, data: state.kbaData });
            });
    },

    updateSpouseMail: (clientGuid: string, id: string, newMail: string, type: ClientType, step?: boolean, callback?: (step: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        loader.show();
        dispatch({ type: actionTypes.ESIGN_REQUEST });
        const data = { clientId: id, emailAddress: newMail, clientType: type };
        return initializeAxios().put('/api/Esign/UpdateEmailAddress/' + clientGuid, data)
            .then(function (response: AxiosResponse<ITaxDocumentModel>) {
                loader.hide();
                if (callback) {
                    callback(step ? step : false);
                }
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.UpadateSpouseMail,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.ESIGN_FAILURE, data: state.esignData.data });
            });
    },

    updateDOB: (clientGuid: string, id: string, dob: string, step: boolean, callback: (step: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        loader.show();
        dispatch({ type: actionTypes.ESIGN_REQUEST });
        const data = { clientId: id, dateOfBirth: dob };
        return initializeAxios().put('/api/Esign/UpdateDateOfBirth/' + clientGuid, data)
            .then(function (response: AxiosResponse<ITaxDocumentModel>) {
                loader.hide();
                callback(step);
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.UpdatingMobileNumber,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.ESIGN_FAILURE, data: state.esignData.data });
            });
    },

    requestSignatureControls: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_REQUEST });
        return initializeAxios().get<ITaxDocumentModel>('/api/Esign/GetDocumentSignatureDataAsync/' + clientGuid)
            .then(function (response: AxiosResponse<DocumentSignatureDataViewModel[]>) {
                const documentAdapter: IDocumentAdapter = DocumentAdapter.create();

                dispatch({
                    type: actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE, data: documentAdapter.convertToClientModel(response.data)
                });
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.SignedDocumentError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_FAILURE });
            });
    },

    requestPreviewSignatureControls: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_REQUEST });
        return initializeAxios().get<ITaxDocumentModel>('/api/Esign/GetPreviewDocumentSignatureDataAsync/' + clientGuid)
            .then(function (response: AxiosResponse<DocumentSignatureDataViewModel[]>) {
                const documentAdapter: IDocumentAdapter = DocumentAdapter.create();

                dispatch({
                    type: actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE, data: documentAdapter.convertToClientModelWithDisable(response.data)
                });
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.SignedDocumentError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_FAILURE });
            });
    },

    updateDocumentSignatureSettingModel: (clientId: string, signatureType: number, callback: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        loader.show();
        dispatch({ type: actionTypes.UPDATE_DOCUMENT_SIGNATURE_SETTINGS_REQUEST, clientId: clientId, signatureType: signatureType });
        return initializeAxios().put<number>('api/Esign/UpdateEsignFormSelectionAsync/' + signatureType + '/' + clientId)
            .then(function (response: AxiosResponse<number>) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: signatureType === SignatureType.ESign ?
                        Constants.SiginingConstants.StatusMessage.DocumentSignatureSelectedAsEsign :
                        Constants.SiginingConstants.StatusMessage.DocumentSignatureSelectedAsManualSign,
                    statusType: StatusType.Success
                })
                loader.hide();
                callback();
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.DocumentSignatureSetting,
                    statusType: StatusType.Error
                });
            });
    },

    updateSignatureControlsData: (data: IDocument[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE, data: data
        });
    },

    sign: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let signatureData: ISignerControlDataModal = DocumentAdapter.create().convertToServerModel(documentData);
        signatureData.clientGuid = clientId;
        initializeAxios().postJson<boolean>(signatureData, 'api/Esign/SignAsync/' + clientId)
            .then(function (response: AxiosResponse<boolean>) {
                const { data } = response;
                callback(data);
                loader.hide();
            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error,
                    statusType: StatusType.Error
                });
            });
    }
}

export const reducer: Reducer<EsignState> = (state: EsignState = initialEsignState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.ESIGN_REQUEST:
            currentState.data = initialTaxDocumentModel;
            return currentState;
        case actionTypes.ESIGN_RESPONSE:
            currentState.data = action.data;
            return currentState;
        case actionTypes.ESIGN_FAILURE:
            currentState.data = action.data
            return currentState;
        case actionTypes.SIGNATURE_CONTROLS_DATA_REQUEST:
            currentState.controlsData = [];
            return currentState;
        case actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE:
            currentState.controlsData = action.data;
            return currentState;
        case actionTypes.SIGNER_REQUEST:
            return initialEsignState;
        case actionTypes.SIGNER_RESPONSE:
            currentState.signerDetails = action.data;
            return currentState;
        case actionTypes.SIGNER_FAILURE:
            currentState.signerDetails = action.data
            return currentState;
        default:
            return currentState || initialEsignState;
    }
};

export const kbaReducer: Reducer<IKBATransactionResponse> = (state: IKBATransactionResponse = initialKBAResponse, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.CA_REQUEST:
            return initialKBAResponse;
        case actionTypes.CA_RESPONSE:
            return action.data;
        case actionTypes.CA_FAILURE:
            return currentState;
        default:
            return currentState || initialKBAResponse;
    }
};