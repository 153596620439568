
export interface ISignProcessInfoViewModel {
	clientId: string,
	lastUpdateOn: Date,
  visitedSteps: any[]
}

export const initialSignProcessInfoViewModel: ISignProcessInfoViewModel = {
	clientId: "",
	lastUpdateOn: new Date(),
	visitedSteps:[]
}
