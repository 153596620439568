import * as React from 'react';
import { StatusCode, UploadedDocument } from '../../../core/domain/models/Organizer/Organizer';
import { BatchUploadedStatus, UploadedFile, ConvertedUploadedFileModel } from '../../../core/domain/models/SourceDocument/SourceDocument';
import { FileUpload, UploadStatus } from '../../Common/FileUpload/FileUpload';
import { UploadHeader } from './UploadHeader';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { SourceDocumentTable } from './SourceDocumentTable';
import { IFileUploadUtilities } from '../../../core/utilities/FileUpload/FileUploadUtilities';
import { CustomEventLog, OrganizerUploadedDocuments, TaxPayerPage, UploadFileWarningMessage } from '../../Common/Constants';
import {
    convertToUploadedFileModel,
    handleDuplicates,
    ConvertToUploadedFileModel,
    GetFileMagicNumber,
    validateWordAndPDFFileContent
} from '../../../components/Helper/UploadDocumentHelper';
import { MarsNotifier } from '../../Common/Notification/MarsNotifier';
import { AxiosResponse } from 'axios';
import { ConflictErrorMessage, CustomResponse } from '../../../store/UploadedDocument/UploadedDocumentStoreModels';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { logger } from '../../../routes';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';
import { initializeAxios } from '../../../core/Services/dataAccess/DataService.Axios';
import { AddUploadedFileToStorage, AddDeletedFilesToStorage, getFileSize, RedirectToDashBoard } from '../../Helper/HelperFunction';


export interface SourceDocumentsProps {
    clientId: string;
    existinguploadedDocuments: UploadedDocument[];
    addDocument(clientId: string, signedDocument: UploadedDocument, successCallback?: () => void, failureCallback?: () => void): void;
    deleteUploadedDocument(clientId: string, id: number, successCallback?: () => void): void;
    requestUploadedDocuments(clientId: string, forceRefresh: boolean): void;
    updateUploadedStatus(status: BatchUploadedStatus): void;
    formName: string;
    disabled: boolean;
    formId?: number;
    changeStatusToCompleted: () => void;
    isSourceDocumentUploadCompleted: boolean;
}

export interface SourceDocumentsState {
    files: UploadedFile[];
    existingFiles: UploadedFile[];
}

const fileUploadUtilities = container.get<IFileUploadUtilities>(TYPES.IFileUploadUtilities);
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class SourceDocumentsUpload extends React.Component<SourceDocumentsProps, SourceDocumentsState> {
    constructor(props: SourceDocumentsProps) {
        super(props);

        this.state = {
            files: [],
            existingFiles: ConvertToUploadedFileModel(this.props.existinguploadedDocuments)

        }
    }

    componentWillReceiveProps(nextProps: SourceDocumentsProps, nextState: SourceDocumentsState) {

        let existingFiles: UploadedFile[] = [];
        let files: UploadedFile[] = this.state.files;
        nextProps.existinguploadedDocuments.forEach((item: UploadedDocument, index: number) => {

            existingFiles.push({
                id: item.id,
                uploadedOn: item.uploadedOn,
                name: item.fileName,
                file: undefined,
                status: UploadStatus.Uploaded,
                uploadPercentage: 100,
                documentGuid: item.documentGuid,
                documentId: item.documentId,
                taxYear: item.taxYear
            });

            let file = this.state.files.find(i => i.name == item.fileName);
            if (file) {
                file.id = item.id;
            }
        });

        this.setState({
            existingFiles: existingFiles,
            files: files
        });
    }

    private handleAddFiles = (files: any) => {
        if (!this.props.disabled) {
            let filePrefix = this.props.formName + '_';
            let convertedUploadedFileModel: ConvertedUploadedFileModel = convertToUploadedFileModel(files, filePrefix);

            if (convertedUploadedFileModel.isFileSizeLimitExceeded) {
                MarsNotifier.Warning(UploadFileWarningMessage(
                    convertedUploadedFileModel.counterFileSizeLimitFailed,
                    files.length,
                    OrganizerUploadedDocuments.SupportedUploadFileSizeWarningMessage), null);
            }
            if (convertedUploadedFileModel.isUnsupportedFileExtension) {
                MarsNotifier.Warning(UploadFileWarningMessage(
                    convertedUploadedFileModel.counterUnsupportedFileExtensionFailed,
                    files.length,
                    OrganizerUploadedDocuments.SupportedFilesMessage), null);
            }

            convertedUploadedFileModel.uploadedFiles = handleDuplicates(
                convertedUploadedFileModel.uploadedFiles,
                this.state.existingFiles);

            this.UpdateExistingFiles(convertedUploadedFileModel.uploadedFiles);

            if (convertedUploadedFileModel.uploadedFiles.length > 0) {
                this.validateFileContent(convertedUploadedFileModel.uploadedFiles).then((result) => {
                    if (result.length > 0) {
                        this.setState((prevState: SourceDocumentsState) => ({
                            files: prevState.files.concat(result)
                        }), () => {

                            this.getUploadLink();
                        });
                    }
                });
            }
        }
    }

    private validateFileContent = (uploadData: UploadedFile[]): Promise<any> => {
        let promise: any = null;
        const _uploadData: UploadedFile[] = Object.assign({}, uploadData);
        for (let i = 0; i < uploadData.length; i++) {
            let uploadedFile = uploadData[i].file;
            promise = new Promise((resolve) => {
                GetFileMagicNumber(uploadedFile).then((result) => {

                    if (!validateWordAndPDFFileContent(result)) {
                        if (result == "") {
                            MarsNotifier.Warning(OrganizerUploadedDocuments.SupportedFilesSizeMessage, null);
                        } else {
                            MarsNotifier.Warning(OrganizerUploadedDocuments.SupportedFilesMessage, null);
                        }
                        const index = uploadData.findIndex(x => x.name == _uploadData[i].name)
                        uploadData.splice(index, 1)
                    }

                    resolve(result)
                });
            });
        }
        return promise.then(() => { return uploadData })
    }

    private uploadProgress = (progress: number, file: any) => {

        const tmpFile: UploadedFile | undefined = this.state.files.find(x => x.file && x.file.upload.uuid == file.file.upload.uuid);

        if (tmpFile) {
            tmpFile.uploadPercentage = progress;

            this.setState((prevState: SourceDocumentsState) => ({
                files: prevState.files
            }));
        }
    }

    private getUploadLink = () => {

        let _self = this;
        const { clientId, updateUploadedStatus } = this.props;
        let fileUploaded: number = 0;
        let fileInError: number = 0;
        const uploadFiles: number = this.state.files.filter(x => x.status == UploadStatus.Wait).length;

        updateUploadedStatus(BatchUploadedStatus.Uploading);

        this.state.files.filter(x => x.status == UploadStatus.Wait).forEach((file: UploadedFile, index: number) => {

            file.status = UploadStatus.Initiating;
            file.name = file.name.trim();
            initializeAxios().get(`/api/OrganizerAdditionalDocument/GetAdditionDocumentUploadLink/${encodeURIComponent(clientId)}?fileName=${file.name}`)
                .then((response: AxiosResponse<CustomResponse<any>>) => {

                    if (response.data.statusCode === StatusCode.Conflict) {
                        fileInError++;
                        if (fileInError === uploadFiles) {

                            this.setState((prevState: SourceDocumentsState) => ({
                                files: prevState.files.filter(x => x.status !== UploadStatus.Initiating && x.status !== UploadStatus.Wait)
                            }));

                            this.props.changeStatusToCompleted();

                            updateUploadedStatus(BatchUploadedStatus.Completed);

                        }
                        return;
                    }
                    this.setState((prevState: SourceDocumentsState) => ({
                        files: prevState.files
                    }), () => {

                        fileUploadUtilities.upload(file.file,
                            response.data.data.sas,
                            file.name ? file.name : "",
                            this.uploadProgress,
                            () => {
                                fileUploaded++;
                                this.handleAddDocument(file.name, getFileSize(file.file.size), uploadFiles, fileUploaded)
                            });
                    });

                }, (error: any) => {
                    if (error?.response?.status === StatusCode.Locked) {
                        RedirectToDashBoard(clientId, dialogBox);
                    }
                    else {
                        _self.state.files.splice(index, 1);

                        this.setState((prevState: SourceDocumentsState) => ({
                            files: prevState.files
                        }));
                    }
                });
        });
    }

    private UpdateExistingFiles(uploadedFiles: UploadedFile[]) {
        let existingFiles: UploadedFile[] = this.state.existingFiles;
        let files: UploadedFile[] = this.state.files;
        uploadedFiles.forEach((item: UploadedFile) => {
            existingFiles.push(item);
        });

        this.setState({
            existingFiles: existingFiles,
            files: files
        });
    }

    private handleAddDocument = (fileName: string, fileSize: string, uploadFiles: number, fileUploaded: number) => {
        const { clientId, addDocument, requestUploadedDocuments, updateUploadedStatus, formId } = this.props;
        let uploadedDocument: UploadedDocument = UploadedDocument.create(fileName, fileSize, formId);
        AddUploadedFileToStorage(fileName);

        uploadFiles == fileUploaded ? addDocument(this.props.clientId,
            uploadedDocument, () => {

                const updatedFiles = this.state.files.map(x => {
                    if (x.name === fileName) {
                        x.status = UploadStatus.Uploaded;
                    }
                    return x;
                });

                this.setState({ files: updatedFiles });

                requestUploadedDocuments(clientId, true);
                updateUploadedStatus(BatchUploadedStatus.Completed);
            },
            () => {
                const files = this.state.files;
                let file = files.find(f => f.name === fileName && f.status != UploadStatus.Uploaded);
                if (file) {
                    file.status = UploadStatus.Error
                }

                this.setState({ files: files });

            }) : (() => {

                const updatedFiles = this.state.files.map(x => {
                    if (x.name === fileName) {
                        x.status = UploadStatus.Uploaded;
                    }
                    return x;
                });

                this.setState({
                    files: updatedFiles
                }, () => {
                    addDocument(clientId, uploadedDocument);
                });
            })();
    }

    private handleDeleteDocument = (clientId: string, id: number) => {

        const { deleteUploadedDocument, requestUploadedDocuments } = this.props;
        deleteUploadedDocument(clientId, id, () => {
            AddDeletedFilesToStorage(this.state.files, id);
            const deletedSourceDocumentName = this.state.files.filter(c => c.id == id)[0]?.name;
            let files: UploadedFile[] = this.state.files.filter((value, index) => value.id != id);
            this.setState({ files: files });
            requestUploadedDocuments(clientId, true);
            const traceEvent: IEventTelemetry = {
                name: `${CustomEventLog.DeleteSourceDocument}`,
                properties: {
                    Page: TaxPayerPage.AddSourceDocumentsPopup,
                    SourceDocumentId: id,
                    SourceDocumentName: deletedSourceDocumentName
                }
            };
            logger.trackEvent(traceEvent);
        });
    }

    public render() {

        const { files } = this.state;
        const { isSourceDocumentUploadCompleted, disabled, clientId } = this.props;
        const fileUploadCssClass: string = files.length > 0 ? "" : "with-border";

        return (<div className="row" title={isSourceDocumentUploadCompleted ? ConflictErrorMessage : ''}>
            <div className={"source-document-upload-left-box text-center"} style={{ width: '20%' }}>
                <div className={"upload-documents with-border"} id={"document-upload"} data-test-auto="47E287CF-BAC3-4E19-9871-48C74718175F">
                    <FileUpload id={"uploaded-document"} cssClass={fileUploadCssClass} onAddFiles={this.handleAddFiles} disabled={disabled}>
                        <UploadHeader minimized={false} disabled={disabled} />
                    </FileUpload>
                </div>
            </div>

            <div className={"source-document-upload-right-box"}
                style={{ width: '80%' }}
                data-test-auto="69A73DBA-8A79-4854-B697-6D6325DB0F5A">
                <SourceDocumentTable
                    clientId={clientId}
                    files={files}
                    deleteUploadedDocument={this.handleDeleteDocument}
                    isSourceDocumentUploadCompleted={isSourceDocumentUploadCompleted}
                />
            </div>
        </div>);
    }
}