import { connect } from 'react-redux';
import { ClientInfo, OrganizerFormDataViewModel } from '../../core/domain/models/Organizer/Organizer';
import { ApplicationState } from "../../store/index";
import { actionCreators as OrganizerMetadataStore } from '../../store/Organizer/OrganizerMetadataStore';
import { actionCreators as OrganizerStore } from '../../store/Organizer/OrganizerStore';
import { actionCreators as OrganizerClientStore } from '../../store/Organizer/OrganizerClientStore';
import { actionCreators as UploadedDocumentStore } from '../../store/UploadedDocument/UploadedDocumentStore';
import { Sign } from "./Sign";
import { ClientType } from '../../core/common/Enums';

function mapStateToProps(state: ApplicationState) {
    return {
        organizerDocument: state.organizerDocument,
        blobForms: (state.organizerDocument as any).blobForms,
        organizerMetadata: state.organizerMetadata,
        uploadedDocuments: (state.organizerDocument as any).uploadedDocuments,
        clientInfo: (state.clientInfo as any).data,
        uploadedDocumentStore: state.uploadedDocumentStore
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestOrganizerForms: (clientId: string) => dispatch(OrganizerStore.requestOrganizerForms(clientId)),
        requestOrganizerControls: (clientId: string) => dispatch(OrganizerStore.requestOrganizerControls(clientId)),
        submitSignedDocument: (clientId: string,
            organizerForms: OrganizerFormDataViewModel,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.submitSignedDocument(clientId, organizerForms, successCallback, failureCallback)),
        finishDocumentSign: (clientId: string,
            organizerForms?: OrganizerFormDataViewModel,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.finishDocumentSign(clientId, organizerForms, successCallback, failureCallback)),
        requestOrganizerMetadata: (clientId: string, successCallback?: () => void) => dispatch(OrganizerMetadataStore.requestOrganizerMetadata(clientId, successCallback)),
        AddNotes: (clientId: string,
            formId: number,
            notes: string,
            pageNo: number,
            successCallback?: () => void,
            failureCallback?: () => void,
            failureCallbackForOrganizerUnavailable?: () => void) => dispatch(OrganizerStore.AddNotes(clientId, formId, notes, pageNo, successCallback, failureCallback, failureCallbackForOrganizerUnavailable)),
        DeleteNotes: (
            clientId: string,
            FormId: number,
            successCallback?: () => void,
            failureCallback?: () => void,
            failureCallbackForOrganizerUnavailable?: () => void) => dispatch(OrganizerStore.DeleteNotes(clientId, FormId, successCallback, failureCallback, failureCallbackForOrganizerUnavailable)),
        addUploadedDocument: (clientId: string,
            uploadedDocument: any,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.addUploadedDocument(clientId, uploadedDocument, successCallback, failureCallback)),

        deleteUploadedDocument: (clientId: string,
            id: number,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.deleteUploadedDocument(clientId, id, successCallback, failureCallback)),

        getSourceDocumentMetadataAsync: (clientId: string,
            id: number,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(UploadedDocumentStore.getSourceDocumentMetadataAsync(clientId, id, successCallback, failureCallback)),

        requestUploadedDocuments: (clientId: string,
            forceRefresh?: boolean) => dispatch(OrganizerStore.requestUploadedDocuments(clientId, forceRefresh)),

        updateWarningPreference: (clientGuid: string,
            hideWarning: boolean,
            callback?: () => void) => dispatch(OrganizerClientStore.updateWarningPreference(clientGuid, hideWarning, callback)),

        sendForReview: (clientGuid: string,
            clientType: ClientType,
            callback: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.sendForReview(clientGuid, clientType, callback, failureCallback)),

        requestSpouseInfo: (clientId: string, forceRefresh?: boolean) => dispatch(OrganizerClientStore.requestSpouseInfo(clientId, forceRefresh)),

        updateSpouseEmail: (clientId: string,
            clientInfo: ClientInfo,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerClientStore.updateSpouseEmail(clientId, clientInfo, successCallback, failureCallback)),

        reviewOrganizer: (clientId: string,
            callback?: () => void) => dispatch(OrganizerStore.reviewOrganizer(clientId, callback)),

        ignoreReviewOrganizer: (clientId: string,
            callback?: () => void) => dispatch(OrganizerStore.ignoreReviewOrganizer(clientId, callback)),

        changeStatusToCompleted: () =>
            dispatch(UploadedDocumentStore.setUploadedDocumentCompleted()),

        requestUploadedDocumentStatus: (clientId: string) =>
            dispatch(UploadedDocumentStore.requestUploadedDocumentStatus(clientId)),

        setUploadedDocumentError: () =>
            dispatch(UploadedDocumentStore.setUploadedDocumentError()),

        resetUploadedDocumentError: () =>
            dispatch(UploadedDocumentStore.resetUploadedDocumentError()),

        setUploadedDocumentCompleted: () =>
            dispatch(UploadedDocumentStore.setUploadedDocumentCompleted())


    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Sign);
