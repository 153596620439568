import { AxiosDataService } from "../core/Services/dataAccess/DataService.Axios";
import { IDataService } from "../core/Services/dataAccess/abstraction/IDataService";
import { IOrganizerService, OrganizerService } from "../core/Services/OrganizerService/OrganizerService";

export class Factory {

    public static createDataService(): IDataService {
        return new AxiosDataService(window.location.href.substr(window.location.href.lastIndexOf('/') + 1))
    } 

    public static createOrganizerService(): IOrganizerService {
        return new OrganizerService(this.createDataService()); 
    } 


    


}