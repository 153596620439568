import { actionTypes } from './../ActionTypes';
import { NotificationAction } from '../Common/NotificationStore';
import { ClientTypesNumber } from '../../core/domain/models/ISignerModel';


export enum SourceDocumentStatus {
    None = 0,
    AwaitingUpload = 1,
    Uploaded = 2,
    Downloaded = 3,
    Completed = 4
}

export interface UploadedDocumentStoreState {
    loading: boolean;
    isCompleted: boolean;
    uploadedDocumentStatus: UploadedDocumentStatus | undefined;
    isError: boolean;
    errorMessage: string;
    sourceDocumentMetaData: any;
}

export interface UploadedDocumentStatus {
    id: number;
    documentId: number;
    status: SourceDocumentStatus;
    createdOn: Date | undefined;
    updatedOn: Date | undefined;
    actedBy: ClientTypesNumber;
    isSourceDocumentCompleted: boolean;
}

export const initialUploadedDocumentStatus: UploadedDocumentStatus = {
    actedBy: ClientTypesNumber.Undefined,
    createdOn: new Date(),
    updatedOn: new Date(),
    documentId: 0,
    id: 0,
    status: SourceDocumentStatus.None,
    isSourceDocumentCompleted: false
}

export const initialUploadedDocumentStoreState: UploadedDocumentStoreState = {
    isCompleted: false,
    loading: false,
    uploadedDocumentStatus: initialUploadedDocumentStatus,
    isError: false,
    errorMessage: "",
    sourceDocumentMetaData: {},
}

export interface ErrorAction {
    type: actionTypes.UPLOADED_DOCUMENT_ERROR;
    isError: boolean;
    errorMessage: string;
}

export interface ErrorResetAction {
    type: actionTypes.UPLOADED_DOCUMENT_ERROR_RESET;
}

export interface UploadedDocumentLoaderAction {
    type: actionTypes.UPLOADED_DOCUMENT_LOADER,
    loading: boolean;
}

export interface SetSourceDocumentMetadata {
    type: actionTypes.SET_SOURCE_DOCUMENT_META_DATA,
    sourceDocumentMetaData: any;
}

export interface ReceiveUploadedDocumentStatus {
    type: actionTypes.RECEIVE_UPLOADED_DOCUMENT_STATUS,
    payload: UploadedDocumentStatus | undefined
}

export interface ReceiveUploadedDocumentCompletedStatus {
    type: actionTypes.RECEIVE_UPLOADED_DOCUMENT_COMPLETED,
    completed: boolean
}

export type KnownAction =
    DispatchAction |
    NotificationAction;

export type DispatchAction =
    UploadedDocumentLoaderAction |
    ReceiveUploadedDocumentStatus |
    ReceiveUploadedDocumentCompletedStatus |
    SetSourceDocumentMetadata |
    ErrorAction |
    ErrorResetAction;


export interface CustomResponse<T> {
    statusCode: number;
    data: T;
}

export const ConflictErrorMessage = "Source document upload has been completed.";


