import { ClientType } from "../../../common/Enums";

export interface IQuestionnaireState {
	isLoading: boolean;
	formData: IFormData;
	intialFormData: IFormData;
}

export interface IFormData {
	formId: number;
	title: string;
	description: string;
	sections: ISections[];
}


export interface ISections {
	sectionId: number;
	sectionName: string;
	order: number;
	sectionItems: ISectionItems[];
}

export interface ISectionItems {
	sectionItemId: number;
	order: number;
	questionTitle: string;
	isRequired: boolean;
	questionType: QuestionType;
	isInValid?: boolean;
	answer: IAnswerModel;
	choices: IChoiceModel[];
}
export enum QuestionType {
    PARAGRAPH = 1,
    YESNO,
    MULTICHOICE
}
export interface IChoiceModel {
	id: number;
	text: string;
	isSelected?: boolean;
	isDeleted: boolean;
	isValid?: boolean;
	subQuestions: ISectionItems[];
}

export interface IAnswerModel {
	answerId: number;
	answerText: string
	clientId: string;
	clientType: ClientType;
}

export const intialFormData: IFormData = {
	formId: 0,
	title: "",
	description: "",
	sections: []
};

export const intialQuestionnaireState: IQuestionnaireState = {
	isLoading: false,
	formData: intialFormData,
	intialFormData: intialFormData
};

export const initialYesNoChoiceData: IChoiceModel[] = [
    { id: 0, text: "Yes", isDeleted: false, subQuestions: [] },
    { id: 0, text: "No", isDeleted: false, subQuestions: [] }
];


export class CustomQuestionsFormDataModel implements IFormData {

	formId: number;
	title: string;
	description: string;
	sections: ISections[]

	constructor(formId: number,
		title: string,
		description: string,
		sections: ISections[]) {

		this.formId = formId;
		this.title = title;
		this.description = description;
		this.sections = sections;
	}

	public static createNullObject(): CustomQuestionsFormDataModel {
		return new CustomQuestionsFormDataModel(0, "", "", []);
	}
	
}


