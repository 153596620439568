import { IEventTelemetry } from "@microsoft/applicationinsights-web";
import { AxiosResponse } from "axios";
import { Action, Reducer } from "redux";
import {
	AutoSave,
	CustomEventLog,
	ErrorMessages,
} from "../../components/Common/Constants";
import { ClientType, ReviewStatus } from "../../core/common/Enums";
import {
	BlobForm,
	IFormData,
	OrganizerFormDataViewModel,
	SignerDocumentModel,
	StatusCode,
	UploadedDocument,
} from "../../core/domain/models/Organizer/Organizer";
import { initializeAxios } from "../../core/Services/dataAccess/DataService.Axios";
import { IFileUtilities } from "../../core/utilities/File/FileUtilities";
import { ILoader } from "../../core/utilities/ui/Loader";
import { logger } from "../../routes";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import { actionTypes } from "../ActionTypes";
import { NotificationAction, StatusType } from "../Common/NotificationStore";
import { AppThunkAction } from "../index";
import { MarsNotifier } from "../../components/Common/Notification/MarsNotifier";
import { RedirectToDashBoard, SetUploadedDocumentsListToStorage } from "../../components/Helper/HelperFunction";
import { actionCreators as UploadedDocumentStore } from "../UploadedDocument/UploadedDocumentStore";
import {
	ConflictErrorMessage,
	CustomResponse,
} from "../UploadedDocument/UploadedDocumentStoreModels";
import { DispatchAction as UploadedDocumentDispatchAction } from "../UploadedDocument/UploadedDocumentStoreModels";
import { IDialogBox } from "src/core/utilities/ui/DialogBox";
interface RequestUploadedDocumentAction {
	type: actionTypes.UPLOADED_DOCUMENTS_REQUEST;
}

interface RequestCacheUploadedDocumentAction {
	type: actionTypes.UPLOADED_DOCUMENTS_CACHE;
	data: UploadedDocument[];
}

interface ResponseUploadedDocumentAction {
	type: actionTypes.UPLOADED_DOCUMENTS_RESPONSE;
	data: UploadedDocument[];
}

interface FailureUploadedDocumentAction {
	type: actionTypes.UPLOADED_DOCUMENTS_FAILURE;
	data: UploadedDocument[];
}

interface RequestEsignDocumentAction {
	type: actionTypes.ORGANIZER_SIGN_REQUEST;
}

interface RequestCacheEsignDocumentAction {
	type: actionTypes.ORGANIZER_SIGN_CACHE;
	data: SignerDocumentModel;
}

interface ResponseEsignDocumentAction {
	type: actionTypes.ORGANIZER_SIGN_RESPONSE;
	data: SignerDocumentModel;
}

interface FailureEsignDocumentAction {
	type: actionTypes.ORGANIZER_SIGN_FAILURE;
	data: SignerDocumentModel;
}

interface RequestBlobFormDataAction {
	type: actionTypes.BLOB_FORMDATA_REQUEST;
}

interface RequestCacheBlobFormDataAction {
	type: actionTypes.BLOB_FORMDATA_CACHE;
	blobForms: BlobForm[];
}

interface ResponseBlobFormDataAction {
	type: actionTypes.BLOB_FORMDATA_RESPONSE;
	blobForms: BlobForm[];
}

interface FailureBlobFormDataAction {
	type: actionTypes.BLOB_FORMDATA_FAILURE;
	blobForms: BlobForm[];
}

interface FormNotesResponce {
	type: actionTypes.ORGANIZER_GET_FORM_NOTES;
	data: string;
	formId: number;
}

export interface OrganizerSignState {
	data: SignerDocumentModel;
	blobForms: BlobForm[];
	uploadedDocuments: UploadedDocument[];
}

export const initialOrganizerSignState: OrganizerSignState = {
	data: SignerDocumentModel.createNullObject(),
	blobForms: [],
	uploadedDocuments: [],
};

type KnownAction = DispatchAction | NotificationAction;

type DispatchAction =
	| ResponseEsignDocumentAction
	| RequestEsignDocumentAction
	| FailureEsignDocumentAction
	| RequestCacheEsignDocumentAction
	| RequestUploadedDocumentAction
	| RequestCacheUploadedDocumentAction
	| ResponseUploadedDocumentAction
	| FailureUploadedDocumentAction
	| RequestBlobFormDataAction
	| RequestCacheBlobFormDataAction
	| ResponseBlobFormDataAction
	| FailureBlobFormDataAction
	| FormNotesResponce;

const loader = container.get<ILoader>(TYPES.ILoader);
const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export const actionCreators = {
	requestOrganizerControls:
		(clientGuid: string, forceRefresh?: boolean): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				const state = getState();

				if (
					!forceRefresh &&
					state.organizerDocument &&
					state.organizerDocument.id > 0
				) {
					dispatch({
						type: actionTypes.ORGANIZER_SIGN_CACHE,
						data: state.organizerDocument,
					});
					return;
				}

				return initializeAxios()
					.get<SignerDocumentModel>(
						"/api/Sign/GetOrganizerDocumentModel/" + clientGuid
					)
					.then(function (response: AxiosResponse<SignerDocumentModel>) {
						dispatch({
							type: actionTypes.ORGANIZER_SIGN_RESPONSE,
							data: response.data,
						});
					})
					.catch(function (error: any) {
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							return;
						}
						dispatch({
							type: actionTypes.ORGANIZER_SIGN_FAILURE,
							data: state.organizerDocument,
						});
						logger.trackError(
							`requestOrganizerControls GetOrganizerDocumentModel failed to push the data with error ${error.message}`
						);
					});
			},

	requestOrganizerForms:
		(clientGuid: string, forceRefresh?: boolean): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				const state = getState();

				if (!forceRefresh && state.blobForms && state.blobForms.length > 0) {
					dispatch({
						type: actionTypes.BLOB_FORMDATA_CACHE,
						blobForms: state.blobForms,
					});

					return;
				}

				//loader.show("", "#pdfViewer");

				return initializeAxios()
					.get<any>("/api/Sign/GetOrganizerFormDataLink/" + clientGuid)
					.then(function (response: AxiosResponse<any>) {
						fileUtilities.downloadObject(response.data.sas).then(
							(data) => {
								dispatch({
									type: actionTypes.BLOB_FORMDATA_RESPONSE,
									blobForms: data,
								});

								//loader.hide();
							},
							(error: string) => {
								dispatch({
									type: actionTypes.BLOB_FORMDATA_FAILURE,
									blobForms: state.blobForms,
								});

								//loader.hide();
							}
						);
					})
					.catch(function (error: any) {
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							return;
						}
						dispatch({
							type: actionTypes.BLOB_FORMDATA_FAILURE,
							blobForms: state.blobForms,
						});
						logger.trackError(
							`requestOrganizerForms failed to push the data with error ${error.message}`
						);
						//loader.hide();
					});
			},

	submitSignedDocument:
		(
			clientGuid: string,
			organizerForms: OrganizerFormDataViewModel,
			successCallback?: () => void,
			failureCallback?: () => void,
			failureCallbackForOrganizerUnavailable?: () => void
		): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				loader.show();

				return initializeAxios()
					.postJson(organizerForms, "/api/Sign/Save/" + clientGuid)
					.then(function (response: AxiosResponse<any>) {
						if (response.data.statusCode === StatusCode.Conflict) {
							failureCallback && failureCallback();
							loader.hide();
							const TraceEvent: IEventTelemetry = {
								name: `${CustomEventLog.OrganizerCompleted}`,
								properties: { ClientGuid: clientGuid },
							};
							logger.trackEvent(TraceEvent);
						} else {
							successCallback && successCallback();
							loader.hide();

							const TraceEvent: IEventTelemetry = {
								name: `${CustomEventLog.submitSignedDocumentSaved}`,
								properties: { ClientGud: clientGuid },
							};
							logger.trackEvent(TraceEvent);
						}
					})
					.catch(function (error: any) {
						loader.hide();
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							failureCallbackForOrganizerUnavailable && failureCallbackForOrganizerUnavailable();
							return;
						}
						failureCallback && failureCallback();
						logger.trackError(
							`submitSignedDocument failed to push the data with error ${error.message}`
						);
					});
			},
	autoSaveOrganizer:
		(
			clientGuid: string,
			organizerForms: OrganizerFormDataViewModel,
			organizerDocument: SignerDocumentModel,
			successCallback: (organizerDocument: SignerDocumentModel) => void,
			failureCallback?: () => void
		): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				return initializeAxios()
					.postJson(organizerForms, "/api/Sign/AutoSave/" + clientGuid)
					.then(function (response: AxiosResponse<any>) {
						if (response.data.statusCode === StatusCode.Conflict) {
							failureCallback && failureCallback();
							loader.hide();
							const TraceEvent: IEventTelemetry = {
								name: `${CustomEventLog.OrganizerCompleted}`,
								properties: { ClientGuid: clientGuid },
							};
							logger.trackEvent(TraceEvent);
						} else if (response.status === StatusCode.Ok) {
							successCallback(organizerDocument);
						} else {
							logger.trackError(
								`Organizer autosave failed` + JSON.stringify(organizerForms)
							);
							dispatch({
								type: actionTypes.NOTIFICATION,
								statusMessage: AutoSave.Failure,
								statusType: StatusType.Warning,
							});
						}
					})
					.catch(function (error: any) {
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							return;
						}
						logger.trackError(`Organizer autosave failed`);
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: AutoSave.Failure,
							statusType: StatusType.Warning,
						});
					});
			},

	finishDocumentSign:
		(
			clientGuid: string,
			organizerForms?: OrganizerFormDataViewModel,
			successCallback?: () => void,
			failureCallback?: () => void,
			failureCallbackForOrganizerUnavailable?: () => void
		): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				loader.show();

				return initializeAxios()
					.postJson(organizerForms, "/api/Sign/Finish/" + clientGuid)
					.then(function (response: AxiosResponse<any>) {
						if (response.data.statusCode === StatusCode.Conflict) {
							failureCallback && failureCallback();
							loader.hide();
							const TraceEvent: IEventTelemetry = {
								name: `${CustomEventLog.OrganizerCompleted}`,
								properties: { ClientGuid: clientGuid },
							};
							logger.trackEvent(TraceEvent);
						} else {
							successCallback && successCallback();
							loader.hide();
							const TraceEvent: IEventTelemetry = {
								name: `${CustomEventLog.finishDocumentSign}`,
								properties: { ClientGuid: clientGuid },
							};
							logger.trackEvent(TraceEvent);
						}
					})
					.catch(function (error: any) {
						loader.hide();
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							failureCallbackForOrganizerUnavailable && failureCallbackForOrganizerUnavailable();
							return;
						}
						failureCallback && failureCallback();
						logger.trackError(
							`finishDocumentSign failed to push the data with error ${error.message}`
						);
					});
			},

	requestUploadedDocuments:
		(clientId: string, forceRefresh?: boolean): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				const state = getState();

				const uploadedDocuments =
					(state.organizerDocument as any).uploadedDocuments != undefined
						? (state.organizerDocument as any).uploadedDocuments
						: initialOrganizerSignState.uploadedDocuments;

				if (!forceRefresh && uploadedDocuments.length > 0) {
					dispatch({
						type: actionTypes.UPLOADED_DOCUMENTS_CACHE,
						data: uploadedDocuments,
					});
					return;
				}
				return initializeAxios()
					.get<UploadedDocument[]>(
						"/api/OrganizerAdditionalDocument/Get/" + clientId,
						{},
						true
					)
					.then(function (response: AxiosResponse<UploadedDocument[]>) {
						dispatch({
							type: actionTypes.UPLOADED_DOCUMENTS_RESPONSE,
							data: response.data,
						});
						SetUploadedDocumentsListToStorage(response.data);
					})
					.catch(function (error: any) {
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							return;
						}
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: error.response
								? error.response.statusText
								: ErrorMessages.UploadDocumentError,
							statusType: StatusType.Error,
						});
						dispatch({
							type: actionTypes.UPLOADED_DOCUMENTS_FAILURE,
							data: uploadedDocuments,
						});
						logger.trackError(
							`requestUploadedDocuments failed to push the data with error ${error.message}`
						);
					});
			},

	addUploadedDocument:
		(
			clientId: string,
			uploadedDocument: UploadedDocument,
			successCallback?: () => void,
			failureCallback?: () => void
		): AppThunkAction<KnownAction | UploadedDocumentDispatchAction> =>
			(dispatch, getState) => {
				const state = getState();
				return initializeAxios()
					.postJson(
						uploadedDocument,
						"/api/OrganizerAdditionalDocument/Add/" + clientId
					)
					.then(function (response: AxiosResponse<CustomResponse<any>>) {
						if (response.data?.statusCode === StatusCode.Conflict) {
							dispatch({
								type: actionTypes.UPLOADED_DOCUMENT_ERROR,
								errorMessage: ConflictErrorMessage,
								isError: true,
							});

							dispatch(UploadedDocumentStore.setUploadedDocumentCompleted());

							dispatch(
								UploadedDocumentStore.requestUploadedDocumentStatus(clientId)
							);

							return;
						} else if (response.data?.statusCode === StatusCode.NotFound) {
							MarsNotifier.Error(response.data?.data, null);

							failureCallback && failureCallback();
						} else {
							successCallback && successCallback();
						}
					})
					.catch(function (error: any) {
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							loader.hide();
							return;
						}
						if (error?.response?.status === StatusCode.Locked) {
							loader.hide();
							RedirectToDashBoard(clientId, dialogBox);
						}
						else {
							dispatch({
								type: actionTypes.NOTIFICATION,
								statusMessage: error.response
									? error.response.statusText
									: ErrorMessages.UploadDocumentError +
									" " +
									uploadedDocument.fileName,
								statusType: StatusType.Error,
							});
							logger.trackError(
								`addUploadedDocument failed to push the data with error ${error.message}`
							);
							failureCallback && failureCallback();
						}
					});
			},

	deleteUploadedDocument:
		(
			clientId: string,
			id: number,
			successCallback?: () => void,
			failureCallback?: () => void
		): AppThunkAction<KnownAction | UploadedDocumentDispatchAction> =>
			(dispatch, getState) => {
				const state = getState();
				loader.show("", ".modal-content", 20);
				return initializeAxios()
					.delete(
						0,
						`/api/OrganizerAdditionalDocument/Delete/${clientId}?uploadedDocumentId=${id}`
					)
					.then(function (response: AxiosResponse<CustomResponse<any>>) {
						if (response.data?.statusCode === StatusCode.Conflict) {
							dispatch({
								type: actionTypes.UPLOADED_DOCUMENT_ERROR,
								errorMessage: ConflictErrorMessage,
								isError: true,
							});

							dispatch(UploadedDocumentStore.setUploadedDocumentCompleted());

							dispatch(
								UploadedDocumentStore.requestUploadedDocumentStatus(clientId)
							);

							loader.hide();
							return;
						} else if (response.data?.statusCode === StatusCode.Gone) {
							dispatch({
								type: actionTypes.UPLOADED_DOCUMENT_ERROR,
								errorMessage: response.data?.data,
								isError: true,
							});

							dispatch(
								UploadedDocumentStore.requestUploadedDocumentStatus(clientId)
							);

							loader.hide();
							return;
						} else {
							successCallback && successCallback();
							loader.hide();
						}
					})
					.catch(function (error: any) {

						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							loader.hide();
							return;
						}

						if (error?.response?.status === StatusCode.Locked) {
							loader.hide();
							RedirectToDashBoard(clientId, dialogBox);
						}
						else {
							failureCallback && failureCallback();
							logger.trackError(
								`deleteUploadedDocument failed to push the data with error ${error.message}`
							);
						}
					});

			},

	completeDocumentUpload:
		(
			clientId: string,
			deletedFiles: string,
			successCallback?: () => void,
			failureCallback?: () => void
		): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				const state = getState();

				loader.show("", ".modal-content", 20);

				return initializeAxios()
					.postJson(
						{
							deletedFiles: deletedFiles,
						},
						"/api/OrganizerAdditionalDocument/ContinueLater/" + clientId
					)
					.then(function (response: AxiosResponse<any>) {
						successCallback && successCallback();
						loader.hide();
						logger.trackTrace(
							`User clicked on ContinueLater and document(s) Uploaded/deleted for clientGuid: ${clientId}`
						);
					})
					.catch(function (error: any) {
						loader.hide();
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							return;
						}
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: error.response
								? error.response.statusText
								: ErrorMessages.UploadDocumentError,
							statusType: StatusType.Error,
						});
						failureCallback && failureCallback();
						logger.trackError(
							`User clicked on ContinueLater and document(s) Uploaded/deleted failed to push the data with error ${error.message}`
						);
					});
			},

	AddNotes:
		(
			clientId: string,
			formId: number,
			notes: string,
			pageNo: number,
			successCallback?: () => void,
			failureCallback?: () => void,
			failureCallbackForOrganizerUnavailable?: () => void
		): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				loader.show();
				return initializeAxios()
					.postJson(
						{
							notes: notes,
							formId: formId,
							pageNo: pageNo,
						},
						`/api/Sign/AddNotes/${clientId}`
					)
					.then(function (response: AxiosResponse<any>) {
						if (response.data.statusCode === StatusCode.Conflict) {
							failureCallback && failureCallback();
							loader.hide();
							const TraceEvent: IEventTelemetry = {
								name: `${CustomEventLog.OrganizerCompleted}`,
								properties: { ClientGuid: clientId },
							};
							logger.trackEvent(TraceEvent);
						}
						else {
							successCallback && successCallback();
							loader.hide();

							const TraceEvent: IEventTelemetry = {
								name: `${CustomEventLog.AddNotes}`,
								properties: {
									ClientGuid: clientId,
									FormId: formId,
									PageNo: pageNo,
								},
							};
							logger.trackEvent(TraceEvent);
						}
					})
					.catch(function (error: any) {
						loader.hide();
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							failureCallbackForOrganizerUnavailable && failureCallbackForOrganizerUnavailable();
							return;
						}
						failureCallback && failureCallback();
						logger.trackError(
							`AddNotes for the form failed with error ${error.message}`
						);
					});
			},

	DeleteNotes:
		(
			clientId: string,
			formId: number,
			successCallback?: () => void,
			failureCallback?: () => void,
			failureCallbackForOrganizerUnavailable?: () => void
		): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				loader.show();
				return initializeAxios()
					.delete(0, `/api/Sign/DeleteNotes/${clientId}?formId=${formId}`)
					.then(function (response: AxiosResponse<any>) {
						if (response.data.statusCode === StatusCode.Conflict) {
							failureCallback && failureCallback();
							loader.hide();
							const TraceEvent: IEventTelemetry = {
								name: `${CustomEventLog.OrganizerCompleted}`,
								properties: { ClientGuid: clientId },
							};
							logger.trackEvent(TraceEvent);
						}
						else {
							successCallback && successCallback();
							loader.hide();

							const TraceEvent: IEventTelemetry = {
								name: `${CustomEventLog.DeleteNotes}`,
								properties: { ClientGuid: clientId, FormId: formId },
							};
							logger.trackEvent(TraceEvent);
						}
					})
					.catch(function (error: any) {
						loader.hide();
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							failureCallbackForOrganizerUnavailable && failureCallbackForOrganizerUnavailable();
							return;
						}
						failureCallback && failureCallback();
						logger.trackError(
							`DeleteNotes for the form failed with error ${error.message}`
						);
					});
			},

	sendForReview:
		(
			clientGuid: string,
			clientType: ClientType,
			callback: () => void,
			failureCallback?: () => void
		): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				loader.show();
				return initializeAxios()
					.postJson({}, `/api/Sign/SendForReview/${clientGuid}`)
					.then(function (response: AxiosResponse<any>) {
						callback && callback();
						loader.hide();

						const TraceEvent: IEventTelemetry = {
							name: `${CustomEventLog.OrganizerSFR}`,
							properties: {
								ClientGuid: clientGuid,
								ClientType: ClientType[clientType],
							},
						};
						logger.trackEvent(TraceEvent);
					})
					.catch(function (error: any) {
						loader.hide();
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							return;
						}
						failureCallback && failureCallback();
						logger.trackError(
							`sendForReview for the form failed with error ${error.message}`
						);
					});
			},

	reviewOrganizer:
		(clientGuid: string, callback?: () => void): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				return initializeAxios()
					.postJson(
						{
							reviewStatus: ReviewStatus.Reviewed,
						},
						`/api/Sign/ReviewOrganizer/${clientGuid}`
					)
					.then(function (response: AxiosResponse<any>) {
						callback && callback();
						loader.hide();

						const TraceEvent: IEventTelemetry = {
							name: `${CustomEventLog.OrganizerReviewed}`,
							properties: { ClientGuid: clientGuid },
						};
						logger.trackEvent(TraceEvent);
					})
					.catch(function (error: any) {
						loader.hide();
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							return;
						}
						logger.trackError(
							`ReviewOrganizer for the form failed with error ${error.message}`
						);
					});
			},

	ignoreReviewOrganizer:
		(clientGuid: string, callback?: () => void): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				return initializeAxios()
					.postJson(
						{
							reviewStatus: ReviewStatus.Ignored,
						},
						`/api/Sign/ReviewOrganizer/${clientGuid}`
					)
					.then(function (response: AxiosResponse<any>) {
						callback && callback();
						loader.hide();

						const TraceEvent: IEventTelemetry = {
							name: `${CustomEventLog.OrganizerReviewIgnored}`,
							properties: { ClientGuid: clientGuid },
						};
						logger.trackEvent(TraceEvent);
					})
					.catch(function (error: any) {
						loader.hide();
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							return;
						}
						logger.trackError(
							`ignoreReviewOrganizer for the form failed with error ${error.message}`
						);
					});
			},

	autoSaveCustomQuestions:
		(
			clientGuid: string,
			formData: IFormData,
			successCallback: (formData: IFormData) => void,
			failureCallback?: () => void
		): AppThunkAction<KnownAction> =>
			(dispatch, getState) => {
				return initializeAxios()
					.postJson(formData, "/api/CustomQuestionnaire/auto-save/" + clientGuid)
					.then(function (response: AxiosResponse<any>) {
						if (response.status === StatusCode.Ok) {
							successCallback(formData);
						} else {
							logger.trackError(
								`Custom Questions autosave failed` + JSON.stringify(formData)
							);
							dispatch({
								type: actionTypes.NOTIFICATION,
								statusMessage: AutoSave.Failure,
								statusType: StatusType.Warning,
							});
						}
					})
					.catch(function (error: any) {
						if (error?.response?.status === StatusCode.OrganizerUnavailable) {
							return;
						}
						logger.trackError(`Custom Questions autosave failed`);
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: AutoSave.Failure,
							statusType: StatusType.Warning,
						});
					});
			},
};

export const reducer: Reducer<OrganizerSignState> = (
	state: OrganizerSignState = initialOrganizerSignState,
	incomingAction: Action
) => {
	const action = incomingAction as DispatchAction;
	const currentState = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.ORGANIZER_SIGN_REQUEST:
			currentState.data = SignerDocumentModel.createNullObject();
			return currentState;
		case actionTypes.ORGANIZER_SIGN_RESPONSE:
		case actionTypes.ORGANIZER_SIGN_CACHE:
			currentState.data = action.data;
			return currentState;
		case actionTypes.ORGANIZER_SIGN_FAILURE:
			currentState.data = action.data;
			return currentState;

		case actionTypes.UPLOADED_DOCUMENTS_REQUEST:
			currentState.uploadedDocuments = [];
			return currentState;
		case actionTypes.UPLOADED_DOCUMENTS_RESPONSE:
		case actionTypes.UPLOADED_DOCUMENTS_CACHE:
			currentState.uploadedDocuments = action.data;
			return currentState;
		case actionTypes.UPLOADED_DOCUMENTS_FAILURE:
			currentState.uploadedDocuments = action.data;
			return currentState;

		case actionTypes.BLOB_FORMDATA_REQUEST:
			currentState.blobForms = [];
			return currentState;
		case actionTypes.BLOB_FORMDATA_RESPONSE:
		case actionTypes.BLOB_FORMDATA_CACHE:
			currentState.blobForms = action.blobForms;
			return currentState;
		case actionTypes.BLOB_FORMDATA_FAILURE:
			currentState.blobForms = action.blobForms;
			return currentState;

		case actionTypes.ORGANIZER_GET_FORM_NOTES:
			currentState.data.formGroup[0].forms.filter(
				(x) => x.formId == action.formId
			)[0].message = action.data;
			return currentState;
		default:
			return currentState || initialOrganizerSignState;
	}
};
