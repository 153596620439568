import * as React from "react";
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { history } from "../../core/Services/dataAccess/History";
import { ILoader } from "../../core/utilities/ui/Loader";

export interface SMSOTPState {
    enteredOtp: number | string;   
    otpDetails?: any;
    isOtpLocked: boolean;
    errorMsg?: string;
    loading: boolean;
}

const loader = container.get<ILoader>(TYPES.ILoader);
export class SMSOTP extends React.Component<any,SMSOTPState>{
    private _param: any;
    constructor(props: any) {     
    super(props);
        this.state = {
            enteredOtp: '',
            isOtpLocked: false,
            loading: true,
        };
  }

componentDidMount() {
    this.props.setIsCoverPage(false);
    this._param = this.props.match.params;
    const { pageValidity } = this.props;
    loader.show();
    pageValidity(this._param.clientId, this.handleError);
}

    private handleRequestOtp = () => {
        const clientId = this._param.clientId;
        const { generateOtp } = this.props;        
        generateOtp(clientId);
    }

    private handleError = (errorState: any) => {
        loader.hide();
        this.setState({ loading: false });
        if (errorState?.errorCode?.includes("OTP_LOCKED"))
            this.setState({
                ...this.state,
                isOtpLocked: true,
                errorMsg: errorState.errorMsg
            })
    }

    private onOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ enteredOtp: e.target.value })
        }
    }

    private navigateToHome(guid: string) {
        history.push(`/organizer/${guid}`);
    }

    private handleOtpVerify = () => {
        const { verifyOTP } = this.props;
        const { enteredOtp } = this.state;
        const clientId = this._param.clientId;
        verifyOTP(enteredOtp, clientId, this.navigateToHome, this.handleError);
    }

    render() {
        const { enteredOtp } = this.state;
        let phoneNo: string = this.props?.otpDetails?.mobileNo;
        let lastNum: string = "";

        if (phoneNo && phoneNo?.length !== 0) {
            lastNum = "{xxx}xxx-" + phoneNo.substr(6);
        }

        return (
            <>
                {
                    !this.state.loading &&
                    (!this.state.isOtpLocked ?
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 auth-otp-page">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 auth-otp-page-container">
                                <div>
                                    <div
                                        data-test-auto="3F6GF5L0TE2MZDW5UCCVJJJCP0"
                                        className="alert alert-danger"
                                        role="alert"
                                        style={{ display: "none" }}
                                    >
                                        {/* @Html.ValidationMessage("CustomError", new { @className = "error-container" }) */}
                                    </div>
                                </div>

                                {/* <!-- Then put toasts within --> */}
                                <div
                                    className="toast"
                                    role="alert"
                                    aria-live="assertive"
                                    aria-atomic="true"
                                    data-delay="5000"
                                    style={{ maxWidth: "inherit" }}
                                ></div>

                                <span
                                    id="spanError"
                                    className="error-container"
                                    style={{ color: "red" }}
                                ></span>
                                <h5 data-test-auto="594BF58A-8747-40AB-B191-D6230C62AFE7">
                                    Authentication
                </h5>
                                <br />
                                <strong data-test-auto="72B14A99-8268-48CC-9CFF-D9855EFE973B">
                                    Mobile Access Code Required
                </strong>
                                <div>
                                    <div
                                        data-test-auto="5877E1VF4W543DKW4MY8AYKW2H"
                                        className="margin-top-10"
                                    >
                                        <p>
                                            Please select “Request Access Code” and we will send you a
                      one-time expiring access code to your mobile device number:{lastNum}

                                        </p>
                                        {/* @Model.MobileNumber.Substring(Model.MobileNumber.Length < 4 ? 0 : Convert.ToInt32(Model.MobileNumber.Length) - 4) */}
                                    </div>
                                    <div
                                        data-test-auto="5877E1VF4W543DKW4MY8AYKH2W"
                                        className="margin-top-10"
                                    >
                                        <p>
                                            If the mobile device number is incorrect please contact your
                                            Tax Professional.
                    </p>
                                    </div>
                                    <div className="margin-top-50">
                                        <button
                                            data-test-auto="KX5TPC2L7MCMN6K4P58LR5AR9D"
                                            type="button"
                                            className="btn btn-primary continue"
                                            style={{ borderRadius: "50px", backgroundColor: "#0973ba" }}
                                            id="btnRequestMobileAccessCode" onClick={this.handleRequestOtp}>
                                            Request Access Code
                    </button>
                                    </div>

                                    <div className="otp-page-container-input-div margin-top-50">
                                        <span data-test-auto="F85BF82A-DF06-4D24-A1F6-3862A8312CB0">
                                            Enter access code here:
                    </span>{" "}
                    &nbsp;
                    <input
                                            data-test-auto="WL2PVLYGV7Y4V6M2KCECL0WT9D"
                                            id="otp"
                                            name="otp"
                                            type="text"
                                            maxLength={6}
                                            onChange={(e) => this.onOtpChange(e)}
                                            value={enteredOtp}
                                            pattern="[0-9]*"
                                        />
                                        <span
                                            className="access-code-expiry-info"
                                            data-test-auto="81F85D4C-7FD0-4841-9CC6-74AC230FBE27"
                                        >
                                            (This code will expire in 20 minutes)
                    </span>
                                    </div>
                                    <div className="margin-top-50">
                                        <button
                                            data-test-auto="XMCH754QXT44G3CPUY5G3700W8"
                                            type="submit"
                                            id="btnContinue"
                                            className={enteredOtp?.toString()?.length === 6 ? "btn btn-primary continue float-right" : "btn btn-primary continue disabled float-right"}
                                            style={{ color: "white", borderRadius: "50px" }}
                                            disabled={enteredOtp?.toString()?.length === 6 ? false : true}
                                            onClick={() => this.handleOtpVerify()}>
                                            Continue
                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        :
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page-container">
                                <div>
                                    <p className="otp-error-message">{this.state.errorMsg}</p>
                                </div>
                            </div>
                        </div>)
                }
          </>
        );
  }
}
export default SMSOTP;
