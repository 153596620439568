export enum actionTypes {
    NOTIFICATION,
    HEADERINFO_REQUEST,
    HEADERINFO_RESPONSE,
    HEADERINFO_FAILURE,

    ESIGN_REQUEST,
    ESIGN_RESPONSE,
    ESIGN_FAILURE,

    TAXING_AUTHORITY_REQUEST,
    TAXING_AUTHORITY_RESPONSE,
    TAXING_AUTHORITY_FAILURE,

    SUMMARY_DETAILS_REQUEST,
    SUMMARY_DETAILS_RESPONSE,
    SUMMARY_DETAILS_FAILURE,

    DOCUMENT_SETTINGS_REQUEST,
    DOCUMENT_SETTINGS_RESPONSE,
    DOCUMENT_SETTINGS_FAILURE,

    DOCUMENT_REVIEW_MODEL_REQUEST,
    DOCUMENT_REVIEW_MODEL_RESPONSE,
    DOCUMENT_REVIEW_MODEL_FAILURE,

    UPDATE_DOCUMENT_SIGNATURE_SETTINGS_REQUEST,
    UPDATE_DOCUMENT_SIGNATURE_SETTINGS_ERROR,

    SIGNATURE_CONTROLS_DATA_REQUEST,
    SIGNATURE_CONTROLS_DATA_RESPONSE,
    SIGNATURE_CONTROLS_DATA_FAILURE,

    //ClientAuthentication
    CA_REQUEST,
    CA_RESPONSE,
    CA_FAILURE,

    //SkipAuthentication
    SA_REQUEST,
    SA_RESPONSE,
    SA_FAILURE,

    SIGN_SUBMIT_REQUEST,
    SIGN_SUBMIT_RESPONSE,
    SIGN_SUBMIT_FAILURE,

    TAXDOCUMENT_REQUEST,
    TAXDOCUMENT_RESPONSE,
    TAXDOCUMENT_FAILURE,



    COMPANY_INFO_REQUEST,
    COMPANY_INFO_RESPONSE,
    COMPANY_INFO_FAILURE,
    COMPANY_INFO_CACHE,

    TAXDOCUMENT_CLIENT_TYPE_REQUEST,
    TAXDOCUMENT_CLIENT_TYPE_RESPONSE,
    TAXDOCUMENT_CLIENT_TYPE_FAILURE,

    UPDATE_TAXCLIENT_MOBILE_NUMBER_REQUEST,
    UPDATE_TAXCLIENT_MOBILE_NUMBER_RESPONSE,

    SIGNER_REQUEST,
    SIGNER_RESPONSE,
    SIGNER_FAILURE,

    PAYSCREEN_DETAILS_REQUEST,
    PAYSCREEN_DETAILS_RESPONSE,
    PAYSCREEN_DETAILS_FAILURE,

    K1DISTRIBUTE_REQUEST,
    K1DISTRIBUTE_RESPONSE,
    K1DISTRIBUTE_FAILURE,
    K1DISTRIBUTE_UPDATE,

    DOWNLOADABLE_DOCUMENTS_REQUEST,
    DOWNLOADABLE_DOCUMENTS_RESPONSE,
    DOWNLOADABLE_DOCUMENTS_FAILURE,

    SIGN_PROCESS_REQUEST,
    SIGN_PROCESS_RESPONSE,
    SIGN_PROCESS_FAILURE,
    SIGN_PROCESS_STEP,
    UPDATE_LAST_VISITED_PROCESS_STEP,

    NEXT_SIGNER_REQUEST,
    NEXT_SIGNER_RESPONSE,
    NEXT_SIGNER_FAILURE,

    SEND_SIGNING_REMINDER_REQUEST,
    SEND_SIGNING_REMINDER_RESPONSE,
    SEND_SIGNING_REMINDER_FAILURE,

    //My Downloads
    MY_DOWNLOAD_REQUEST,
    MY_DOWNLOAD_RESPONSE,
    MY_DOWNLOAD_FAILURE,

    PREVIEW_DOCUMENTS_REQUEST,
    PREVIEW_DOCUMENTS_RESPONSE,
    PREVIEW_DOCUMENTS_FAILURE,

    ORGANIZER_SIGN_REQUEST,
    ORGANIZER_SIGN_RESPONSE,
    ORGANIZER_SIGN_FAILURE,
    ORGANIZER_SIGN_CACHE,
    ORGANIZER_METADATA_REQUEST,
    ORGANIZER_METADATA_RESPONSE,
    ORGANIZER_METADATA_CACHE,
    ORGANIZER_METADATA_FAILURE,
    ORGANIZER_GET_FORM_NOTES,

    ENGAGEMENT_DOCUMENT_SIGN_REQUEST,
    ENGAGEMENT_DOCUMENT_SIGN_RESPONSE,
    ENGAGEMENT_DOCUMENT_SIGN_FAILURE,
    ENGAGEMENT_DOCUMENT_SIGN_CACHE,

    UPLOADED_DOCUMENTS_REQUEST,
    UPLOADED_DOCUMENTS_RESPONSE,
    UPLOADED_DOCUMENTS_FAILURE,
    UPLOADED_DOCUMENTS_CACHE,


    BLOB_FORMDATA_REQUEST,
    BLOB_FORMDATA_RESPONSE,
    BLOB_FORMDATA_FAILURE,
    BLOB_FORMDATA_CACHE,



    SPOUSE_INFO_REQUEST,
    SPOUSE_INFO_RESPONSE,
    SPOUSE_INFO_FAILURE,
    SPOUSE_INFO_CACHE,

    RECEIVE_CLIENT_DETAILS,
    FETCH_CLIENT_DETAILS,
    SET_ERROR_MSG,

    SET_OTP_LOADING,
    SET_VALID_OTP_PAGE,
    SET_INVALID_OTP_PAGE,
    SET_OTP_ERROR,
    OTP_GENERATE_FAILED,
    SET_MOBILE_VAL,
    OTP_GENERATE_SUCCESS,
    GENERATE_TAXPAYER_OTP,

    RECEIVE_HEADER_DETAILS,
    RESET_NOTIFICATION_RESPONSE,

    SET_IS_COVER_PAGE,

    ORGANIZER_PREPARER_MESSAGE_RESPONSE,

    UPLOADED_DOCUMENT_LOADER,
    RECEIVE_UPLOADED_DOCUMENT_STATUS,
    RECEIVE_UPLOADED_DOCUMENT_COMPLETED,
    UPLOADED_DOCUMENT_ERROR,
    UPLOADED_DOCUMENT_ERROR_RESET,
    SET_SOURCE_DOCUMENT_META_DATA,

    // Custom Questions Actions
    HANDLE_PARAGRAPH_ON_CHANGE,
    GET_FORM_DATA,
    SET_IS_INVALID_FLAG,
    HANDLE_YES_NO_ON_CHANGE,
    HANDLE_MCQ_ON_CHANGE,

    SET_USER_DATA,
    SET_CLIENT_INFO_DATA,

    // signalR notifications
    DOCUMENT_STATUS_UPDATED
}