import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { Form, SignerDocumentModel, UploadMethod, EngagementLetterFormData, FormGroup, GroupType, StatusCode } from '../../core/domain/models/Organizer/Organizer';
import { IFileUtilities } from '../../core/utilities/File/FileUtilities';
import { ILoader } from '../../core/utilities/ui/Loader';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { actionTypes } from '../ActionTypes';
import { NotificationAction } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { logger } from '../../routes';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { CustomEventLog } from "../../components/Common/Constants";
import { initializeAxios } from '../../core/Services/dataAccess/DataService.Axios';
import { DisplayDownloadFile } from '../../core/utilities/DisplayDownloadFile';


interface RequestEngagementDocumentAction {
    type: actionTypes.ENGAGEMENT_DOCUMENT_SIGN_REQUEST;
}

interface RequestCacheEngagementDocumentAction {
    type: actionTypes.ENGAGEMENT_DOCUMENT_SIGN_CACHE;
    data: SignerDocumentModel;
}

interface ResponseEngagementDocumentAction {
    type: actionTypes.ENGAGEMENT_DOCUMENT_SIGN_RESPONSE;
    data: SignerDocumentModel;
}

interface FailureEngagementDocumentAction {
    type: actionTypes.ENGAGEMENT_DOCUMENT_SIGN_FAILURE;
    data: SignerDocumentModel;
}

export interface EngagementDocumentSignState {
    data: SignerDocumentModel;
}

export const initialEngagementDocumentSignState: EngagementDocumentSignState = {
    data: SignerDocumentModel.createNullObject(),
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    ResponseEngagementDocumentAction
    | RequestEngagementDocumentAction
    | FailureEngagementDocumentAction
    | RequestCacheEngagementDocumentAction

const loader = container.get<ILoader>(TYPES.ILoader);
const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);

export const actionCreators = {

    requestEngagementDocuments: (clientGuid: string, forceRefresh?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();

        if (!forceRefresh && state.engagementDocument && state.engagementDocument.id > 0) {
            dispatch({ type: actionTypes.ENGAGEMENT_DOCUMENT_SIGN_CACHE, data: state.engagementDocument });
            return;
        }

        return initializeAxios().get<SignerDocumentModel>('/api/EngagementLetter/GetEngagementLetterModel/' + clientGuid)
            .then(function (response: AxiosResponse<SignerDocumentModel>) {

                if (response.data.uploadMethod == UploadMethod.Batch) { // Fetch enagament letter form data from blob

                    if (response.data.formGroup.length > 0) {
                        response.data.formGroup.forEach(function (formGroup: any) {
                            formGroup.forms.forEach(function (form: any) {

                                if (form.formData.controlValues.length <= 0) {
                                    form.formData = EngagementLetterFormData.createNullObject([]);
                                }

                            });
                        });

                        return initializeAxios().get<any>('/api/Sign/GetEngagementLetterFormDataLink/' + clientGuid)
                            .then(function (enagementLetterSasLinkResponse: AxiosResponse<any>) {

                                fileUtilities.downloadObject(enagementLetterSasLinkResponse.data.sas)
                                    .then((data) => {


                                        const formGroup: FormGroup | undefined = response.data.formGroup.find((e: any) => e.type == GroupType.Engagement);
                                        data.forms.forEach(function (form: Form) {
                                            const tmpForm: Form | undefined = formGroup?.forms.find(x => x.pageNo == form.pageNo);

                                            if (tmpForm) {
                                                tmpForm.formData = form.formData;
                                            }

                                        });

                                        formGroup?.forms.forEach(function (form: any) { // sort for next naviagation

                                            if (form.formData && form.formData.controlList) {
                                                form.formData.controlList = form.formData.controlList.sort((x: any, y: any) => x.boundingRectangle.top - y.boundingRectangle.top).reverse();
                                            }

                                        });


                                        dispatch({
                                            type: actionTypes.ENGAGEMENT_DOCUMENT_SIGN_RESPONSE, data: response.data
                                        });

                                    }, (error: string) => {
                                        dispatch({ type: actionTypes.ENGAGEMENT_DOCUMENT_SIGN_FAILURE, data: state.engagementDocument });
                                    });

                            })
                            .catch(function (error: any) {
                                dispatch({ type: actionTypes.ENGAGEMENT_DOCUMENT_SIGN_FAILURE, data: state.engagementDocument });
                                logger.trackError(`requestEngagementDocuments failed to push the data with error ${error.message}`)
                            });
                    }
                    else {
                        dispatch({ type: actionTypes.ENGAGEMENT_DOCUMENT_SIGN_RESPONSE, data: response.data });
                    }
                }

                else {

                    dispatch({
                        type: actionTypes.ENGAGEMENT_DOCUMENT_SIGN_RESPONSE, data: response.data
                    });
                }

            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({ type: actionTypes.ENGAGEMENT_DOCUMENT_SIGN_FAILURE, data: state.engagementDocument });
                logger.trackError(`requestEngagementDocuments failed to push the data with error ${error.message}`)
            });

    },

    decline: (clientGuid: string,
        remarks: string,
        successCallback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

            loader.show();
            return initializeAxios().postJson({ remarks: remarks }, '/api/EngagementLetter/Decline/' + clientGuid)
                .then(function (response: AxiosResponse<any>) {
                    if (response.data.statusCode === StatusCode.Conflict) {
                        failureCallback && failureCallback();
                        loader.hide();
                        const TraceEvent: IEventTelemetry = {
                            name: `${CustomEventLog.OrganizerCompleted}`,
                            properties: { ClientGuid: clientGuid },
                        };
                        logger.trackEvent(TraceEvent);
                    } else {
                        const TraceEvent: IEventTelemetry = {
                            name: `${CustomEventLog.EngagementLetterDeclined}`,
                            properties: { ClientGuid: clientGuid }
                        };
                        logger.trackEvent(TraceEvent);
                        successCallback && successCallback();
                        loader.hide();
                    }
                })
                .catch(function (error: any) {
                    if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                        return;
                    }
                    failureCallback && failureCallback();
                    loader.hide();
                });
        },

    skipEngagementSign: (clientGuid: string,
        signedDocument: SignerDocumentModel,
        successCallback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

            loader.show();

            let url: string = "";

            if (signedDocument.uploadMethod == UploadMethod.Batch) {
                url = "/api/EngagementLetter/Batch/Skip/";
            }
            else {
                url = "/api/EngagementLetter/Skip/";
            }
            if (signedDocument.formGroup == undefined)
                signedDocument.formGroup = [FormGroup.createNullObject([], GroupType.Engagement)];

            return initializeAxios().postJson(signedDocument.formGroup, url + clientGuid)
                .then(function (response: AxiosResponse<any>) {
                    if (response.data.statusCode === StatusCode.Conflict) {
                        failureCallback && failureCallback();
                        loader.hide();
                        const TraceEvent: IEventTelemetry = {
                            name: `${CustomEventLog.OrganizerCompleted}`,
                            properties: { ClientGuid: clientGuid },
                        };
                        logger.trackEvent(TraceEvent);
                    } else {
                        const TraceEvent: IEventTelemetry = {
                            name: `${CustomEventLog.EngagementLetterSkipped}`,
                            properties: { ClientGuid: clientGuid }
                        };
                        logger.trackEvent(TraceEvent);
                        successCallback && successCallback();
                        loader.hide();
                    }
                })
                .catch(function (error: any) {
                    if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                        return;
                    }
                    failureCallback && failureCallback();
                    loader.hide();
                    logger.trackError(`skipEngagementSign failed to push the data with error ${error.message}`)
                });
        },

    submitSignedDocument: (clientGuid: string,
        signedDocument: SignerDocumentModel,
        successCallback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

            loader.show();

            let url: string = "";

            if (signedDocument.uploadMethod == UploadMethod.Batch) {

                url = "/api/EngagementLetter/Batch/Sign/";
            }
            else {
                url = "/api/EngagementLetter/Sign/";
            }

            return initializeAxios().postJson({
                formGroup: signedDocument.formGroup, esignDocs: signedDocument.additionalEsign
            }, url + clientGuid)
                .then(function (response: AxiosResponse<any>) {
                    if (response.data.statusCode === StatusCode.Conflict) {
                        failureCallback && failureCallback();
                        loader.hide();
                        const TraceEvent: IEventTelemetry = {
                            name: `${CustomEventLog.OrganizerCompleted}`,
                            properties: { ClientGuid: clientGuid },
                        };
                        logger.trackEvent(TraceEvent);
                    }
                    else {
                        const TraceEvent: IEventTelemetry = {
                            name: `${CustomEventLog.EngagementLetterSigned}`,
                            properties: { ClientGuid: clientGuid }
                        };
                        logger.trackEvent(TraceEvent);
                        successCallback && successCallback();
                        loader.hide();
                    }
                })
                .catch(function (error: any) {
                    if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                        return;
                    }
                    failureCallback && failureCallback();
                    loader.hide();
                    logger.trackError(`skipEngagementSign failed to push the data with error ${error.message}`)
                });

        },

    getAdditionalFileDownLoadLink: (clientGuid: string, fileGuid: string, documentId: number, FileName: string, uploadMethod: UploadMethod): AppThunkAction<KnownAction> => (dispatch, getState) => {

        loader.show();
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        let url: string = `/api/Download/GetAdditionalFileDownLoadLink/${encodeURIComponent(clientGuid)}?fileGuid=${fileGuid}&documentId=${documentId}&fileName=${FileName}&uploadMethod=${uploadMethod}`;

        return initializeAxios().get<any>(url, config)
            .then(function (response: any) {
                const contentDisposition = response.headers["content-disposition"];
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                if (fileNameMatch && fileNameMatch.length > 1) {
                    FileName = fileNameMatch[1];
                }
                const displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(response.data, FileName);
                loader.hide();
            })
            .catch(function (error: any) {
                loader.hide();
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                logger.trackError(`getAdditionalFileDownLoadLink failed to getThe link ${error.message}`)
            });
    }
}

export const reducer: Reducer<EngagementDocumentSignState> = (state: EngagementDocumentSignState = initialEngagementDocumentSignState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.ENGAGEMENT_DOCUMENT_SIGN_REQUEST:
            currentState.data = SignerDocumentModel.createNullObject();
            return currentState;
        case actionTypes.ENGAGEMENT_DOCUMENT_SIGN_RESPONSE:
        case actionTypes.ENGAGEMENT_DOCUMENT_SIGN_CACHE:
            currentState.data = action.data;
            return currentState;
        case actionTypes.ENGAGEMENT_DOCUMENT_SIGN_FAILURE:
            currentState.data = action.data
            return currentState;
        default:
            return currentState || initialEngagementDocumentSignState;
    }
};