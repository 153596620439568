import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ClientViewCoverPage from "./ClientViewCoverPage";
import * as  CoverPageStore from "../../store/CoverPage/CoverPageStore";
import { actionCreators as helperAction } from "../../store/Common/HelperStore";

import { actionCreators as coverPageAction } from "../../store/CoverPage/CoverPageStore";
import { ApplicationState } from "../../store/index";


function mapStateToProps(state: ApplicationState) {
    return {
        headerInfo: state.headerInfo
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestHeaderInfo: (id: string, isPreview: boolean, callback?: (data: any) => void, errorCallback?: (errorStatus: CoverPageStore.ErrorStatus) => void) =>
            dispatch(coverPageAction.requestHeaderInfo(id, isPreview, callback, errorCallback)),

        setIsCoverPage: (isCoverPage: boolean) => dispatch(helperAction.setIsCoverPage(isCoverPage)),
        getClientId: (clientId: string, navigate: (guid: string) => void) =>
            dispatch(coverPageAction.getClientId(clientId, navigate)),
        dispatch,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(ClientViewCoverPage));