import { ControlType, SignatureData, ControlValue, Control, QuestionType } from "../../../core/domain/models/Organizer/Controls";
import { Form, OrganizerFormData, FormType, SignerDocumentModel, BlobForm } from "../../../core/domain/models/Organizer/Organizer";
import { ClientType } from "../../../core/common/Enums";
import moment from "moment";
import { ColorCode } from "../../../core/common/BrandColors";


export class ViewerHelper {
    public static readonly ControlIdPrefix = "control_";
    public static readonly ControlKeyPrefix = "control_key_";

    public static updateOrganizerFormControl(blobForm: BlobForm, currentForm: Form, controlData: any, controlPops: any, clientType: number ,callback?: (control: any) => void): void {

        const controlId: string = controlPops.id.replace(ViewerHelper.ControlIdPrefix, "");

        if (currentForm.formType == FormType.Organizer) {

            //let form: Form | undefined = availableForms.find(x => x.formGuid == currentForm.formGuid);

            //if (!form) { // if no form add a form

            //    let data: OrganizerFormData= OrganizerFormData.create([]);
            //    form = Form.create(FormType.Organizer, currentForm.formGuid, data);
            //    availableForms.push(form);
            //}

            let tmpControlValue: ControlValue = ControlValue.create(currentForm.formGuid, controlId, "");

            let tmpFormControl: Control | undefined = blobForm.controlList.find(x => x.id == controlId);

            if (tmpFormControl) {

                if (tmpFormControl.controlType == ControlType.Textbox) {
                    tmpControlValue.value = controlData.text
                    tmpControlValue.filledBy = clientType
                }

                if(tmpFormControl.controlType == ControlType.Date){
                    //format date as YYYY-MM-DD
                    tmpControlValue.value = moment(controlData.value).format("YYYY-MM-DD");
                    tmpControlValue.filledBy = clientType

                }

                else if (tmpFormControl.controlType == ControlType.Checkbox) {
                    tmpControlValue.value = controlData.checked
                    tmpControlValue.filledBy = clientType
                }

                else if (tmpFormControl.controlType == ControlType.Dropdown) {
                    tmpControlValue.value = controlData.selectedItem
                    tmpControlValue.filledBy = clientType
                }
                else if (tmpFormControl.controlType == ControlType.RadioButtonGroup) {

                    controlData.forEach(function (radio: any) {

                        //const tmpRadio = formControl.controlData.items.find((x: any) => x.id == radio.id);

                        //if (tmpRadio) {
                        //    tmpRadio.controlData.selected = radio.value.checked;
                        //}

                        if (radio.value.checked) {
                            tmpControlValue.value = radio.id;
                            tmpControlValue.filledBy = clientType;
                        }
                    });
                }
                else if (tmpFormControl.controlType == ControlType.Signature) {
                    //formControl.controlData = SignatureData.create(controlData.name,
                    //    controlData.signature,
                    //    controlData.signatureMode);
                }
                else {
                    console.error("Control not implemented");
                }
            }

            let formControl: ControlValue | undefined = (currentForm.formData as OrganizerFormData).controlValues.find(x => x.controlGuid == controlId);

            if (formControl) {
                formControl.value = tmpControlValue.value;
                formControl.filledBy = tmpControlValue.filledBy;
            } else {
                (currentForm.formData as OrganizerFormData).controlValues = (currentForm.formData as OrganizerFormData).controlValues.concat(tmpControlValue);
            }
        }
    }

    public static getSignatureData(forms: SignerDocumentModel): SignatureData | undefined {

        let signatureData: SignatureData | undefined = undefined;

        for (let i = 0; i < (forms as any).data.formGroup.forms.length; i++) {

            const formData: OrganizerFormData = (forms as any).data.formGroup.forms[i].formData as OrganizerFormData;

            if (formData && formData.controlValues) {

                for (let j = 0; j < formData.controlValues.length; j++) {

                    const control: any = formData.controlValues[j];

                    if (control.controlType == 6 && control.controlData && control.controlData.uri) { // only for signature control

                        signatureData = {
                            name: control.controlData.name,
                            uri: control.controlData.uri,
                            signatureMode: control.controlData.signatureMode
                        };

                        break;
                    }
                }
            }

            if (signatureData) {
                break;
            }
        }

        return signatureData;
    }

    public static setSignatureData(forms: SignerDocumentModel, signatureData: any, controlId?: string): void {

        (forms as any).data.formGroup.forms.forEach(function (item: Form) {

            const formData: OrganizerFormData = item.formData as OrganizerFormData;

            formData && formData.controlValues && formData.controlValues.forEach(function (control: any) {

                if (control.controlType == 6 && control.controlData && (control.controlData.uri || control.id == controlId)) { // only for signature control

                    control.controlData = {
                        name: signatureData.name,
                        uri: signatureData.uri,
                        signatureMode: signatureData.signatureMode
                    };
                }
            });
        });
    }

    public static getControlColor = (userType: ClientType) => {
        if (userType === ClientType.Taxpayer) {
            return ColorCode.TAXPAYER_COLOR;
        } else if (userType === ClientType.Spouse) {
            return ColorCode.SPOUSE_COLOR;
        } 
    }

    public static getCheckBoxClassName = (userType: ClientType) => {
        if (userType === ClientType.Taxpayer) {
            return "checkbox-taxpayer";
        } else if (userType === ClientType.Spouse) {
            return "checkbox-spouse";

        }
    }
    public static getDatePickerClassName = (userType: ClientType) => {
        if(userType === ClientType.Taxpayer) {
            return "datepicker-taxpayer";
        } else if (userType === ClientType.Spouse){
            return "datepicker-spouse";
        }
    }

    public static compareFormControl = (a: any, b: any) => {

        if (Math.abs(a.boundingRectangle.top - b.boundingRectangle.top) > 5) {

            if (a.boundingRectangle.top > b.boundingRectangle.top) return -1;

            if (a.boundingRectangle.top < b.boundingRectangle.top) return 1;
        }
        if (a.boundingRectangle.left < b.boundingRectangle.left) return -1;

        if (a.boundingRectangle.left > b.boundingRectangle.left) return 1;

        return 0;
    }

    public static GetBoundingRectangle = (control: any) => {
        if (control.type == QuestionType.YesNo) {
            return control.radioButtonGroup.boundingRectangle;
        } else {
            return control.inputTextbox.boundingRectangle;
        }
    }
}
