import * as React from 'react';
import Skeleton from 'react-loading-skeleton';


export interface IShimmerProps {
    lineCount?: number;
    height?: number;
    width?: number;
}

export class Shimmer extends React.Component<IShimmerProps, {}> {

    constructor(props: any) {
        super(props);
    }

    public render() {
        return (<Skeleton count={this.props.lineCount} width={this.props.width} height={this.props.height} />);
    }
}