import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ClientInfo } from '../../../core/domain/models/Organizer/Organizer';
import { EngagementLetterConstants } from '../../Common/Constants';
import { SpouseInfo } from './Parts/SpouseInfo';
import { IUtilities } from '../../../core/utilities/Utilities';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { ISnackbar } from '../../../core/utilities/ui/Snackbar';

interface SpouseInfoModalProps {
    show: boolean;
    clientId: string;
    clientInfo: ClientInfo;
    onClose(): void;
    onRequestSpouseInfo(clientId: string, forceRefresh?: boolean): void;
    onUpdateSpouseEmail(clientInfo: ClientInfo): void;
    title: string;
    message: string;
}

interface SpouseInfoModalState {
    clientInfo: ClientInfo;
}


const utilities = container.get<IUtilities>(TYPES.IUtilities);
const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

export class SpouseInfoModal extends React.Component<SpouseInfoModalProps, SpouseInfoModalState> {

    constructor(props: SpouseInfoModalProps) {
        super(props);

        this.state = {
            clientInfo: ClientInfo.createNullObject()
        }
    }

    static getDerivedStateFromProps(nextProps: SpouseInfoModalProps, prevState: SpouseInfoModalState) {

        if (prevState.clientInfo.id !== nextProps.clientInfo.id) {
            return { clientInfo: nextProps.clientInfo };
        }

        return null;
    }

    componentDidMount() {
        const { onRequestSpouseInfo, clientId } = this.props;
        onRequestSpouseInfo(clientId);
    }

    private handleUpdateEmail = () => {

        const { onUpdateSpouseEmail, clientId } = this.props;
        const { clientInfo } = this.state;

        if (utilities.isValidEmailAddress(clientInfo.email) == false) {
            snackbar.show(EngagementLetterConstants.SpouseEmailValidationError);
            return;
        }

        onUpdateSpouseEmail(clientInfo);
    }

    private handleEmailChange = (email: string) => {

        this.state.clientInfo.email = email;

        this.setState(prevState => {
            return {
                clientInfo: prevState.clientInfo
            }
        });
    }

    private onCancel = () => {

        this.state.clientInfo.email = "";

        this.setState(prevState => {
            return {
                clientInfo: prevState.clientInfo
            }
        });

        this.props.onClose();
    }

    public render() {

        const { show, onClose, title, message } = this.props;
        const { clientInfo } = this.state;

        return (<Modal show={show}>
            <Modal.Header data-test-auto="320325DC-3043-44C8-A9DB-ACD876903B41">
                <Modal.Title>
                    {title}
                    <button type="button" className="close" onClick={this.onCancel}>
                        <span aria-hidden="true">x</span>
                    </button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SpouseInfo clientInfo={clientInfo} onEmailChange={this.handleEmailChange} message={message} />
            </Modal.Body>
            <Modal.Footer>

                <Button
                    data-test-auto="4418D370-E36E-4AE7-BD89-97EF3758CF26"
                    className={"btn btn-white btn-default btn-default-extended bootbox-cancel"}
                    variant="outline-light"
                    onClick={this.onCancel}>
                    Cancel
                </Button>

                <Button className={"btn-primary-extended"}
                    data-test-auto="0CDC79C8-111C-49C2-A196-AD8CD3D2D362"
                    onClick={this.handleUpdateEmail}>
                    Save and Continue
                </Button>

            </Modal.Footer>
        </Modal>);
    }
}
