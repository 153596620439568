import { injectable } from "inversify";
import { IDataService } from "../dataAccess/abstraction/IDataService";
import { DataAccessConstants } from "../../common/Constants";
import { AxiosRequestConfig } from "axios";
import { SignProcessInfo } from "../../domain/models/signingprocess/SignProcess";

export interface IOrganizerService {
    getDocumentStatusAndSourceFileSettings(clientId: string): any;
    getEngagementLetterSignatureUploadLink(clientId: string): any;
    getOrganizerDownloadLink(clientId: string): any;
    getEngagemementLetterDownloadData(clientId: string): any;
    getSignProcessinfo(clientId: string): any;
    updateSignProcessinfo(signprocessinfo: SignProcessInfo): void;
    downloadIndividualSourceDocument(documentId: number, fileName: string, documentGuid: string, year: number, clientId: string): any;
    downloadAllSourceDocuments(clientId: string, taxYear: number): any;
    downloadSelectedSourceDocuments(clientId: string, fileIds: string, taxYear : number): any;
}

@injectable()
export class OrganizerService implements IOrganizerService {

    private _dataService: IDataService;

    constructor(dataService: IDataService) {
        this._dataService = dataService;
        this._dataService.set(DataAccessConstants.BaseUrl, 10);
    }    
    getDocumentStatusAndSourceFileSettings(clientId: string): any {
        const timestamp: string = new Date().getTime().toString(); // to remove request cache
        return this._dataService.get('Document/GetDocumentStatus/' + clientId + "&timestamp=" + timestamp + "");
    }
  
    getEngagementLetterSignatureUploadLink(clientId: string): any {
        return this._dataService.get('Upload/getEngagementLetterSignatureUploadLink/' + clientId);
    }

    getOrganizerDownloadLink(clientId: string): any {
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return this._dataService.get('Download/GetOrganizerDocumentDownloadLink/' + clientId,config);
    }

    getEngagemementLetterDownloadData(clientId: string): any {
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return this._dataService.get('Download/GetEngagementLetterDownloadLink/' + clientId, config);
    }

    getSignProcessinfo(clientId: string): any {
        const timestamp: string = new Date().getTime().toString();
        return this._dataService.get('SignProcess/GetLastProcessedStepAsync/' + clientId + "&timestamp=" + timestamp + "");
    }

    updateSignProcessinfo(signprocessinfo: SignProcessInfo): any {
        return this._dataService.post('SignProcess/UpdateProcessedStepAsync/' + signprocessinfo.clientId, signprocessinfo);
    }

    downloadIndividualSourceDocument(documentId: number, fileName: string, documentGuid: string, year: number, clientId : string): any
    {
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return this._dataService.get(`Download/GetSourceDocument/${documentId}/${fileName}/${documentGuid}/${year}/${clientId}`, config);
    }

    downloadAllSourceDocuments(clientId: string, taxYear : number):any {
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return this._dataService.get(`Download/DownloadAllSourceDocuments/${taxYear}/${clientId}`, config);
    }
    
    downloadSelectedSourceDocuments(clientId: string, fileIds: string, taxYear : number):any {
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return this._dataService.get(`Download/DownloadSelectedSourceDocuments/${taxYear}/${clientId}?fileIds=${fileIds}`, config);
    }

}