import {
    SvgIconSummaryWizardActive,
    SvgIconReviewWizardActive,
    SvgIconSignWizardActive,
    SvgIconDistributeK1sWizardActive,
    SvgIconMakePaymentsWizardActive,
    SvgIconSendElectronically,
    SvgIconSendManually,
    SvgIconDownloadAll,
    SvgIconContactHeader,
    SvgIconCalendarHeader,
    SvgIconAccountHeader,
    SvgIconManualSignature,
    SvgIconEsign,
    SvgIconSignDocumentsSummary,
    SvgIconDownloadDocumentsSummary,
    SvgIconTaxPaymentsSummary,
    SvgIconPrintAndSend,
    SvgIconPayTaxPrep,
    SvgIconDistributeK1sSummary
} from './Icons/SvgIcons';
export const SiginingConstants = {
    StatusMessage: {
        ManualSignSelectConfirmationMessage: 'Are you sure, you want to continue with manual sign ?',
        DocumentSignatureSelectedAsEsign: 'Tax Document Signature has been selected as Esign',
        DocumentSignatureSelectedAsManualSign: 'Tax Document Signature has been selected as Manual Sign',
        EditSpouseEmail: 'Edit Spouse Mail',
    },
    WarningMessage:
    {
        InvalidEmailAddress: 'Please Enter valid email Address',
        InvalidDOB: 'Please select valid date of birth'
    },
    SelectSignMessage: "Select the method you would like to use to sign your documents",
    SelectSignTitle: "Sign Tax Documents",
    ESignImageCaption: "e-Sign",
    EsignCaption: "Sign electronically from this Website",
    ManuallySignImageCaption: "Manually Sign",
    ManuallySignCaption: "Print, Sign and Return the forms",
}

export const ErrorMessages = {
    PreparerDocumentSettingError: "Error occured while loading DocumentSettings",
    HeaderInfoError: "Error occurred while loading Header Information",
    TaxingAuthorityError: "Error occured while loading Taxing Authority",
    SignedDocumentError: "Error occurred while laoding signed documents model",
    AddSignedDocument: "Error occcured while uploading Signed document",
    DeleteSignedDocument: "Error occured while deleting Signed document",
    CompleteManualSign: "Error occured while completing the singed document",
    SummaryDetails: "Error occured while loading the Summary of the document",
    EsignModel: "Error occured while loading Esign Page",
    Signers: "Error occured while loading Signer information",
    ClientAuthentication: "Error occurred while authenticating user",
    ClientValidation: "Error occurred while validating client answer",
    UpadateSpouseMail: "Error occurred while updating spouse email",
    UpdatingMobileNumber: "Error occurred while updating mobile number",
    UpdatingDob: "Error occured while updating Date of Birth",
    SingatureControl: "Error occurred while requesting signature controls",
    DocumentSignatureSetting: "Error occurred while updating document signature settings",
    CompanyDetails: "Error occured while loading Company Details",
    DocumentReviewModel: "Error occured while loading Review Documents",
    RequestDocumentError: "Error occurred while requesting Tax Document model",
    RequestClientType: "Error occurred while fetching the Client Type",
    RequestKBAStatus: "Error occurred while loading Knowledge Based Authentication",
    RequestNextSigner: "Error occured while loading Next Signer Details",
    UpdateProcessInfoError: "Error occurred while updating process information",
    GetLastVisitedStepError: "Error occurred while requesting GetLastVisitedStep",
    GetCurrentStepAndRoleError: "Error occurred while requesting GetCurrentStepAndRole",
    ChangeMobileNumberError: "Unable to update the Mobile Number",
    UploadDocumentError: "Error occurred while processing uploaded document",
}

export const MyAccountConstants = {
    StatusMessage: {
        EditSpouseDetails: 'Spouse details updated successfully',
        EditTaxpayerDetails: 'Taxpayer details updated successfully',
        EditMobileNumber: 'Mobile number updated successfully',
    },
    WarningMessage:
    {
        InvalidEmailAddress: 'Please enter valid email address'
    }
}

export const MyDownloadsConstants = {
    StatusMessage: 'Download process may take some time, the downloaded <FILENAME> will be available in "Downloads"',
    DeleteMyDownload: 'Selected File has been deleted',
    ClearAllMyDownloads: 'All Downloaded files has been deleted',
    DeleteSingleFileWarning: 'File cannot be deleted, since Download is in progress.',
    DialogBox: {
        ConfirmDeletionSingle: 'Are you sure you want to delete this file?',
        ConfirmDeletionAll: 'Are you sure you want to clear all files?',
        Confirm: 'Yes',
        Cancel: 'Cancel',
        Title: 'Confirm Deletion'
    }
}

export const ValidationContants = {
    PhoneNumberWarning: 'Please enter the phone number.',
    PhoneNumberLengthWarning: 'Please enter a 10 digit phone number.',
}

export const Common = {
    Wizard: {
        PreviousButtonLabel: "Back ",
        NextButtonLabel: "Continue "
    },
    DialogBox: {
        ConfirmationTitle: "Confirmation",
        WarningTitle: "Warning",
        Cancel: "Cancel",
        Confrim: "Yes"
    },
    Images: {
        download: SvgIconDownloadAll,
        SummaryStepIcon: SvgIconSummaryWizardActive,
        ReviewStepIcon: SvgIconReviewWizardActive,
        SignStepIcon: SvgIconSignWizardActive,
        PayStepIcon: SvgIconMakePaymentsWizardActive,
        DistributeStepIcon: SvgIconDistributeK1sWizardActive,
        SendElectronically: SvgIconSendElectronically,
        SendManually: SvgIconSendManually,
        ContactIcon: SvgIconContactHeader,
        CalendarIcon: SvgIconCalendarHeader,
        HeaderAccountIcon: SvgIconAccountHeader,
        ElectronicalSignIcon: SvgIconEsign,
        ManuallySignIcon: SvgIconManualSignature
    },
    Routes: {
        Organizer: "/organizer/sign/:clientId",
        CustomQuestions: "/customQuestions/:clientId",
    }
}

export const OrganizerUploadedDocuments = {
    ModalTitle: "Add Source Documents",
    DownloadDocumentTitle: "Please download documents to continue",
    DownloadDocumentButtonText: "Download documents requiring signature",
    UploadDocumentTitle: "After manually signing documents please select a method to send them to your tax preparer",
    UploadDocumentSecondaryTitle: "Upload Signed Documents",
    SendByMailText: "Send by Mail",
    SendByFaxText: "Send by Fax",
    FileDeleteConfirmation: "Are you sure you want to delete the file ?",
    DownloadEfileLink: "/api/Download/GetManualSignDocument/",
    UploadAreaButtonText: "Click to Upload",
    UploadAreaText: "Drag documents here",
    UploadAreaMinimizedText: "Drag documents here or click here to upload",
    UploadAreaTextSeperation: "Or",
    UploadNotAllowedMessage: "Upload not allowed in preview mode",
    SupportedFilesMessage: "Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.",
    ConfirmMessage: "All document(s) have been successfully uploaded and will be forwarded to <COMPANYNAME>. Do you want to procced?",
    UploadInProgressMessage: "The file upload is in progress. The window can be closed after all documents have finished uploading.",
    UploadInProgressOnNotifyMessage: "The file(s) upload is in progress.",
    UploadInProgressOnCompleteMessage: "The file upload is in progress. You can complete after all documents have finished uploading.",
    UploadInProgressOnBackMessage: "The file upload is in progress. Please wait until all documents have finished uploading.",
    UploadInProgressMessageTitle: "Confirmation",
    CompleteDocumentUploadBtnText: "Save & Notify CPA",
    NotifyToCPAPendingCloseButtonDisabledTooltip: "Please click 'Save & Notify CPA' to finish the upload.",
    ModalBodyText: " Please upload all of your corresponding tax documents here. To submit your documents, click Finish. Once you click Finish, you cannot upload additional documents. If you prefer to upload additional documents later, click Continue Later and return to this page at another time.",
    ModelSourceDocumentFooterInfo: "Documents uploaded can be viewed/downloaded from Summary -> Source document page",
    SupportedFilesSizeMessage: 'Please upload a file size greater than 0kb.',
    MaximumFileNameLengthCharactersMessage: "* Maximum file name length 200 characters",
    SourceDocumentUploadWarningmsg: "Encrypted files, password-protected, or locked documents are not supported.",
    SupportedUploadFileSizeWarningMessage: "Uploaded file(s) is too large. Please upload file(s) less than 500MB."
}

export const K1EngagementType = {
    E1065: "Partners",
    E1120S: "Shareholders",
    E1041: "Beneficiaries"
}

export const K1ShareHolderConstants = {
    DownloadAllK1Link: "/api/Download/GetAllK1DocumentAsync/",
    WarningMessage:
    {
        EmailAdrressWarning: 'Please enter email address.',
        InvalidEmailAddress: 'Please enter valid email Address.',
        CheckBoxSelect: 'Please select atleast one',
        EmailAdrressEmpty: 'Email address can not be blank',
        EntityTypeWarning: 'Please select entity type',
        K1DistributionWarning: 'Are you sure you would like to continue without distributing your K-1’s',
        Cancel: 'No, Return',
        Confirm: 'Yes, I\'ll do it later'
    },
    SuccessMessage:
    {
        emailUpdated: 'Email address updated successfully',
        detailsUpdated: 'K1 details updated successfully',
        emailSent: 'Email sent successfully'
    }
}

export const OrganizerStatusChangedWarning = "Changes have been made to your document. Please refresh the page."

export const SessionKey = {
    RefreshPopUpOpen: "IsRefreshPopUpOpen"
}

export const SignProcessConstants = {
    ProcessRecoveryConfirmation: "Organizer Process Recovery Confirmation",
    ProcessRecoveryMessage: "Would like to resume where you left off or start over ?",
    ProcessRecoveryConfirmationDialogLeftButton: "Resume",
    ProcessRecoveryConfirmationDialogRightButton: "Start over",
}

export const CompletedSummaryConstant = {
    SpouseReminderAlertMessage: "Spouse electronic signature required to file taxes",
    TaxpayerReminderAlertMessage: "Taxpayer electronic signature required to file taxes",
    InstructionText: "You can access and manage all of your tax documents by selecting an option below",
    SecondSignerAlertMessage: "You are done, we are awaiting <SIGNERNAME> signature",
    CompletedMessage: "All required steps for your <TAXYEAR> returns are Completed!",
    WelcomeMessage: "Welcome <TAXPAYERNAME>",
    SendSigningReminderSuccess: "Signing Reminder has been successfully sent",
    SendSigningReminderFailure: "Failed to send the Signing Reminder",
    Images: {
        exclaimation: './dist/assets/images/Exclamation-Circle.svg',
        ViewDocument: SvgIconDownloadDocumentsSummary,
        ViewDownloadSignedDocument: SvgIconSignDocumentsSummary,
        MakeTaxPayment: SvgIconTaxPaymentsSummary,
        PrintFiledReturn: SvgIconPrintAndSend,
        PayTaxPrepFee: SvgIconPayTaxPrep,
        DistributeK1: SvgIconDistributeK1sSummary
    },
    ImageCaption: {
        ViewDownloadDocument: 'View or Download Tax Documents',
        ViewDownloadSignedDocument: 'View or Download Signed Documents',
        MakeTaxPayment: 'Make Tax Payments',
        PrintFiledReturn: 'Print & Mail Paper Filed Returns',
        PayTaxPrepFee: 'Pay Tax Preparation Fees',
        DistributeK1: 'Distribute K-1s'
    }
}

export const PayConstants = {
    VoucherSettingUpdated: "Payment Voucher Notification Settings Updated Successfully.",
    ErrorVoucherSettingUpdated: "Error occurred while updating payment Voucher document settings"
}

export const WelcomeSummaryConstants = {
    InstructionText: "Please follow these easy steps so your taxes can filed.",
    WelcomeMessage: "Your <TAXYEAR> tax returns are ready!",
}

export const PathConstants = {

    DistributeK1: "/distributeK1/",
    DistributeK1Electronically: "/distributeK1/electronic/",
    DistributeK1Manually: "/distributeK1/manual/",
    CompletionWizard: "/signcompleted/",
    Pay: "/pay/",
    ManualSign: "/manualsign/",
    OrganizerSignFlow: "/organizer/",
    PreviewFlow: "/previewflow/",
    Download: "/download/"
}

export const Permissions = {
    Read: "Read",
}

export const KBAConstants = {
    KBARetryExceedMessage: "As per IRS regulations after three failures at  Identity Verification you must manually sign Form(s) 8879",
    KBAFailureMessage: "The system is unable to establish your identity. Fetching new set of questions."
}

export const OrganizerConstants = {
    FinishConfirmationTitle: "Confirm Completion",
    FinishConfirmationMessage: `Are you sure you've completed answering all of the information pertaining to you and this tax year ?`,
    FinishSendForReviewMessage: `Please ensure you've entered all your information. After you click "Finish," the Organizer will be marked as complete, preventing further changes by you or your spouse.
                                 <br/><br/>You can send the Organizer to your spouse for review using the "Send to My Spouse" option. However, the Organizer won't be marked as complete until the "Finish" button is clicked.`,
    FinishConfirmationConfirmText: "Finish",
    FinishConfirmationCancellText: "Review Missing Information",
    FinishSendForReviewText: "Send to My Spouse",
    ReviewBySecondSignerPopup: {
        Title: "Confirm Organizer Completion",
        Mesage: "{0} has completed organizer. Kindly confirm Organizer completion.",
        ReviewButton: "Review missing information",
        ConfirmButton: "Organizer Complete"
    },
    SignCompletePageURL: "/signingcomplete/",
    OrganizerSummaryPageURL: "/organizer/",
    OrganizerSignPageURL: "/Organizer/sign/",
    EngagementLetterSignPageURL: "/engagementletter/sign/",
    PreviewURL: "/previewflow/",
    ClientViewURL: "/clientView/",
    CustomQuestionsURL: "/customQuestions/",
    SignDataSavedMessage: "Successfully saved Organizer Data",
    SignDataSavedMessageTitle: "Success",
    RedirectToSummaryPageText: "Go to Summary Page",

    SummaryPageSecondaryMessage: "Please follow these easy steps",
    SummaryPageSignEngagementLetterText: "Sign Documents",
    SummaryPageCompleteOrganizerText: "Complete Organizer",
    SummaryPageCustomQuestionsText: "Custom Questionnaire",
    SummaryPageDownloadEngagementLetterText: "Download Signed Documents",
    SummaryPageDownloadOrganizerText: "Download Organizer",
    SummaryPageUploadDocumentsText: "Upload Documents",
    SummaryPageCompletedUploadDocumentsText: "View & Download Documents",
    SummaryPageContinueButtonText: "Click Here to Continue",
    SummaryPageDeclinedEngagementLetterText: "Engagement Letter Declined to Sign",
    SummaryPageCustomQuestionsCompletedText: "Custom Questionnaire Completed",
    SummaryPageSignEngagementLetterTextForSecondSigner: "Awaiting first signer to complete",

    RedirectConfirmationMessage: "Your unsaved edits will be discarded, Are you sure ?",
    DownloadOrganizerFileName: "Organizer.pdf",
    SpouseInfoUpdateModalTitle: "Spouse's Email Confirmation",
    SpouseInfoUpdateModalBodyText: "Kindly provide your spouse's email address, which can be the same as yours in case you share an email account. The recipient will receive a request to review the Organizer.",
    IncompleteCQCompleteOrganizerDisbaled: "Please complete the custom questionnaire before accessing the fillable organizer.",
    EsignPendingCQDisabled: "Please complete all signing documents before you start filing custom questionnaire.",
    EsignPendingCompleteOrgDisabled: "Please complete all signing documents before you start filing organizer.",
    EsignPendingUploadDocumentDisabled: "Please complete all signing documents before you start uploading documents.",
    CustomQuestionsCompleteCQDisabled: "You can download your filled custom questionnaire by downloading the fillable organizer."
}

export const EngagementLetterConstants = {
    FinishConfirmationTitle: "Engagement Letter Complete",
    FinishConfirmationMessage: `Are you sure ?`,

    FinishConfirmationConfirmText: "Yes",
    FinishConfirmationCancellText: "cancel",

    SigningCompletedMessage: "Successfully signed Engagement letter, You will be redirected to Organizer page",

    UploadingSignatureMessage: "Uploading Signature ...",
    SubmitDocumentMessage: "Saving Sign info ...",

    SpouseInfoUpdateModalTitle: "Spouse's Signature Required",
    SpouseInfoUpdateModalBodyText: "Please provide an email address for your spouse. It may be the same as your own if you share an email account. The recipient will receive a similar request asking them to Sign their forms electronically.",

    SpouseNameTitle: "Spouse's Name : ",
    SpouseEmailTitle: "Email Address ",

    SpouseEmailValidationError: "Enter a valid email address.",

    DeclineModalBodyText: "If you select CONTINUE, this document will be voided and inaccessible to other signers.",

    DeclineRemarksTextboxLabel: "Please enter the reason for declining below:",
    DeclineRemarksModalTitle: "Decline Engagement Letter",
    DownloadEngagementLetterFileName: "EngagementLetter.pdf"
}

export const PreviewConstants = {
    GeneratingPreviewMessage: "Generating Document Preview. Please wait..",
    GeneratingPreviewTimeLimitExceededMsg: "Generating Document Preview is taking longer than expected. Please try again",
    GeneratingPreviewFailedMessage: "Generating Document Preview failed. Please try again",
    IsPreviewMode: "IsPreviewMode",
    IsClientView: "IsClientView",
    DeleteDocumentMessage: "Document deletion is not allowed in Client View",
    FinishMessage: "Finish is not allowed in Client View/Preview.",
    DownloadMessage: "Document is not available for download in ClientView/Preview",
    PreviewNotSupported: "Preview unavailable. Unsupported file format.",
}

export const SourceDocumentConstants = {
    Uploaded: "Uploaded",
    Added: "Added",
    Deleted: "Deleted",
    IsSourceDocumentEnabled: 'IsSourceDocumentEnabled',
    UploadNotAllowedMessage: "Upload not allowed in preview mode",
    NoSourceDocuments: "No Source documents uploaded.",
    NoFilesSelected: "No files selected."
}

export const CoverPageConstants = {
    SuccessMessage: {

    },
    ErrorMessage: {
        clientFetchFailed: "Unable to fetch client Data, Please try again",
        serverError: "Internal Server Error,Please try again"
    }
}

export const DisplayError = [
    "ERROR_DELETED_RETURN",
    "ERROR_RECALLED_RETURN",
    "ERROR_INVALID_LINK",
    "OTP_LOCKED_MESSAGE",
    "SSN_LOCKED_MESSAGE",
    "SSN_LOCKED",
    "OTP_LOCKED",
    "RETURN_DECLINED_FOR_SIGN",
    "ERROR_INVALID_OTP"
];

export const OTPPageConstants = {
    SuccessMessage: {
        OTPGenerateSuccess: "A one-time access code was sent to your email."
    },
    ErrorMessage: {
        lockedByOTP: "Maximum retry reached for access code",
        serverError: "Internal Server Error, Please try to refresh",
        OTPGenerateFailed: "Unable to generate access code, please try again",
        OTPExpired: "Entered access code has expired",
        OTPError: "Please enter a valid access code",
    }
}

export const OTPMobilePageConstants = {
    SuccessMessage: {
        OTPGenerateSuccess: "A one-time access code was sent to you via a text message."

    },
    ErrorMessage: {
        lockedByOTP: "Maximum retry reached for access code",
        serverError: "Internal Server Error, Please try to refresh",
        OTPError: "Please enter a valid access code",
        OTPGenerateFailed: "Unable to generate access code, please try again",
    }
}

export const HeaderConstants = {
    ErrorMessage: {
        RetrieveFailed: "Unable to fetch Details, Please Refresh and try again"
    }
}

export const SignProcessInfoConstants = {
    SignProcessInfo: "SignProcessInfo",
    LastVisitedPage: "LastVisitedPage"
}

export const AdditionalEsignDocumentTypes = {
    'ConsentForm': 'Consent Form',
    'Additional_EngagementLetter': 'Additional Engagement Letter',
    'Other': 'Other'
}

export const AdditionalEsignAdjustments = {
    CheckBoxTopAdjustment: 13,
    ControlItemLeftAdjustment: 30,
    PdfViewerTopAdjustment: 10
}

export const AutoSave = {
    Success: "Autosave successful",
    Failure: "Autosave failed"
}

export const SupportedFilePreviewTypes = ['.pdf', '.xlsx', '.jpeg', '.png', '.docx', '.jpg']

export const TaxPayerPage = {
    CompleteOrganizerPage: 'Complete Organizer Page',
    UploadDocumentsPage: 'Upload Source Documents Page',
    AddSourceDocumentsPopup: 'Add Source Documents Popup from Complete Organizer Page',
    FinishButton: 'Finish',
    ContinueLaterButton: 'Continue Later'
}

export const FormPreviewConstants = {
    RequiredValidation: "This field is required",
    ParagraphTypeQuestion: {
        placeholder: "Answer goes here",
        maxLength: 3250
    },
}

export const ValidationModalConstants = {
    ModalTitle: "Incomplete Mandatory Question(s)",
    ModalBodyText: "To proceed with your submission, kindly provide the missing information for the following questions",
    FORM_VALIDATION_ERROR: "Please fill in the required fields",
}

export const CustomEventLog = {
    UpdateSpouseEMail: "Organizer updated spouse email",
    OrganizerCompleted: "Organizer already completed",
    submitSignedDocumentSaved: "Organizer saved",
    finishDocumentSign: "Organizer finished",
    AddNotes: "FormNotes added",
    DeleteNotes: "FormNotes deleted",
    OrganizerSFR: "Organizer send for review",
    OrganizerReviewed: "Organizer reviewed",
    OrganizerReviewIgnored: "Organizer review ignored",
    EngagementLetterDeclined: "Engagement letter declined",
    EngagementLetterSkipped: "Engagement letter skipped",
    EngagementLetterSigned: "Engagement letter signed",
    PreviewSourceDocument: "Preview Source Document",
    DeleteSourceDocument: "Delete Source Document"
}

export const CustomQuestion = {
    CQCompleted: "This document has been completed. Please refresh the page."
}

export const UploadFileWarningMessage = (fileCount: number, totalFileCount: number, warningMessage: string) => `Unable to upload ${fileCount} out of ${totalFileCount} file(s), ${warningMessage}`;