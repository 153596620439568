import * as React from 'react';
import { OrganizerUploadedDocuments } from '../../Common/Constants';

interface UploadHeaderProps {
    minimized?: boolean;
    disabled?: boolean;
}

export const UploadHeader: React.FunctionComponent<UploadHeaderProps> = ({
    minimized,
    disabled
}) => {

    let fileUploadArea = () => {

        return (minimized ? (<div data-test-auto="609FAE9E-6540-469A-BA40-922DC11D1E68" className={"filepicker upload-area minimized"}>
            <div><h6 className="Tablet-h6">{OrganizerUploadedDocuments.UploadAreaMinimizedText}</h6></div>
        </div>) : (<React.Fragment><div className={"filepicker upload-area"}
            data-test-auto="FB2FA195-8F1A-4422-964D-3921950C8F8D">
                <div><h5 className="Tablet-h5"> {OrganizerUploadedDocuments.UploadAreaText}</h5></div>            
                <div><h6 className="Tablet-h6"> {OrganizerUploadedDocuments.UploadAreaTextSeperation} </h6></div>
                <div><button className={disabled ? "disable-btn-ssr btn-tablet" : "btn-ssr btn-tablet"} data-test-auto={"C113515F-B901-4B2C-8DC2-E7F3F3ECCD80"} disabled={disabled} >
                    {OrganizerUploadedDocuments.UploadAreaButtonText}
            </button>
            </div>
        </div>

            {/* <div className={"filepicker upload-area d-sm-block d-md-none"} data-test-auto="FB2FA195-8F1A-4422-964D-3921950C8F8D">
                    <div><span>{OrganizerUploadedDocuments.UploadAreaMinimizedText}</span></div>
            </div> */}
        </React.Fragment>));
    }


    return (<div className={"dz-open-file-browse"} style={disabled ? { pointerEvents: "none" } : {}}>
        {fileUploadArea()}
    </div>);
};