import { FormGroupStatus } from 'src/core/domain/models/Organizer/Organizer';
import { actionTypes } from '../ActionTypes';

interface UpdateStatusAction {
    type: actionTypes.DOCUMENT_STATUS_UPDATED;
    payload: FormGroupStatus; // Adjust type if needed
}

type SignalRActions = UpdateStatusAction;

export interface OrganizerState {
    organizerStatus: FormGroupStatus;
}

const initialState: OrganizerState = {
    organizerStatus: FormGroupStatus.None,
};

export const actionCreators = {
    updateSignalROrganizerStatus: (status: FormGroupStatus): UpdateStatusAction => ({
        type: actionTypes.DOCUMENT_STATUS_UPDATED,
        payload: status
    })
};

const signalRReducer = (
    state = initialState,
    action: SignalRActions
): OrganizerState => {
    switch (action.type) {
        case actionTypes.DOCUMENT_STATUS_UPDATED:
            return {
                ...state,
                organizerStatus: action.payload
            };
        default:
            return state;
    }
};

export default signalRReducer;