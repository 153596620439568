import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { StatusCode } from '../../core/domain/models/Organizer/Organizer';
import { actionTypes } from '../ActionTypes';
import { NotificationAction } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { ILoader } from '../../core/utilities/ui/Loader';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { OrganizerMetadata } from '../../core/domain/models/Organizer/Organizer';
import { logger } from '../../routes';
import { initializeAxios } from '../../core/Services/dataAccess/DataService.Axios';


interface RequestMetadataAction {
    type: actionTypes.ORGANIZER_METADATA_REQUEST;
}

interface RequestCacheMetadataAction {
    type: actionTypes.ORGANIZER_METADATA_CACHE;
    data: OrganizerMetadata;
}

interface ResponseMetadataAction {
    type: actionTypes.ORGANIZER_METADATA_RESPONSE;
    data: OrganizerMetadata;
}

interface FailureMetadataAction {
    type: actionTypes.ORGANIZER_METADATA_FAILURE;
    data: OrganizerMetadata;
}

export interface OrganizerMetadataState {
    organizerMetadata: OrganizerMetadata;
}

export const initialOrganizerMetadataState: OrganizerMetadataState = {
    organizerMetadata: OrganizerMetadata.createNullObject(),
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    ResponseMetadataAction
    | RequestMetadataAction
    | FailureMetadataAction
    | RequestCacheMetadataAction

const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {

    requestOrganizerMetadata: (clientGuid: string, successCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();

        return initializeAxios().get<OrganizerMetadata>('/api/Document/GetOrganizerMetadata/' + clientGuid)
            .then(function (response: AxiosResponse<OrganizerMetadata>) {
                successCallback && successCallback();
                dispatch({
                    type: actionTypes.ORGANIZER_METADATA_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                if (error?.response?.status === StatusCode.OrganizerUnavailable) {
                    return;
                }
                dispatch({ type: actionTypes.ORGANIZER_METADATA_FAILURE, data: state.organizerMetadata });
                logger.trackError(`requestOrganizerMetadata failed to push the data with error ${error.message}`)
            });
    },
}

export const reducer: Reducer<OrganizerMetadataState> = (state: OrganizerMetadataState = initialOrganizerMetadataState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.ORGANIZER_METADATA_REQUEST:
            currentState.organizerMetadata = OrganizerMetadata.createNullObject();
            return currentState;
        case actionTypes.ORGANIZER_METADATA_RESPONSE:
        case actionTypes.ORGANIZER_METADATA_CACHE:
            currentState.organizerMetadata = action.data;
            return currentState;
        case actionTypes.ORGANIZER_METADATA_FAILURE:
            currentState.organizerMetadata = action.data
            return currentState;
        default:
            return currentState || initialOrganizerMetadataState;
    }
}; 