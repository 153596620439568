import { connect } from 'react-redux';
import { SignerDocumentModel, ClientInfo, UploadMethod } from '../../core/domain/models/Organizer/Organizer';
import { ApplicationState } from "../../store/index";
import { actionCreators as EngagementDocumentStore } from '../../store/Organizer/EngagementDocumentStore';
import { actionCreators as OrganizerClientStore } from '../../store/Organizer/OrganizerClientStore';
import { createLoadingSelector } from '../../store/selectors';
import { EngagementLetterSign } from "./EngagementLetterSign";
import { actionCreators as OrganizerMetadataStore } from '../../store/Organizer/OrganizerMetadataStore';

const loadingSelector = createLoadingSelector(['HEADERINFO', 'SUMMARY_DETAILS',
    'TAXING_AUTHORITY', 'DOCUMENT_REVIEW_MODEL', 'DOCUMENT_SETTINGS']);

function mapStateToProps(state: ApplicationState) {
    return {
        engagementDocument: state.engagementDocument,
        clientInfo: (state.clientInfo as any).data,
        organizerMetadata: (state.organizerMetadata as any).organizerMetadata
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {

        requestEngagementDocuments: (clientId: string) => dispatch(EngagementDocumentStore.requestEngagementDocuments(clientId)),

        decline: (clientId: string,
            remarks: string,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(EngagementDocumentStore.decline(clientId,
                remarks,
                successCallback,
                failureCallback)),

        skipEngagementSign: (clientId: string,
            signedDocument: SignerDocumentModel,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(EngagementDocumentStore.skipEngagementSign(clientId,
                signedDocument,
                successCallback,
                failureCallback)),

        submitSignedDocument: (clientId: string,
            signedDocument: SignerDocumentModel,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(EngagementDocumentStore.submitSignedDocument(clientId,
                signedDocument,
                successCallback,
                failureCallback)),

        requestSpouseInfo: (clientId: string, forceRefresh?: boolean) => dispatch(OrganizerClientStore.requestSpouseInfo(clientId, forceRefresh)),
        updateSpouseEmail: (clientId: string,
            clientInfo: ClientInfo,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerClientStore.updateSpouseEmail(clientId,
                clientInfo,
                successCallback,
                failureCallback)),
        requestOrganizerMetadata: (clientId: string) => dispatch(OrganizerMetadataStore.requestOrganizerMetadata(clientId)),
        getAdditionalFileDownLoadLink: (clientId: string, fileGuid: string, documentId: number, fileName: string, uploadMethod: UploadMethod) => dispatch(EngagementDocumentStore.getAdditionalFileDownLoadLink(clientId, fileGuid, documentId, fileName, uploadMethod)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EngagementLetterSign);
