import { IHeaderInfoViewModel } from "src/core/domain/viewModels/IHeaderInfoViewModel";
import * as LoaderStore from "../store/Common/LoaderStore";
import * as NotificationStore from "../store/Common/NotificationStore";
import * as AccountStore from "../store/Common/AccountStore";
import * as HelperStore from "../store/Common/HelperStore";
import { ICommonDataViewModel } from "../core/domain/viewModels/ICommonDataViewModel";
import * as OTPstore from "./OTP/OTPStore";
import * as OrganizerStore from "../store/Organizer/OrganizerStore";
import * as OrganizerMetadataStore from "../store/Organizer/OrganizerMetadataStore";
import * as EngagementDocumentStore from "../store/Organizer/EngagementDocumentStore";
import * as OrganizerClientStore from "../store/Organizer/OrganizerClientStore";
import * as EsignStore from "../store/Common/EsignStore";
import * as PreparerMessageStore from "../store/Organizer/PreparerMessageStore";
import * as CompanyStore from "../store/Common/Company/CompanyStore";
import * as TaxDocumentStore from "../store/Common/TaxDocumentStore";
import {
	BlobForm,
	ClientInfo,
	OrganizerMetadata,
	SignerDocumentModel,
} from "../core/domain/models/Organizer/Organizer";
import { IKBATransactionResponse } from "../core/domain/models/IKBA";
import * as PreviewStore from '../store/Organizer/PreviewStore';
import { UploadedDocumentStoreState } from "./UploadedDocument/UploadedDocumentStoreModels";
import { reducer as UploadedDocumentReducer } from './UploadedDocument/UploadedDocumentStore';
import { IQuestionnaireState } from "../core/domain/models/Organizer/CustomQuestionModel";
import { reducer as customQuestionStore } from './CqPreview/CustomQuestionStore';
import { IDownloadableDocumentsViewModel } from "../core/domain/viewModels/IDownloadableDocumentsViewModel";
import signalRReducer, { OrganizerState } from "./SignalR/SignalRStore";
// The top-level state object
export interface ApplicationState {
	notification: NotificationStore.NotificationState;
	headerInfo: IHeaderInfoViewModel;
	loader: LoaderStore.IRequestStatusDictionary;
	downloadableDocuments: IDownloadableDocumentsViewModel;
	commonData: ICommonDataViewModel;
	otpDetails: OTPstore.OTPState;
	organizerMetadata: OrganizerMetadata;
	engagementDocument: SignerDocumentModel;
	clientInfo: ClientInfo;
	organizerDocument: SignerDocumentModel;
	blobForms: BlobForm[];
	esignData: EsignStore.EsignState;
	kbaData: IKBATransactionResponse;
	preparerMessage: PreparerMessageStore.OrganizerPreparerMessageState;
	companyData: CompanyStore.CompanyState;
	taxReturn: TaxDocumentStore.TaxDocumentState;
	previewStatus: PreviewStore.PreviewState;
	uploadedDocumentStore: UploadedDocumentStoreState;
	customQuestionStore: IQuestionnaireState;
	signalROrganizerState: OrganizerState
}

export const reducers = {
	notification: NotificationStore.reducer,
	headerInfo: AccountStore.reducer,
	commonData: HelperStore.reducer,
	loader: LoaderStore.reducer,
	otpDetails: OTPstore.reducer,
	organizerMetadata: OrganizerMetadataStore.reducer,
	organizerDocument: OrganizerStore.reducer,
	clientInfo: OrganizerClientStore.reducer,
	engagementDocument: EngagementDocumentStore.reducer,
	esignData: EsignStore.reducer,
	kbaData: EsignStore.kbaReducer,
	preparerMessage: PreparerMessageStore.reducer,
	companyData: CompanyStore.reducer,
	taxReturn: TaxDocumentStore.reducer,
	previewStatus: PreviewStore.reducer,
	uploadedDocumentStore: UploadedDocumentReducer,
	customQuestionStore: customQuestionStore,
	signalROrganizerState: signalRReducer
};

export interface AppThunkAction<TAction> {
	(
		dispatch: (action: TAction | AppThunkAction<TAction>) => void,
		getState: () => ApplicationState
	): void;
}
