import * as React from 'react';

interface FooterProps {
    onSave(): void;
    onFinish(): void;
    onGoBack(): void;
    currentPage: number;
    viewPanel: any;
    finishEnabled?: boolean;
}
const leftArrowClassName = "fas fa-chevron-circle-left footer-navigation-button";
const rightArrowClassName = "fas fa-chevron-circle-right footer-navigation-button";
const arrowDisabledClassName = "footer-navigation-button-disabled";

export const Footer: React.FC<FooterProps> = (props) => {

    const { currentPage, viewPanel, finishEnabled } = props;
    const [totalPages, setTotalPages] = React.useState<number>();

    const handleNextPage = () => {
        if (currentPage !== totalPages) {
            viewPanel && viewPanel.gotoPage(currentPage + 1);
        }
    }

    const handlePrevPage = () => {
        if (currentPage !== 1) {
            viewPanel && viewPanel.gotoPage(currentPage - 1);
        }
    }

    React.useEffect(() => {
        if (viewPanel && (!totalPages || totalPages === 0)) {
            setTotalPages(viewPanel.getTotalPages())
        }
    }, [viewPanel, currentPage])

    return (<footer>

        {/*<a className="rd-nav-link btn-decline">
                <button type="submit" onClick={this.props.onGoBack} className="btn btn-default btn-default-extended btn-login text-green"
                    id="btnDecline"><i className={"fa fa-angle-left"}></i> Back</button>
            </a>*/}
        <div className="footer-navigation-buttons-container">
            <i className={currentPage === 1 ? `${leftArrowClassName} ${arrowDisabledClassName}` : leftArrowClassName} onClick={handlePrevPage}></i>
            <i className={currentPage === totalPages ? `${rightArrowClassName} ${arrowDisabledClassName}` : rightArrowClassName} onClick={handleNextPage}></i>
        </div>
        <a className="rd-nav-link btn-finish">
            <button data-test-auto='3c77ce7a-bbe6-11eb-8529-0242ac130003'
                type="submit" onClick={props.onFinish} disabled={!finishEnabled}
                className="btn btn-primary btn-primary-extended btn-login pull-right btn-tablet-width-100 mt-6"
                id="btnFinish">Finish</button>
        </a>

        <a className="rd-nav-link btn-finish">
            <button data-test-auto='3c77d186-bbe6-11eb-8529-0242ac130003'
                type="submit" onClick={props.onSave}
                className="btn btn-primary btn-primary-extended btn-login pull-right btn-tablet-width-100 mt-6"
                id="btnContinue">Save & Continue Later</button>

        </a>

    </footer>);
}
