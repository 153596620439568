import { initializeAxios } from "src/core/Services/dataAccess/DataService.Axios";
import { container } from "src/startup/inversify.config";
import { TYPES } from "src/startup/types";
import { ILoader } from "src/core/utilities/ui/Loader";
import { AxiosResponse } from "axios";
import { ISignalRConnectionInfo } from "src/core/common/model/SignalRConnectionInfo";
import { HeaderConstant, SignalRConstant } from "src/core/common/SignalRConstant";


const loader = container.get<ILoader>(TYPES.ILoader);

export const getWebSocketConnectionInfo = (
    clientGuid: string,
    callback: (data: any) => void
): Promise<void> => {
    loader.show();
    const url = `/api/WebSocket/GetWebSocketConnectionInfo/${clientGuid}`;

    return initializeAxios().get<any>(url)
        .then((response: any) => {
            callback(response.data);
        })
        .catch((error: any) => {
            // Log the error message if needed
            console.error('Error fetching WebSocket URL:', error);
        })
        .finally(() => {
            loader.hide();
        });
};

export const signalRConnectionInit = (
    user: string,
    apiBaseUrl: string,
    token: string,
    callback: (data: ISignalRConnectionInfo) => void) => {
    var userIdHeader = SignalRConstant.headers.userId.toString();
    var clientType = SignalRConstant.headers.clientType.toString();
    let Authorization = HeaderConstant.Authorization;
    let Bearer = HeaderConstant.Bearer;
    var axios = initializeAxios();
    axios.set(apiBaseUrl);//set signal R base url
    var config = {
        "headers": {
            [userIdHeader]: user,
            [clientType]: SignalRConstant.TP,
            [Authorization]: `${Bearer} ${token}`
        }
    };

    axios.post(`/signalr/negotiate`, '', config)
        .then((response) => response)
        .then(function (response: AxiosResponse<ISignalRConnectionInfo>) {
            if (callback) {
                callback(response.data);
            }
        })
        .catch((error: any) => {
        });
}

export const addGroup = (user: string, group: string, apiBaseUrl: string, token: string) => {
    var userIdHeader = SignalRConstant.headers.userId.toString();
    var clientType = SignalRConstant.headers.clientType.toString();
    let Authorization = HeaderConstant.Authorization;
    let Bearer = HeaderConstant.Bearer;
    var data = JSON.stringify({
        recipient: user,
        groupname: group
    })
    var axios = initializeAxios();
    axios.set(apiBaseUrl);
    var config = {
        "headers": {
            [userIdHeader]: user,
            [clientType]: SignalRConstant.TP,
            [Authorization]: `${Bearer} ${token}`
        }
    };
    axios.post(`/signalr/AddToGroup`, data, config)
        .then((resp: any) => {
            if (resp.status == 200) {
                console.log("User added to the group successfully")
            }
        })
        .catch((error: any) => {
        });
}
